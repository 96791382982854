import { InjectionToken, inject } from '@angular/core';
import { Auth0Client, AuthorizationParams } from '@auth0/auth0-spa-js';

import {
    SharedSessionCookieStorageService
} from '../../../feature-modules/auth/services/cookie/shared-session-cookie-storage.service';

import { AuthClientConfig } from './types/sso-auth-client.config';
import { IAuthConfig } from './types/sso-auth-config.interface';
import useragent from './types/sso-useragent';

export class Auth0ClientFactory {
    static createClient(configFactory: AuthClientConfig): Auth0Client {
        const config: IAuthConfig = configFactory.get();

        if (!config) {
            throw new Error(
                'Configuration must be specified either through AuthModule.forRoot or through AuthClientConfig.set'
            );
        }

        // eslint-disable-next-line @typescript-eslint/typedef
        const { redirectUri, clientId, maxAge, httpInterceptor, ...rest } = config;

        const organisationIdFromCookies: string = inject(SharedSessionCookieStorageService).getOrganisationId();

        const authorizationParams: AuthorizationParams = {
            redirect_uri: redirectUri,
        };

        if (organisationIdFromCookies !== null) {
            authorizationParams.organization = organisationIdFromCookies;
        }

        return new Auth0Client({
            authorizationParams,
            clientId,
            ...rest,
            auth0Client: {
                name: useragent.name,
                version: useragent.version,
            },
        });
    }
}

// tslint:disable-next-line:naming-convention
export const Auth0ClientService: InjectionToken<Auth0Client> = new InjectionToken<Auth0Client>(
    'auth0.client'
);
