import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CmAlertModule } from '../../../new-shared/components/cm-alert/cm-alert.module';

import { EndUserActivityViewEmailComponent } from './end-user-activity-view-email.component';


@NgModule({
    declarations: [EndUserActivityViewEmailComponent],
    imports: [CommonModule, TranslateModule, CmAlertModule, FormsModule],
    exports: [EndUserActivityViewEmailComponent],
})
export class EndUserActivityViewEmailModule {}
