import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { EllipsisModule } from '../../pipes/ellipsis/ellipsis.module';

import { ButtonComponent } from './cm-button.component';

@NgModule({
    declarations: [ButtonComponent],
    imports: [CommonModule, TranslateModule, EllipsisModule],
    exports: [ButtonComponent],
})
export class CmButtonModule {}
