import { Directive, ElementRef, Input, NgZone, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[highlightText]',
})
export class HighlightTextDirective implements OnChanges {
    @Input() searchedWords: string;
    @Input() highlightText: string;

    constructor(
        private _elementRef: ElementRef,
        private _renderer2: Renderer2,
        private ngZone: NgZone
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.ngZone.runOutsideAngular(() => {
            if (!this.searchedWords) {
                this._renderer2.setProperty(
                    this._elementRef.nativeElement,
                    'innerHTML',
                    this.highlightText
                );
    
                return;
            }
    
            this._renderer2.setProperty(
                this._elementRef.nativeElement,
                'innerHTML',
                this.getFormattedText()
            );
        });
    }

    getFormattedText(): string {
        const re: RegExp = new RegExp(`(${this.searchedWords})`, 'gi');

        if (!this.searchedWords?.length) {
            return;
        }

        return this.highlightText.replace(re, '<span class="highlight">$1</span>');
    }
}
