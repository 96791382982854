export enum AlertTypeLegacy {
    success = 'success',
    error = 'error',
    warning = 'warning',
    info = 'info',
}

export enum AlertType {
    primary = 'primary',
    secondary = 'secondary',
    success = 'success',
    danger = 'danger',
    error = 'error',
    warning = 'warning',
    info = 'info',
    light = 'light',
    dark = 'dark',
}
