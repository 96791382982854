import { Component, Input } from '@angular/core';

import { CardFooterContentPosition, CardFooterVariant } from './card-footer.enum';

@Component({
    selector: 'cm-card-footer',
    templateUrl: './card-footer.component.html',
    styleUrls: ['./card-footer.component.scss'],
})
export class CardFooterComponent {
    @Input() variant: CardFooterVariant = CardFooterVariant.default;
    @Input() contentPosition: CardFooterContentPosition = CardFooterContentPosition.left;

    cardFooterVariant: typeof CardFooterVariant = CardFooterVariant;
    cardFooterContentPosition: typeof CardFooterContentPosition = CardFooterContentPosition;
}
