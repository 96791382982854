import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { API_ENDPOINTS } from '../../../shared/constants/api/api-endpoints.constants';
import { ROUTES_PATHS } from '../../../shared/constants/routes.constants';
import { SystemsEnum } from '../../enums/systems.enum';
import {
    IPartnerSystems,
    ISystemInfo,
    ISystemsLinkage,
    ISystemURLs
} from '../../interfaces/partner-systems.types';
import { AuthorizedHttpService } from '../http/authorized-http.service';

@Injectable({
    providedIn: 'root',
})
export class ApiTryPagesService {
    constructor(private _authorizedHttpService: AuthorizedHttpService,
                @Inject(DOCUMENT) private document: Document) {}

    getPartnerAccessToSystemsInfo$(): Observable<IPartnerSystems> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.PROXY.SYSTEMS
        );
    }

    getSystemLinks(systems: ISystemInfo[]): ISystemURLs {
        const isUserOnProdDomain: boolean = this.document.location.hostname === ROUTES_PATHS.DOMAIN.CLOUD || this.document.location.hostname === ROUTES_PATHS.DOMAIN.PRE_PROD;
        const isProdEnv: boolean = environment.production && isUserOnProdDomain;
        const systemsLinkage: ISystemsLinkage = this.verifyAccessToOtherPortals(systems);

        return {
            COMMS: this.prepareCommsUrl(isProdEnv, systemsLinkage.isCommsAccLinked),
            DISTRIBUTION: this.prepareDistributionUrl(isProdEnv, systemsLinkage.isHardwareAccLinked),
            HARDWARE: this.prepareHardwareUrl(isProdEnv, systemsLinkage.isDistributionAccLinked)
        };
    }

    verifyAccessToOtherPortals(systems: ISystemInfo[]): ISystemsLinkage {
        return {
            isCommsAccLinked: systems.some((singleSystem: ISystemInfo) => singleSystem.slug === SystemsEnum.comms),
            isHardwareAccLinked: systems.some((singleSystem: ISystemInfo) => singleSystem.slug === SystemsEnum.hardware),
            isDistributionAccLinked: systems.some((singleSystem: ISystemInfo) => singleSystem.slug === SystemsEnum.distribution)
        }
    }

    private prepareCommsUrl(isProdEnv: boolean, isCommsAccLinked: boolean): string {
        const commsUrl: string = isProdEnv ? ROUTES_PATHS.EXTERNAL.COMMS_PROD : ROUTES_PATHS.EXTERNAL.COMMS_UAT;

        return isCommsAccLinked ? commsUrl : `${commsUrl}${ROUTES_PATHS.EXTERNAL.COMMS_TRY}`;
    }

    private prepareHardwareUrl(isProdEnv: boolean, isHardwareAccLinked: boolean): string {
        const hardwareUrl: string = isProdEnv ? ROUTES_PATHS.EXTERNAL.HARDWARE_PROD : ROUTES_PATHS.EXTERNAL.HARDWARE_UAT;

        return isHardwareAccLinked? hardwareUrl : `${hardwareUrl}${ROUTES_PATHS.EXTERNAL.HARDWARE_TRY}`;
    }

    private prepareDistributionUrl(isProdEnv: boolean, isDistributionAccLinked: boolean): string {
        const distributionUrl: string = isProdEnv ? ROUTES_PATHS.EXTERNAL.DISTRIBUTION_PROD : ROUTES_PATHS.EXTERNAL.DISTRIBUTION_UAT;

        return isDistributionAccLinked? distributionUrl : null;
    }
}
