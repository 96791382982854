import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnChanges,
    Output,
    Renderer2,
    SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { WINDOW } from '../../core/injection-tokens/window.injection-token';
import { IWindow } from '../../core/interfaces/window.interface';

@Directive({ selector: '[appCopyToClipboard]' })
export class CopyToClipboardDirective implements OnChanges {
    @Input('appCopyToClipboard') payload: string;
    @Input() addStylesToCopy: boolean = false;
    @Output() readonly copied: EventEmitter<string> = new EventEmitter<string>();
    private _iconEl: HTMLElement;

    constructor(
        @Inject(WINDOW) private _window: IWindow,
        private _elementRef: ElementRef,
        private _renderer2: Renderer2,
        private _translateService: TranslateService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.addStylesToCopy) {
            this._iconEl = document.createElement('i');
            this._renderer2.setAttribute(this._iconEl, 'class', 'fas fa-copy');
            this._renderer2.setAttribute(
                this._iconEl,
                'title',
                this._translateService.instant('COPY')
            );
            this._renderer2.setStyle(this._iconEl, 'position', 'absolute');
            this._renderer2.setStyle(this._iconEl, 'top', '50%');
            this._renderer2.setStyle(this._iconEl, 'transform', 'translateY(-50%)');
            this._renderer2.setStyle(this._iconEl, 'font-size', '22px');
            this._renderer2.setStyle(this._elementRef.nativeElement, 'position', 'relative');
            this._renderer2.setStyle(this._elementRef.nativeElement, 'cursor', 'pointer');
            this._renderer2.setStyle(this._elementRef.nativeElement, 'transition', 'opacity 0.25s');
        }
    }

    @HostListener('mouseenter') onMouseOver(): void {
        if (!this.addStylesToCopy) {
            return;
        }

        this._renderer2.appendChild(this._elementRef.nativeElement, this._iconEl);
        this._renderer2.setStyle(this._elementRef.nativeElement, 'font-weight', 'bold');
        this._renderer2.setStyle(this._elementRef.nativeElement, 'padding-right', '20px');
        this._renderer2.setStyle(this._iconEl, 'right', '0');
    }

    @HostListener('mouseleave') onMouseOut(): void {
        if (!this.addStylesToCopy) {
            return;
        }

        this._renderer2.setStyle(this._elementRef.nativeElement, 'font-weight', 'normal');
        this._renderer2.setStyle(this._elementRef.nativeElement, 'padding-right', '0');
        this._renderer2.removeChild(this._elementRef.nativeElement, this._iconEl);
    }

    @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
        const listener: (e: ClipboardEvent) => void = (copyEvent: ClipboardEvent) => {
            const clipboard: DataTransfer = copyEvent.clipboardData || this._window.clipboardData;
            const parentElement: HTMLElement = this._elementRef.nativeElement.parentElement;

            clipboard.setData('text', this.payload.toString());
            copyEvent.preventDefault();

            this._setTempClassName(parentElement);

            this.copied.emit(this.payload);
        };

        event.preventDefault();
        if (!this.payload) {
            return;
        }

        document.addEventListener('copy', listener, false);
        document.execCommand('copy');
        document.removeEventListener('copy', listener, false);
    }

    private _setTempClassName(parentElement: HTMLElement): void {
        const delay: number = 1000;

        if (this.addStylesToCopy) {
            const delayOpacity: number = 500;

            this._renderer2.setStyle(this._elementRef.nativeElement, 'opacity', '0.5');

            setTimeout(() => {
                this._renderer2.setStyle(this._elementRef.nativeElement, 'opacity', '1');
            }, delayOpacity);
        }

        parentElement.classList.add('copied');
        setTimeout(() => {
            parentElement.classList.remove('copied');
        }, delay);
    }
}
