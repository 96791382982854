import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ITab } from '../../interfaces/tab.interface';

import { TabsFacadeService } from './tabs.facade.service';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
    providers: [TabsFacadeService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements OnInit, OnDestroy {
    @Input() tabItems: ITab[];
    isScreenSmall$: Observable<boolean> = this._tabsFacadeService.isScreenSmall$;
    selectedTab$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    private readonly _destroyTrigger$: Subject<void> = new Subject<void>();

    constructor(private _tabsFacadeService: TabsFacadeService) {}

    ngOnInit(): void {
        this._tabsFacadeService.isScreenSmall$
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe(() =>
                this.selectedTab$.next(this._tabsFacadeService.getSelectedTabName(this.tabItems))
            );
    }

    ngOnDestroy(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }

    handleSelectedTab(name: string): void {
        this.selectedTab$.next(name);
    }
}
