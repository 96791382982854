import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { IDictionary } from '../interfaces/dictionary.interface';

@Directive({
    selector: '[gcExpand]',
})
export class GcExpandDirective implements OnInit {
    @Input() gcExpandHeight: string = 'auto';
    private _gcExpand: boolean;
    @Input() set gcExpand(value: boolean) {
        this._gcExpand = value;
        this._setStyles();
    }

    get gcExpand(): boolean {
        return this._gcExpand;
    }

    constructor(private _elementRef: ElementRef) {}

    ngOnInit(): void {
        const style: IDictionary<string | number> = this._elementRef.nativeElement.style;

        style.overflow = 'hidden';
        style.transition = 'all 0.2s ease-in-out';
    }

    private _setStyles(): void {
        const style: IDictionary<string | number> = this._elementRef.nativeElement.style;

        if (!this._gcExpand) {
            style.maxHeight = 0;
        } else {
            style.maxHeight = this.gcExpandHeight;
        }
    }
}
