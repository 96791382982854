import { Component } from '@angular/core';

import {
    ButtonAction,
    ButtonStyle,
    ICloudMarketButton,
} from '../../../../shared/interfaces/cm-button.types';
import { IRateIt } from '../../interfaces/rate-it.interface';
import { RateItPopupService } from '../../providers/rate-it-popup.service';

@Component({
    selector: 'app-rate-it-popup',
    templateUrl: './rate-it-popup.component.html',
    styleUrls: ['./rate-it-popup.component.scss'],
})
export class RateItPopupComponent {
    modalAnimation: boolean = false;
    submitButton: ICloudMarketButton = {
        Action: ButtonAction.none,
        Style: ButtonStyle.dashboard,
        Text: 'Submit',
        Data: {},
    };
    rating: any;
    comment: string = '';

    get showPopup(): boolean {
        return this._rateItPopupService.show && this._rateItPopupService.model !== null;
    }

    get model(): IRateIt {
        return this._rateItPopupService.model;
    }

    constructor(private _rateItPopupService: RateItPopupService) {}

    submitRating(): void {
        this._rateItPopupService.hidePopup();
        this._rateItPopupService.saveRateItData(this.rating, this.comment);
    }

    rateLater(): void {
        this._rateItPopupService.hidePopup();
    }

    dismissClose(): void {
        this._rateItPopupService.hidePopup();
        this._rateItPopupService.saveRateItData();
    }
}
