import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CmFormsModule } from '../../../../feature-modules/design-system/components/forms/cm-forms.module';
import { CmInputValidationModule } from '../../../../feature-modules/design-system/components/forms/components/input-validation/cm-input-validation.module';
import { AsFormControlPipeModule } from '../../../pipes/as-form-control/as-form-control-pipe.module';

import { CmSelectInputComponent } from './cm-select-input.component';

@NgModule({
    declarations: [CmSelectInputComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        CmFormsModule,
        CmInputValidationModule,
        AsFormControlPipeModule,
    ],
    exports: [CmSelectInputComponent],
})
export class SelectInputModule {}
