import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';

import { LoadingIndicatorService } from '../services/loading-indicator.service';

@Injectable()
export class LoadingIndicatorInterceptor implements HttpInterceptor {
    constructor(private readonly _loadingIndicatorService: LoadingIndicatorService) {}

    intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        return of(true).pipe(
            tap(() => {
                this._loadingIndicatorService.increaseRequestCount();
            }),
            switchMap(() => next.handle(req)),
            finalize(() => {
                this._loadingIndicatorService.decreaseRequestCount();
            })
        );
    }
}
