import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Pipe({
    name: 'asFormControl',
})
export class AsFormControlPipe implements PipeTransform {
    transform(control: UntypedFormControl | AbstractControl): UntypedFormControl {
        return control as UntypedFormControl;
    }
}
