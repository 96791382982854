import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DeviceScreenService } from '../../../core/services/device-screen/device-screen.service';
import { ScreenSize } from '../../enum/screen-size.enum';
import { IScreenSize } from '../../interfaces/screen-size.interface';
import { ITab } from '../../interfaces/tab.interface';

@Injectable()
export class TabsFacadeService {
    isScreenSmall$: Observable<boolean> = this._deviceScreenService
        .getScreenSizeFlags$()
        .pipe(map((deviceScreenSizeFlags: IScreenSize) => deviceScreenSizeFlags[ScreenSize.small]));

    constructor(private _deviceScreenService: DeviceScreenService, private _router: Router) {}

    getSelectedTabName(tabs: ITab[]): string {
        return tabs.find((tab: ITab) => this._router.url.includes(tab.routerLink))?.name;
    }
}
