import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';

import { DefaultPageRouteService } from '../../../core/services/default-page-route.service';
import { ROUTES_PATHS } from '../../../shared/constants/routes.constants';
import { PageViewMethod } from '../../../shared/services/ga-service/ga-page-load';
import { GAService } from '../../../shared/services/ga-service/ga-service';
import { AbstractPageLoad } from '../../../shared/services/ga-service/shared-classes';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent extends AbstractPageLoad implements AfterViewInit, OnDestroy {
    readonly pageName: string = 'error/404';
    readonly pageType: string = 'error';
    readonly pageViewMethod: PageViewMethod = PageViewMethod.page;
    readonly routes: typeof ROUTES_PATHS = ROUTES_PATHS;
    private readonly _title: string = 'Page not found';
    private readonly _destroyTrigger$: Subject<void> = new Subject<void>();

    constructor(
        private _titleService: Title,
        protected _gaService: GAService,
        private _location: Location,
        private _defaultPageRouteService: DefaultPageRouteService
    ) {
        super(_gaService);
        this._titleService.setTitle(this._title);
    }

    ngOnDestroy(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }

    goHome(): void {
        this._defaultPageRouteService.goToDefaultPage();
    }

    goBack(): void {
        this._location.back();
    }

    ngAfterViewInit(): void {
        this.recordPageLoad();
    }
}
