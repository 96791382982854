import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DeviceScreenService } from '../../../core/services/device-screen/device-screen.service';
import { GlobalEventService } from '../../../core/services/global-event.service';
import { Grid } from '../../../models/grid.model';
import { ScreenSize } from '../../enum/screen-size.enum';
import { IScreenSize } from '../../interfaces/screen-size.interface';

@Component({
    selector: 'div[grid]',
    templateUrl: './grid.component.html',
})
export class GridComponent<T extends { displayOpenDropdownlist?: any }>
    implements OnInit, OnDestroy
{
    @Input() gridControl: Grid<T>;
    isDisplayFullActions: boolean = true;
    displayOpenDropdownlist: boolean = false;
    isLoadAll: boolean = false;
    private readonly _destroyTrigger$: Subject<void> = new Subject<void>();

    constructor(
        private _eventService: GlobalEventService,
        private _deviceScreenService: DeviceScreenService
    ) {}

    ngOnInit(): void {
        this._deviceScreenService
            .getScreenSizeFlags$()
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe((deviceScreenSizeFlags: IScreenSize) => {
                this.displayResponsive(deviceScreenSizeFlags[ScreenSize.small]);
            });
    }

    ngOnDestroy(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }

    clearSearch(): void {
        this.gridControl.options.SearchValue = null;
        this.gridControl.options.Page = 1;
        this.isLoadAll = false;
        this._eventService.publish('clearSearch', this.gridControl.name);
        this.gridControl.refresh();
    }

    loadAll(): void {
        this.gridControl.options.Page = 0;
        this.isLoadAll = true;
        this.gridControl.refresh();
    }

    displayResponsive(isSmallScreen: boolean): void {
        this.isDisplayFullActions = !isSmallScreen;

        if (this.gridControl === undefined) {
            return;
        }
        if (this.gridControl.items === undefined) {
            return;
        }

        this.gridControl.items.forEach((item: T) => {
            if (item.displayOpenDropdownlist !== undefined) {
                delete item.displayOpenDropdownlist;
            }
        });
    }

    showPaging(): boolean {
        return (
            this.gridControl.paginationInfo.CurrentPage <
                this.gridControl.paginationInfo.TotalPages &&
            this.gridControl.paginationInfo.TotalRecords >
                this.gridControl.paginationInfo.PageSize &&
            !this.isLoadAll
        );
    }
}
