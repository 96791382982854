import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IExecutionResult } from '../../core/interfaces/execution-result.interface';
import { IHostedExchangeEmailRouteSetting } from '../../core/interfaces/hosted-exchange-email-route-setting.interface';
import { ISynchronisation } from '../../core/interfaces/synchronisation.interface';
import { AuthorizedHttpService } from '../../core/services/http/authorized-http.service';
import { PopupService } from '../../feature-modules/popups/services/popup.service';
import { HostedExchangeManagement } from '../../pages/customer/manage-service/hosted-exchange/hosted-exchange.management';
import { API_ENDPOINTS } from '../constants/api/api-endpoints.constants';
import { HostedExchangePopUps } from '../interfaces/cm-button.types';

@Injectable()
export class HostedExchangeService {
    management: HostedExchangeManagement;

    constructor(
        private _authorizedHttpService: AuthorizedHttpService,
        private _popupService: PopupService,
        private _router: Router,
    ) {}

    hostedExchangePopup(
        payLoad: { organisationId: string; mailboxAddress?: string },
        popUpId: HostedExchangePopUps
    ): void {
        this.management = new HostedExchangeManagement(this._router);
        this.management.organisationId = payLoad.organisationId;
        if (payLoad.mailboxAddress) {
            this.management.mailboxAddress = payLoad.mailboxAddress;
        }

        switch (popUpId) {
            case HostedExchangePopUps.setMailboxSize:
                this._popupService.show(null, 'setting-mailbox', { organisationId: payLoad.organisationId });
                break;
            case HostedExchangePopUps.setOutlookRuleSize:
                this._popupService.show(null, 'setting-rulesize', { organisationId: payLoad.organisationId });
                break;
            case HostedExchangePopUps.manageSignatures:
                this.management.getSettingSignatureInfo(
                    this._authorizedHttpService,
                    (data: any) => {
                        this._popupService.show(null, 'setting-signature', {
                            data,
                            management: this.management,
                        });
                    }
                );
                break;
            case HostedExchangePopUps.manageDisclaimer:
                this.management.getSettingDisclaimerInfo(
                    this._authorizedHttpService,
                    (data: any) => {
                        this._popupService.show(null, 'setting-disclaimer', {
                            data,
                            m: this.management,
                        });
                    }
                );
                break;
            case HostedExchangePopUps.activatePublicFolder:
                this._popupService.show(null, 'setting-activate-public-folder', {
                    management: this.management,
                });
                break;
            case HostedExchangePopUps.deletePublicFolder:
                this._popupService.show(null, 'setting-delete-public-folder', {
                    organisationId: this.management.organisationId,
                });
                break;
            case HostedExchangePopUps.activateSharedMailbox:
                this._popupService.show(null, 'setting-activate-shared-mailbox', {
                    organisationId: this.management.organisationId,
                });
                break;
            case HostedExchangePopUps.deleteSharedMailbox:
                this._popupService.show(null, 'setting-delete-shared-mailbox', {
                    organisationId: this.management.organisationId
                });
                break;
            case HostedExchangePopUps.synchronisationSettings:
                this.management.getSettingSynchroniseInfo(
                    this._authorizedHttpService,
                    (data: IExecutionResult<ISynchronisation>) => {
                        this._popupService.show(null, 'setting-synchronise', {
                            data,
                            management: this.management,
                        });
                    }
                );
                break;
            case HostedExchangePopUps.clientMobileAccess:
                this.management.getClientMobileAccessInfo(
                    this._authorizedHttpService,
                    (data: any) => {
                        this._popupService.show(null, 'setting-client-mobile-access', {
                            data,
                            management: this.management,
                        });
                    }
                );
                break;
            case HostedExchangePopUps.emailRoutes:
                this.management
                    .getEmailRoutesInfo$(this._authorizedHttpService)
                    .subscribe((data: IExecutionResult<IHostedExchangeEmailRouteSetting>) => {
                        this._popupService.show(null, 'setting-email-routes', {
                            data,
                            management: this.management,
                        });
                    });
                break;
            case HostedExchangePopUps.editMailboxForwarding:
                this.management.getForwardingInfo(this._authorizedHttpService, () => {
                    this._popupService.show(null, 'forwarding-hosted-exchange', this.management);
                });
                break;
            case HostedExchangePopUps.editMailboxDistribution:
                this.management.getDistributionGroups(this._authorizedHttpService, () => {
                    this._popupService.show(null, 'distribution-group', this.management);
                });
                break;
            case HostedExchangePopUps.editMailboxPublicFolders:
                this._popupService.show(null, 'public-folders', this.management);
                break;
            case HostedExchangePopUps.editRoomMailboxGeneral:
                this._authorizedHttpService
                    .get$(
                        API_ENDPOINTS.EXCHANGE_ROOM_MAILBOX.GET_PREPARE_EDIT(this.management.mailboxAddress)
                    )
                    .subscribe((r: any) => {
                        this._popupService.show(null, 'edit-room-mailbox', {
                            organisationId: this.management.organisationId,
                            model: r,
                        });
                    });
                break;
            case HostedExchangePopUps.activateApplicationImpersonation:
                this._popupService.show(null, 'activate-app-impersonation-popup', {
                    organisationId: this.management.organisationId,
                });

                break;
            case HostedExchangePopUps.activateAll:
                this._popupService.show(null, 'activate-all-mailbox', {
                    organisationId: this.management.organisationId,
                });
                break;
            default:
                break;
        }
    }
}
