import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { GAService } from '../../../../../shared/services/ga-service/ga-service';
import { IFinalizingLoginStrategy } from '../../../interfaces/finalizing-login-strategy.interface';
import { ILoginUserResponse } from '../../../interfaces/login-user-response.interface';
import { LoginService } from '../../login/login-strategy/login.service';


@Injectable({
    providedIn: 'root',
})
export class SessionRecreateFinalizingService implements IFinalizingLoginStrategy {
    constructor(
        private _loginService: LoginService,
        private _gaService: GAService,
        private _translateService: TranslateService,
    ) {}

    finalizeLogin$(): Observable<void> {
        return this._processSilentRefreshLogin$();
    }

    private _processSilentRefreshLogin$(): Observable<void> {
        return this._loginService.getLoginUser$()
            .pipe(
                map((loginUserResponse: ILoginUserResponse) => {
                    this._gaService.setResellerData(loginUserResponse.mex, loginUserResponse.gaUserReference);
                }),
                catchError(() => throwError({error_description: this._translateService.instant('LOGIN.BACKEND_ERROR')}))
            )
    }
}
