// eslint-disable-next-line max-classes-per-file
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router, UrlTree } from '@angular/router';
import { IUsersPermissionsResponse } from '@cloudmarket/permissions-contract';
import { Permission, View } from '@cloudmarket/permissions-contract/lib/permissions.enum';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IPermissionsResponse } from '../../../core/interfaces/permissions-response.interface';
import { ApiPermissionsService } from '../../../core/services/api/api-permissions.service';
import { ROUTES_PATHS } from '../../../shared/constants/routes.constants';

export class PermissionGuard {
    // eslint-disable-next-line @typescript-eslint/tslint/config
    static forView(...views: View[]) {
        @Injectable({
            providedIn: 'root',
        })
        class PermissionViewCheck implements CanLoad, CanActivate, CanActivateChild {
            private readonly _accessDenied: UrlTree;

            constructor(
                private _apiPermissionsService: ApiPermissionsService,
                private _router: Router
            ) {
                this._accessDenied = this._router.createUrlTree([ROUTES_PATHS.ACCESS_DENIED]);
            }

            canLoad(): Observable<boolean | UrlTree> {
                return this._hasAccessToView$();
            }

            canActivate(): Observable<boolean | UrlTree> {
                return this._hasAccessToView$();
            }

            canActivateChild(): Observable<boolean | UrlTree> {
                return this._hasAccessToView$();
            }

            private _hasAccessToView$(): Observable<boolean | UrlTree> {
                return this._apiPermissionsService.getPermissions$().pipe(
                    map((permissions: IPermissionsResponse) => {
                        const userPermissions: IUsersPermissionsResponse =
                            permissions.usersPermissions;

                        return (
                            userPermissions?.views.some((userView: View) =>
                                views.some((view: View) => view === userView)
                            ) || this._accessDenied
                        );
                    })
                );
            }
        }

        return PermissionViewCheck;
    }

    // eslint-disable-next-line @typescript-eslint/tslint/config
    static forPermission(...permissions: Permission[]) {
        @Injectable({
            providedIn: 'root',
        })
        class PermissionCheck implements CanLoad, CanActivate, CanActivateChild {
            private readonly _accessDenied: UrlTree;

            constructor(
                private _apiPermissionsService: ApiPermissionsService,
                private _router: Router
            ) {
                this._accessDenied = this._router.createUrlTree([ROUTES_PATHS.ACCESS_DENIED]);
            }

            canLoad(): Observable<boolean | UrlTree> {
                return this._hasAccess$();
            }

            canActivate(): Observable<boolean | UrlTree> {
                return this._hasAccess$();
            }

            canActivateChild(): Observable<boolean | UrlTree> {
                return this._hasAccess$();
            }

            private _hasAccess$(): Observable<boolean | UrlTree> {
                return this._apiPermissionsService.getPermissions$().pipe(
                    map((permissionsResponse: IPermissionsResponse) => {
                        const userPermissions: IUsersPermissionsResponse =
                            permissionsResponse.usersPermissions;

                        return (
                            userPermissions?.permissions.some((userPermission: Permission) =>
                                permissions.some(
                                    (permission: Permission) => permission === userPermission
                                )
                            ) || this._accessDenied
                        );
                    })
                );
            }
        }

        return PermissionCheck;
    }
}
