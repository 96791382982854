import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ROUTES_PATHS } from '../../../../shared/constants/routes.constants';
import { AlertType } from '../../../../shared/enum/alert-type.enum';
import { LoginType } from '../../../../shared/enum/login/login-type.enum';
import { ButtonVariant } from '../../../../shared/interfaces/cm-button.types';
import { IErrorData } from '../../../../shared/interfaces/error-data.interface';
import { PageViewMethod } from '../../../../shared/services/ga-service/ga-page-load';
import { GAService } from '../../../../shared/services/ga-service/ga-service';
import { AbstractPageLoad } from '../../../../shared/services/ga-service/shared-classes';
import { WhitelabelService } from '../../../../shared/services/whitelabel.service';
import { FooterService } from '../../../layout/providers/footer.service';
import { ILoginState } from '../../interfaces/login-state.interface';
import { ILogin } from '../../interfaces/login.interface';
import { LoginContext } from '../../services/login/login-context';
import { LoginMfaService } from '../../services/login/mfa/login-mfa.service';

@Component({
    selector: 'app-login',
    templateUrl: './sign-in.component.html',
    // tslint:disable-next-line:relative-url-prefix
    styleUrls: ['./sign-in.component.scss', '../auth.scss'],
})
export class SignInComponent extends AbstractPageLoad implements OnInit, OnDestroy {
    pageName: string = 'identity/sign-in';
    pageType: string = 'identity';
    pageViewMethod: PageViewMethod = PageViewMethod.page;
    loginModel: ILogin = {
        username: '',
        password: '',
        datetime: '',
        mfaKey: '',
        mfaToken: '',
    };
    username: string = '';
    password: string = '';
    submitted: boolean = false;
    needHumanConfirmation: boolean = false;
    loggingIn: boolean = false;
    loginText: string = 'SIGN_IN.SIGN_IN';
    token: string;
    failedLogins: number = 0;
    modalAnimation: boolean = false;
    loginForm: UntypedFormGroup;
    displayErrorMessage: string;
    showPasswordText: string = 'Show';
    passwordVisible: boolean = false;
    readonly routes: typeof ROUTES_PATHS = ROUTES_PATHS;
    readonly alertType: typeof AlertType = AlertType;
    readonly buttonVariant: typeof ButtonVariant = ButtonVariant;
    private _defaultErrorMessage: string = 'SIGN_IN.DEFAULT_ERROR_MESSAGE';
    private _params: Params;
    private readonly _destroyTrigger$: Subject<void> = new Subject();

    constructor(
        protected _gaService: GAService,
        private _activatedRoute: ActivatedRoute,
        private _footerService: FooterService,
        private _formBuilder: UntypedFormBuilder,
        private _router: Router,
        private _translateService: TranslateService,
        private _whiteLabelService: WhitelabelService,
        private _loginMfaService: LoginMfaService,
        private _loginContext: LoginContext,
    ) {
        super(_gaService);
        this.username = this._router.getCurrentNavigation().extras.state?.userName;
    }

    showPassword(): void {
        this.passwordVisible = !this.passwordVisible;

        this.showPasswordText = this.passwordVisible
            ? 'SIGN_IN.HIDE_PASSWORD'
            : 'SIGN_IN.SHOW_PASSWORD';
    }

    editUsername(): void {
        const navigationExtras: NavigationExtras = {
            state: { userName: this.username },
        };

        if (this._params) {
            navigationExtras.queryParams = this._params;
        }
        this._router.navigate([ROUTES_PATHS.LOGIN], navigationExtras);
    }

    handleFormSubmission(): void {
        if (this.loginForm.valid) {
            this._newFormSignIn();

            return;
        }
        this._updateFailedLogins();
    }

    goToResetPassword(): void {
        this._router.navigate([ROUTES_PATHS.RESET_PASSWORD]);
    }

    executeLoginMfa$(loginData: ILogin): Observable<void> {
        return this._loginContext.executeStrategy$(loginData);
    }

    ngOnInit(): void {
        this.recordPageLoad();

        // Note: Should this happen at login?
        this._footerService.clear();
        this._setDisplayingLoginStatus();

        /**
         Adding a New Automatic Login Type:
         1. Implement Interfaces: If you want to add a new LoginType that should start automatically,
         add new implementations for the interfaces 'ILoginStrategy' and 'IFinalizingLoginStrategy'.
         2. Update Selectors: Add the appropriate conditions in the 'autoSelect$' method of 'LoginStrategySelector' class,
         which should return an Observable of 'ILoginStrategy'.
         The method signature is 'autoSelect$(params: Params): Observable<ILoginStrategy>'.
         If necessary, do the same for 'LoginFinalizingStrategySelector.select$(params: Params ): Observable<IFinalizingLoginStrategy>'.
         After these steps, the login process will run automatically.
         For instance, when the Cloud token is valid and External Services require Cloud authorization, the login process will be triggered.
         */
        this._loginContext.tryExecuteLoginStrategy$()
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe();

        this._handleParams();

        this._loginFormInit();
    }

    ngOnDestroy(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }

    /**
     Adding a New Manual Login Type:
     If you wish to introduce a new login type that needs to be initiated manually, follow these steps:
     1. Implement Interfaces: Create new implementations for the following interfaces:
     - ILoginStrategy
     - IFinalizingLoginStrategy
     2. Update Selectors: Add the corresponding conditions for the new login type in the `select` method of `LoginStrategySelector` class:
     - select(loginType: LoginType) : ILoginStrategy
     If necessary, do the same for `LoginFinalizingStrategySelector.select$(params :Params): Observable<IFinalizingLoginStrategy>`.
     3. Set Strategy: Use the `LoginContext.setStrategy(loginType: LoginType): void` method to set the strategy for the new login type.
     4. Start Process: Initiate the login process using the `executeStrategy$(loginData?: ILogin): Observable<void>` method.
     Note: The methods in classes that implement `ILoginStrategy` and `IFinalizingLoginStrategy`
     interfaces will be executed automatically.
     */
    private _login(loginData: ILogin): void {
        this._loginContext.setStrategy(LoginType.nonSso);
        this._loginContext.executeStrategy$(loginData)
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe({
                error: (error: IErrorData) => {
                    if (!this._loginMfaService.isMfaErrorHandled(error, loginData, this.executeLoginMfa$.bind(this))) {
                        this._handleError(error);
                    }
                },
            });
    }

    private _handleParams(): void {
        this._activatedRoute.queryParams
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe((queryParams: Params) => {
                if (queryParams) {
                    this._params = queryParams;
                }
            });
    }

    private _handleError(error: IErrorData | null): void {
        const errorDescription: string = error?.error_description || '';
        this.displayErrorMessage = errorDescription || this._getDefaultDisplayErrorMessage();
        this._updateFailedLogins();
        this._setLoggingIn(false);
    }

    private _loginFormInit(): void {
        this._whiteLabelService.resetTheme();
        this.loginForm = this._formBuilder.group(
            {
                username: [{ value: this.username || '', disabled: true }, Validators.required],
                password: ['', Validators.required],
                notRobot: false,
            },
            {
                updateOn: 'change',
            },
        );
    }

    private _validateAllFields(formGroup: UntypedFormGroup): void {
        Object.keys(formGroup.controls).forEach((field: string) => {
            const control: AbstractControl = formGroup.get(field);

            if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });

                return;
            }

            if (control instanceof UntypedFormGroup) {
                this._validateAllFields(control);
            }
        });
    }

    private _enableIsHumanValidation(): void {
        this.loginForm.get('notRobot').setValidators([Validators.requiredTrue]);
        this.loginForm.get('notRobot').updateValueAndValidity();
    }

    private _newFormSignIn(): void {
        this._setLoggingIn(true);
        this.loginModel.username = this.username;
        this.loginModel.password = this.loginForm.get('password').value;
        this._login(this.loginModel);
    }

    private _setStatus(loginStatus: ILoginState): void {
        if (loginStatus.username) {
            this.loginForm.patchValue({ username: loginStatus.username });
        }

        this._setLoggingIn(loginStatus.isProcessed);
    }

    private _setLoggingIn(loggingIn: boolean): void {
        this.loggingIn = loggingIn;
        this.loginText = this.loggingIn ? 'SIGN_IN.SIGNING_IN' : 'SIGN_IN.SIGN_IN';
    }

    private _updateFailedLogins(): void {
        const maxFailedLoginAttempts: number = 3;

        this.failedLogins += 1;
        this._validateAllFields(this.loginForm);

        if (this.failedLogins < maxFailedLoginAttempts) {
            return;
        }

        this.needHumanConfirmation = true;

        if (!this.loginForm.get('notRobot').validator) {
            this._enableIsHumanValidation();
        }
    }

    private _getDefaultDisplayErrorMessage(): string {
        return this._translateService.instant(this._defaultErrorMessage);
    }

    /**
     You can subscribe to log in process statuses from LoginContext.loginStatus$.
     By default, property is implemented, returning a boolean that determines whether the login process is running.
     You can pass your own custom status from your class implementing the
     @interface ILoginStrategy
     @property status$
     */
    private _setDisplayingLoginStatus(): void {
        this._loginContext.loginStatus$
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe((loginStatus: ILoginState) => {
                this._setStatus(loginStatus);
            });
    }
}
