import { Inject, Injectable } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';
import { Subject } from 'rxjs';
import { debounceTime, filter, first, takeUntil } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { LoadingIndicatorService } from '../../feature-modules/loading-indicator/services/loading-indicator.service';
import { WINDOW } from '../injection-tokens/window.injection-token';
import { IWindow } from '../interfaces/window.interface';

@Injectable({
    providedIn: 'root',
})
export class MonitoringService {
    private _custIdRegex: RegExp = /mex\d{8}-\d{8}/i;
    private _guidRegex: RegExp =
        /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i;
    private _refRegex: RegExp = /[0-9]*%2B[0-9]*/i;
    private _plusRegex: RegExp = /%2B/i;
    private _config: Microsoft.ApplicationInsights.IConfig = {
        instrumentationKey: environment.appInsights.instrumentationKey,
    };
    private readonly _navigationStartTrigger$: Subject<void> = new Subject();

    constructor(
        @Inject(WINDOW) private _window: IWindow,
        private _loadingIndicatorService: LoadingIndicatorService
    ) {
        if (!AppInsights.config) {
            AppInsights.downloadAndSetup(this._config);
        }
    }

    logPageView(): void {
        AppInsights.trackPageView();
    }

    startNavigationEvent(url: string): void {
        this._navigationStartTrigger$.next();

        if (AppInsights.context) {
            AppInsights.context.operation.id =
                this._window.Microsoft.ApplicationInsights.Util.newId();
            AppInsights.context.operation.name = url;
        }
        AppInsights.startTrackEvent(this.getMonitoringUrl(url));
    }

    endNavigationEvent(url: string): void {
        // after page loads and no requests are yet started, we need to wait for them
        // additionally after some requests are chained and new requests start -> we also need to wait for them
        const debounceTimeValue: number = 200;

        this._loadingIndicatorService
            .getRequestsCount$()
            .pipe(
                debounceTime(debounceTimeValue),
                filter((requestsCount: number) => !requestsCount),
                first(),
                takeUntil(this._navigationStartTrigger$)
            )
            .subscribe(() => {
                this._stopTrackEvent(url);
            });
    }

    getMonitoringUrl(url: string): string {
        let str: string = url;

        str = str.replace(this._custIdRegex, '{customerId}');
        str = str.replace(this._guidRegex, '{guid}');
        str = str.replace(this._refRegex, '{objectReference}');
        str = str.replace(this._plusRegex, '+');

        return str.split('?')[0];
    }

    private _stopTrackEvent(url: string): void {
        AppInsights.stopTrackEvent(this.getMonitoringUrl(url), { type: 'PAGE LOAD TIME' });
    }
}
