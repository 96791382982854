import { Component, Input } from '@angular/core';

import { IActionItem } from './action-item.interface';

@Component({
    selector: 'app-g-header',
    templateUrl: './g-header.component.html',
    styleUrls: ['./g-header.component.scss'],
})
export class GHeaderComponent {
    @Input() secondaryText: string;
    @Input() actionItems: IActionItem[];
    @Input() type: string = 'primary-heading';
}
