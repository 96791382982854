import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad, Route,
    RouterStateSnapshot, UrlSegment,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { SessionManagementService } from '../services/session-management/session-management.service';

@Injectable({
    providedIn: 'root',
})
export class SessionGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private _sessionManagementService: SessionManagementService
    ) {}

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean | UrlTree> | boolean | UrlTree {
        return this._sessionManagementService.getSessionStateForCanLoad$(segments);
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | boolean | UrlTree {
        return this._sessionManagementService.getSessionStateForCanActive$(state);
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | boolean | UrlTree {
        return this._sessionManagementService.getSessionStateForCanActiveChild$(state);
    }
}
