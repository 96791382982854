import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_ENDPOINTS } from '../../../../shared/constants/api/api-endpoints.constants';
import { AuthorizedHttpService } from '../../http/authorized-http.service';

import { IMailboxAlias } from './types/api-mailbox.types';

@Injectable({
    providedIn: 'root',
})
export class ApiMailboxService {
    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    deleteAlias$(emailAddress: string, alias: string): Observable<boolean> {
        return this._authorizedHttpService
            .delete$(API_ENDPOINTS.MAILBOX.DELETE_ALIAS(emailAddress, alias))
            .pipe(
                this._authorizedHttpService.handleApiErrorOperator$,
                this._authorizedHttpService.mapResponseOperator$<boolean>()
            );
    }

    addAlias$(userEmail: string, alias: string): Observable<boolean> {
        return this._authorizedHttpService
            .post$(API_ENDPOINTS.MAILBOX.ADD_ALIAS, {
                alias,
                userEmail,
            })
            .pipe(
                this._authorizedHttpService.handleApiErrorOperator$,
                this._authorizedHttpService.mapResponseOperator$<boolean>()
            );
    }

    getMailboxAliases$(emailAddress: string): Observable<IMailboxAlias[]> {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.MAILBOX.GET_MAILBOX_ALIASES(emailAddress))
            .pipe(
                this._authorizedHttpService.handleApiErrorOperator$,
                this._authorizedHttpService.mapResponseOperator$<IMailboxAlias[]>()
            );
    }

    getOrganisationDomains$(emailAddress: string): Observable<string[]> {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.MAILBOX.GET_ORGANISATION_DOMAINS(emailAddress))
            .pipe(
                this._authorizedHttpService.handleApiErrorOperator$,
                this._authorizedHttpService.mapResponseOperator$<string[]>()
            );
    }

    activateAllMailboxes$(organisationId: string): Observable<string> {
        return this._authorizedHttpService
            .post$(
                API_ENDPOINTS.MAILBOX.ACTIVATE_ALL_MAILBOXES(organisationId),
                {}
            )
            .pipe(
                this._authorizedHttpService.handleApiErrorOperator$,
                this._authorizedHttpService.mapResponseOperator$<string>()
            );
    }
}
