import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LayoutService {
    previousUserType$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    isPreviousTypePresent(value: boolean): void {
        this.previousUserType$.next(value);
    }

    getIsPreviousTypePresent$(): Observable<boolean> {
        return this.previousUserType$.asObservable();
    }
}
