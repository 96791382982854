// eslint-disable-next-line max-classes-per-file
import { Injectable } from '@angular/core';

import { IDictionary } from '../../shared/interfaces/dictionary.interface';

@Injectable({
    providedIn: 'root',
})
export class GlobalEventService {
    private static _subscribers: IDictionary<any> = {};

    subscribe(
        name: string,
        func: (data: any) => void,
        error: (e: any) => void = null
    ): GlobalEventSubscription {
        if (!name) {
            throw new Error('must provide name');
        }

        if (!func) {
            throw new Error('must provide func');
        }

        let subscriber: any = {};

        if (GlobalEventService._subscribers[name]) {
            subscriber = GlobalEventService._subscribers[name];
        } else {
            GlobalEventService._subscribers[name] = subscriber;
        }

        const id: string = name + new Date().getTime();

        subscriber[id] = { func, error };

        return new GlobalEventSubscription(id, subscriber);
    }

    publish(name: string, data: any = null): void {
        if (!name) {
            throw new Error('must provide name');
        }

        if (GlobalEventService._subscribers[name]) {
            const subscriber: any = GlobalEventService._subscribers[name];

            for (const prop in subscriber) {
                if (subscriber.hasOwnProperty(prop)) {
                    const obj: any = subscriber[prop];

                    try {
                        obj.func(data);
                    } catch (e) {
                        if (obj.error) {
                            obj.error(e);
                        }
                    }
                }
            }
        }
    }
}

export class GlobalEventSubscription {
    constructor(private _id: string, private _subscriber: any) {}

    unsubscribe(): void {
        // eslint-disable-next-line @typescript-eslint/tslint/config
        delete this._subscriber[this._id];
    }
}
