import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import { environment } from '../../../../../../environments/environment';
import { WINDOW } from '../../../../../core/injection-tokens/window.injection-token';
import { ROUTES_PATHS } from '../../../../../shared/constants/routes.constants';
import { STORAGE_KEYS } from '../../../../../shared/constants/storage-keys.constants';
import { ILoginStrategy } from '../../../interfaces/login-strategy.interface';

import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root',
})
export class LoginMs365Service implements ILoginStrategy {
    private _idToken: string;
    private readonly _o365AuthClientId: string = environment.office365Auth.clientId;
    private readonly _o365AuthNonce: string = environment.office365Auth.nonce;

    constructor(
        @Inject(WINDOW) private _window: Window,
        private _router: Router,
        private _loginService: LoginService,
        private _activatedRoute: ActivatedRoute,
    ) {
    }

    login$(): Observable<void> {
        return this._activatedRoute.fragment
            .pipe(
                map((fragment: string) => new URLSearchParams(fragment)),
                switchMap((urlSearchParams: URLSearchParams) => {
                    const idToken: string = urlSearchParams.get('id_token');

                    if (idToken) {
                        return this._processLogin$(idToken);
                    }

                    this._goToMSLogin();

                    return EMPTY;
                }),
                take(1));
    }

    deleteIdToken(): void {
        sessionStorage.removeItem(STORAGE_KEYS.O365_AUTH_ID_TOKEN);
    }

    getIdToken(): string | null {
        return this._idToken || sessionStorage.getItem(STORAGE_KEYS.O365_AUTH_ID_TOKEN);
    }

    private _goToMSLogin(): void {
        const location: Location = this._window.location;
        const siteBaseUrl: string = location.origin;
        const params: string = new URLSearchParams(location.search).toString();
        const paramsAsBase64: string = btoa(params);

        this._window.location.href = ROUTES_PATHS.MS_LOGIN.URL(
            this._o365AuthClientId,
            siteBaseUrl,
            paramsAsBase64,
            this._o365AuthNonce);
    }

    private _processLogin$(idToken: string): Observable<void> {
        this._setIdToken(idToken);

        return this._loginService.ms365Login$(idToken)
            .pipe(
                catchError((error: unknown) => {
                    this._router.navigate([ROUTES_PATHS.MS_LOGIN.LINK_ACCOUNT]);

                    return throwError(error);
                }),
            );
    }

    private _setIdToken(token: string): void {
        this._idToken = token;
        sessionStorage.setItem(STORAGE_KEYS.O365_AUTH_ID_TOKEN, token);
    }
}
