import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { BreadcrumbsComponent } from './components/breadcrumbs.component';
import { DynamicBreadcrumbPipe } from './pipes/dynamic-breadcrumb.pipe';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule
    ],
    declarations: [BreadcrumbsComponent, DynamicBreadcrumbPipe],
    exports: [BreadcrumbsComponent],
})
export class BreadcrumbsModule {}
