import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WaitSpinnerComponent } from './wait-spinner.component';

@NgModule({
    declarations: [WaitSpinnerComponent],
    imports: [CommonModule],
    exports: [WaitSpinnerComponent],
})
export class WaitSpinnerModule {}
