export interface IDynamicForm {
    Name: string;
    Fields: IDynamicFormField[];
}

export interface IDynamicFormField {
    Type: DynamicFormFieldType;
    Label: string;
    PropertyName: string;
    Regex: string;
    Mandatory: string;
    DisplayOrder: string;
    InvalidMessage: string;
    ToolTip: string;
    Message: string;
    Valid: boolean;
    Touched: boolean;
}

export enum DynamicFormFieldType {
    text = 1,
    password = 2,
}
