import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';

import { IExecutionResult } from '../../../../core/interfaces/execution-result.interface';
import { AuthorizedHttpService } from '../../../../core/services/http/authorized-http.service';
import { AbstractPopup } from '../../../../feature-modules/popups/classes/abstract-popup.class';
import { HostedVoiceV2SitePropertyType } from '../../../../pages/customer/add-service/hosted-voice-v2/hosted-voice-v2.management';
import { API_ENDPOINTS } from '../../../constants/api/api-endpoints.constants';
import { KeyboardKey } from '../../../enum/keyboard-key.enum';

@Component({
    selector: 'hosted-voice-order-summary-popup',
    templateUrl: './hosted-voice-order-summary-popup.component.html',
})
export class HostedVoiceOrderSummaryPopupComponent
    extends AbstractPopup<any>
    implements OnInit, AfterViewInit
{
    modalAnimation: boolean = false;
    orderId: number;
    organisationId: string;
    model: any;

    constructor(private _authorizedHttpService: AuthorizedHttpService) {
        super();
    }

    ngOnInit(): void {
        this._authorizedHttpService
            .get$(
                API_ENDPOINTS.HOSTED_VOICE.GET_ORDER(this.organisationId, this.orderId)
            )
            .subscribe((result: IExecutionResult<any>) => {
                if (result.Succeed) {
                    this.model = result.Result;
                }
            });
    }

    ngAfterViewInit(): void {
        const animationDelay: number = 150;

        setTimeout(() => {
            if (!this.modalAnimation) {
                this.modalAnimation = true;
            }
        }, animationDelay);
    }

    updateParams(val: any): void {
        this.organisationId = val.organisationId;
        this.orderId = val.orderId;
    }

    cancel(): void {
        this.close();
    }

    convertIndex(index: number): string {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return String.fromCharCode(index + 65);
    }

    convertPropertyType(type: HostedVoiceV2SitePropertyType): string {
        if (type === HostedVoiceV2SitePropertyType.Residential) {
            return 'Residential';
        }

        return 'Commercial';
    }

    @HostListener('window:keydown', ['$event']) keyboardInput(event: KeyboardEvent): void {
        if (event.key === KeyboardKey.escape) {
            this.close();
        }
    }
}
