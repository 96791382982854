import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';

import { PopupService } from '../../feature-modules/popups/services/popup.service';
import { IAdditionalServiceAgreement } from '../../models/agreement/interfaces/additional-service-agreement.interface';
import { IMultipleAgreementServiceDetails } from '../../models/agreement/interfaces/multiple-agreement-service-details.interface';
import { IMultipleAgreements } from '../../models/agreement/interfaces/multiple-agreements.interface';
import { ISignedService } from '../../models/agreement/interfaces/signed-service.interface';
import { ServiceCode } from '../../models/service/service-step.model';
import { IConfirmationParams } from '../../new-shared/components/modals/confirmation/interface/confirmation.types';
import { ROUTES_PATHS } from '../../shared/constants/routes.constants';
import { Office365ProductGroups, Service } from '../../shared/enum/service-type.enum';
import { IExecutionResult } from '../interfaces/execution-result.interface';

import { ApiAgreementService } from './api/agreement/api-agreement.service';
import { ApiServiceAgreementService } from './api/services/api-service-agreement.service';
import { GlobalDataService } from './global-data.service';

@Injectable({
    providedIn: 'root',
})
export class AgreementService {
    routesPaths: typeof ROUTES_PATHS = ROUTES_PATHS;
    constructor(
        private _popupService: PopupService,
        private _apiAgreementsService: ApiAgreementService,
        private _apiServiceAgreementsService: ApiServiceAgreementService,
        private _translateService: TranslateService,
        private _dataService: GlobalDataService,
        private _router: Router
    ) {}

    redirectPopup(
        serviceId?: number,
        subId?: number,
        agreementId?: number
    ): void {
        this._apiAgreementsService.getCanAgree$().subscribe((canAgree: boolean) => {
            if (canAgree) {
                this.showRedirectToAgreementsDialog(serviceId, subId, agreementId);
            } else {
                this.showNoPermissionToAgreeDialog();
            }
        });
    }

    showRedirectToAgreementsDialog(
        serviceId?: number,
        subId?: number,
        agreementId?: number
    ): void {
        this._popupService.show(
            null,
            'confirmation',
            {
                title: this._translateService.instant('AGREEMENTS.PLEASE_SIGN'),
                message: (serviceId === ServiceCode.office365 && subId === Office365ProductGroups.Office365Secure) ?
                    this._translateService.instant('AGREEMENTS.TO_CONTINUE_WITH_THE_SETUP') :
                    this._translateService.instant('AGREEMENTS.TO_ADD_A_SERVICE_YOU_MUST'),
                isSecondaryButtonHidden: true,
                primaryButtonText: this._translateService.instant('AGREEMENTS.REVIEW_AND_SIGN_NOW'),
                primaryButtonAction: () => this.redirectToAgreements(agreementId)
            } as IConfirmationParams
        )
    }

    redirectToAgreements(agreementId: number): void{
        this._dataService.setData('your-account-data', { activeFlow: 'service agreement' });
        this._router.navigate([this.routesPaths.SETTINGS.REDIRECT_TO_AGREEMENTS(agreementId)]);
    }

    showNoPermissionToAgreeDialog(): void {
        this._popupService.show(
            null,
            'confirmation',
            {
                title: this._translateService.instant('AGREEMENTS.AGREEMENTS'),
                message: this._translateService.instant('SERVICE.OFFICE365_CROSS_SELL.AGREEMENT.AUTHORISED_USER_ONLY'),
                isPrimaryButtonHidden: true,
                secondaryButtonText: this._translateService.instant('CLOSE')
            } as IConfirmationParams
        )
    }

    getLinkedAgreementsAsync$(
        serviceId: number,
        groupId?: number,
        unsignedOnly?: boolean
    ): Observable<IExecutionResult<IMultipleAgreementServiceDetails[]>> {
        return this._apiAgreementsService.getLinkedAgreements$(serviceId, groupId, unsignedOnly);
    }

    checkSignedAgreements$(
        agreements: IMultipleAgreementServiceDetails[]
    ): Observable<IExecutionResult<IMultipleAgreements>> {
        const getUnsignedOnly: boolean = true;
        const agreementList: number[] = agreements.map(
            (agreement: IMultipleAgreementServiceDetails) => agreement.serviceId
        );

        return this._apiAgreementsService.getAgreements$(agreementList, getUnsignedOnly);
    }

    // Change this as we have checkSignedAgreements used elsewhere
    checkSignedAgreement$(
        serviceId: number,
        groupId: number
    ): Observable<IExecutionResult<ISignedService>> {
        const azureAgreementBackendServiceId: number = 20;

        if (serviceId === undefined) {
            return;
        }
        let currentServiceId: number = serviceId;

        // Azure agreement
        // A workaround to match with the server implementation.
        if (serviceId === Service.Microsoft365 && groupId === Office365ProductGroups.Azure) {
            currentServiceId = azureAgreementBackendServiceId;
        }

        return this._apiAgreementsService.checkSignedServiceId$(currentServiceId);
    }

    signAgreements$(
        services: number[]
    ): Observable<IExecutionResult<IAdditionalServiceAgreement[]>> {
        return this._apiServiceAgreementsService.getInlineAgreements$(services);
    }

    signAgreement$(
        serviceId: number,
        marketplaceServiceId: number,
        groupId: number
    ): Observable<[boolean, IExecutionResult<IAdditionalServiceAgreement>]> {
        return forkJoin([
            this._apiAgreementsService.getCanAgree$(),
            this._apiServiceAgreementsService.getInlineAgreement$(serviceId, groupId),
        ]);
    }
}
