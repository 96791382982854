import { DOCUMENT } from '@angular/common';
import {
    Component,
    EventEmitter, HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
} from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';

import { KeyboardKey } from '../../../shared/enum/keyboard-key.enum';

import { MODAL_VARIANTS } from './modal.constants';
import { ModalVariant } from './modal.types';

@Component({
    selector: 'cm-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
    @Input() closeable: boolean = true;
    @Input() scrollable: boolean = true;
    @Input() variant: ModalVariant = ModalVariant.medium;
    @Input() screenCentered: boolean = false;
    @Input() hideHeader: boolean = false;
    @Input() isRoundedExtraLarge: boolean = false;
    @Output() readonly onClose: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly onEscape: EventEmitter<void> = new EventEmitter<void>();
    classes: Record<string, boolean>;
    private _routerSubscription: Subscription = null;

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _renderer2: Renderer2,
        private _router: Router
    ) {}

    @HostListener('window:keydown', ['$event'])
    keyboardInput(event: KeyboardEvent): void {
        if (event.key === KeyboardKey.escape) {
            this.onEscape.emit();
        }
    }

    ngOnInit(): void {
        this.classes = {
            [MODAL_VARIANTS[this.variant]]: true,
            'modal-dialog-scrollable': this.scrollable,
        };

        this._disableBodyOverflow();

        this._routerSubscription = this._router.events.subscribe((event: RouterEvent) => {
            if(event instanceof NavigationEnd) {
                this.onClose.emit();
            }
        })
    }


    ngOnDestroy(): void {
        this._enableBodyOverflow();
        this._routerSubscription?.unsubscribe();
    }

    private _disableBodyOverflow(): void {
        this._renderer2.setStyle(this._document.body, 'overflow', 'hidden');
    }

    private _enableBodyOverflow(): void {
        this._renderer2.removeStyle(this._document.body, 'overflow');
    }
}
