import { Directive, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive() // added for build --prod fix error related to usage of component methods when no decorator is provided for class
export abstract class AbstractControlValueAccessor<T> implements ControlValueAccessor {
    private _value: T = null;
    get value(): T {
        return this._value;
    }

    @Input()
    set value(newValue: T) {
        this.writeValue(newValue);
    }

    onChange: (parameter: T) => any = (parameter: T) => {};

    onTouched: (parameter: T) => any = (parameter: T) => {};

    writeValue(value: T): void {
        this._value = value;
        this.onChange(value);
        this.onTouched(value);
    }

    registerOnChange(func: (value: T) => void): void {
        this.onChange = func;
    }

    registerOnTouched(func: (value: T) => void): void {
        this.onTouched = func;
    }
}

/* eslint-disable */
export function abstractValueAccessorProvider(type: any) {
    return {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => type),
        multi: true,
    };
}

/* tslint:enable */
