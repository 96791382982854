import { Component, Input } from '@angular/core';

import {
    AbstractControlValueAccessor,
    abstractValueAccessorProvider,
} from '../../../shared/classes/abstract-control-value-accessor.class';

import { CmFileInputAccept } from './cm-file-input-accept.enum';

@Component({
    selector: 'cm-file-input',
    templateUrl: './cm-file-input.component.html',
    providers: [abstractValueAccessorProvider(FileInputComponent)],
})
export class FileInputComponent extends AbstractControlValueAccessor<FileList> {
    @Input() required: boolean = false;
    @Input() multiple: boolean = false;
    @Input() accept: string = CmFileInputAccept.all;
    files: FileList;

    onFileSelected(event: Event): void {
        this.files = (event.target as HTMLInputElement).files;
    }
}
