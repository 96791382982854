import { Inject, Injectable } from '@angular/core';

import { DOCUMENT } from '../../core/injection-tokens/document.injection-token';
import { WINDOW } from '../../core/injection-tokens/window.injection-token';
import { REGEX_FACTORY } from '../constants/regexp/regex.constants';
import { ICookieOptions } from '../interfaces/cookie-options.interface';

@Injectable({
    providedIn: 'root',
})
export class CookieService {
    constructor(
        @Inject(WINDOW) private _window: Window,
        @Inject(DOCUMENT) private _document: Document
    ) {}

    get(name: string): string | null {
        const matches: string[] = this._document.cookie.match(REGEX_FACTORY.cookieName(name));

        return matches ? decodeURIComponent(matches[1]) : null;
    }

    set(name: string, value: unknown, customOptions: ICookieOptions = {}): void {
        const options: ICookieOptions = {
            path: '/',
            ...customOptions,
        };

        if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }

        let updatedCookie: string = `${encodeURIComponent(name)}=${encodeURIComponent(`${value}`)}`;

        for (const optionKey in options) {
            if (options.hasOwnProperty(optionKey)) {
                updatedCookie += `; ${optionKey}`;
                const optionValue: number | string | boolean = options[optionKey];

                if (optionValue !== true) {
                    updatedCookie += `=${optionValue}`;
                }
            }
        }

        this._document.cookie = updatedCookie;
    }

    remove(name: string, customOptions: ICookieOptions = {}): void {
        this.set(name, '', {
            domain: customOptions.domain || this._window.location.hostname,
            expires: new Date(),
        });
    }
}
