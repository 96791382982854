import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { WINDOW } from '../../core/injection-tokens/window.injection-token';
import { IExecutionResult } from '../../core/interfaces/execution-result.interface';
import { ApiFeatureFlagsService } from '../../core/services/api/api-feature-flags.service';
import { ApiCustomerService } from '../../core/services/api/customer/api-customer.service';
import { ICustomerDetailsModel } from '../../core/services/api/customer/types/customer-details-model.interface';
import { DefaultPageRouteService } from '../../core/services/default-page-route.service';
import { Resource, UserInfo } from '../../core/services/resource.service';
import { LayoutService } from '../../feature-modules/layout/providers/layout.service';
import { FEATURE_FLAGS } from '../constants/features/feature-flags.constants';
import { ROUTES_PATHS } from '../constants/routes.constants';
import { STORAGE_KEYS } from '../constants/storage-keys.constants';
import { UserType } from '../enum/user-type.enum';

import { WhitelabelService } from './whitelabel.service';

@Injectable({
    providedIn: 'root',
})
export class UserSwitcherService {
    private readonly _previousUserTypeKey: string = STORAGE_KEYS.PREVIOUS_TYPE;

    constructor(
        @Inject(WINDOW) private _window: Window,
        private _router: Router,
        private _resource: Resource,
        private _layoutService: LayoutService,
        private _defaultPageRouteService: DefaultPageRouteService,
        private _apiCustomerService: ApiCustomerService,
        private _whitelabelService: WhitelabelService,
        private _apiFeatureFlagService: ApiFeatureFlagsService,
    ) {}

    switchBetweenUserTypes(): void {
        const currentUserType: string = localStorage.getItem(STORAGE_KEYS.USERTYPE);

        switch (currentUserType) {
            case UserType.endUser:
                this._switchBackToOriginalUserType();
                break;
            case UserType.endUserAdmin:
                this._switchToHex(currentUserType);
                break;
            case UserType.reseller:
                this._switchToHex(currentUserType);
                break;
            case UserType.engineer:
                this._switchToHex(currentUserType);
                break;
            default:
                break;
        }

        this._applyTheme();
    }

    updateUserTypeIfUserIsSwitched(stateUrl: string): void {
        if (
            stateUrl.startsWith(ROUTES_PATHS.END_USER.BASE) &&
            !!localStorage.getItem(this._previousUserTypeKey)
        ) {
            this._updateUserType(UserType.endUser);
        }
    }

    private _applyTheme(): void {
        const userType: string = localStorage.getItem(STORAGE_KEYS.USERTYPE);
        if(
            !this._apiFeatureFlagService.isFeatureFlagEnabled(FEATURE_FLAGS.DISABLE_PARTNER_LEVEL_WHITELABEL) ||
            (
                userType !== UserType.reseller &&
                userType !== UserType.engineer
            )
        ) {
            this._whitelabelService.applyTheme();
        } else {
            this._whitelabelService.resetTheme();
        }
    };

    private _updateUserType(userType: string): void {
        localStorage.setItem(STORAGE_KEYS.USERTYPE, userType);
        localStorage.setItem(STORAGE_KEYS.USER_TYPE, this._convertUserType(userType));

        const isEndUserAdmin: boolean = userType === UserType.endUserAdmin;
        const isEndUser: boolean = userType === UserType.endUser;

        this._resource.beginUpdateUser((storage: UserInfo) => {
            storage.isEndUserAdmin = isEndUserAdmin;
            storage.isEndUser = isEndUser;
        });
    }

    private _switchToHex(currentUserType: string): void {
        localStorage.setItem(this._previousUserTypeKey, currentUserType);
        this._layoutService.isPreviousTypePresent(true);
        this._updateUserType(UserType.endUser);
        this._router.navigate([ROUTES_PATHS.END_USER.BASE]);
    }

    private _switchBackToOriginalUserType(): void {
        const previousType: string = localStorage.getItem(this._previousUserTypeKey);
        this._updateUserType(previousType);
        localStorage.removeItem(this._previousUserTypeKey);
        this._layoutService.isPreviousTypePresent(false);

        if (previousType === UserType.endUserAdmin) {
            this._updateAndRedirectEndUserAdmin();
        } else {
            this._defaultPageRouteService.goToDefaultPage();
        }
    }

    private _convertUserType(userType: string): string {
        return userType === UserType.reseller || userType === UserType.engineer
            ? UserType.admin
            : userType.toLowerCase();
    }

    private _updateAndRedirectEndUserAdmin(): void {
        this._apiCustomerService
            .getCustomerEndUserAdmin$()
            .subscribe((result: IExecutionResult<ICustomerDetailsModel>) => {
                const organisationId: string = result.Result.Id;

                this._resource.beginUpdateUser((storage: UserInfo) => {
                    storage.isEndUserAdmin = true;
                    storage.endUserAdminId = organisationId;
                });

                localStorage.setItem(STORAGE_KEYS.CUSTOMER_ADMIN_CLOUD_ORGANISATION_ID, organisationId);

                this._defaultPageRouteService.goToDefaultPage(organisationId);
            });
    }
}
