import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertType } from 'app/shared/enum/alert-type.enum';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SsoAuthService } from '../../../../core/services/sso-auth/sso-auth.service';
import { ROUTES_PATHS } from '../../../../shared/constants/routes.constants';
import { LoginType } from '../../../../shared/enum/login/login-type.enum';
import { PageViewMethod } from '../../../../shared/services/ga-service/ga-page-load';
import { GAService } from '../../../../shared/services/ga-service/ga-service';
import { AbstractPageLoad } from '../../../../shared/services/ga-service/shared-classes';
import { WhitelabelService } from '../../../../shared/services/whitelabel.service';
import { PopupService } from '../../../popups/services/popup.service';
import { ILoginState } from '../../interfaces/login-state.interface';
import { LoginContext } from '../../services/login/login-context';
import { AuthorisationPageSelectorService } from '../../services/login/pageSelector/authorisation-page-selector.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['../auth.scss'],
})
export class LoginComponent extends AbstractPageLoad implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('userNameInput') userNameInput: ElementRef<HTMLInputElement>;
    pageName: string = 'identity/login';
    pageType: string = 'identity';
    pageViewMethod: PageViewMethod = PageViewMethod.page;
    userName: string = '';
    errorMessage: string;
    loggingIn: boolean = false;
    loginForm: UntypedFormGroup;
    isLoading$: Observable<boolean>;
    serverError: string;
    readonly routes: typeof ROUTES_PATHS = ROUTES_PATHS;
    readonly alertType: typeof AlertType = AlertType;
    private readonly _destroyTrigger$: Subject<void> = new Subject<void>();
    private _returnQueryParams: Params;
    private readonly _timeoutTime: number = 500;

    constructor(
        protected _gaService: GAService,
        private _formBuilder: UntypedFormBuilder,
        private _router: Router,
        private _whiteLabelService: WhitelabelService,
        private _ssoAuthService: SsoAuthService,
        private _translateService: TranslateService,
        private _popupService: PopupService,
        private _authorisationPageSelectorService: AuthorisationPageSelectorService,
        private _loginContext: LoginContext,
        private _activatedRoute:  ActivatedRoute
    ) {
        super(_gaService);
        this.isLoading$ = this._ssoAuthService.isLoading$();
        this.userName = this._router.getCurrentNavigation().extras.state?.userName;
        this._activatedRoute.queryParams
            .subscribe((params: Params) => this._returnQueryParams = params);

    }

    goToMSLogin(): void {
        this._loginContext.setStrategy(LoginType.ms365);
        this._loginContext.executeStrategy$().subscribe();
    }

    handleFormSubmission(): void {
        if (this.loggingIn) {
            return;
        }

        const userNameFormControl: AbstractControl = this.loginForm.get('userName');

        if (!this.loginForm.valid) {
            userNameFormControl.markAsTouched({ onlySelf: true });

            return;
        }

        this._setFormInactive();
        this.userName = userNameFormControl.value;

        this._checkUserLoginFlowAndRedirectToAuthorisationPage();
    }

    ngOnInit(): void {
        this.recordPageLoad();
        this._setDisplayingSsoErrorIfExist();
        this._setDisplayingLoginStatus();
        this._loginFormInit();

        /**
         Adding a New Automatic Login Type:
         1. Implement Interfaces: If you want to add a new LoginType that should start automatically,
         add new implementations for the interfaces 'ILoginStrategy' and 'IFinalizingLoginStrategy'.
         2. Update Selectors: Add the appropriate conditions in the 'autoSelect$' method of 'LoginStrategySelector' class,
         which should return an Observable of 'ILoginStrategy'.
         The method signature is 'autoSelect$(params: Params): Observable<ILoginStrategy>'.
         If necessary, do the same for 'LoginFinalizingStrategySelector.select$(params: Params ): Observable<IFinalizingLoginStrategy>'.
         After these steps, the login process will run automatically.
         For instance, when the Cloud token is valid and External Services require Cloud authorization, the login process will be triggered.
         */
        this._loginContext.tryExecuteLoginStrategy$()
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe({
                error: () => this._handleServerError()
            });
    }

    ngAfterViewInit(): void {
        this._fillLoginFormIfBrowserAutoCompletedField();
    }

    ngOnDestroy(): void {
        this._setActiveForm();
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }

    private _setStatus(loginStatus: ILoginState): void {
        if (loginStatus.username) {
            this.loginForm.patchValue({ userName: loginStatus.username });
        }
        if (loginStatus.isProcessed) {
            this._setFormInactive();
        } else {
            this._setActiveForm();
        }
        if (loginStatus.error) {
            this._handleServerError(this._getDefaultErrorMessage());
        }
    }

    private _setDisplayingSsoErrorIfExist(): void {
        this._ssoAuthService.getSsoLoginError$()
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe((authError: Error): void => {
                this._popupService.show(null, 'auth-error', authError)
            })
    }

    /**
      You can subscribe to login process statuses from LoginContext.loginStatus$.
      By default, property is implemented, returning a boolean that determines whether the login process is running.
      You can pass your own custom status from your class implementing the ILoginStrategy interface
      @property status$
     */
    private _setDisplayingLoginStatus(): void {
        this._loginContext.loginStatus$
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe((loginStatus: ILoginState) => {
                this._setStatus(loginStatus);
            });
    }

    private _loginFormInit(): void {
        this._whiteLabelService.resetTheme();
        this.loginForm = this._formBuilder.group(
            {
                userName: [this.userName, [Validators.required, Validators.maxLength(100)]],
            },
            {
                updateOn: 'change',
            }
        );
    }

    private _checkUserLoginFlowAndRedirectToAuthorisationPage(): void {
        this._authorisationPageSelectorService
            .redirectPage$(this.userName, this._returnQueryParams)
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe({
                error: (error: Error) => this._handleServerError(error.message),
                complete: () => this._setActiveLoginFormBeforeRedirectionToSsoLoginPage(),
            });
    }

    private _setActiveForm(): void {
        this.loggingIn = false;
        this.loginForm?.get('userName').enable();
        this.serverError = '';
    }

    private _setFormInactive(): void {
        this.loggingIn = true;
        this.loginForm?.get('userName').disable();
    }

    private _handleServerError(message?: string): void {
        const errorMessage: string = message || this._getDefaultErrorMessage();

        this._setActiveForm();
        this.serverError = errorMessage;
    }

    private _getDefaultErrorMessage(): string {
        return this._translateService.instant('LOGIN.BACKEND_ERROR');
    }

    // Workaround for the following case:
    // After returning from the Auth0 login page by clicking the browser's back button
    // and after rendering the view,
    // the browser completes the field value with the built-in 'autocomplete',
    // but after clicking: 'ngSubmit', the form control is empty.
    private _fillLoginFormIfBrowserAutoCompletedField(): void {
        setTimeout(() => {
            this.loginForm.setValue({ userName: this.userNameInput.nativeElement.value });
        }, this._timeoutTime);
    }

    // For localhost everything works fine.
    // This is a workaround for an environment other than localhost.
    // When redirecting to the Auth0 login page, the component is not destroyed,
    // so the ngOnDestroy() method is not called and the "form" control is still inactive.
    // When you return from the Auth0 login page by clicking the browser's back button,
    // the component retains its previous status.
    // To activate the control when the user returns to this component,
    // we change the status of the form control to active immediately
    // before redirecting to the Auth0 page.
    private _setActiveLoginFormBeforeRedirectionToSsoLoginPage(): void {
        this._setActiveForm();
    }
}
