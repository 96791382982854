import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

import { BUTTON_STYLE_CLASSES } from '../../constants/button-style-classes.constants';
import { ButtonSize } from '../../enum/button-size.enum';
import { ButtonStyle, ButtonVariant, ICloudMarketButton } from '../../interfaces/cm-button.types';
import { GAService } from '../../services/ga-service/ga-service';
import { InteractionType } from '../../services/ga-service/ga-visitor-interaction';

@Component({
    selector: 'app-dropdown-menu',
    templateUrl: './dropdown-menu.component.html',
    styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent<T> implements OnChanges, OnDestroy {
    @Input() header: string = 'Select...';
    @Input() buttons: ICloudMarketButton[];
    @Input() forceDropDown: boolean = false;
    @Input() width: number;
    @Input() size: ButtonSize = ButtonSize.regular;
    @Input() variant: ButtonVariant = ButtonVariant.primary;
    @Output() readonly customClick: EventEmitter<T> = new EventEmitter();
    @Output() readonly stateChange: EventEmitter<boolean> = new EventEmitter();
    @ViewChild(BsDropdownDirective) dropDownWrapper: BsDropdownDirective;
    @ViewChild('dropDownAnchor') dropDownAnchor: ElementRef;
    @ViewChild('dropDownList') dropDownList: ElementRef;
    isSingleButton: boolean = false;
    visibleButtons: ICloudMarketButton[];
    menuAlignment: 'left' | 'right' = 'left';
    readonly buttonVariant: typeof ButtonVariant = ButtonVariant;
    readonly buttonStyle: Record<ButtonVariant, string> = BUTTON_STYLE_CLASSES;

    constructor(private _gaService: GAService) {
        document.addEventListener('click', this._windowClickHandler, true);
    }

    ngOnChanges(changes: SimpleChanges): void {
        let buttonsChanged: ICloudMarketButton[] = this.buttons || [];

        if (changes.buttons) {
            buttonsChanged = this._filterOutHiddenButtons(buttonsChanged);
        }

        if (changes.buttons || changes.forceDropDown) {
            this.isSingleButton = !this.forceDropDown && buttonsChanged.length === 1;
            this.visibleButtons = this._ascertainButtonsStyles(buttonsChanged);
        }
    }

    ngOnDestroy(): void {
        document.removeEventListener('click', this._windowClickHandler, true);
    }

    handleMenuButtonClick(clickEvent: MouseEvent): void {
        clickEvent.stopPropagation();
        this.setMenuAlignment();

        setTimeout(() => {
            this.dropDownWrapper.toggle(true);
        });
    }

    handleMenuOptionClick(clickEvent: MouseEvent): void {
        clickEvent.stopPropagation();

        setTimeout(() => {
            this.dropDownWrapper.hide();
        });
    }

    setMenuAlignment(): void {
        if (!this.dropDownAnchor?.nativeElement) {
            return;
        }

        const dropDownAnchorElement: HTMLElement = this.dropDownAnchor.nativeElement;
        const boundingRect: DOMRect = dropDownAnchorElement.getBoundingClientRect();
        const screenWidth: number = document.documentElement.clientWidth;
        const halfDivider: number = 2;

        this.menuAlignment = boundingRect.right > screenWidth / halfDivider ? 'right' : 'left';
    }

    selectButton(selectedButton: ICloudMarketButton): void {
        if (!this.isSingleButton) {
            this._gaService.visitorInteraction(InteractionType.quickLinksCLick, {
                quick_links_loc: 'actions',
                quick_links_name: selectedButton.Text,
            });
        }

        this.customClick.emit(selectedButton.Data);
    }

    @HostListener('window:resize', ['$event'])
    @HostListener('window:scroll', ['$event'])
    private _onScroll(): void {
        this.setMenuAlignment();
    }

    private _filterOutHiddenButtons(buttons: ICloudMarketButton[] = []): ICloudMarketButton[] {
        return buttons.filter((button: ICloudMarketButton) => button.Style !== ButtonStyle.hidden);
    }

    private _ascertainButtonsStyles(buttons: ICloudMarketButton[] = []): ICloudMarketButton[] {
        // we create copy to avoid original buttons mutation
        const buttonsCopy: ICloudMarketButton[] = buttons.map((button: ICloudMarketButton) => ({
            ...button,
        }));

        if (this.isSingleButton) {
            buttonsCopy[0].Style = ButtonStyle.dropDownSingle;
        } else {
            buttonsCopy.forEach((button: ICloudMarketButton) => {
                button.Style = ButtonStyle.dropDown;
            });
        }

        return buttonsCopy;
    }

    private _windowClickHandler: (event: MouseEvent) => void = (event: MouseEvent) => {
        const wasToggleButtonClicked: boolean = event.target === this.dropDownAnchor?.nativeElement;
        const wasDropdownMenuClicked: boolean = !!(event.target as HTMLElement).closest(
            '.dropdown-menu'
        );

        // menu cannot be hidden on toggle click (hide/show handled in other method)
        // menu cannot be hidden on menu click, this click handler is used in js catch phase
        // thus menu would close before handling options click
        if (!wasToggleButtonClicked && !wasDropdownMenuClicked) {
            this.dropDownWrapper?.toggle();
        }
    };
}
