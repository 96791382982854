import { Pipe, PipeTransform } from '@angular/core';

interface IKeyValue {
    key: any;
    value: any;
}

@Pipe({
    name: 'customKeyValue',
})
export class CustomKeyValuePipe implements PipeTransform {
    transform(value: any[]): any {
        const keys: IKeyValue[] = [];

        for (const key in value) {
            if (value.hasOwnProperty(key)) {
                keys.push({ key, value: value[key] });
            }
        }

        return keys;
    }
}
