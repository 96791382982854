import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, lastValueFrom, Observable } from 'rxjs';

import { WINDOW } from '../../../core/injection-tokens/window.injection-token';
import { IExecutionResult } from '../../../core/interfaces/execution-result.interface';
import { IWindow } from '../../../core/interfaces/window.interface';
import { AgreementService } from '../../../core/services/agreement.service';
import { ApiAgreementService } from '../../../core/services/api/agreement/api-agreement.service';
import { ApiAnnouncementService } from '../../../core/services/api/api-announcement.service';
import { ApiDistributionGroupService } from '../../../core/services/api/api-distribution-group.service';
import { ApiDomainService } from '../../../core/services/api/api-domain.service';
import { ApiFeatureFlagsService } from '../../../core/services/api/api-feature-flags.service';
import { DownloadService } from '../../../core/services/download.service';
import { GlobalDataService } from '../../../core/services/global-data.service';
import { GlobalEventService } from '../../../core/services/global-event.service';
import { AuthorizedHttpService } from '../../../core/services/http/authorized-http.service';
import { ServiceService } from '../../../core/services/service.service';
import { IUserInterface } from '../../../feature-modules/auth/interfaces/user.interface';
import { KnowledgeBaseService } from '../../../feature-modules/knowledge-base/services/knowledge-base/knowledge-base.service';
import { IDialogEventHandler } from '../../../feature-modules/popups/interfaces/dialog-event-handler.interface';
import { PopupService } from '../../../feature-modules/popups/services/popup.service';
import { IMultipleAgreementServiceDetails } from '../../../models/agreement/interfaces/multiple-agreement-service-details.interface';
import { ISignedService } from '../../../models/agreement/interfaces/signed-service.interface';
import { IModifyServiceModel } from '../../../models/service/modify-service.model';
import { EmailSecurityManagement } from '../../../pages/customer/manage-service/email-security/email-security.management';
import { UserService } from '../../../pages/customer/user/user.service';
import { API_ENDPOINTS } from '../../constants/api/api-endpoints.constants';
import { BUTTON_STYLE_CLASSES } from '../../constants/button-style-classes.constants';
import { NewServiceId, Service } from '../../enum/service-type.enum';
import {
    ButtonAction,
    ButtonStyle,
    ButtonVariant,
    ICloudMarketButton,
    IPopUpData,
    ISsoData,
} from '../../interfaces/cm-button.types';
import { GAService } from '../../services/ga-service/ga-service';
import {
    IGAVisitorInteraction,
    InteractionType,
} from '../../services/ga-service/ga-visitor-interaction';
import { HostedExchangeService } from '../../services/hosted-exchange.service';
import { BaseUrl } from '../../urls/base.url';

@Component({
    selector: 'cm-button',
    templateUrl: './cm-button.component.html',
    styleUrls: ['./cm-button.component.scss'],
    providers: [HostedExchangeService],
})
export class ButtonComponent implements OnInit, IDialogEventHandler {
    @Input() buttonData: ICloudMarketButton = {
        Action: ButtonAction.none,
        Text: '',
    };
    @Input() size: 'small' | 'large' | 'regular' = 'regular';
    @Input() disabled: boolean = false;
    @Input() ellipsisLength: number = 0;
    @Input() variant: ButtonVariant = ButtonVariant.primary;
    @Output() readonly customClick: EventEmitter<void> = new EventEmitter();
    knowledgebasePath: string;
    knowledgebaseId: number;
    add: boolean = false;
    isModifyButtonVisible: boolean = false;
    isLinkButtonVisible: boolean = false;
    management: any;
    readonly buttonStyle: Record<ButtonVariant, string> = BUTTON_STYLE_CLASSES;

    constructor(
        @Inject(WINDOW) private _window: IWindow,
        private _popupService: PopupService,
        private _router: Router,
        private _downloadService: DownloadService,
        private _userService: UserService,
        private _knowledgeBaseService: KnowledgeBaseService,
        private _dataService: GlobalDataService,
        private _serviceService: ServiceService,
        private _eventService: GlobalEventService,
        private _hostedExchangeService: HostedExchangeService,
        private _gaService: GAService,
        private _apiAnnouncementService: ApiAnnouncementService,
        private _route: ActivatedRoute,
        private _agreementService: AgreementService,
        private _apiFeatureFlagService: ApiFeatureFlagsService,
        private _apiAgreementsService: ApiAgreementService,
        private _apiDistributionGroupService: ApiDistributionGroupService,
        private _apiDomainService: ApiDomainService,
        private _authorizedHttpService: AuthorizedHttpService
    ) {}

    ngOnInit(): void {
        if (this.buttonData.AnalyticsData) {
            const object: any = this.buttonData.AnalyticsData
                ? {
                      button_action: this.buttonData.AnalyticsData.Action,
                      button_page: this.buttonData.AnalyticsData.Page,
                      button_campaign: this.buttonData.AnalyticsData.Campaign,
                  }
                : null;

            if (object) {
                this._gaService.visitorInteraction(InteractionType.buttonImpression, object);
            }
        }

        this.add = this.buttonData.Style === ButtonStyle.add;
        this.isModifyButtonVisible = this.buttonData.Style === ButtonStyle.modify;
        this.isLinkButtonVisible = this.buttonData.Style === ButtonStyle.marketplaceLink;
        this.disabled = this.disabled || this.buttonData.IsDisabled || false;
    }

    buttonClick(): boolean {
        try {
            if (this.disabled) {
                return false;
            }

            if (this.buttonData.AnalyticsData) {
                const object: IGAVisitorInteraction = this.buttonData.AnalyticsData
                    ? {
                          button_action: this.buttonData.AnalyticsData.Action,
                          button_page: this.buttonData.AnalyticsData.Page,
                          button_campaign: this.buttonData.AnalyticsData.Campaign,
                      }
                    : null;

                if (object) {
                    this._gaService.visitorInteraction(InteractionType.buttonClick, object);
                }
            }

            if (this.buttonData.Data && this.buttonData.Data.SetParam) {
                this._dataService.setData(
                    this.buttonData.Data.SetParam,
                    this.buttonData.Data.SetParamValue
                );
            }

            switch (this.buttonData.Action) {
                case ButtonAction.popUp:
                    const popupData: IPopUpData = this.buttonData.Data as IPopUpData;

                    if (popupData.PopUp === 'list-customer') {
                        this._doListCustomer(popupData);
                    } else {
                        this._popupService.show(this, popupData.PopUp, popupData.Payload);
                    }
                    break;

                case ButtonAction.navigate:
                    if (this.buttonData.Data.SetData) {
                        this._dataService.setData(this.buttonData.Data.SetData, {
                            level: 'customer',
                            organisationId: this.buttonData.Data.Id,
                            userId: 0,
                            mode: 'modify',
                        });
                    }

                    this._router.navigateByUrl(this.buttonData.Data.Route);
                    break;
                case ButtonAction.download:
                    this._userService.getLoggedInUserData$().subscribe((user: IUserInterface) => {
                        this._downloadService
                            .generateGuid$(this.buttonData.Data.downloadId, user.userName)
                            .subscribe((resp: IExecutionResult<string>) => {
                                if (resp.Succeed) {
                                    const gaData: IGAVisitorInteraction = {
                                        file_type: 'file-from-guid',
                                        file_name: resp.Result,
                                    };

                                    this._gaService.visitorInteraction(
                                        InteractionType.downloadFile,
                                        gaData
                                    );
                                    window.open(
                                        API_ENDPOINTS.DOWNLOAD.FROM_GUID(resp.Result, user.userName)
                                    );
                                }
                            });
                    });
                    break;
                case ButtonAction.downloadFile:
                    if (!this.buttonData.Data.APIRoute) {
                        this._downloadService.download(
                            {
                                Id: this.buttonData.Data.Id,
                                Guid: this.buttonData.Data.Guid,
                            },
                            this.buttonData.Data.Filename
                        );
                    } else {
                        this._downloadService.downloadWithUrl$(
                            `${BaseUrl.baseUrl}${this.buttonData.Data.APIRoute}`,
                            { Id: this.buttonData.Data.Id, Guid: this.buttonData.Data.Guid },
                            this.buttonData.Data.Filename
                        );
                    }
                    break;
                case ButtonAction.externalUrl:
                    this.customClick.emit();

                    return true;
                case ButtonAction.knowledgeBase:
                    if (this.buttonData.Data.knowledgebasePath) {
                        this.knowledgebasePath = this.buttonData.Data.knowledgebasePath;
                    } else {
                        this.knowledgebaseId = this.buttonData.Data.knowledgebaseId;
                    }
                    this.showKnowledgebase();
                    break;
                case ButtonAction.addService:
                    this._addService(
                        this.buttonData.Data.ServiceId,
                        this.buttonData.Data.SubId,
                        this.buttonData.Data.ServiceName,
                        this.buttonData.Data.Level,
                        this.buttonData.Data.OrganisationId,
                        this.buttonData.Data.ParentId,
                        this.buttonData.Data.UserEmail,
                        this.buttonData.Data.Upgrade,
                        this.buttonData.Data.ProductId,
                        this.buttonData.Data.Mode
                    );
                    break;
                case ButtonAction.distributionGroupPopUp:
                    this._distributionGroupPopup(this.buttonData.Data);
                    break;
                case ButtonAction.publishEvent:
                    this._eventService.publish(
                        this.buttonData.Data.Event,
                        this.buttonData.Data.Payload
                    );
                    break;
                case ButtonAction.hostedExchangePopUps:
                    this._hostedExchangeService.hostedExchangePopup(
                        this.buttonData.Data.Payload,
                        this.buttonData.Data.PopUpId
                    );
                    break;
                case ButtonAction.sso:
                    this.sso(this.buttonData.Data);
                    break;
                case ButtonAction.export:
                    this.export(this.buttonData.Data);
                    break;
                case ButtonAction.modify:
                    if (this.buttonData.Data.OrderId) {
                        this._modifyService(
                            this.buttonData.Data.ServiceId,
                            this.buttonData.Data.Level,
                            this.buttonData.Data.OrganisationId,
                            this.buttonData.Data.ParentId,
                            this.buttonData.Data.OrderId
                        );
                    } else {
                        this.modify(this.buttonData.Data);
                    }
                    break;
                case ButtonAction.announcementAction:
                    this._apiAnnouncementService
                        .doNotShowAgain$(Number(this._route.snapshot.paramMap.get('id')))
                        .subscribe(() => {
                            this._router.navigate([this.buttonData.Data.Route]);
                        });
                    break;
                default:
                    break;
            }
            this.customClick.emit();

            return false;
        } catch (e) {
            return false;
        }
    }

    async checkAgreement(
        popupData: IPopUpData,
        partnerIdPromise: Promise<IExecutionResult<number>>
    ): Promise<void> {
        const crossSellServiceId: number = 8;
        const crossSellServiceSubId: number = 1;
        const azureAgreementServiceId: number = 8;
        const azureAgreementServiceSubId: number = 6;
        const azureAgreementServerServiceId: number = 20;
        const crossSellFeatureFlag: boolean =
            this._apiFeatureFlagService.isFeatureFlagEnabled('CrossSell');
        let serviceId: number = popupData.Payload.serviceId;

        // Cross-sell feature hijack
        if (
            crossSellFeatureFlag &&
            popupData.Payload.serviceId === crossSellServiceId &&
            popupData.Payload.subId === crossSellServiceSubId
        ) {
            popupData.Payload.newJourney = true;
        }

        // Azure agreement
        // A workaround to match with the server implementation.
        if (
            popupData.Payload.serviceId === azureAgreementServiceId &&
            popupData.Payload.subId === azureAgreementServiceSubId
        ) {
            serviceId = azureAgreementServerServiceId;
        }

        if (popupData.Payload.newJourney) {
            this._popupService.show(null, popupData.PopUp, popupData.Payload);

            return;
        }

        const checkSignedService$: Observable<IExecutionResult<ISignedService>> =
            this._apiAgreementsService.checkSignedServiceId$(serviceId);
        const getLinkedAgreements$: Observable<
            IExecutionResult<IMultipleAgreementServiceDetails[]>
        > = this._apiAgreementsService.getLinkedAgreements$(
            popupData.Payload.serviceId,
            popupData.Payload.subId,
            true
        );

        forkJoin([checkSignedService$, getLinkedAgreements$]).subscribe(
            async (
                response: [
                    IExecutionResult<ISignedService>,
                    IExecutionResult<IMultipleAgreementServiceDetails[]>
                ]
            ) => {
                const signedServiceResult: ISignedService = response[0].Result;
                const linkedResult: IMultipleAgreementServiceDetails[] = response[1].Result;

                if (signedServiceResult.signed && linkedResult.length > 0) {
                    signedServiceResult.signed = false;
                }

                if (!signedServiceResult.signed) {
                    this._agreementService.redirectPopup(
                        serviceId,
                        popupData.Payload.subId,
                        signedServiceResult.agreementId
                    );
                } else {
                    if (partnerIdPromise === null) {
                        this._popupService.show(null, popupData.PopUp, popupData.Payload);
                    }

                }
            }
        );
    }

    export(data: any): void {
        this._authorizedHttpService
            .downloadBlobAsFile$(`${BaseUrl.baseUrl}${data.Path}`)
            .subscribe();
    }

    modify(data: IModifyServiceModel): void {
        this._serviceService.modifyService(data);
    }

    sso(data: ISsoData): void {
        if (data.Service === NewServiceId.AcronisBackup && data.UserLevel) {
            this._authorizedHttpService
                .get$(API_ENDPOINTS.ACRONIS_BACKUP.RESELLER_JUMP_TO_USER_LINK(data.Id))
                .subscribe((result: IExecutionResult<string>) => {
                    if (result.Succeed) {
                        this._window.open(result.Result);
                    }
                });
        }
    }

    executeCommand(command: string, data: any): void {
        if (command === 'yes') {
            this._dataService.setData('your-account-data', { activeFlow: 'service agreement' });
            this._router.navigate([
                `account-setting/terms-conditions/${data.agreementId}/false/false`,
            ]);
        }
    }

    showKnowledgebase(): void {
        if (this.knowledgebasePath) {
            this._knowledgeBaseService.getArticleByPath(this.knowledgebasePath);
        } else {
            this._knowledgeBaseService.getArticle(this.knowledgebaseId);
        }
        this._knowledgeBaseService.displayKnowledgeBase = true;
    }

    private _addService(
        serviceId: number,
        subId: number,
        serviceName: string,
        level: string,
        id: string,
        parentId: string = null,
        userEmail: string = null,
        upgrade: boolean = false,
        productId: number = 0,
        mode: string = null
    ): void {
        this._serviceService.addService(
            serviceId,
            subId,
            serviceName,
            level,
            id,
            parentId,
            userEmail,
            upgrade,
            productId,
            mode
        );
    }

    private _modifyService(
        serviceId: number,
        level: string,
        id: string,
        parentId: string = null,
        orderId: number = 0
    ): void {
        this._serviceService.modifyServiceCust(serviceId, level, id, parentId, orderId);
    }

    private _distributionGroupPopup(data: any): void {
        this.management = new EmailSecurityManagement(
            this._router,
            this._apiDistributionGroupService,
            this._apiDomainService
        );
        this.management.organisationId = data.Payload.organisationId;
        this._popupService.show(null, data.PopUp, {
            management: this.management,
            model: data.Payload.model,
            viewModel: data.Payload.viewModel,
            dg: data.Payload.dg,
        });
    }

    private _doListCustomer(popupData: IPopUpData): void {
        if (popupData.Payload.serviceId === Service.Microsoft365) {
            const partnerIdPromise: Promise<IExecutionResult<number> | undefined> = lastValueFrom(
                this._authorizedHttpService.get$<IExecutionResult<number>>(API_ENDPOINTS.OFFICE365.HAS_PARTNER_ID)
            )

            this.checkAgreement(popupData, partnerIdPromise);
        } else {
            this.checkAgreement(popupData, null);
        }
    }
}
