export enum KeyboardKey {
    backspace = 'Backspace',
    tab = 'Tab',
    enter = 'Enter',
    shift = 'Shift',
    ctrl = 'Ctrl',
    alt = 'Alt',
    pauseBreak = 'PauseBreak',
    capsLock = 'CapsLock',
    escape = 'Escape',
    space = 'Space',
    pageUp = 'PageUp',
    pageDown = 'PageDown',
    end = 'End',
    home = 'Home',
    leftArrow = 'LeftArrow',
    upArrow = 'UpArrow',
    rightArrow = 'RightArrow',
    downArrow = 'DownArrow',
    insert = 'Insert',
    delete = 'Delete',
    closedParen = 'ClosedParen',
    exclamationMark = 'ExclamationMark',
    atSign = 'AtSign',
    poundSign = 'PoundSign',
    hash = 'Hash',
    dollarSign = 'DollarSign',
    percentSign = 'PercentSign',
    caret = 'Caret',
    hat = 'Hat',
    ampersand = 'Ampersand',
    star = 'Star',
    asterik = 'Asterik',
    openParen = 'OpenParen',
    zero = '0',
    one = '1',
    two = '2',
    three = '3',
    four = '4',
    five = '5',
    six = '6',
    seven = '7',
    eight = '8',
    nine = '9',
    a = 'a',
    b = 'b',
    c = 'c',
    d = 'd',
    e = 'e',
    f = 'f',
    g = 'g',
    h = 'h',
    i = 'i',
    j = 'j',
    k = 'k',
    l = 'l',
    m = 'm',
    n = 'n',
    o = 'o',
    p = 'p',
    q = 'q',
    r = 'r',
    s = 's',
    t = 't',
    u = 'u',
    v = 'v',
    w = 'w',
    x = 'x',
    y = 'y',
    z = 'z',
    capitalA = 'A',
    capitalB = 'B',
    capitalC = 'C',
    capitalD = 'D',
    capitalE = 'E',
    capitalF = 'F',
    capitalG = 'G',
    capitalH = 'H',
    capitalI = 'I',
    capitalJ = 'J',
    capitalK = 'K',
    capitalL = 'L',
    capitalM = 'M',
    capitalN = 'N',
    capitalO = 'O',
    capitalP = 'P',
    capitalQ = 'Q',
    capitalR = 'R',
    capitalS = 'S',
    capitalT = 'T',
    capitalU = 'U',
    capitalV = 'V',
    capitalW = 'W',
    capitalX = 'X',
    capitalY = 'Y',
    capitalZ = 'Z',
    leftWindowKey = 'LeftWindowKey',
    rightWindowKey = 'RightWindowKey',
    selectKey = 'SelectKey',
    multiply = 'Multiply',
    add = 'Add',
    subtract = 'Subtract',
    decimalPoint = 'DecimalPoint',
    divide = 'Divide',
    f1 = 'F1',
    f2 = 'F2',
    f3 = 'F3',
    f4 = 'F4',
    f5 = 'F5',
    f6 = 'F6',
    f7 = 'F7',
    f8 = 'F8',
    f9 = 'F9',
    f10 = 'F10',
    f11 = 'F11',
    f12 = 'F12',
    numLock = 'NumLock',
    scrollLock = 'ScrollLock',
    semiColon = 'SemiColon',
    equals = 'Equals',
    comma = 'Comma',
    dash = 'Dash',
    period = '.',
    underScore = 'UnderScore',
    plusSign = 'PlusSign',
    forwardSlash = 'ForwardSlash',
    tilde = 'Tilde',
    graveAccent = 'GraveAccent',
    openBracket = 'OpenBracket',
    closedBracket = 'ClosedBracket',
    quote = 'Quote',
}
