import { Injectable } from '@angular/core';

import {
    AgreementServiceResellerValidityFacadeService
} from '../../../../core/services/agreement-service-reseller-validity-facade.service';
import { ApiPermissionsService } from '../../../../core/services/api/api-permissions.service';
import { LoginStorageService } from '../login/storage/login-storage.service';

@Injectable({
    providedIn: 'root',
})
export class LogoutService {
    constructor(
        private _apiPermissionsService: ApiPermissionsService,
        private _resellerAgreementValidityFacadeService: AgreementServiceResellerValidityFacadeService,
        private _loginStorageService: LoginStorageService,
    ) {}

    logout(): void {
        this._loginStorageService.resetLocalStorage();
        this._apiPermissionsService.clearPermissions();
        this._resellerAgreementValidityFacadeService.clearAgreementState();
    }
}
