import { GADataMethods } from './ga-data-methods';

// eslint-disable:naming-convention
export class GAVisitorInteraction implements IGAVisitorInteraction {
    event: string = 'visitor interaction';
    interaction_name: string;
    form_name: string;
    form_field: string;
    form_message: string;
    sc_type: string;
    sc_name: string;
    quick_links_loc: string;
    quick_links_name: string;
    tab_name: string;
    file_type: string;
    file_name: string;
    filter_name: string;
    filter_value: string;
    button_action: string;
    button_page: string;
    button_campaign: string;
    ecommerce: any;

    constructor(interactionType: InteractionType, data: any) {
        this.interaction_name = this.getVisitorInteractionType(interactionType);

        if (data.form_name) {
            this.form_name = data.form_name;
        }
        if (data.form_field) {
            this.form_field = data.form_field;
        }
        if (data.form_message) {
            this.form_message = data.form_message;
        }
        if (data.sc_type) {
            this.sc_type = data.sc_type;
        }
        if (data.sc_name) {
            this.sc_name = data.sc_name;
        }
        if (data.quick_links_loc) {
            this.quick_links_loc = data.quick_links_loc;
        }
        if (data.quick_links_name) {
            this.quick_links_name = data.quick_links_name;
        }
        if (data.tab_name) {
            this.tab_name = data.tab_name;
        }
        if (data.file_type) {
            this.file_type = data.file_type;
        }
        if (data.file_name) {
            this.file_name = data.file_name;
        }
        if (data.filter_name) {
            this.filter_name = data.filter_name;
        }
        if (data.filter_value) {
            this.filter_value = data.filter_value;
        }
        if (data.button_action) {
            this.button_action = data.button_action;
        }
        if (data.button_page) {
            this.button_page = data.button_page;
        }
        if (data.button_campaign) {
            this.button_campaign = data.button_campaign;
        }
        if (data.ecommerce) {
            this.ecommerce = data.ecommerce;
        }
        GADataMethods.processData(this);
    }

    getVisitorInteractionType(type: InteractionType): string {
        switch (type) {
            case InteractionType.formTracking:
                return 'form tracking';
            case InteractionType.formSubmit:
                return 'form submit';
            case InteractionType.formError:
                return 'form error';
            case InteractionType.saveChanges:
                return 'save changes';
            case InteractionType.quickLinksCLick:
                return 'quick links click';
            case InteractionType.changeTab:
                return 'change tab';
            case InteractionType.downloadFile:
                return 'download file';
            case InteractionType.exportFile:
                return 'export file';
            case InteractionType.applyFilter:
                return 'apply filter';
            case InteractionType.selectService:
                return 'select service';
            case InteractionType.selectLicence:
                return 'select licence';
            case InteractionType.reviewOrder:
                return 'review order';
            case InteractionType.completeProcess:
                return 'complete process';
            case InteractionType.buttonClick:
                return 'button click';
            case InteractionType.buttonImpression:
                return 'button impression';
            default:
                throw new Error('Method not implemented');
        }
    }
}

export interface IGAVisitorInteraction {
    event?: string;
    interaction_name?: string;
    form_name?: string;
    form_field?: string;
    form_message?: string;
    sc_type?: string;
    sc_name?: string;
    quick_links_loc?: string;
    quick_links_name?: string;
    tab_name?: string;
    file_type?: string;
    file_name?: string;
    filter_name?: string;
    filter_value?: string;
    button_action?: string;
    button_page?: string;
    button_campaign?: string;
    ecommerce?: any;
    organisation_id?: string
}

export enum InteractionType {
    formTracking = 1,
    formSubmit = 2,
    formError = 3,
    saveChanges = 4,
    quickLinksCLick = 5,
    changeTab = 6,
    downloadFile = 7,
    exportFile = 8,
    applyFilter = 9,
    selectService = 10,
    selectLicence = 11,
    reviewOrder = 12,
    completeProcess = 13,
    buttonClick = 14,
    buttonImpression = 15,
}
