import { Component, Input } from '@angular/core';

import { CardVariant } from '../card-variant.enum';

@Component({
    selector: 'cm-card-title',
    templateUrl: './card-title.component.html',
})
export class CardTitleComponent {
    @Input() variant: CardVariant = CardVariant.default;

    cardTitleVariant: typeof CardVariant = CardVariant;
}
