import { adjacencyGraphs, dictionary as commonPackageDictionary } from '@zxcvbn-ts/language-common';
import { dictionary as enPackageDictionary, translations } from '@zxcvbn-ts/language-en';

// todo: specify type
// tslint:disable-next-line:naming-convention
export const CONFIG_OPTIONS: any = {
    translations,
    graphs: adjacencyGraphs,
    dictionary: {
        ...commonPackageDictionary,
        ...enPackageDictionary,
    },
};
