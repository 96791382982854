import {
    IDisasterRecoveryConfirmModel,
    IDisasterRecoveryInfoModel,
    IDisasterRecoveryModel
} from '../interfaces/email-security-disaster-recovery-modal.types';

export const DISASTER_RECOVERY_MODEL_INIT_VALUES: IDisasterRecoveryModel = {
    Address: '',
    StartDate: null,
    EndDate: null,
    Deliver: '',
    Password: '',
}

export const DISASTER_RECOVERY_INFO_MODEL_INIT_VALUES: IDisasterRecoveryInfoModel = {
    DomainName: '',
    IsUserMessage: false,
    IsDomMessage: false,
    MaxDate: '',
    MinDate: '',
    Deliver: '',
};

export const DISASTER_RECOVERY_CONFIRM_MODEL_INIT_VALUES: IDisasterRecoveryConfirmModel = {
    Restore: '',
    FromDate: '',
    ToDate: '',
    EmailCount: '',
    Duration: '',
    ResDomain: '',
    EpFrom: '',
    EpTo: '',
    Deliver: '',
};
