import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SubMenuFacadeService } from './sub-menu.facade.service';

@Component({
    selector: 'app-sub-menu',
    templateUrl: './sub-menu.component.html',
    styleUrls: ['./sub-menu.component.scss'],
    providers: [SubMenuFacadeService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubMenuComponent<T extends { routerLink: string; title: string }>
    implements OnInit, OnDestroy
{
    // eslint-disable-next-lineno-any
    @ContentChild(TemplateRef) tmpl: TemplateRef<any>;
    @Input() items: T[];
    isScreenSmall$: Observable<boolean> = this._subMenuFacadeService.isScreenSmall$;
    selectedTab$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    private readonly _destroyTrigger$: Subject<void> = new Subject<void>();

    constructor(private _subMenuFacadeService: SubMenuFacadeService<T>) {}

    ngOnInit(): void {
        this._subMenuFacadeService.isScreenSmall$
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe(() =>
                this.selectedTab$.next(this._subMenuFacadeService.getSelectedLinkName(this.items))
            );
    }

    ngOnDestroy(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }

    handleSelectedTab(name: string): void {
        this.selectedTab$.next(name);
    }
}
