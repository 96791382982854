import { AfterViewInit, Component } from '@angular/core';

import { PageViewMethod } from '../../../../shared/services/ga-service/ga-page-load';
import { GAService } from '../../../../shared/services/ga-service/ga-service';
import { AbstractPageLoad } from '../../../../shared/services/ga-service/shared-classes';

@Component({
    selector: 'no-access-resources',
    templateUrl: './no-access-resources.component.html',
    styleUrls: ['./no-access-resources.component.scss'],
})
export class NoAccessResourcesComponent extends AbstractPageLoad implements AfterViewInit {
    readonly pageName: string = 'no-access-resources';
    readonly pageType: string = 'no-access-resources';
    readonly pageViewMethod: PageViewMethod = PageViewMethod.page;

    constructor(protected _gaService: GAService) {
        super(_gaService);
    }

    ngAfterViewInit(): void {
        this.recordPageLoad();
    }
}
