import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SignInGuard } from './guards/sign-in.guard';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { HandleExtAuthComponent } from './pages/handle-ext-auth/handle-ext-auth.component';
import { LinkAccountComponent } from './pages/link-account/link-account.component';
import { LoginComponent } from './pages/login/login.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';

const administrationRoutes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'sign-in', component: SignInComponent, canActivate: [SignInGuard] },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'change-password/:usertype/:username/:GUID', component: ChangePasswordComponent },
    { path: 'change-password', component: ChangePasswordComponent },
    { path: 'handle-ext-auth', component: HandleExtAuthComponent },
    { path: 'link-account', component: LinkAccountComponent },
];

@NgModule({
    imports: [RouterModule.forChild(administrationRoutes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {}
