import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable, of } from 'rxjs';

import { IFinalizingLoginStrategy } from '../../../interfaces/finalizing-login-strategy.interface';
import { RedirectionServiceManagementService } from '../../redirect/redirection-service-management.service';
import { LoginJumpService } from '../login-strategy/login-jump.service';

import { AdminJumpFinalizingService } from './admin-jump-finalizing.service';
import { CloudLoginFinalizingService } from './cloud-login-finalizing.service';
import { RedirectFinalizingService } from './redirect-finalizing.service';

@Injectable({
    providedIn: 'root',
})
export class LoginFinalizingStrategySelector {
    constructor(
        private _cloudLoginService: CloudLoginFinalizingService,
        private _loginJumpService: LoginJumpService,
        private _redirectService: RedirectFinalizingService,
        private _adminJumpFinalizingService: AdminJumpFinalizingService,
        private _redirectionServiceManagementService: RedirectionServiceManagementService
    ) {
    }

    select$(params: Params): Observable<IFinalizingLoginStrategy> {
        const isRedirectedService: boolean = this._redirectionServiceManagementService.isServiceRedirectionFlow(params);

        return of(this._select(isRedirectedService, params));
    }

    private _select(isRedirectedService: boolean, params: Params): IFinalizingLoginStrategy {
        let finalizingLoginStrategy: IFinalizingLoginStrategy;
        if (isRedirectedService) {
            finalizingLoginStrategy = this._redirectService;
        } else if (this._loginJumpService.isSysOpsLogin(params)) {
            finalizingLoginStrategy = this._adminJumpFinalizingService;
        } else {
            finalizingLoginStrategy = this._cloudLoginService;
        }

        return finalizingLoginStrategy;
    }
}
