/* eslint-disable @angular-eslint/use-component-view-encapsulation */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { IArticle } from '../../../../models/knowledgebase/article.interface';
import { KnowledgeBaseService } from '../../services/knowledge-base/knowledge-base.service';

@Component({
    selector: 'app-giacom-knowledge-base',
    templateUrl: './knowledge-base.component.html',
    styleUrls: ['./knowledge-base.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class KnowledgeBaseComponent implements OnInit {
    isClose: boolean = false;

    get currentArticle(): IArticle {
        return this._knowledgeBaseService.currentArticle;
    }

    get currentCategory(): IArticle {
        return this._knowledgeBaseService.currentCategory;
    }

    get categoryArticles(): IArticle[] {
        return this._knowledgeBaseService.categoryArticles;
    }

    get loading(): boolean {
        return (
            this._knowledgeBaseService.currentArticle === null &&
            this._knowledgeBaseService.currentCategory === null
        );
    }

    constructor(private _knowledgeBaseService: KnowledgeBaseService) {}

    ngOnInit(): void {
        this.isClose = false;
    }

    onClose(): void {
        const closeDelay: number = 1000;

        setTimeout(() => {
            this._knowledgeBaseService.displayKnowledgeBase = false;
        }, closeDelay);
        this.isClose = true;
    }

    showArticle(article: IArticle): void {
        this._knowledgeBaseService.getArticle(article.Id);
    }

    viewCategory(id: number): void {
        this._knowledgeBaseService.getCategory(id);
        this._knowledgeBaseService.getCategoryArticles(id);
        this._knowledgeBaseService.clearArticle();
    }
}
