import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderBy', pure: false })
export class OrderByPipe implements PipeTransform {
    transform<T>(array: T[], orderField: string, orderType: boolean): T[] {
        if (!array) {
            return;
        }

        array.sort((a: T, b: T) => {
            const ae: T = a[orderField];
            const be: T = b[orderField];

            if (ae === undefined && be === undefined) {
                return 0;
            }
            if (ae === undefined && be !== undefined) {
                return orderType ? 1 : -1;
            }
            if (ae !== undefined && be === undefined) {
                return orderType ? -1 : 1;
            }
            if (ae === be) {
                return 0;
            }

            return orderType
                ? ae.toString().toLowerCase() > be.toString().toLowerCase()
                    ? -1
                    : 1
                : be.toString().toLowerCase() > ae.toString().toLowerCase()
                ? -1
                : 1;
        });

        return array;
    }
}
