import { AzureUsageBy, AzureUsagePeriod } from './azure-usage.enum';

export const AZURE_USAGE_DROPDOWN_DATES: Record<AzureUsagePeriod, string> = {
    [AzureUsagePeriod.last7Days]: '7',
    [AzureUsagePeriod.last14Days]: '14',
    [AzureUsagePeriod.last28Days]: '28',
};

export const AZURE_DROPDOWN_BY_INITIAL_VALUES: { value: string; text: string }[] = [
    { value: AzureUsageBy.inTotal.toString(), text: 'MANAGE_SERVICE.AZURE_BILLING.IN_TOTAL' },
    { value: AzureUsageBy.byGroup.toString(), text: 'MANAGE_SERVICE.AZURE_BILLING.BY_GROUP' },
    { value: AzureUsageBy.byResource.toString(), text: 'MANAGE_SERVICE.AZURE_BILLING.BY_RESOURCE' },
    { value: AzureUsageBy.byMeter.toString(), text: 'MANAGE_SERVICE.AZURE_BILLING.BY_METER' },
];

export const AZURE_DROPDOWN_DATES_INITIAL_VALUES: { value: string; text: string }[] = [
    {
        value: AZURE_USAGE_DROPDOWN_DATES[AzureUsagePeriod.last7Days],
        text: 'MANAGE_SERVICE.AZURE_BILLING.LAST_7_DAYS',
    },
    {
        value: AZURE_USAGE_DROPDOWN_DATES[AzureUsagePeriod.last14Days],
        text: 'MANAGE_SERVICE.AZURE_BILLING.LAST_14_DAYS',
    },
    {
        value: AZURE_USAGE_DROPDOWN_DATES[AzureUsagePeriod.last28Days],
        text: 'MANAGE_SERVICE.AZURE_BILLING.LAST_28_DAYS',
    },
];
