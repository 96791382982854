import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { IExecutionResult } from '../../../../core/interfaces/execution-result.interface';
import { GlobalEventService } from '../../../../core/services/global-event.service';
import { AuthorizedHttpService } from '../../../../core/services/http/authorized-http.service';
import { AbstractPopup } from '../../../../feature-modules/popups/classes/abstract-popup.class';
import { BaseUrl } from '../../../../shared/urls/base.url';

import { CmPopupService } from './cm-popup.service';
import {
    CloudMarketPopupPropertyDependencyType,
    CloudMarketPopupType,
    ICloudMarketPopup,
    ICloudMarketPopupSchema,
    ICloudMarketPopupSchemaItem,
    RequestMethod,
} from './cm-popup.types';

@Component({
    selector: 'cm-popup',
    templateUrl: './cm-popup.component.html',
})
export class CloudMarketPopupComponent extends AbstractPopup<ICloudMarketPopup> implements OnInit {
    model: ICloudMarketPopup;
    popupType: typeof CloudMarketPopupType = CloudMarketPopupType;
    error: boolean = false;
    confirm: string | null = null;
    hiddenProperties: string[] = [];
    disabledProperties: string[] = [];
    processing: boolean = false;
    private _loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    get loading$(): Observable<boolean> {
        return this._loading$;
    }

    constructor(
        private _eventService: GlobalEventService,
        private _authorizedHttpService: AuthorizedHttpService,
        private _cmPopupService: CmPopupService
    ) {
        super();
    }

    fetchSchema(): void {
        if (this.model.Schema) {
            return;
        }

        this._cmPopupService
            .fetchSchema$(this.model.ApiGetSchemaPath)
            .subscribe((schema: ICloudMarketPopupSchema) => {
                this.model.Schema = schema;
                this.setUntouched(true);
                this.processDependencies();
                this._updateLoadingState();
            });
    }

    fetchData(): void {
        if (this.model.Data) {
            return;
        }

        this._cmPopupService.fetchData$(this.model.ApiGetDataPath).subscribe((data: unknown) => {
            this.model.Data = data;
            this.processDependencies();
            this._updateLoadingState();
        });
    }

    processDependencies(): void {
        if (!this.model?.Schema?.PropertyDependencies || !this.model?.Data) {
            return;
        }
        this.hiddenProperties = [];
        this.disabledProperties = [];
        for (const dependency of this.model.Schema.PropertyDependencies) {
            const dataItem: unknown = this.model.Data[dependency.PropertyDataItem];

            if (dataItem !== dependency.PropertyDataItemDependencyValue) {
                return;
            }

            if (dependency.Type === CloudMarketPopupPropertyDependencyType.disable) {
                this.disabledProperties.push(dependency.DependentPropertyDataItem);
            } else if (dependency.Type === CloudMarketPopupPropertyDependencyType.hide) {
                this.hiddenProperties.push(dependency.DependentPropertyDataItem);
            }
        }
    }

    isHidden(schemaItem: ICloudMarketPopupSchemaItem): boolean {
        return this.hiddenProperties.indexOf(schemaItem.DataItem) !== -1;
    }

    isDisabled(schemaItem: ICloudMarketPopupSchemaItem): boolean {
        return this.disabledProperties.indexOf(schemaItem.DataItem) !== -1;
    }

    setUntouched(untouched: boolean): void {
        if (!this.model.Schema) {
            return;
        }
        for (const schemaItem of this.model.Schema.Items) {
            schemaItem.Untouched = untouched;
        }
    }

    submitClick(): void {
        this.processing = true;
        const valid: boolean = this.validate();

        this.setUntouched(false);

        if (!valid) {
            this.processing = false;

            return;
        }

        let response$: Observable<IExecutionResult<unknown>> = new Observable<
            IExecutionResult<unknown>
        >();

        if (this.model.ApiSubmitRequestMethod === RequestMethod.post) {
            response$ = this._authorizedHttpService.post$(
                `${BaseUrl.baseUrl}${this.model.ApiSubmitPath}`,
                this.model.Data
            );
        }
        if (this.model.ApiSubmitRequestMethod === RequestMethod.delete) {
            response$ = this._authorizedHttpService.deleteWithBody$(
                `${BaseUrl.baseUrl}${this.model.ApiSubmitPath}`,
                this.model.Data
            );
        }
        if (this.model.ApiSubmitRequestMethod === RequestMethod.put) {
            response$ = this._authorizedHttpService.put$(
                `${BaseUrl.baseUrl}${this.model.ApiSubmitPath}`,
                this.model.Data
            );
        }

        response$.subscribe((res: IExecutionResult<unknown>) => {
            if (res.Succeed) {
                this.processing = false;
                if (this.model.Schema.UpdateEvent) {
                    this.model.Schema.UpdateEvent.split('|').forEach((event: string) =>
                        this._eventService.publish(event)
                    );
                }
                if (res.Messages.length > 0) {
                    this.confirm = res.Messages[0];
                } else {
                    this.model = null;
                    this.close();
                }
            } else {
                this.processing = false;
                this.error = true;
            }
        });
    }

    validate(schemaItemToValidate?: ICloudMarketPopupSchemaItem): boolean {
        this.processDependencies();

        let valid: boolean = true;

        const data: unknown = this.model.Data;
        const schema: ICloudMarketPopupSchema = this.model.Schema;

        const properties: string[] = schemaItemToValidate
            ? [schemaItemToValidate.DataItem]
            : Object.keys(data);

        for (const property of properties) {
            const value: unknown = data[property];
            const schemaItems: ICloudMarketPopupSchemaItem[] = schema.Items.filter(
                (item: ICloudMarketPopupSchemaItem) => item.DataItem === property
            );

            if (schemaItems.length !== 1) {
                return;
            }

            const schemaItem: ICloudMarketPopupSchemaItem = schemaItems[0];

            schemaItem.Valid = true;
            const isPropertyHidden: boolean = this.hiddenProperties.some(
                (hiddenProperty: string) => hiddenProperty === property
            );

            if (schemaItem.Regex && !isPropertyHidden) {
                const regex: RegExp = new RegExp(schemaItem.Regex);
                const str: string = value.toString();

                if (!str.match(regex)) {
                    valid = false;
                    schemaItem.Valid = false;
                }
            }
            if (schemaItem.RequiredValue && value !== schemaItem.RequiredValue) {
                valid = false;
                schemaItem.Valid = false;
            }
        }

        return valid;
    }

    updateParams(val: ICloudMarketPopup): void {
        if (val) {
            this.model = val;
        }
    }

    ngOnInit(): void {
        this.fetchSchema();
        this.fetchData();
        this.setUntouched(true);
        this.processDependencies();
    }

    private _updateLoadingState(): void {
        if (this.error) {
            this._loading$.next(false);

            return;
        }

        this._loading$.next(!this.model || !this.model.Schema || !this.model.Data);
    }
}
