import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WINDOW } from '../../../../core/injection-tokens/window.injection-token';
import { IExecutionResult } from '../../../../core/interfaces/execution-result.interface';
import { ApiFeatureFlagsService } from '../../../../core/services/api/api-feature-flags.service';
import { ApiKnowledgeBaseArticlesService } from '../../../../core/services/api/knowledgebase/api-knowledge-base-articles.service';
import { AuthorizedHttpService } from '../../../../core/services/http/authorized-http.service';
import { IArticle } from '../../../../models/knowledgebase/article.interface';
import {
    IKnowledgeBaseResultModel,
    IKnowledgeBaseSearch,
} from '../../../../models/service/knowledgebase.model';
import { API_ENDPOINTS } from '../../../../shared/constants/api/api-endpoints.constants';
import { ROUTES_PATHS } from '../../../../shared/constants/routes.constants';
import { STORAGE_KEYS } from '../../../../shared/constants/storage-keys.constants';

@Injectable({
    providedIn: 'root',
})
export class KnowledgeBaseService {
    currentArticle: IArticle;
    currentCategory: any;
    categoryArticles: IArticle[];
    displayKnowledgeBase: boolean = false;

    constructor(
        @Inject(WINDOW) private _window: Window,
        private _authorizedHttpService: AuthorizedHttpService,
        private _featureService: ApiFeatureFlagsService,
        private _apiArticlesService: ApiKnowledgeBaseArticlesService
    ) {}

    getArticle(id: number): void {
        this.currentArticle = null;
        this._authorizedHttpService
            .get$(API_ENDPOINTS.KNOWLEDGEBASE.GET_ARTICLE(id))
            .subscribe((data: IExecutionResult<IArticle>) => {
                this.currentArticle = data.Result;

                if (this.currentArticle.Categories.length) {
                    this.getCategory(this.currentArticle.Categories[0]);
                }
            });
    }

    getArticleByPath(path: string): void {
        this.currentArticle = null;
        const pathEncoded: string = path.replace(new RegExp('/', 'g'), '_');

        this._authorizedHttpService
            .get$(API_ENDPOINTS.KNOWLEDGEBASE.GET_ARTICLE_BY_PATH(pathEncoded))
            .subscribe((data: IExecutionResult<IArticle>) => {
                this.currentArticle = data.Result;
                if (this.currentArticle.Categories.length > 0) {
                    this.getCategory(this.currentArticle.Categories[0]);
                }
            });
    }

    getCategory(categoryId: number): void {
        this.currentCategory = null;
        this._authorizedHttpService
            .get$(API_ENDPOINTS.KNOWLEDGEBASE.GET_CATEGORY(categoryId))
            .subscribe((result: IExecutionResult<any>) => {
                this.currentCategory = result.Result;
            });
    }

    getCategoryArticles(categoryId: number): void {
        this.categoryArticles = null;
        this._authorizedHttpService
            .get$(API_ENDPOINTS.KNOWLEDGEBASE.GET_ARTICLES_FOR_CATEGORY(categoryId))
            .subscribe((data: IExecutionResult<IArticle[]>) => {
                this.categoryArticles = data.Result;
            });
    }

    showRoot(): void {
        this.clearArticle();
        this.categoryArticles = null;
        this.getCategory(0);
    }

    clearArticle(): void {
        this.currentArticle = null;
    }

    getPopularArticles$(): Observable<IArticle[]> {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.KNOWLEDGEBASE.GET_POPULAR_ARTICLES)
            .pipe(map((result: IExecutionResult<IArticle[]>) => result.Result));
    }

    getArticles$(searchTerms: string): Observable<IArticle[]> {
        return this._authorizedHttpService
            .get$(
                API_ENDPOINTS.KNOWLEDGEBASE.SEARCH_ARTICLES(searchTerms)
            )
            .pipe(map((result: IExecutionResult<IArticle[]>) => result.Result));
    }

    getArticleLinks$(
        types?: string[],
        lookups?: string[]
    ): Observable<IKnowledgeBaseResultModel[]> {
        const searchModel: IKnowledgeBaseSearch = {
            KBTypes: types,
            KBLookups: lookups,
        };

        return this._apiArticlesService.getArticles$(searchModel);
    }

    openArticle(kbLink: IKnowledgeBaseResultModel): void {
        const isKnowledgeBaseEnabled: boolean =
            this._featureService.isFeatureFlagEnabled('Knowledgebase');

        if (isKnowledgeBaseEnabled && kbLink.HelpJuiceUrl?.length > 0) {
            this.openHelpJuiceArticle(kbLink.HelpJuiceUrl);
        } else {
            this.openWordPressArticle(kbLink.WordPressId);
        }
    }

    openWordPressArticle(articleId: number): void {
        this.getArticle(articleId);
        this.displayKnowledgeBase = true;
    }

    redirectToHelpJuice(token: string, siteBaseUrl$: BehaviorSubject<string>): void {
        siteBaseUrl$.subscribe((siteBaseUrl: string) => {
            const helpJuiceUrl: string = ROUTES_PATHS.EXTERNAL.KNOWLEDGE_BASE.HELPJUICE;
            const subdomain: string = 'cloudmarket';
            const articlePath: string = localStorage.getItem(STORAGE_KEYS.ARTICLE_PATH);
            const fallbackUrl: string = localStorage.getItem(STORAGE_KEYS.FALLBACK_URL);
            const envUrlPart: string = this._generateEnvUrlPart(siteBaseUrl);

            const cloudMarketKnowledgeBaseUrl: string =
                ROUTES_PATHS.EXTERNAL.KNOWLEDGE_BASE.CLOUDMARKET_KNOWLEDGEBASE_WITH_FALLBACK_GENERATED(
                    envUrlPart
                );

            this._window.location.href = this.generateKnowledgebaseUrl(
                helpJuiceUrl,
                subdomain,
                cloudMarketKnowledgeBaseUrl,
                token,
                articlePath,
                fallbackUrl
            );
        });
    }

    getHelpJuiceToken$(): Observable<string> {
        return this._authorizedHttpService.get$<string>(
            API_ENDPOINTS.KNOWLEDGEBASE.GET_HELP_JUICE_TOKEN
        );
    }

    openHelpJuiceArticle(helpJuiceUrl: string): void {
        window.open(helpJuiceUrl);
    }

    private _generateEnvUrlPart(siteBaseUrl: string): string {
        if (
            siteBaseUrl === ROUTES_PATHS.EXTERNAL.CLOUDMARKET_UAT_BASE_URL ||
            siteBaseUrl === ROUTES_PATHS.EXTERNAL.CLOUDMARKET_DEV_BASE_URL
        ) {
            return '.uat';
        }

        return '';
    }

    private generateKnowledgebaseUrl(
        helpJuiceUrl: string,
        subdomain: string,
        cloudMarketKnowledgeBaseUrl: string,
        token: string,
        articlePath: string,
        fallbackUrl: string
    ): string {
        if (articlePath) {
            return ROUTES_PATHS.EXTERNAL.KNOWLEDGE_BASE.ARTICLE(
                helpJuiceUrl,
                subdomain,
                token,
                cloudMarketKnowledgeBaseUrl,
                articlePath
            );
        } else if (fallbackUrl) {
            return ROUTES_PATHS.EXTERNAL.KNOWLEDGE_BASE.URL_WITH_INIT_FALLBACK_SET(
                helpJuiceUrl,
                subdomain,
                token,
                fallbackUrl
            );
        }

        return ROUTES_PATHS.EXTERNAL.KNOWLEDGE_BASE.HOMEPAGE(
            helpJuiceUrl,
            subdomain,
            token,
            cloudMarketKnowledgeBaseUrl
        );
    }
}
