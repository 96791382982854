import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { GlobalEventService } from '../../core/services/global-event.service';
import { PopupService } from '../../feature-modules/popups/services/popup.service';
import { ROUTES_PATHS } from '../../shared/constants/routes.constants';
import { IResetPasswordPayload } from '../services/components/exchange/tabs/reset-password-tab/reset-password-payload.interface';

import { IListedUser } from './interfaces/listed-user.interface';

@Injectable({
    providedIn: 'root',
})
export class CustomerCommandsService {
    constructor(
        private _router: Router,
        private _popupService: PopupService,
        private _eventService: GlobalEventService
    ) {}

    addService(userIdentity: number, organisationId: string): void {
        this._popupService.show(null, 'select-service', {
            organisationId,
            userId: userIdentity,
            level: 'user',
        });

        this._eventService.publish('addServiceContext');
        setTimeout(() => {
            this._eventService.publish('addServiceContext');
            /* eslint-disable @typescript-eslint/no-magic-numbers */
        }, 500);
    }

    assignAdminPermission(model: IListedUser, organisationId: string): void {
        this._popupService.show(null, 'assign-customer-admin', {
            organisationId,
            user: model,
        });
    }

    deleteUser(userId: number, organisationId: string): void {
        const id: string = `${organisationId}+${userId}+`;

        this._router.navigate([`${ROUTES_PATHS.CUSTOMER.ACTIONS.DELETE_USER(id)}`]);
    }

    manageUser(userId: number, organisationId: string, isSuspended: boolean): void {
        this._router.navigate([
            `${ROUTES_PATHS.CUSTOMER.ACTIONS.USER_DETAILS}`,
            `${userId}+${organisationId}+${isSuspended}`,
        ]);
    }

    removeAdminPermission(userName: string): void {
        this._popupService.show(null, 'revert-customer-admin', {
            Id: userName,
            FullAccountName: userName,
        });
    }

    resetPassword(userId: number, userName: string, isProductUserContext: boolean, userType?: boolean): void {
        const resetPasswordPayload: IResetPasswordPayload = {
            user_id: userId,
            user_name: userName,
            user_type: userType ? 'EndUserAdmin' : 'CustomerUser',
            is_product_user_context: isProductUserContext
        };

        this._popupService.show(null, 'reset-end-user-password', resetPasswordPayload);
    }
}
