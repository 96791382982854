import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { LoginType } from '../../../../shared/enum/login/login-type.enum';
import { LoginContext } from '../../services/login/login-context';

@Component({
    selector: 'app-handle-ext-auth',
    templateUrl: './handle-ext-auth.component.html',
})
export class HandleExtAuthComponent implements OnInit, OnDestroy {
    private _destroyTrigger$: Subject<void> = new Subject();

    constructor(
        private _loginContext: LoginContext
    ) {}

    ngOnInit(): void {
        this._loginContext.setStrategy(LoginType.ms365);
        this._loginContext.executeStrategy$()
            .subscribe();
    }

    ngOnDestroy(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }
}
