import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { SharedSessionCookieStorageService } from '../../../feature-modules/auth/services/cookie/shared-session-cookie-storage.service';

@Injectable({ providedIn: 'root' })
export class HardwareSessionManagementService {
    constructor(private _sharedSessionCookieStorageService: SharedSessionCookieStorageService) {}

    terminateHardwareSession$(): Observable<boolean> {
        this._sharedSessionCookieStorageService.removeHardwareSessionId();

        return of(true);
    }
}
