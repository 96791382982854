import { Component, Input } from '@angular/core';

@Component({
    selector: 'cm-text-header',
    templateUrl: './cm-text-header.component.html',
    styleUrls: ['./cm-text-header.component.scss'],
})
export class CmTextHeaderComponent {
    @Input() showBorder: boolean = true;
}
