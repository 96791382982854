import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';

import { BlockUiService } from '../services/block-ui.service';

@Injectable()
export class BlockUiInterceptor implements HttpInterceptor {
    constructor(private _blockUiService: BlockUiService) {}

    intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        const submitRequestTypes: string[] = ['post', 'put', 'delete'];

        return of(true).pipe(
            tap(() => {
                this._blockUiService.increaseRequestCount();

                if (submitRequestTypes.includes(req.method.toLowerCase())) {
                    this._blockUiService.increaseSubmitRequestCount();
                }
            }),
            switchMap(() => next.handle(req)),
            finalize(() => {
                this._blockUiService.decreaseRequestCount();

                if (submitRequestTypes.includes(req.method.toLowerCase())) {
                    this._blockUiService.decreaseSubmitRequestCount();
                }
            })
        );
    }
}
