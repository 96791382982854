import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IAdditionalServiceAgreement } from '../../../../models/agreement/interfaces/additional-service-agreement.interface';
import { API_ENDPOINTS } from '../../../../shared/constants/api/api-endpoints.constants';
import { IExecutionResult } from '../../../interfaces/execution-result.interface';
import { AuthorizedHttpService } from '../../http/authorized-http.service';

@Injectable({
    providedIn: 'root',
})
export class ApiServiceAgreementService {
    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    getInlineAgreements$(
        services: number[]
    ): Observable<IExecutionResult<IAdditionalServiceAgreement[]>> {
        return this._authorizedHttpService.post$(API_ENDPOINTS.SERVICES.GET_INLINE_AGREEMENTS, services);
    }

    getInlineAgreement$(
        serviceId: number,
        groupId?: number
    ): Observable<IExecutionResult<IAdditionalServiceAgreement>> {
        let path: string = API_ENDPOINTS.SERVICES.GET_INLINE_AGREEMENT(serviceId);

        if (groupId) {
            path = API_ENDPOINTS.SERVICES.GET_INLINE_AGREEMENT_FOR_GROUP(serviceId, groupId);
        }

        return this._authorizedHttpService.get$(path);
    }
}
