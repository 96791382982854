import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { SsoAuthService } from '../../../../core/services/sso-auth/sso-auth.service';
import { STORAGE_KEYS } from '../../../../shared/constants/storage-keys.constants';
import { LoginType } from '../../../../shared/enum/login/login-type.enum';
import { AuthService } from '../auth/auth.service';
import { SharedSessionCookieStorageService } from '../cookie/shared-session-cookie-storage.service';

@Injectable({
    providedIn: 'root',
})
export class RedirectionServiceManagementService {

    constructor(
        private _ssoAuthService: SsoAuthService,
        private _sharedSessionCookieStorageService: SharedSessionCookieStorageService,
        private _authService: AuthService,
    ) {
    }

    isServiceRedirectionFlow(params: Params): boolean {
        const isParamServiceExist: boolean = !!localStorage.getItem(STORAGE_KEYS.SERVICE) || params.service;

        if (isParamServiceExist) {
            this._setRedirectionParams(params);
        }

        return isParamServiceExist;
    }

    getLoginType$(params: Params): Observable<LoginType | null> {
        return this._isAuth0Session$(params)
            .pipe(
                map((isSsoRedirect: boolean) => {
                    if (isSsoRedirect) {
                        return LoginType.sso;
                    }

                    if (this._isCloudTokenValid(params)) {
                        return LoginType.nonSso;
                    }

                    return null;
                }),
            );
    }

    private _isAuth0Session$(params: Params): Observable<boolean> {
        return this.isServiceRedirectionFlow(params)
            ? this._tryCreateAuth0LocalSession$()
            : of(false);
    }


    private _isCloudTokenValid(params: Params): boolean {
        return this.isServiceRedirectionFlow(params)
            && this._authService.tokenNotExpired();
    }


    private _setRedirectionParams(queryParams: Params): void {
        const existingService: string = localStorage.getItem(STORAGE_KEYS.SERVICE);
        localStorage.removeItem(STORAGE_KEYS.FALLBACK_URL);
        localStorage.removeItem(STORAGE_KEYS.ARTICLE_PATH);


        if (!existingService && queryParams.service) {
            localStorage.setItem(STORAGE_KEYS.SERVICE, queryParams.service);
        }
        if (queryParams.article_path) {
            localStorage.setItem(STORAGE_KEYS.ARTICLE_PATH, queryParams.article_path);
        }
        if (queryParams.fallback_url) {
            localStorage.setItem(STORAGE_KEYS.FALLBACK_URL, queryParams.fallback_url);
        }
    }


    private _tryCreateAuth0LocalSession$(): Observable<boolean> {
        return this._ssoAuthService
            .isAuthenticated$()
            .pipe(
                concatMap((isAuthenticated: boolean) => {
                    const options: GetTokenSilentlyOptions = isAuthenticated
                        ? undefined
                        : {
                            cacheMode: 'off',
                            authorizationParams: {
                                organization: this._sharedSessionCookieStorageService.getOrganisationId(),
                            },
                        };

                    return this._ssoAuthService.getAccessTokenSilently$(options)
                        .pipe(
                            map((token: string | null) => !!token),
                            catchError(() => of(false)));
                }),
            );
    }
}
