import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-dropdown',
    templateUrl: './router-dropdown.component.html',
    styleUrls: ['./router-dropdown.component.scss'],
})
export class RouterDropdownComponent<T> {
    // eslint-disable-next-lineno-any
    @ContentChild(TemplateRef) tmpl: TemplateRef<any>;
    @Input() tabItems: T[];
    @Input() selectedTab: string;
}
