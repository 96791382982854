import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { IExecutionResult } from '../../../../core/interfaces/execution-result.interface';
import { IHostedExchangeEmailRouteSetting } from '../../../../core/interfaces/hosted-exchange-email-route-setting.interface';
import { IHostedExchangeEmailRouteUpdate } from '../../../../core/interfaces/hosted-exchange-email-route-update.interface';
import { ISynchronisation } from '../../../../core/interfaces/synchronisation.interface';
import { AuthorizedHttpService } from '../../../../core/services/http/authorized-http.service';
import { API_ENDPOINTS } from '../../../../shared/constants/api/api-endpoints.constants';

/**
 * @todo: The class is used in various contexts - not only on customer page
 * (e.g. as a parameter for HEX popups which in fact are shared). It's worth to consider if
 * the class could be splitted to some kind of API service(s) and strictly related class
 * for customer page management.
 */
export class HostedExchangeManagement {
    set organisationId(val: string) {
        this._organisationId = val;
    }

    get organisationId(): string {
        return this._organisationId;
    }

    set userId(val: string | number) {
        this._userId = val;
    }

    get userId(): string | number {
        return this._userId;
    }

    flow: typeof ExchangeManagementFlow = ExchangeManagementFlow;
    selectedFlow: ExchangeManagementFlow = ExchangeManagementFlow.settings;
    serviceId: any;
    level: string;
    mailboxAddress: string;
    generalInfo: any;
    forwardingInfo: any;
    distributionGroups: any;
    publicFoldersEnabled: boolean;
    sharedMailboxEnabled: boolean;
    publicFolderPermission: any;
    sharedMailboxPermission: any;
    addressBook: any;
    aliases: any;
    permissionInfo: any;
    mailEnabledContacts: any;
    setting: any;
    exchangeVersion: any;
    private _organisationId: string;
    private _userId: string | number;

    constructor(
        private _router: Router,
    ) {}

    selectFlow(flow: ExchangeManagementFlow): void {
        this.selectedFlow = flow;
    }

    getExchangeVersion(api: AuthorizedHttpService): void {
        api.get$(
            API_ENDPOINTS.MAILBOX.CHECK_EXCHANGE_VERSION(this.organisationId)
        ).subscribe((exeResult: IExecutionResult<string>) => {
            if (exeResult.Succeed) {
                this.exchangeVersion = exeResult.Result;
            }
        });
    }

    getDomainInfoForEditing(
        api: AuthorizedHttpService,
        domain: string,
        fnc: (data: any) => void
    ): void {
        api.get$(
            API_ENDPOINTS.EXCHANGE_DOMAIN.GET_DOMAIN_INFO_FOR_EDITING(this.organisationId, domain)
        ).subscribe((json: any) => {
            if (fnc) {
                fnc(json);
            }
        });
    }

    getSettingInfo(api: AuthorizedHttpService): void {
        api.get$(
            API_ENDPOINTS.EXCHANGE_SETTING.GET_SETTING_INFO(this.organisationId)
        ).subscribe((json: IExecutionResult<any>) => {
            this.setting = json.Result;
        });
    }

    getClientMobileAccessInfo(api: AuthorizedHttpService, fnc: (data: any) => void): void {
        api.get$(
            API_ENDPOINTS.EXCHANGE_SETTING.CLIENT_MOBILE_ACCESS_INFO(this.organisationId)
        ).subscribe((json: any) => {
            if (fnc) {
                fnc(json);
            }
        });
    }

    getEmailRoutesInfo$(
        api: AuthorizedHttpService
    ): Observable<IExecutionResult<IHostedExchangeEmailRouteSetting>> {
        return api.get$(
            API_ENDPOINTS.EXCHANGE_SETTING.GET_EMAIL_ROUTE_SETTING(this.organisationId)
        );
    }

    getSettingSignatureInfo(api: AuthorizedHttpService, fnc: (data: any) => void): void {
        api.get$(
            API_ENDPOINTS.EXCHANGE_SETTING.GET_SIGNATURE_INFO(this.organisationId)
        ).subscribe((json: any) => {
            if (fnc) {
                fnc(json);
            }
        });
    }

    getSettingSynchroniseInfo(api: AuthorizedHttpService, fnc: (data: any) => void): void {
        api.get$(
            API_ENDPOINTS.EXCHANGE_SETTING.GET_SYNCHRONISE_INFO(this.organisationId)
        ).subscribe((json: any) => {
            if (fnc) {
                fnc(json);
            }
        });
    }

    getSettingSignatureOfDomain(
        api: AuthorizedHttpService,
        domain: string,
        fnc: (data: any) => void
    ): void {
        api.get$(API_ENDPOINTS.EXCHANGE_SETTING.GET_SIGNATURE_OF_DOMAIN(domain)).subscribe(
            (json: any) => {
                if (fnc) {
                    fnc(json);
                }
            }
        );
    }

    getSettingDisclaimerInfo(api: AuthorizedHttpService, fnc: (data: any) => void): void {
        api.get$(
            API_ENDPOINTS.EXCHANGE_SETTING.GET_DISCLAIMER_INFO(this.organisationId)
        ).subscribe((json: any) => {
            if (fnc) {
                fnc(json);
            }
        });
    }

    getSettingDisclaimerInfoOfAction(
        api: AuthorizedHttpService,
        action: string,
        fnc: (data: any) => void
    ): void {
        api.get$(
            API_ENDPOINTS.EXCHANGE_SETTING.GET_DISCLAIMER_INFO_FOR_ACTION(this.organisationId, action)
        ).subscribe((response: any) => {
            if (fnc) {
                fnc(response);
            }
        });
    }

    updateSettingSynchronisation(
        api: AuthorizedHttpService,
        model: ISynchronisation,
        fnc: (data: any) => void
    ): void {
        api.put$(
            API_ENDPOINTS.EXCHANGE_SETTING.GET_SYNCHRONISATION_INFO(this.organisationId),
            model
        ).subscribe((response: any) => {
            if (fnc) {
                fnc(response);
            }
        });
    }

    updateEmailDeliveryRoute$(
        api: AuthorizedHttpService,
        model: IHostedExchangeEmailRouteUpdate
    ): Observable<IExecutionResult<void>> {
        return api.post$(
            API_ENDPOINTS.EXCHANGE_SETTING.UPDATE_EMAIL_DELIVERY_ROUTE(this.organisationId),
            model
        );
    }

    updateSettingSignature(api: AuthorizedHttpService, model: any, fnc: (data: any) => void): void {
        api.put$(
            API_ENDPOINTS.EXCHANGE_SETTING.UPDATE_SIGNATURE_INFO(this.organisationId),
            model
        ).subscribe((response: any) => {
            if (fnc) {
                fnc(response);
            }
        });
    }

    updateSettingDisclaimer(
        api: AuthorizedHttpService,
        model: any,
        fnc: (data: any) => void
    ): void {
        api.put$(
            API_ENDPOINTS.EXCHANGE_SETTING.UPDATE_DISCLAIMER_INFO(this.organisationId),
            model
        ).subscribe((response: any) => {
            if (fnc) {
                fnc(response);
            }
        });
    }

    activatePublicFolder(api: AuthorizedHttpService, fnc: (data: any) => void): void {
        api.put$(
            API_ENDPOINTS.EXCHANGE_SETTING.UPDATE_PUBLIC_FOLDER(this.organisationId),
            {}
        ).subscribe((response: any) => {
            if (fnc) {
                fnc(response);
            }
        });
    }

    deletePublicFolder(api: AuthorizedHttpService, fnc: (data: any) => void): void {
        api.delete$(
            API_ENDPOINTS.EXCHANGE_SETTING.DELETE_PUBLIC_FOLDER(this.organisationId)
        ).subscribe((json: any) => {
            if (fnc) {
                fnc(json);
            }
        });
    }

    activateSharedMailbox(api: AuthorizedHttpService, fnc: (data: any) => void): void {
        api.put$(
            API_ENDPOINTS.EXCHANGE_SETTING.UPDATE_SHARED_MAILBOX(this.organisationId),
            {}
        ).subscribe((response: any) => {
            if (fnc) {
                fnc(response);
            }
        });
    }

    deleteSharedMailbox(api: AuthorizedHttpService, fnc: (data: any) => void): void {
        api.delete$(
            API_ENDPOINTS.EXCHANGE_SETTING.DELETE_SHARED_MAILBIOX(this.organisationId)
        ).subscribe((json: any) => {
            if (fnc) {
                fnc(json);
            }
        });
    }

    deleteDisclaimer(
        api: AuthorizedHttpService,
        disclaimerId: string,
        fnc: (data: any) => void
    ): void {
        api.deleteWithBody$(
            API_ENDPOINTS.EXCHANGE_SETTING.DELETE_DISCLAIMER(this.organisationId),
            { Id: disclaimerId }
        ).subscribe((json: any) => {
            if (fnc) {
                fnc(json);
            }
        });
    }

    getGeneralInfo(api: AuthorizedHttpService, fnc: (data: any) => void): void {
        api.get$(API_ENDPOINTS.MAILBOX.GET_GENERAL_INFO(this.mailboxAddress)).subscribe(
            (json: IExecutionResult<any>) => {
                this.generalInfo = json.Result;
                this.getPublicFoldersEnabled(api);
                this.getSharedMailboxEnabled(api);

                if (fnc) {
                    fnc(json);
                }
            }
        );
    }

    getPublicFoldersEnabled(api: AuthorizedHttpService): any {
        api.get$(
            API_ENDPOINTS.EXCHANGE_SETTING.GET_PUBLIC_FOLDERS_ENABLED(this.organisationId)
        ).subscribe((callResult: IExecutionResult<any>) => {
            if (callResult.Succeed) {
                this.publicFoldersEnabled = callResult.Result;
                this.getPublicFolderPermission(api);
            }
        });
    }

    getSharedMailboxEnabled(api: AuthorizedHttpService): any {
        api.get$(
            API_ENDPOINTS.EXCHANGE_SETTING.GET_SHARED_MAILBOX_ENABLED(this.organisationId)
        ).subscribe((callResult: IExecutionResult<boolean>) => {
            if (callResult.Succeed) {
                this.sharedMailboxEnabled = callResult.Result;
                this.getSharedMailboxPermission(api);
            }
        });
    }

    getPublicFolderPermission(api: AuthorizedHttpService): any {
        if (this.exchangeVersion === '2016') {
            api.get$(
                API_ENDPOINTS.EXCHANGE_SETTING.GET_SHARED_MAILBOX_PERMISSION(this.mailboxAddress)
            ).subscribe((callResult: IExecutionResult<any>) => {
                if (callResult.Succeed) {
                    this.publicFolderPermission = callResult.Result;
                }
            });
        } else {
            api.get$(
                API_ENDPOINTS.EXCHANGE_SETTING.GET_PUBLIC_FOLDERS_PERMISSION(this.mailboxAddress)
            ).subscribe((callResult: IExecutionResult<any>) => {
                if (callResult.Succeed) {
                    this.publicFolderPermission = callResult.Result;
                }
            });
        }
    }

    getSharedMailboxPermission(api: AuthorizedHttpService): any {
        api.get$(
            API_ENDPOINTS.EXCHANGE_SETTING.GET_SHARED_MAILBOX_PERMISSION(this.mailboxAddress)
        ).subscribe((callResult: IExecutionResult<any>) => {
            if (callResult.Succeed) {
                this.sharedMailboxPermission = callResult.Result;
            }
        });
    }

    getForwardingInfo(api: AuthorizedHttpService, fnc: (data: any) => void): void {
        api.get$(API_ENDPOINTS.MAILBOX.GET_FORWARDING_INFO(this.mailboxAddress)).subscribe(
            (json: IExecutionResult<any>) => {
                this.forwardingInfo = json.Result;

                if (fnc) {
                    fnc(json);
                }
            }
        );
    }

    getDistributionGroups(api: AuthorizedHttpService, fnc: (data: any) => void): void {
        api.get$(
            API_ENDPOINTS.MAILBOX.GET_DISTRIBUTION_GROUPS(this.mailboxAddress)
        ).subscribe((json: IExecutionResult<any>) => {
            this.distributionGroups = json.Result;

            if (fnc) {
                fnc(json);
            }
        });
    }

    getPermissionInfo(api: AuthorizedHttpService, callback?: (data: any) => void): void {
        api.get$(API_ENDPOINTS.EXCHANGE_PERMISSION.GET_PERMISSION_INFO(this.mailboxAddress)).subscribe(
            (json: IExecutionResult<any>) => {
                this.permissionInfo = json.Result;

                if (callback) {
                    callback(this.permissionInfo);
                }
            }
        );
    }

    getMailEnabledContacts(
        api: AuthorizedHttpService,
        pagingInput: any,
        callback: (result: any) => void
    ): void {
        api.post$(
            API_ENDPOINTS.EXCHANGE_MAIL_ENABLED_CONTACT.GET_MAIL_ENABLED_CONTACTS(this.organisationId),
            pagingInput
        ).subscribe((json: IExecutionResult<any>) => {
            this.mailEnabledContacts = json.Result;
            if (callback) {
                callback(json);
            }
        });
    }

    deleteUser(api: AuthorizedHttpService, user: any): void {
        const id: string = `${this.organisationId}+${this.userId}+${this.serviceId}`;

        this._router.navigate([`customers/customer/${id}/delete-service`]);
    }

    isHiddenUserFlow(): boolean {
        return true;
    }

    isSelectedUserFlow(): boolean {
        return false;
    }

    isSelectedSettingsFlow(): boolean {
        return this.selectedFlow === ExchangeManagementFlow.settings;
    }

    isSelectedAliasesFlow(): boolean {
        return this.selectedFlow === ExchangeManagementFlow.aliases;
    }

    isSelectedPermissionsFlow(): boolean {
        return this.selectedFlow === ExchangeManagementFlow.permissions;
    }

    isSelectedAddressBookFlow(): boolean {
        return this.selectedFlow === ExchangeManagementFlow.addressBook;
    }

    isSelectedDistributionGroupsFlow(): boolean {
        return this.selectedFlow === ExchangeManagementFlow.distributionGroups;
    }

    isSelectedMailEnabledContactsFlow(): boolean {
        return this.selectedFlow === ExchangeManagementFlow.mailEnabledContacts;
    }

    isSelectedDomainsFlow(): boolean {
        return this.selectedFlow === ExchangeManagementFlow.domains;
    }

    isSelectedDNSFlow(): boolean {
        return this.selectedFlow === ExchangeManagementFlow.dns;
    }

    isSelectedRoomMailboxesFlow(): boolean {
        return this.selectedFlow === ExchangeManagementFlow.roomMailboxes;
    }

    isSelectedAppImpersonationFlow(): boolean {
        return this.selectedFlow === ExchangeManagementFlow.appImpersonation;
    }
}

export enum ExchangeManagementFlow {
    settings = 0,
    users = 1,
    aliases = 2,
    permissions = 3,
    addressBook = 4,
    distributionGroups = 5,
    mailEnabledContacts = 6,
    domains = 7,
    dns = 8,
    roomMailboxes = 9,
    appImpersonation = 10,
}
