import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BUTTON_STYLE_CLASSES } from '../../constants/button-style-classes.constants';
import { ButtonVariant } from '../../interfaces/cm-button.types';

@Component({
    selector: 'g-button',
    templateUrl: './g-button.component.html',
    styleUrls: ['./g-button.component.scss'],
})
export class GButtonComponent implements OnInit {
    @Input() variant: ButtonVariant = ButtonVariant.primary;
    @Input() btnSize: 'small' | 'medium' | 'large' = 'medium';
    @Input() btnInverse: boolean = false;
    @Input() btnDisabled: boolean = false;
    @Input() btnFullWidth: 'true' | 'mobile-only';
    @Input() btnIconClass: string;
    @Input() btnIconPos: 'right' | 'left';
    @Input() btnHideTxtOnMobile: boolean;
    @Input() externalClasses: string = '';
    @Output() readonly btnClickEvent: EventEmitter<any> = new EventEmitter<any>();
    btnTypeClass: string;
    btnSizeClass: string;
    btnFullWidthClass: string = '';
    iconPosition: string;
    isIconBtn: boolean;
    readonly buttonStyle: Record<ButtonVariant, string> = BUTTON_STYLE_CLASSES;
    readonly buttonVariant: typeof ButtonVariant = ButtonVariant;

    ngOnInit(): void {
        this.btnSizeClass = this.setBtnSizeClass(this.btnSize);

        if (this.btnIconClass) {
            this.isIconBtn = true;
            this.iconPosition = this.setIconPosition(this.btnIconPos);
        }

        if (this.btnFullWidth) {
            this.btnFullWidthClass = this.setBtnFullWidth(this.btnFullWidth);
        }
    }

    setBtnTypeClass(type: 'primary' | 'secondary' | 'unstyled'): void {
        switch (type) {
            case 'secondary':
                this.variant = ButtonVariant.secondary;

                return;
            default:
                this.variant = ButtonVariant.primary;

                return;
        }
    }

    setBtnSizeClass(size: 'small' | 'medium' | 'large'): string {
        switch (size) {
            case 'small':
                return 'btn-sm';
            case 'large':
                return 'btn-lg';
            default:
                return 'btn-md';
        }
    }

    setIconPosition(position: 'right' | 'left'): string {
        switch (position) {
            case 'right':
                return 'right';
            default:
                return 'left';
        }
    }

    setBtnFullWidth(value: 'true' | 'mobile-only'): string {
        switch (value) {
            case 'mobile-only':
                return 'btn--full-width-mobile';
            default:
                return 'btn--full-width';
        }
    }

    onClick(e: Event): void {
        this.btnClickEvent.emit();
    }
}
