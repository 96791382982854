import { Injectable } from '@angular/core';

import { IExecutionResult } from '../../../core/interfaces/execution-result.interface';
import { ApiFooterService } from '../../../core/services/api/api-footer.service';
import { ICloudMarketButton } from '../../../shared/interfaces/cm-button.types';
import { AuthService } from '../../auth/services/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class FooterService {
    private _initializing: boolean = false;
    private _buttons: ICloudMarketButton[];

    constructor(private _apiFooterService: ApiFooterService, private _authService: AuthService) {}

    get buttons(): ICloudMarketButton[] {
        if (this._buttons || this._initializing || !this._authService.isLoggedIn('')) {
            return this._buttons;
        }
        this._initializing = true;
        this._apiFooterService
            .getFooterButtons$()
            .subscribe((res: IExecutionResult<ICloudMarketButton[]>) => {
                if (res.Succeed) {
                    this._buttons = res.Result;
                    this._initializing = false;
                }
            });
    }

    clear(): void {
        this._buttons = null;
    }
}
