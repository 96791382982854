import {
    AfterContentInit,
    AfterViewInit,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    TemplateRef,
    TrackByFunction,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { castArray, intersection, uniq, without } from 'lodash-es';
import { NgScrollbar } from 'ngx-scrollbar';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';

import { TABLE_GRID_COLUMN_SIZES } from '../../constants/table-grid-column-sizes.constants';
import { ITableColumnRef } from '../../interfaces/table-column-ref.interface';
import { ITableColumn } from '../../interfaces/table-column.interface';
import { CmTableColumnComponent } from '../table-column/cm-table-column.component';

@Component({
    selector: 'cm-table',
    templateUrl: './cm-table.component.html',
    styleUrls: ['./cm-table.component.scss'],
})
export class CmTableComponent<T> implements AfterContentInit, AfterViewInit {
    @Input() data: T[];
    @Input() hideHeaderRow: boolean = false;
    @Input() set maxHeight(maxHeight: number | string) {
        if(!maxHeight) {
            this.maxHeightStandarized = '0px';
        }
        this.maxHeightStandarized = typeof maxHeight === 'number' ? `${maxHeight}px` : maxHeight;
    };
    @Input() set minWidth(minWidth: number | string) {
        if(!minWidth) {
            this.minWidthStandarized = '500px';
        }
        this.minWidthStandarized = typeof minWidth === 'number' ? `${minWidth}px` : minWidth;
    }
    @Input() expandedRowOffset: number = 0;
    @Input() selectedRow: T | undefined = undefined;
    @Input() tableClasses: string = '';
    @Input() headerClasses: string = '';
    @Input() withoutBottomBorder: boolean = false;
    @Input() rowClickEnabled: boolean = false;
    @Output() readonly rowClick: EventEmitter<T> = new EventEmitter<T>();
    @ViewChild(NgScrollbar) scrollbar: NgScrollbar;
    @ViewChildren('modernTableCell') modernTableCells: QueryList<ElementRef<HTMLTableCellElement>>;
    @ViewChildren('modernTableHeaderCell') modernTableHeaderCells: QueryList<ElementRef<HTMLTableCellElement>>;
    @ContentChild('expandRowTemplate') expandRowTemplate: TemplateRef<unknown>;
    @ContentChildren(CmTableColumnComponent) columns: QueryList<CmTableColumnComponent>;
    columnRefs$: Observable<ITableColumnRef[]>;
    gridColumns: SafeStyle;
    gridColumns$: Observable<SafeStyle>;
    expandedRowIndexes: number[] = [];
    maxHeightStandarized: string = '';
    minWidthStandarized: string = '';

    constructor(
        private _domSanitizer: DomSanitizer,
    ) {}

    @Input() trackByFunction: TrackByFunction<unknown> = (index: number) => index;
    readonly trackByIndex: TrackByFunction<unknown> = (index: number) => index;

    ngAfterContentInit(): void {
        this._initColumnRefs();
    }

    ngAfterViewInit(): void {
        if (this.selectedRow !== undefined) {
            this.scrollToSelection();
        }
    }

    scrollToSelection(): void {
        const selectedRow: T | undefined = this.selectedRow;

        if (selectedRow === undefined) {
            return;
        }
        const firstExpandedRowIndex: number = this.data.indexOf(selectedRow);

        combineLatest([
            merge([
                this.modernTableCells.get(firstExpandedRowIndex * this.columns.length + 1),
            ]).pipe(
                filter(Boolean),
                first()
            ),
            this._getHeaderRowHeight$(),
        ])
            .pipe(
                first(),
                catchError(() => of([null, null]))
            )
            .subscribe(([elementToScrollTo, headerRowHeight]: [ElementRef, number]) => {
                if(elementToScrollTo && headerRowHeight) {
                    const scrollDelay: number = 300; // required to wait for scrollbar content to render

                    setTimeout(() => {
                        this.scrollbar.scrollToElement(elementToScrollTo.nativeElement, {
                            top: -headerRowHeight,
                        });
                    }, scrollDelay);
                }
            });
    }

    isRowExpanded(rowIndex: number): boolean {
        return this.expandedRowIndexes.includes(rowIndex);
    }

    toggleRow(rowIndex: number | number[]): void {
        const rowsToToggle: number[] = castArray(rowIndex);
        const rowsToCollapse: number[] = intersection(this.expandedRowIndexes, rowsToToggle);
        const rowsToExpand: number[] = without(rowsToToggle, ...rowsToCollapse);

        this.collapseRow(rowsToCollapse);
        this.expandRow(rowsToExpand);
    }

    expandAllRows(): void {
        this.expandRow(Array.from({ length: this.data.length }, (item: unknown, index: number) => index));
    }

    collapseAllRows(): void {
        this.collapseRow(
            Array.from({ length: this.data.length }, (item: unknown, index: number) => index)
        );
    }

    expandRow(rowIndex: number | number[]): void {
        this.expandedRowIndexes = uniq([...this.expandedRowIndexes, ...castArray(rowIndex)]);
    }

    collapseRow(rowIndex: number | number[]): void {
        this.expandedRowIndexes = without(this.expandedRowIndexes, ...castArray(rowIndex));
    }

    emitRowClickIfEnabled(entry: T): void {
        if (!this.rowClickEnabled) {
            return;
        }

        this.rowClick.emit(entry);
    }

    private _initColumnRefs(): void {
        this.columnRefs$ = this._getColumnChanges$()
            .pipe(map(this._toColumnRefChanges$))
            .pipe(
                switchMap((columnRefsObservables: Observable<ITableColumnRef>[]) =>
                    combineLatest(columnRefsObservables)
                )
            );
        this.gridColumns$ = this.columnRefs$.pipe(
            map(this._toGridColumns),
            map(this._domSanitizer.bypassSecurityTrustStyle),
            tap((gridColumns: SafeStyle) => {
                this.gridColumns = gridColumns;
            })
        );
    }

    private _getHeaderRowHeight$(): Observable<number> {
        return merge(
            this.modernTableHeaderCells.changes.pipe(
                filter(
                    (headerCells: QueryList<ElementRef<HTMLDivElement>>) => !!headerCells.length
                ),
                map((headerCells: QueryList<ElementRef<HTMLDivElement>>) => headerCells.toArray())
            ),
            of(this.modernTableHeaderCells.toArray())
        ).pipe(
            filter((headerCells: ElementRef<HTMLDivElement>[]) => !!headerCells.length),
            map((headerCells: ElementRef<HTMLDivElement>[]) =>
                headerCells.slice(0, this.columns.length - 1)
            ),
            map((headerCells: ElementRef<HTMLDivElement>[]) =>
                Math.max(
                    ...headerCells.map(
                        (headerCell: ElementRef<HTMLDivElement>) =>
                            headerCell.nativeElement.offsetHeight
                    )
                )
            )
        );
    }

    private _getColumnChanges$(): Observable<ITableColumn[]> {
        return merge(
            of(this.columns.toArray()),
            this.columns.changes.pipe(
                map((queryList: QueryList<ITableColumn>) => queryList.toArray())
            )
        );
    }

    private _toColumnRefChanges$(
        columnComponents: CmTableColumnComponent[]
    ): Observable<ITableColumnRef>[] {
        return columnComponents.map((columnComponent: CmTableColumnComponent) =>
            columnComponent.getColumnRefChanges$()
        );
    }

    private _toGridColumns(columnRefs: ITableColumnRef[]): string {
        return columnRefs
            .map((columnRef: ITableColumnRef) => TABLE_GRID_COLUMN_SIZES[columnRef.size])
            .join(' ');
    }
}
