import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, UrlSegment, UrlTree } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';

import { DefaultPageRouteService } from '../../../core/services/default-page-route.service';
import { ROUTES_PATHS } from '../../../shared/constants/routes.constants';
import { AuthService } from '../services/auth/auth.service';
import { LogoutService } from '../services/logout/logout.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
    constructor(
        private _router: Router,
        private _authService: AuthService,
        private _logoutService: LogoutService,
        private _defaultPageRouteService: DefaultPageRouteService
    ) {}

    intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        if (request.withCredentials) {
            return this._handleSessionValidation$(request, next);
        }

        return next.handle(request);
    }

    private _handleSessionValidation$<T>(
        request: HttpRequest<T>,
        next: HttpHandler
    ): Observable<HttpEvent<T>> {
        const cloudSessionExpired: boolean = this._authService.tokenExpired();
        if (cloudSessionExpired) {
            this._skipOrRedirectToLoginPage();

            return EMPTY;
        }

        return next.handle(request);
    }

    private _skipOrRedirectToLoginPage(): void {
        const stateUrl: string = this._router.routerState.snapshot.url;
        const urlTree: UrlTree = this._router.parseUrl(stateUrl);
        const firstSegment: UrlSegment = urlTree.root.children.primary.segments[0];

        if (firstSegment.path === ROUTES_PATHS.LOGIN) {
            return;
        }

        localStorage.clear();
        this._logoutService.logout();

        if (firstSegment.path === ROUTES_PATHS.SIGN_IN) {
            this._defaultPageRouteService.goToDefaultLoginPage();

            return;
        }

        this._defaultPageRouteService.goToDefaultLoginPage(stateUrl);
    }
}
