import { Component } from '@angular/core';

import { ROUTES_PATHS } from '../../../../shared/constants/routes.constants';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    readonly routes: typeof ROUTES_PATHS = ROUTES_PATHS;
}
