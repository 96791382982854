import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Params,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';

import { ROUTES_PATHS } from '../../../shared/constants/routes.constants';
import { LoginJumpService } from '../services/login/login-strategy/login-jump.service';

@Injectable({
    providedIn: 'root',
})
export class SignInGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _loginJumpService: LoginJumpService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree | boolean {
        const params: Params = next.queryParams;

        if (this._hasAccessToSignInPage(params)) {
            return true;
        }

        return this._router.createUrlTree([ROUTES_PATHS.LOGIN], { queryParams: params });
    }

    isSysOpsLogin(params: Params): boolean {
        return this._loginJumpService.isSysOpsLogin(params);
    }

    private _hasAccessToSignInPage(params: Params): boolean {
        return this._isRedirectedFromLoginPage() || this.isSysOpsLogin(params);
    }

    private _isRedirectedFromLoginPage(): boolean {
        return !!this._router.getCurrentNavigation().extras.state?.userName;
    }
}
