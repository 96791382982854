export class ResetPasswordStateModel {
    userType: string;

    constructor(public token: string, public userName: string, userType: string) {
        this.userType = this.getUserType(userType);
    }

    getUserType(oldType: string): string {
        switch (oldType) {
            case 'enduseradmin':
            case 'enduser':
                return 'customeruser';
            default:
                return oldType;
        }
    }
}

export interface IResetPasswordModel {
    userName: string;
    userType: string;
    existingPassword: string;
    newPassword: string;
}

export interface IChangePasswordModel {
    userName: string;
    userType: string;
    key: string;
    existingPassword: string;
    newPassword: string;
}

export interface IGeneratePasswordModel {
    userName: string;
    userType: string;
    firstName: string;
    LastName: string;
}
