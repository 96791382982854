import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SynchronizationStatus } from 'app/pages/billing/psa/enums/synchronization-status.enum';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { IIntegrationAzureBillingPeriod } from '../../../pages/billing/psa/interfaces/azure/integration-azure-billing-period.interface';
import { IIntegrationAzureChargeItem } from '../../../pages/billing/psa/interfaces/azure/integration-azure-charge-item.interface';
import { IIntegrationAzureChargeUpdateItem } from '../../../pages/billing/psa/interfaces/azure/integration-azure-charge-update.interface';
import { IIntegrationPsaContract } from '../../../pages/billing/psa/interfaces/integration-psa-contract.interface';
import {
    IntegrationProductMovementResponse,
    IntegrationProductMovementResponseItem,
    IntegrationReconciliationResponse,
    IntegrationReconciliationResponseItem,
} from '../../../pages/billing/psa/interfaces/integration-report.models';
import {
    IPsaItem,
    IPsaOption,
} from '../../../pages/billing/psa/interfaces/match-selector.types';
import { ISynchronizedData } from '../../../pages/billing/psa/interfaces/sync/synchronized-data.interface';
import {
    IUpdateIgnoreSalesOrdersModel,
    PsaIntegration,
    PsaIntegrationSettings,
} from '../../../pages/your-account/integration/integration.models';
import { API_ENDPOINTS } from '../../../shared/constants/api/api-endpoints.constants';
import { PsaEndpoints } from '../../../shared/constants/api/psa-endpoints.enum';
import { FEATURE_FLAGS } from '../../../shared/constants/features/feature-flags.constants';
import { BaseUrl } from '../../../shared/urls/base.url';
import { IExecutionResult } from '../../interfaces/execution-result.interface';
import { IOverviewServiceApi } from '../../interfaces/overview-service-api.interface';
import { AuthorizedHttpService } from '../http/authorized-http.service';

import { ApiFeatureFlagsService } from './api-feature-flags.service';

@Injectable({
    providedIn: 'root',
})
export class ApiPsaService {
    private readonly _newPsaFeatureFlag: string = FEATURE_FLAGS.PSA_NEW_SERVICE;

    constructor(
        private _authorizedHttpService: AuthorizedHttpService,
        private _featureFlagService: ApiFeatureFlagsService
    ) {}

    refreshCustomers$(): Observable<IExecutionResult<void>> {
        return this._authorizedHttpService.post$(this.getUrl(PsaEndpoints.refreshCustomers), null);
    }

    refreshServices$(): Observable<IExecutionResult<void>> {
        return this._authorizedHttpService.post$(this.getUrl(PsaEndpoints.refreshServices), null);
    }

    refreshAzure$(): Observable<IExecutionResult<void>> {
        return this._authorizedHttpService.post$(
            this.getUrl(PsaEndpoints.refreshAzureReport),
            null
        );
    }

    refreshProductMovement$(): Observable<IExecutionResult<void>> {
        return this._authorizedHttpService.post$(
            this.getUrl(PsaEndpoints.refreshProductMovementReport),
            null
        );
    }

    refreshReconciliation$(): Observable<IExecutionResult<void>> {
        return this._authorizedHttpService.post$(
            this.getUrl(PsaEndpoints.refreshReconciliationReport),
            null
        );
    }

    getCustomers$(): Observable<IExecutionResult<ISynchronizedData<IPsaItem[]>>> {
        return this._authorizedHttpService
            .get$(this.getUrl(PsaEndpoints.customerMappings))
            .pipe(
                map((result: IExecutionResult<ISynchronizedData<IPsaItem[]> | IPsaItem[]>) =>
                    this._wrapInSynchronizationDataIfNeeded(result)
                )
            );
    }

    getPsaCustomers$(): Observable<IExecutionResult<ISynchronizedData<IPsaOption[]>>> {
        return this._authorizedHttpService
            .get$(this.getUrl(PsaEndpoints.psaCustomers))
            .pipe(
                map((result: IExecutionResult<ISynchronizedData<IPsaOption[]> | IPsaOption[]>) =>
                    this._wrapInSynchronizationDataIfNeeded(result)
                )
            );
    }

    getServices$(): Observable<IExecutionResult<ISynchronizedData<IPsaOption[]>>> {
        return this._authorizedHttpService
            .get$(this.getUrl(PsaEndpoints.cmProducts))
            .pipe(
                map((result: IExecutionResult<ISynchronizedData<IPsaOption[]> | IPsaOption[]>) =>
                    this._wrapInSynchronizationDataIfNeeded(result)
                )
            );
    }

    getPsaServices$(): Observable<IExecutionResult<ISynchronizedData<IPsaItem[]>>> {
        return this._authorizedHttpService
            .get$(this.getUrl(PsaEndpoints.serviceMappings))
            .pipe(
                map((result: IExecutionResult<ISynchronizedData<IPsaItem[]> | IPsaItem[]>) =>
                    this._wrapInSynchronizationDataIfNeeded(result)
                )
            );
    }

    getProductMovement$(
        date: Date
    ): Observable<IExecutionResult<ISynchronizedData<IntegrationProductMovementResponse>>> {
        const url: string = this.getUrl(PsaEndpoints.productMovementReport).replace(
            '{date}',
            typeof date === 'string' ? date : date.toDateString()
        );

        return this._authorizedHttpService
            .get$(url)
            .pipe(
                map(
                    (
                        result: IExecutionResult<
                            | ISynchronizedData<IntegrationProductMovementResponse>
                            | IntegrationProductMovementResponse
                        >
                    ) => this._wrapInSynchronizationDataIfNeeded(result)
                )
            );
    }

    getProductMovementDateRange$(monthsToSubtract: number): Observable<IExecutionResult<Date[]>> {
        return this._authorizedHttpService.get$(
            `${this.getUrl(PsaEndpoints.productMovementDateRange)}/${monthsToSubtract}`
        );
    }

    getReconciliationReport$(): Observable<
        IExecutionResult<ISynchronizedData<IntegrationReconciliationResponse[]>>
    > {
        const url: string = this.getUrl(PsaEndpoints.reconciliationReport);

        return this._authorizedHttpService
            .get$(url)
            .pipe(
                map(
                    (
                        result: IExecutionResult<
                            | ISynchronizedData<IntegrationReconciliationResponse[]>
                            | IntegrationReconciliationResponse[]
                        >
                    ) => this._wrapInSynchronizationDataIfNeeded(result)
                )
            );
    }

    updateCustomers$(items: IPsaItem[]): Observable<IExecutionResult<boolean>> {
        const url: string = this.getUrl(PsaEndpoints.updateCustomerMappings);

        return this._authorizedHttpService.post$(url, items);
    }

    updateProducts$(services: IPsaItem[]): Observable<IExecutionResult<boolean>> {
        const url: string = this.getUrl(PsaEndpoints.updateProductMappings);

        return this._authorizedHttpService.post$(url, services);
    }

    updateProductMovement$(
        reportData: IntegrationProductMovementResponseItem[]
    ): Observable<IExecutionResult<void>> {
        return this._authorizedHttpService.post$(
            this.getUrl(PsaEndpoints.updateProductMovementReport),
            reportData
        );
    }

    updateReconciliation$(
        reportData: IntegrationReconciliationResponseItem[]
    ): Observable<IExecutionResult<void>> {
        return this._authorizedHttpService.post$(
            this.getUrl(PsaEndpoints.updateReconciliationReport),
            reportData
        );
    }

    getAvailableIntegrations$(): Observable<IExecutionResult<PsaIntegration[]>> {
        return this._authorizedHttpService.get$(this.getUrl(PsaEndpoints.availableIntegrations));
    }

    getSettings$(): Observable<IExecutionResult<PsaIntegrationSettings>> {
        return this._authorizedHttpService.get$(this.getUrl(PsaEndpoints.getSettings));
    }

    updateSettings$(data: PsaIntegrationSettings): Observable<IExecutionResult<void>> {
        const url: string = this.getUrl(PsaEndpoints.setSettings);

        return this._authorizedHttpService.post$(url, data);
    }

    updateIgnoreSalesOrders$(
        ignoreSalesOrders: IUpdateIgnoreSalesOrdersModel
    ): Observable<IExecutionResult<void>> {
        const url: string = this.getUrl(PsaEndpoints.updateIgnoreSalesOrders);

        return this._authorizedHttpService.put$(url, ignoreSalesOrders);
    }

    downloadProductMovement$(date: Date, fileName: string): Observable<HttpResponse<Blob>> {
        const url: string = this.getUrl(PsaEndpoints.productMovementAsCSV).replace(
            '{date}',
            date.toDateString()
        );

        return this._authorizedHttpService.downloadBlobAsFile$(url, fileName);
    }

    downloadReconciliation$(fileName: string): Observable<HttpResponse<Blob>> {
        return this._authorizedHttpService.downloadBlobAsFile$(
            this.getUrl(PsaEndpoints.reconcoliationReportAsCSV),
            fileName
        );
    }

    getOverviewUnmatchedServices$(): Observable<IExecutionResult<IOverviewServiceApi[]>> {
        return this._authorizedHttpService.get$(this.getUrl(PsaEndpoints.overview));
    }

    refreshData$(): Observable<IExecutionResult<null>> {
        if (this._isNewPSAFeatureFlagEnabled()) {
            return this._authorizedHttpService.post$(this.getUrl(PsaEndpoints.refreshAll), null);
        }

        return of(null);
    }

    getPsaContracts$(): Observable<IExecutionResult<ISynchronizedData<IIntegrationPsaContract[]>>> {
        this._verifyNewServiceIsUsed();

        return this._authorizedHttpService
            .get$(this.getUrl(PsaEndpoints.psaContracts))
            .pipe(
                map(
                    (
                        result: IExecutionResult<
                            ISynchronizedData<IIntegrationPsaContract[]> | IIntegrationPsaContract[]
                        >
                    ) => this._wrapInSynchronizationDataIfNeeded(result)
                )
            );
    }

    refreshPsaContracts$(): Observable<IExecutionResult<void>> {
        this._verifyNewServiceIsUsed();

        return this._authorizedHttpService.post$(
            this.getUrl(PsaEndpoints.refreshPsaContracts),
            null
        );
    }

    getAzureCharges$(
        forDate: Date
    ): Observable<IExecutionResult<ISynchronizedData<IIntegrationAzureChargeItem[]>>> {
        this._verifyNewServiceIsUsed();

        return this._authorizedHttpService
            .get$(
                `${this._getApiServiceUrl()}${API_ENDPOINTS.PSA.AZURE_CHARGES.GET_FOR_DATE(
                    typeof forDate === 'string' ? forDate : forDate?.toDateString()
                )}`
            )
            .pipe(
                map(
                    (
                        result: IExecutionResult<
                            | ISynchronizedData<IIntegrationAzureChargeItem[]>
                            | IIntegrationAzureChargeItem[]
                        >
                    ) => this._wrapInSynchronizationDataIfNeeded(result)
                )
            );
    }

    getAzureChargesPeriods$(
        numberOfMonthsBack: number
    ): Observable<IExecutionResult<IIntegrationAzureBillingPeriod[]>> {
        this._verifyNewServiceIsUsed();

        return this._authorizedHttpService.get$(
            `${this._getApiServiceUrl()}${API_ENDPOINTS.PSA.AZURE_CHARGES.DATE_RANGES(
                numberOfMonthsBack
            )}`
        );
    }

    refreshAzureCharges$(forDate?: Date): Observable<IExecutionResult<void>> {
        this._verifyNewServiceIsUsed();

        if (forDate) {
            return this._authorizedHttpService.post$(
                `${this._getApiServiceUrl()}${API_ENDPOINTS.PSA.AZURE_CHARGES.REFRESH_FOR_DATE(
                    typeof forDate === 'string' ? forDate : forDate?.toDateString()
                )}`,
                null
            );
        }

        return this._authorizedHttpService.post$(
            `${this._getApiServiceUrl()}${API_ENDPOINTS.PSA.AZURE_CHARGES.REFRESH()}`,
            null
        );
    }

    updateAzureCharges$(
        charges: IIntegrationAzureChargeUpdateItem[]
    ): Observable<IExecutionResult<void>> {
        this._verifyNewServiceIsUsed();

        return this._authorizedHttpService.post$(
            `${this._getApiServiceUrl()}${API_ENDPOINTS.PSA.AZURE_CHARGES.UPDATE()}`,
            charges
        );
    }

    updateSingleAzureCharge$(
        charge: IIntegrationAzureChargeUpdateItem
    ): Observable<IExecutionResult<void>> {
        this._verifyNewServiceIsUsed();

        return this._authorizedHttpService.post$(
            `${this._getApiServiceUrl()}${API_ENDPOINTS.PSA.AZURE_CHARGES.UPDATE()}`,
            charge
        );
    }

    getUrl(endpoint: PsaEndpoints): string {
        if (this._isNewPSAFeatureFlagEnabled()) {
            return `${this._getApiServiceUrl()}${API_ENDPOINTS.PSA.SERVICE.get(endpoint)}`;
        }

        return API_ENDPOINTS.PSA.MONOLITH.get(endpoint);
    }

    private _verifyNewServiceIsUsed(): void {
        if (!this._isNewPSAFeatureFlagEnabled()) {
            throw new Error('This method is only supported for new PSA service implementation.');
        }
    }

    private _getApiServiceUrl(): string {
        if (BaseUrl.psaIntegrationUrl) {
            return `${BaseUrl.psaIntegrationUrl}`;
        }

        return `${BaseUrl.baseUrlCloudMarket}/psaintegration/v1`;
    }

    private _isNewPSAFeatureFlagEnabled(): boolean {
        return this._featureFlagService.isFeatureFlagEnabled(this._newPsaFeatureFlag);
    }

    private _wrapInSynchronizationDataIfNeeded<T>(
        result: IExecutionResult<T | ISynchronizedData<T>>
    ): IExecutionResult<ISynchronizedData<T>> {
        if (!result.Result || !Object.keys(result.Result).includes('synchronization')) {
            const synchronziedResultStub: ISynchronizedData<T> = {
                response: result.Result as T,
                synchronization: {
                    status: SynchronizationStatus.unknown,
                },
            };

            result.Result = synchronziedResultStub;
        }

        return result as IExecutionResult<ISynchronizedData<T>>;
    }
}
