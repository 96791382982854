import { Pipe, PipeTransform } from '@angular/core';

import { IPsaItem } from '../psa/interfaces/match-selector.types';

@Pipe({
    name: 'matchSelectorFilter',
})
export class MatchSelectorPipe implements PipeTransform {
    transform(items: IPsaItem[], searchString: string): IPsaItem[] {
        if (!searchString || searchString === '') {
            return items;
        }

        return items.filter(
            (x: IPsaItem) => x.name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1
        );
    }
}
