import { Injectable } from '@angular/core';

import { IArchiveListFilterModel } from '../../models/user/archive-list-filter.model';

@Injectable({
    providedIn: 'root',
})
export class ArchiveService {
    private _savedSearch: IArchiveListFilterModel;

    getSavedSearch(): IArchiveListFilterModel | null {
        if (this._savedSearch) {
            return this._savedSearch;
        }

        return null;
    }

    setSavedSearch(filter: IArchiveListFilterModel): void {
        this._savedSearch = filter;
    }
}
