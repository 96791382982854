import { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { zxcvbn, zxcvbnOptions, ZxcvbnResult } from '@zxcvbn-ts/core';

import { IValidationRule } from '../../new-shared/components/password/validation-rule.interface';
import { CONFIG_OPTIONS } from '../../new-shared/components/password/zxcvbn-options.config';
import { IDictionary } from '../interfaces/dictionary.interface';

zxcvbnOptions.setOptions(CONFIG_OPTIONS);

export class PasswordValidator {
    static hasAlphaCharsOnly(control: UntypedFormControl): ValidationErrors | null {
        const alphanumericalCharsRegex: RegExp = new RegExp('^[a-zA-Z0-9]*$');

        return alphanumericalCharsRegex.test(control.value) ? null : { hasAlphaCharsOnly: true };
    }

    static hasUppercase(control: UntypedFormControl): ValidationErrors | null {
        const uppercaseCharRegex: RegExp = new RegExp('[A-Z]');

        return uppercaseCharRegex.test(control.value) ? null : { hasUppercase: true };
    }

    static hasLowercase(control: UntypedFormControl): ValidationErrors | null {
        const lowercaseCharRegex: RegExp = new RegExp('[a-z]');

        return lowercaseCharRegex.test(control.value) ? null : { hasLowercase: true };
    }

    static hasNumber(control: UntypedFormControl): ValidationErrors | null {
        const numberCharRegex: RegExp = new RegExp('[0-9]');

        return numberCharRegex.test(control.value) ? null : { hasNumber: true };
    }

    static minimumScore(ruleValue: number): ValidatorFn | null {
        return (control: UntypedFormControl) => {
            const strength: ZxcvbnResult = zxcvbn(control.value);

            return strength.score >= ruleValue
                ? null
                : { minimumScore: true, score: strength.score };
        };
    }

    static minimumLength(ruleValue: number): ValidatorFn | null {
        return (control: UntypedFormControl) =>
            control.value.length >= ruleValue ? null : { minimumLength: true };
    }

    static multiplePattern(validationRule: IValidationRule): ValidatorFn | null {
        return (control: UntypedFormControl) => {
            const patternRegex: RegExp = new RegExp(validationRule.rule as RegExp);
            const failResponse: IDictionary<boolean> = {};

            failResponse[validationRule.name] = true;

            return patternRegex.test(control.value) ? null : failResponse;
        };
    }
}
