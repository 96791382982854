import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccessDeniedComponent } from './pages/direct-routes/access-denied/access-denied.component';
import { PageNotFoundComponent } from './pages/direct-routes/page-not-found/page-not-found.component';
import { PermissionErrorModule } from './pages/direct-routes/permission-error/permission-error.module';
import { SpamReleaseComponent } from './pages/direct-routes/spam-release/spam-release.component';
import { VerifyEmailArchiveAccessComponent } from './pages/direct-routes/verify-email/verify-email-archive-access.component';
import { ROUTES_PATHS } from './shared/constants/routes.constants';

const noAuthRoutes: Routes = [
    { path: 'spam-release/:MID/:UID', component: SpamReleaseComponent },
    { path: 'verify-email-archive-access/:ACCESSTYPE/:TOKEN', component: VerifyEmailArchiveAccessComponent },
    { path: ROUTES_PATHS.NOT_FOUND, component: PageNotFoundComponent },
    { path: ROUTES_PATHS.ACCESS_DENIED, component: AccessDeniedComponent },
    { path: '**', redirectTo: ROUTES_PATHS.NOT_FOUND }, // 404
];

@NgModule({
    imports: [PermissionErrorModule, RouterModule.forChild(noAuthRoutes)],
    exports: [RouterModule],
})
export class NoAuthRoutingModule {}
