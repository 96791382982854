import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    Input,
    TemplateRef,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ITableColumnAlign } from '../../interfaces/table-column-align.interface';
import { ITableColumnRef } from '../../interfaces/table-column-ref.interface';
import { ITableColumnSize } from '../../interfaces/table-column-size.interface';
import { ITableColumn } from '../../interfaces/table-column.interface';

@Component({
    selector: 'cm-table-column',
    template: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmTableColumnComponent implements ITableColumn, AfterContentInit {
    @ContentChild('headerTemplate') headerTemplate: TemplateRef<unknown>;
    @ContentChild('entryTemplate') entryTemplate: TemplateRef<unknown>;
    private _currentEntryTemplate$: BehaviorSubject<TemplateRef<unknown>> = new BehaviorSubject(
        null
    );
    private _currentHeaderTemplate$: BehaviorSubject<TemplateRef<unknown>> = new BehaviorSubject(
        null
    );
    private _currentSize$: BehaviorSubject<ITableColumnSize> = new BehaviorSubject(null);
    private _currentAlign$: BehaviorSubject<ITableColumnAlign> = new BehaviorSubject('left');

    @Input() set size(size: ITableColumnSize) {
        this._currentSize$.next(size);
    }

    @Input() set align(align: ITableColumnAlign) {
        this._currentAlign$.next(align);
    }

    getColumnRefChanges$(): Observable<ITableColumnRef> {
        return combineLatest([
            this._currentSize$.pipe(filter((value: ITableColumnSize) => !!value)),
            this._currentAlign$,
            this._currentHeaderTemplate$.asObservable(),
            this._currentEntryTemplate$.asObservable(),
        ]).pipe(
            map(
                ([size, align, headerTemplate, entryTemplate]: [
                    ITableColumnSize,
                    ITableColumnAlign,
                    TemplateRef<unknown>,
                    TemplateRef<unknown>
                ]) => ({
                    size,
                    align,
                    headerTemplate,
                    entryTemplate,
                })
            )
        );
    }

    ngAfterContentInit(): void {
        this._initTemplates();
    }

    private _initTemplates(): void {
        this._currentHeaderTemplate$.next(this.headerTemplate);
        this._currentEntryTemplate$.next(this.entryTemplate);
    }
}
