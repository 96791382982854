import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'quantityChange',
})
export class QuantityChangePipe implements PipeTransform {
    transform(value: number): string {
        const prefix: string = value > 0 ? '+' : '';

        return `${prefix}${value}`;
    }
}
