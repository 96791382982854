import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { BreadcrumbsService } from '../services/breadcrumbs.service';

@Pipe({
    name: 'dynamicBreadcrumb',
})
export class DynamicBreadcrumbPipe implements PipeTransform {

    constructor(
        private readonly _translateService: TranslateService,
        private readonly _breadcrumbsService: BreadcrumbsService
    ) {}

    // eslint-disable-next-line rxjs/finnish
    transform(label: string|((data: unknown) => string)): Observable<string> {
        return this._breadcrumbsService
            .getExternalData$()
            .pipe(switchMap((data: unknown) => {
                if(typeof label === 'string') {
                    return this._translateService.get(label, data)
                }

                return of(label(data));
            }))
    }
}
