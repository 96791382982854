export enum ServiceName {
    hostedExchange = 'Hosted Exchange',
    skykick = 'SkyKick',
    '365EmailSecurity' = '365 Email Security',
    exclaimer = 'Exclaimer',
    acronisCyberCloud = 'Acronis Cyber Cloud',
    azure = 'Azure',
    azureReservedInstance = 'Azure Reserved Instance',
    azureServerSubscription = 'Azure Server Subscription',
    bitdefenderMspSecurity = 'Bitdefender MSP Security',
    bitTitanMigrationWiz = 'BitTitan MigrationWiz',
    cloudServer = 'Cloud Server',
    bitdefender = 'Bitdefender',
    acronisBackup = 'Acronis Backup',
    secureMessaging = 'Secure Messaging',
    emailSecurity = 'Email Security',
    hostedExchangeBackup = 'Hosted Exchange Backup',
    microsoft365 = 'Microsoft 365',
    microsoft365Secure = 'Microsoft 365 Secure',
    perpetualLicensing = 'Perpetual Licensing',
    securityAwareness = 'Security Awareness',
    sophosMsp = 'Sophos MSP',
    teamsDirectRouting = 'Teams Direct Routing',
    teamsOperatorConnect = 'Teams Operator Connect',
    titanHQ = 'TitanHQ',
    webroot = 'Webroot',
    barracudaMsp = 'Barracuda MSP'
}
