// TODO: verify if it is safe to change the naming convention in this file
// eslint-disable:naming-convention
export enum Service {
    NotSpecified = 0,
    Exchange = 1,
    SharePoint = 2,
    Backup = 3,
    AdvancedSecurity = 4,
    SecureEmailMessaging = 5,
    Cloud = 6,
    WebSecurity = 7,
    Microsoft365 = 8, // previously Office365
    BackupAcronis = 9,
    Cirius = 10,
    InfluencerProgramme = 11,
    BitDefender = 12,
    HostedVoice = 13,
    AppInteract = 14,
    HostedVoiceHardware = 15,
    MessageLabs = 16,
    Exclaimer = 17,
    AcronisFilesCloud = 18,
    CloudTalk = 19,
    Skykick = 21,
    AzureRI = 22,
    DropSuite = 23,
    Vade = 27,
    AzureSS = 26,
    CloudServer = 30,
    Webroot = 31,
    TitanHQ = 32,
}

export enum OrganisationService {
    Exchange = 1,
    SharePoint = 2,
    AdvancedSecurity = 4,
    Microsoft365 = 8, // previously Office365
    BackupAcronis = 9,
    Cirius = 10,
    BitDefender = 12,
    HostedVoice = 13,
    AppInteract = 14,
    HostedVoiceHardware = 15,
    MessageLabs = 16,
    Exclaimer = 17,
    AcronisFilesCloud = 18,
    CloudTalk = 19,
    Skykick = 21,
    AzureRI = 22,
    DropSuite = 23,
    AzureSS = 26,
    Vade = 27,
    CloudServer = 30,
    Webroot = 31,
    TitanHQ = 32,
}

export enum Office365ProductGroups {
    Office365Core = 1,
    PowerBI = 2,
    DynamicsCRM = 3,
    Intune = 4,
    EnterpriseMobilitySuite = 5,
    Azure = 6,
    Office365Secure = 9,
}

export enum ProductCategory {
    HostedExchange = 1,
    SharePoint = 2,
    AcronisBackup = 3,
    EmailSecurity = 4,
    Office365 = 6,
    Bitdefender = 7,
    Azure = 8,
    Cirius = 10,
    HostedVoice = 13,
    AppInteract = 14,
    MessageLabs = 16,
}

export enum NewServiceId {
    NotSpecified = 0,
    Exchange = 1,
    SharePoint = 2,
    EmailSecurity = 3,
    AcronisBackup = 4,
    SecureEmailMessaging = 5,
    BitDefender = 6,
    Office365 = 7,

    EnterpriseMobilitySuite = 9,
    Dynamics365 = 10,
    Intune = 11,
    PowerBI = 12,
    HostedVoice = 19,
    AppInteract = 14,
    HostedVoiceHardware = 15,
    MessageLabs = 16,
    Exclaimer = 17,
    AcronisFilesCloud = 26,
    CloudTalk = 27,
    Skykick = 28,
    AzureRI = 30,
    DropSuite = 31,
    Vade = 35,
    AzureSS = 34,
    Office365Secure = 38,
    CloudServer = 39,
    Webroot = 40,
    TitanHQ = 41,
    Azure = 42,
}

export enum ServiceJourneyMessageType {
    Add = 1,
    Modify = 2,
    Delete = 3,
}

// "Product" references "service" DB table
export enum ProductJourneyType {
    Unknown = 0,
    ActivationLicence = 1,
    ActivationConsumption = 2,
    Licence = 3,
    LicenceAndOveruse = 4,
    Instance = 5,
    OneTime = 6,
}

export enum ProductOrderLimit {
    Unknown = 0,
    OncePerReseller = 1,
    OncePerCustomer = 2,
    MultiplePerCustomer = 3,
}

export enum ServiceInstanceStatus {
    Activating = 0,
    Active = 1,
    ActivationFailed = 2,
    Deleting = 3,
    Deleted = 4,
    DeletionFailed = 5,
    Upgraded = 6,
}
