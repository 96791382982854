import { Component, Input, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-giacom-error-popup',
    templateUrl: './error-popup.component.html',
})
export class ErrorPopupComponent {
    @ViewChild('errorModal') errorModal: ModalDirective;
    @Input() errorMessage: string | string[];

    get errorMessages(): string[] {
        if (this.errorMessage instanceof Array) {
            return this.errorMessage;
        }

        return [this.errorMessage];
    }

    show(): void {
        this.errorModal.show();
    }

    hide(): void {
        this.errorModal.hide();
    }
}
