import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { PopupService } from '../../feature-modules/popups/services/popup.service';
import { ISignedService } from '../../models/agreement/interfaces/signed-service.interface';
import { IModifyServiceModel } from '../../models/service/modify-service.model';
import { AddServiceContextEnum } from '../../models/service/service.model';
import {
    Office365ProductGroups,
    OrganisationService,
    ProductCategory,
    Service,
} from '../../shared/enum/service-type.enum';
import { IDictionary } from '../../shared/interfaces/dictionary.interface';
import { WINDOW } from '../injection-tokens/window.injection-token';
import { IExecutionResult } from '../interfaces/execution-result.interface';
import { IServiceListingResult } from '../interfaces/service-listing.interface';
import { IWindow } from '../interfaces/window.interface';

import { AgreementService } from './agreement.service';
import { ApiAgreementService } from './api/agreement/api-agreement.service';
import { ApiServiceListingService } from './api/services/api-service-listing.service';
import { GlobalDataService } from './global-data.service';

@Injectable({
    providedIn: 'root',
})
export class ServiceService {
    private _commandGoToMSPortal: string = 'go-to-ms-portal';
    private _commandGoToAzurePortal: string = 'go-to-azure-portal';
    private _commandGoToEMSPortal: string = 'go-to-ems-portal';
    private _commandGoToNimblePortal: string = 'go-to-nimble-portal';
    private _commandGoToExclaimerPortal: string = 'go-to-exclaimer-portal';
    private _commandGotoBitdefenderSite: string = 'go-to-bit-site';
    private _commandGotoSymantecSite: string = 'go-to-symantec-site';
    private _commandGotoAcronisPortal: string = 'go-to-acronis-portal';
    private _commandGotoCiriusPortal: string = 'go-to-cirius-portal';
    private _actionManageAzure: string = 'manage-azure';
    private _actionManageExchange: string = 'manage-exchange';
    private _actionEmailSecurity: string = 'manage-email-security';
    private _actionManageHostedVoice: string = 'manage-hosted-voice';
    private _actionManageAppInteract: string = 'manage-app-interact';
    private _actionManageBitDefender: string = 'manage-bitdefender';
    private _actionManageHostedVoiceHardware: string = 'manage-hosted-voice-hardware';
    private _actionMigrate: string = 'migrate-exchange';
    private _actionDeleteMessageLabs: string = 'delete-message-labs';
    private _acronisResellerPortalLink: string;
    private _level: string = 'customer';
    private _parentId: string;
    private _id: string;
    private _userEmail: string;

    constructor(
        @Inject(WINDOW) private _window: IWindow,
        private _router: Router,
        private _dataService: GlobalDataService,
        private _popupService: PopupService,
        private _agreementService: AgreementService,
        private _apiAgreementsService: ApiAgreementService,
        private _apiServiceListingService: ApiServiceListingService
    ) {}

    modifyServiceCust(
        serviceId: number,
        level: string,
        id: string,
        parentId: string,
        orderId: number = 0
    ): void {
        this._level = level;
        this._id = id;
        this._parentId = parentId;

        if (serviceId !== Service.HostedVoice) {
            return;
        }

        this._dataService.setData('add-hosted-voice-service-data', {
            orderId,
            level: 'customer',
            organisationId: this._id,
            userId: 0,
            mode: 'modify-order',
        });
        this._router.navigate([`customers/customer/${this._id}/add-service/hosted-voice`]);
    }

    addService(
        serviceId: number,
        subId: number,
        serviceName: string,
        level: string,
        id: string,
        parentId: string,
        userEmail: string,
        upgrade: boolean = false,
        productId: string|number = 0,
        mode: string = null
    ): void {
        this._level = level;
        this._id = id;
        this._parentId = parentId;
        this._userEmail = userEmail;
        this._apiAgreementsService
            .checkSignedServiceId$(serviceId)
            .subscribe((result: IExecutionResult<ISignedService>) => {
                const isSigned: boolean = result.Result.signed;
                const urlSegment: string = upgrade ? 'upgrade-service' : 'add-service';

                if (!isSigned) {
                    this._agreementService.redirectPopup(
                        serviceId,
                        subId,
                        result.Result.agreementId
                    );
                } else {
                    const atUserLevel: boolean = this._level === 'user';
                    const addServiceInput: unknown = {
                        subId,
                        serviceName,
                        productId,
                        serviceid: serviceId,
                        customerId: atUserLevel ? this._parentId : this._id,
                        userId: atUserLevel ? this._id : undefined,
                        id: this._id,
                        context: atUserLevel
                            ? AddServiceContextEnum.user
                            : AddServiceContextEnum.customer,
                        mode: upgrade ? 'upgrade' : '',
                    };

                    if (serviceId === Service.Microsoft365) {
                        const customerUserId: string =
                            this._level === 'user' ? `${this._parentId}+${this._id}` : this._id;
                        const serviceRouteName: string = this.getServiceRouteName(
                            Service.Microsoft365,
                            subId
                        );

                        const bypassNceQuestion: boolean = upgrade;

                        if (bypassNceQuestion) {
                            this._router.navigate([
                                'customers/customer',
                                customerUserId,
                                urlSegment,
                                serviceRouteName,
                                productId || '',
                            ]);
                        } else {
                            this._apiServiceListingService
                                .getServiceListingId$(OrganisationService.Microsoft365, subId)
                                .subscribe((listing: IExecutionResult<IServiceListingResult>) => {
                                    this._router.navigate(
                                        ['customers/customer', customerUserId, 'nce-question'],
                                        {
                                            queryParams: {
                                                serviceRouteName,
                                                listingId: listing.Result.listingId,
                                                isNewJourney: listing.Result.isNewJourney,
                                            },
                                        }
                                    );
                                });
                        }
                        this._dataService.setData('addServiceContext', addServiceInput);
                    } else if (serviceId === Service.Exchange) {
                        const customerUserId: string =
                            this._level === 'user' ? `${this._parentId}+${this._id}` : this._id;

                        this._dataService.setData('add-hosted-exchange-service-data', {
                            productId,
                            level: this._level,
                            userId: this._id,
                            mode: upgrade ? 'upgrade' : this._level === 'user' ? 'modify' : 'add',
                        });
                        if (this._level === 'customer') {
                            this._router.navigate([
                                `customers/customer/${this._id}/${urlSegment}/hosted-exchange${
                                    upgrade ? `/${productId}` : ''
                                }`,
                            ]);
                        } else if (this._level === 'user') {
                            this._router.navigate([
                                `customers/customer/${customerUserId}/${urlSegment}/hosted-exchange${
                                    upgrade ? `/${productId}` : ''
                                }`,
                            ]);
                        }
                    } else if (serviceId === Service.AdvancedSecurity) {
                        this._dataService.setData('add-email-security-service-data', {
                            level: this._level,
                            userId: this._id,
                            mode: upgrade ? 'upgrade' : 'add',
                        });
                        if (this._level === 'customer') {
                            this._router.navigate([
                                `customers/customer/${this._id}/${urlSegment}/email-security${
                                    upgrade ? `/${productId}` : ''
                                }`,
                            ]);
                        } else if (this._level === 'user') {
                            this._router.navigate([
                                `customers/customer/${this._parentId}/${urlSegment}/email-security${
                                    upgrade ? `/${productId}` : ''
                                }`,
                            ]);
                        }
                    } else if (serviceId === Service.SharePoint) {
                        this._dataService.setData('add-share-point-service-data', {
                            level: this._level,
                            userId: this._id,
                            mode: this._level === 'user' ? 'modify' : 'add',
                        });
                        if (this._level === 'customer') {
                            this._router.navigate([
                                `customers/customer/${this._id}/add-service/share-point`,
                            ]);
                        } else if (this._level === 'user') {
                            this._router.navigate([
                                `customers/customer/${this._parentId}/add-service/share-point`,
                            ]);
                        }
                    } else if (
                        serviceId === Service.BackupAcronis ||
                        serviceId === Service.Backup
                    ) {
                        this._dataService.setData('add-acronis-backup-service-data', {
                            level: this._level,
                            userId: this._id,
                            logonEmail: this._userEmail,
                            mode: upgrade ? 'upgrade' : 'add',
                        });

                        if (this._level === 'customer') {
                            this._router.navigate([
                                `customers/customer/${this._id}/${urlSegment}/acronis-backup${
                                    upgrade ? `/${productId}` : ''
                                }`,
                            ]);
                        } else if (this._level === 'user') {
                            this._router.navigate([
                                `customers/customer/${this._parentId}/${urlSegment}/acronis-backup${
                                    upgrade ? `/${productId}` : ''
                                }`,
                            ]);
                        }
                    } else if (serviceId === Service.Exclaimer) {
                        this._router.navigate([
                            'customers/customer',
                            this._id,
                            'add-services',
                            serviceId,
                            'Add',
                        ]);
                    } else if (serviceId === Service.HostedVoiceHardware) {
                        this._dataService.setData('add-hosted-voice-hardware-service-data', {
                            level: this._level,
                            organisationId: this._id,
                            mode: 'add',
                        });

                        this._router.navigate([
                            `customers/customer/${this._id}/add-service/hosted-voice-hardware`,
                        ]);
                    } else if (serviceId === Service.AcronisFilesCloud) {
                        if (this._level === 'customer') {
                            this._router.navigate([
                                'customers/customer',
                                this._id,
                                'add-services',
                                serviceId,
                                'Add',
                                'customer',
                            ]);
                        } else {
                            this._router.navigate([
                                'customers/customer',
                                this._id,
                                'add-services',
                                serviceId,
                                'Add',
                                'user',
                            ]);
                        }
                    } else if (serviceId === Service.HostedVoice) {
                        if (this._level === 'customer') {
                            this._dataService.setData('add-hosted-voice-service-data', {
                                level: this._level,
                                organisationId: this._id,
                                userId: 0,
                                mode: 'modify',
                            });
                            this._router.navigate([
                                `customers/customer/${this._id}/add-service/hosted-voice`,
                            ]);
                        }
                    } else if (serviceId === Service.BitDefender) {
                        this._dataService.setData('add-bitdefender-service-data', {
                            level: this._level,
                            organisationId: this._id,
                            userId: 0,
                            mode: 'modify',
                        });
                        this._router.navigate([
                            `customers/customer/${this._id}/add-service/bitdefender`,
                        ]);
                    } else if (serviceId === Service.CloudTalk) {
                        this._dataService.setData('add-cloudtalk-service-data', {
                            level: this._level,
                            organisationId: this._id,
                            userId: 0,
                            mode: mode === null ? 'add' : mode,
                        });
                        this._router.navigate([
                            `customers/customer/${this._id}/add-service/cloudtalk`,
                        ]);
                    } else if (serviceId === Service.DropSuite) {
                        this._dataService.setData('add-dropsuite-service-data', {
                            level: this._level,
                            organisationId: this._id,
                            userId: 0,
                            mode: mode === null ? 'add' : mode,
                        });
                        this._router.navigate([
                            `customers/customer/${this._id}/add-service/dropsuite`,
                        ]);
                    } else if (serviceId === Service.Vade) {
                        this._dataService.setData('add-vade-service-data', {
                            level: this._level,
                            organisationId: this._id,
                            userId: 0,
                            mode: mode === null ? 'add' : mode,
                        });
                        this._router.navigate([`customers/customer/${this._id}/add-service/vade`]);
                    } else if (serviceId === Service.AzureRI || serviceId === Service.AzureSS) {
                        this._dataService.setData('add-one-time-purchase-service-data', {
                            level: this._level,
                            organisationId: this._id,
                            userId: 0,
                            mode: mode === null ? 'add' : mode,
                        });
                        let serviceRoute: string = '';

                        switch (serviceId) {
                            case Service.AzureRI:
                                serviceRoute = 'azure-ri';
                                break;
                            case Service.AzureSS:
                                serviceRoute = 'azure-ss';
                                break;
                            default:
                                break;
                        }
                        this._router.navigate([
                            `customers/customer/${this._id}/add-service/${serviceRoute}`,
                        ]);
                    } else if (serviceId === Service.SecureEmailMessaging) {
                        this._dataService.setData('secure-email-exchange-model', {
                            level: this._level,
                            userId: this._parentId,
                            customerId: this._id,
                            mode: this._level === 'user' ? 'modify' : 'add',
                        });
                        this._router.navigate([
                            `customers/customer/${this._id}/add-service/secure-message`,
                        ]);
                    }
                }
            });
    }

    modifyService(data: IModifyServiceModel): void {
        switch (data.ServiceId) {
            case 1: // Exchange
                this._dataService.setData('add-hosted-exchange-service-data', {
                    level: data.Level,
                    organisationId: data.OrganisationId,
                    userId: data.UserId,
                    mode: 'modify',
                    productId: data.ProductId,
                });
                this._router.navigate([
                    `customers/customer/${data.OrganisationId}+${data.UserId}+modify/add-service/hosted-exchange/${data.ProductId}`,
                ]);

                break;

            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            case 9: // Acronis
                this._dataService.setData('add-acronis-backup-service-data', {
                    level: data.Level,
                    organisationId: data.OrganisationId,
                    userId: data.UserId,
                    logonEmail: data.UserRef,
                    mode: 'modify',
                });
                if (data.Popup) {
                    const payload: unknown = {
                        params: {
                            id: `${data.OrganisationId}+${data.UserId}+modify`,
                            serviceName: 'acronis-backup',
                        },
                        queryParams: {},
                    };

                    this._popupService.show(null, 'add-service-popup', payload);
                } else {
                    this._router.navigate([
                        `customers/customer/${data.OrganisationId}+${data.UserId}+modify/add-service/acronis-backup`,
                    ]);
                }
                break;

            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            case 26: // OneTimePurchase
                this._dataService.setData('add-one-time-purchase-service-data', {
                    level: data.Level,
                    organisationId: data.OrganisationId,
                    mode: 'modify',
                });
                this._router.navigate([
                    `customers/customer/${data.OrganisationId}+modify/add-service/one-time-purchase`,
                ]);
                break;

            default:
                break;
        }
    }

    executeCommand(
        name: string,
        params?: IDictionary<unknown>,
        model?: unknown,
        level?: unknown
    ): void {
        switch (name) {
            case this._commandGoToMSPortal:
                this._window.open('http://portal.office.com/');
                break;
            case this._commandGoToAzurePortal:
                this._window.open(
                    'https://login.microsoftonline.com/common/oauth2/authorize?resource=https%3a%2f%2fmanagement.core.windows.net%2f&response_mode=form_post&response_type=code+id_token&scope=user_impersonation+openid&state=OpenIdConnect.AuthenticationProperties%3d0QgVhioYNyFZjF022NsD7iq3VXSoEDgjYGO6b45inJ1trrMRbGJSVH600sAg6z9jWbncwlYAGXJlURjFPBtg3b-JSodj3PkzRyU19Bf0vWN_lEG-ODKMZ9GdCAItqvSazhuaHp14z9I8-YsIVpTIn6oNcgFh6g4Bjj7dhAvsf8-w1M50&nonce=636358786202546765.MGUzMDE1ZjYtYWQyZS00NGU5LWE3YTUtMjQ2OGM4YWFmNjBmYWQ5MzBiMDEtNjA0NC00NzVkLWFhOTAtY2I2MWMxYmMxNmI2&client_id=c44b4083-3bb0-49c1-b47d-974e53cbdf3c&redirect_uri=https%3a%2f%2fportal.azure.com%2fsignin%2findex%2f%3fsignIn%3d1&site_id=501430'
                );
                break;
            case this._commandGoToEMSPortal:
                this._window.open('https://login.microsoftonline.com/');
                break;
            case this._commandGoToNimblePortal:
                this._window.open('https://app.nimble.com');
                break;
            case this._commandGoToExclaimerPortal:
                this._window.open('https://portal.exclaimer.com/');
                break;
            case 'yes':
                this._dataService.setData('your-account-data', { activeFlow: 'service agreement' });
                this._router.navigate([
                    `account-setting/terms-conditions/${params.agreementId}/false/false`,
                ]);
                break;
            case this._commandGotoBitdefenderSite:
                this._window.open('https://gravityzone.bitdefender.com/');
                break;
            case this._commandGotoSymantecSite:
                this._window.open('https://clients.messagelabs.com/Dashboard');
                break;
            case this._actionManageAzure:
                this._router.navigate([`customers/customer/${this._id}/manage-service/azure`]);
                break;
            case this._commandGotoAcronisPortal:
                this._window.open(this._acronisResellerPortalLink);
                break;
            case this._commandGotoCiriusPortal:
                this._window.open('https://webapp.secure-messaging.com/');
                break;
            case this._actionManageExchange:
                let manageExchangeId: string = this._id;

                if (this._level === 'user') {
                    manageExchangeId = `${this._parentId}+${this._id}`;
                    this._router.navigate([
                        `customers/customer/${manageExchangeId}/manage-service/hosted-exchange/${this._userEmail}`,
                    ]);
                } else {
                    this._router.navigate([
                        `customers/customer/${manageExchangeId}/manage-service/hosted-exchange`,
                    ]);
                }
                break;

            case this._actionMigrate:
                this._router.navigate([`customers/customer/${this._id}/migrate-exchange`]);
                break;

            case this._actionManageHostedVoice:
                this._router.navigate([
                    `customers/customer/${this._id}/manage-service/hosted-voice`,
                ]);
                break;

            case this._actionEmailSecurity:
                let mangaeEmailSecurityId: string = this._id;

                if (this._level === 'user') {
                    mangaeEmailSecurityId = `${this._parentId}+${this._id}`;
                    this._router.navigate([
                        `customers/customer/${mangaeEmailSecurityId}/manage-service/email-security/${this._userEmail}`,
                    ]);
                } else {
                    this._router.navigate([
                        `customers/customer/${mangaeEmailSecurityId}/manage-service/email-security`,
                    ]);
                }
                break;
            case this._actionManageAppInteract:
                if (this._level === 'customer') {
                    this._router.navigate([
                        `customers/customer/${this._id}/manage-service/app-interact`,
                    ]);
                }
                break;
            case this._actionManageBitDefender:
                if (this._level === 'customer') {
                    this._router.navigate([
                        `customers/customer/${this._id}/manage-service/bitdefender`,
                    ]);
                }
                break;
            case this._actionManageHostedVoiceHardware:
                if (this._level === 'customer') {
                    this._router.navigate([
                        `customers/customer/${this._id}/manage-service/hosted-voice-hardware`,
                    ]);
                }
                break;
            case this._actionDeleteMessageLabs:
                if (this._level === 'customer') {
                    const id: string = `${this._id}+${Service.MessageLabs}`;

                    this._router.navigate([`customers/customer/${id}/delete-service`]);
                }
                break;
            default:
                break;
        }
    }

    getServiceRouteName(serviceType: Service, productGroup: Office365ProductGroups): string {
        let serviceRouteName: string;

        switch (serviceType) {
            case Service.Exchange:
                serviceRouteName = 'hosted-exchange';
                break;

            case Service.SecureEmailMessaging:
            case Service.Cirius:
                serviceRouteName = 'secure-message';
                break;

            case Service.AdvancedSecurity:
                serviceRouteName = 'email-security';
                break;

            case Service.SharePoint:
                serviceRouteName = 'share-point';
                break;

            case Service.Microsoft365:
                switch (productGroup) {
                    case Office365ProductGroups.PowerBI:
                        serviceRouteName = 'power-bi';
                        break;
                    case Office365ProductGroups.DynamicsCRM:
                        serviceRouteName = 'dynamics-365';
                        break;
                    case Office365ProductGroups.Intune:
                        serviceRouteName = 'intune';
                        break;
                    case Office365ProductGroups.EnterpriseMobilitySuite:
                        serviceRouteName = 'ems';
                        break;
                    case Office365ProductGroups.Azure:
                        serviceRouteName = 'azure';
                        break;
                    case Office365ProductGroups.Office365Secure:
                        serviceRouteName = 'office-365-secure';
                        break;
                    default:
                        serviceRouteName = 'office-365';
                        break;
                }
                break;

            case Service.Backup:
            case Service.BackupAcronis:
                serviceRouteName = 'acronis-backup';
                break;
            case Service.BitDefender:
                serviceRouteName = 'bitdefender';
                break;
            case Service.HostedVoice:
                serviceRouteName = 'hosted-voice';
                break;
            case Service.AppInteract:
                serviceRouteName = 'appinteract';
                break;
            case Service.MessageLabs:
                serviceRouteName = 'message-labs';
                break;

            case Service.HostedVoiceHardware:
                serviceRouteName = 'hosted-voice-hardware';
                break;

            case Service.AcronisFilesCloud:
                serviceRouteName = 'acronis-files-cloud';
                break;
            case Service.CloudTalk:
                serviceRouteName = 'cloudtalk';
                break;
            case Service.Skykick:
                serviceRouteName = 'skykick';
                break;
            case Service.DropSuite:
                serviceRouteName = 'dropsuite';
                break;
            case Service.AzureRI:
                serviceRouteName = 'azure-ri';
                break;
            case Service.AzureSS:
                serviceRouteName = 'azure-ss';
                break;
            case Service.Vade:
                serviceRouteName = 'vade';
                break;
            case Service.CloudServer:
                serviceRouteName = 'cloud-server';
                break;
            case Service.Webroot:
                serviceRouteName = 'webroot';
                break;
            case Service.TitanHQ:
                serviceRouteName = 'titanhq';
                break;
            default:
                break;
        }

        return serviceRouteName;
    }

    getServiceIdFromProductCategoryId(productCategory: ProductCategory): Service {
        let service: Service = Service.Microsoft365;

        switch (productCategory) {
            case ProductCategory.HostedExchange:
                service = Service.Exchange;
                break;

            case ProductCategory.SharePoint:
                service = Service.SharePoint;
                break;

            case ProductCategory.AcronisBackup:
                service = Service.BackupAcronis;
                break;

            case ProductCategory.EmailSecurity:
                service = Service.AdvancedSecurity;
                break;

            case ProductCategory.Cirius:
                service = Service.SecureEmailMessaging;
                service = Service.Cirius;
                break;

            case ProductCategory.Office365:
                service = Service.Microsoft365;
                break;

            case ProductCategory.Bitdefender:
                service = Service.BitDefender;
                break;
            case ProductCategory.AppInteract:
                service = Service.AppInteract;
                break;
            default:
                break;
        }

        return service;
    }
}
