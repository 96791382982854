import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { CmModalModule } from '../../../../../new-shared/components/cm-modal/modal.module';
import { GAFormModule } from '../../../../../shared/directives/ga-form/ga-form.module';
import { PopUpDetails } from '../../../../popups/classes/pop-up-details.class';
import { PopupService } from '../../../../popups/services/popup.service';

import { AuthErrorModalComponent } from './auth-error-modal.component';

@NgModule({
    declarations: [AuthErrorModalComponent],
    imports: [
        CmModalModule,
        CommonModule,
        FormsModule,
        GAFormModule,
        TooltipModule.forRoot(),
        TranslateModule,
    ],
    exports: [AuthErrorModalComponent],
})
export class AuthErrorModalModule {
    static popupRegistered: boolean = false;
    constructor(private _popupService: PopupService) {
        if (AuthErrorModalModule.popupRegistered) {
            return;
        }

        AuthErrorModalModule.popupRegistered = true;

        this._popupService.registerPopups({
            'auth-error': new PopUpDetails(
                AuthErrorModalComponent,
                'auth',
                'miscellaneous'
            ),
        });
    }
}
