import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IAddedServiceModel } from '../../../../models/service/added-service.model';
import { API_ENDPOINTS } from '../../../../shared/constants/api/api-endpoints.constants';
import { IExecutionResult } from '../../../interfaces/execution-result.interface';
import { IMarketplaceService } from '../../../interfaces/marketplace-service.interface';
import { AuthorizedHttpService } from '../../http/authorized-http.service';

@Injectable({
    providedIn: 'root',
})
export class ApiMarketplaceServicesService {
    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    getServices$(typeId?: number): Observable<IExecutionResult<IMarketplaceService[]>> {
        let getServicesUrl: string = API_ENDPOINTS.SERVICES.GET_SERVICES;

        if (typeId) {
            getServicesUrl = API_ENDPOINTS.SERVICES.GET_SERVICES_FOR_TYPE(typeId);
        }

        return this._authorizedHttpService.get$(getServicesUrl);
    }

    getServicesByEndUserAdmin$(
        typeId?: number
    ): Observable<IExecutionResult<IMarketplaceService[]>> {
        let getServicesUrl: string = API_ENDPOINTS.SERVICES.GET_SERVICES_BY_END_USER_ADMIN;

        if (typeId) {
            getServicesUrl = API_ENDPOINTS.SERVICES.GET_SERVICES_BY_END_USER_ADMIN_FOR_TYPE(typeId);
        }

        return this._authorizedHttpService.get$(getServicesUrl);
    }

    getServiceOfCustomer$(
        organisationId: string
    ): Observable<IExecutionResult<IAddedServiceModel[]>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.SERVICES.GET_SERVICES_OF_RESELLER(organisationId)
        );
    }
}
