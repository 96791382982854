import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { LoadingIndicatorInterceptor } from './interceptors/loading-indicator.interceptor';
import { LoadingIndicatorService } from './services/loading-indicator.service';

@NgModule({
    imports: [CommonModule],
    declarations: [LoadingIndicatorComponent],
    exports: [LoadingIndicatorComponent],
})
export class LoadingIndicatorModule {
    static forRoot(): ModuleWithProviders<LoadingIndicatorModule> {
        return {
            ngModule: LoadingIndicatorModule,
            providers: [
                LoadingIndicatorService,
                { provide: HTTP_INTERCEPTORS, useClass: LoadingIndicatorInterceptor, multi: true },
            ],
        };
    }
}
