import { Validators } from '@angular/forms';

import { FormConfig } from '../../../../types/form-config.type';
import {
    IDisasterRecoveryFirstStepForm,
    IDisasterRecoverySecondStepForm
} from '../interfaces/email-security-disaster-recovery-modal.types';

export const DISASTER_RECOVERY_FIRST_STEP_FORM_FIELDS: Record<keyof IDisasterRecoveryFirstStepForm, string> = {
    startDate: 'startDate',
    endDate: 'endDate',
    deliver: 'deliver',
};

export const DISASTER_RECOVERY_SECOND_STEP_FORM_FIELDS: Record<keyof IDisasterRecoverySecondStepForm, string> = {
    password: 'password',
};

export const disasterRecoveryFirstStepFormConfig: (configObject: IDisasterRecoveryFirstStepForm) => FormConfig = (
    configObject: IDisasterRecoveryFirstStepForm
): FormConfig => ({
    [DISASTER_RECOVERY_FIRST_STEP_FORM_FIELDS.startDate]: [configObject.startDate, [Validators.required]],
    [DISASTER_RECOVERY_FIRST_STEP_FORM_FIELDS.endDate]: [configObject.endDate, [Validators.required]],
    [DISASTER_RECOVERY_FIRST_STEP_FORM_FIELDS.deliver]: [configObject.deliver, [Validators.required]],
});

export const disasterRecoverySecondStepFormConfig: (configObject: IDisasterRecoverySecondStepForm) => FormConfig = (
    configObject: IDisasterRecoverySecondStepForm
): FormConfig => ({
    [DISASTER_RECOVERY_SECOND_STEP_FORM_FIELDS.password]: [configObject.password],
});
