import { Directive, HostListener, Input } from '@angular/core';

import { IKnowledgeBaseResultModel } from '../../../../models/service/knowledgebase.model';
import { KnowledgeBaseService } from '../../services/knowledge-base/knowledge-base.service';

@Directive({
    selector: '[kblink]',
})
export class KnowledgebaseLinkDirective {
    @Input('kblink') kbLink: IKnowledgeBaseResultModel;

    constructor(private _knowledgeBaseService: KnowledgeBaseService) {}

    @HostListener('click') click(): void {
        this._knowledgeBaseService.openArticle(this.kbLink);
    }
}
