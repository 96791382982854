import { Component, Input } from '@angular/core';

import { AlertType } from '../../../shared/enum/alert-type.enum';
import { IDictionary } from '../../../shared/interfaces/dictionary.interface';

import { ALERT_CLASSES_BY_TYPE, ALERT_ICONS_BY_TYPE } from './cm-alert.constants';

@Component({
    selector: 'cm-alert',
    templateUrl: './cm-alert.component.html',
    styleUrls: ['./cm-alert.component.scss'],
})
export class CmAlertComponent {
    @Input() type: AlertType = AlertType.primary;
    @Input() dismissible: boolean = true;
    @Input() class: string;
    @Input() active: boolean = true;
    alertIconByType: IDictionary<string> = ALERT_ICONS_BY_TYPE;
    defaultAlertIcon: string = ALERT_ICONS_BY_TYPE[AlertType.primary];

    get alertClass(): { [key: string]: boolean } {
        return {
            [ALERT_CLASSES_BY_TYPE[this.type || AlertType.primary]]: true,
            [this.class]: true,
            'alert-dismissible': this.dismissible,
        };
    }

    get iconColors(): { [key: string]: string } {
        return {
            primaryColor: this.type === AlertType.warning ? '#000000' : '#FFFFFF',
            secondaryColor: `var(--${this.type === AlertType.error ? 'danger' : this.type})`,
        };
    }

    dismiss(): void {
        this.active = false;
    }
}
