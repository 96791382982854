import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CmFormsModule } from '../../../../../feature-modules/design-system/components/forms/cm-forms.module';
import { GiacomDateModule } from '../../../../../shared/pipes/giacom-date/giacom-date.module';
import { CmTableModule } from '../../../cm-table/cm-table.module';

import { CoterminateFormControlComponent } from './coterminate-form-control.component';

@NgModule({
    declarations: [CoterminateFormControlComponent],
    imports: [
        CmTableModule,
        CommonModule,
        TranslateModule,
        GiacomDateModule,
        CmFormsModule,
        FormsModule,
    ],
    exports: [CoterminateFormControlComponent],
})
export class CoterminateFormControlModule {}
