import { Inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { View } from '@cloudmarket/permissions-contract/lib/permissions.enum';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { CustomerFlow } from '../../pages/customer/customer-flow.enum';
import { ROUTES_PATHS } from '../../shared/constants/routes.constants';
import { STORAGE_KEYS } from '../../shared/constants/storage-keys.constants';
import { UserType } from '../../shared/enum/user-type.enum';
import { WINDOW } from '../injection-tokens/window.injection-token';

import { ApiPermissionsService } from './api/api-permissions.service';
import { SingleExperienceUserService } from './single-experience/single-experience-user.service';

@Injectable({
    providedIn: 'root',
})
export class DefaultPageRouteService {
    private readonly _viewNavigateExcluded: View[] = [View.academy, View.community];
    constructor(
        @Inject(WINDOW) private _window: Window,
        private _apiPermissionService: ApiPermissionsService,
        private _router: Router,
        private _singleExperienceUserService: SingleExperienceUserService) {}

    goToDefaultPage(organisationId?: string): void {
        this._singleExperienceStateForUser$()
            .pipe(
                switchMap((isSingleExperienceEnabled: boolean) =>
                    this._getDefaultUrlPageBasedOnSingleExperienceState$(isSingleExperienceEnabled, organisationId)),
                take(1))
            .subscribe((url: string) => this._navigateToUrl(url));
    }

    /**
     * @returns Default page url logged user depending on permissions
     * @param organisationId OrganisationId logged user
     * @remarks Use this method if feature flags and permissions have not been got yet.
     */
    getCloudDefaultPageUrl$(organisationId?: string): Observable<string> {
        return this._apiPermissionService
            .getPermissions$()
            .pipe(map(() => this._getCloudDefaultPageUrl(organisationId)));
    }

    goToDefaultLoginPage(cloudStateUrl?: string, singleExperienceFullUrl?: string): void {
        this._singleExperienceStateForUser$()
            .subscribe((isSingleExperienceEnabledForUser: boolean) => {
                if (isSingleExperienceEnabledForUser) {
                    this._window.location.href = singleExperienceFullUrl
                        ? singleExperienceFullUrl
                        : ROUTES_PATHS.EXTERNAL.SINGLE_EXPERIENCE_LOGIN_PAGE;
                } else {
                    const urlTree: UrlTree = this.getDefaultUrlCloudLoginPage(cloudStateUrl);
                    this._router.navigateByUrl(urlTree);
                }
            })
    }

    getDefaultUrlCloudLoginPage(stateUrl?: string): UrlTree {
        const baseurl: string = ROUTES_PATHS.LOGIN;

        return this._isEmptyOrEndUserUrl(stateUrl)
            ? this._router.parseUrl(baseurl)
            : this._router.createUrlTree([baseurl], {
                  queryParams: {
                      return: stateUrl,
                  },
              });
    }

    private _getDefaultUrlPageBasedOnSingleExperienceState$(
        isSingleExperienceEnabled: boolean,
        organisationId?: string
    ): Observable<string> {
        if (isSingleExperienceEnabled) {
            return of(ROUTES_PATHS.EXTERNAL.SINGLE_EXPERIENCE_DASHBOARD);
        }

        return this._apiPermissionService.getPermissions$().pipe(
            map(() => this._getCloudDefaultPageUrl(organisationId))
        );
    }

    private _navigateToUrl(url: string): void {
        if (url.startsWith('http://') || url.startsWith('https://')) {
            this._window.location.href = url;
        } else {
            this._router.navigateByUrl(url);
        }
    }

    private _singleExperienceStateForUser$(): Observable<boolean> {
        return this._singleExperienceUserService.isEnabledForUser$()
            .pipe(
                map((isSingleExperienceEnabledForUser: boolean) => isSingleExperienceEnabledForUser
                    && !this._isEndUser()
                    && !this._isEndUserAdmin()),
                take(1));
    }

    private _getCloudDefaultPageUrl(organisationId?: string): string {
        const orgId: string = organisationId || localStorage.getItem(STORAGE_KEYS.CUSTOMER_ADMIN_CLOUD_ORGANISATION_ID);

        let url: string;

        if (this._isEndUser()) {
            url = ROUTES_PATHS.END_USER.BASE;
        } else {
            url = this._getNavigationLink(orgId);
        }

        return url;
    }

    private _isEmptyOrEndUserUrl(url?: string): boolean {
        return !url || url === '/' || url.startsWith(ROUTES_PATHS.END_USER.BASE);
    }

    private _getNavigationLink(organisationId: string): string {
        const userViews: View[] = this._apiPermissionService.getUsersPermissions()?.views;
        const noAccessRoute: string = ROUTES_PATHS.DASHBOARD.NO_ACCESS_RESOURCES;

        if (!userViews) {
            return noAccessRoute;
        }

        const viewsToNavigate: View[] = userViews.filter(
            (viewPermission: View) =>
                !this._viewNavigateExcluded.some(
                    (viewExcluded: View) => viewExcluded === viewPermission
                )
        );

        const firstViewToNavigate: View = Math.min(...viewsToNavigate);

        switch (firstViewToNavigate) {
            case View.home:
                return ROUTES_PATHS.DASHBOARD.PAGE;
            case View.customers:
                if (this._isEndUserAdmin()) {
                    return ROUTES_PATHS.CUSTOMER.MANAGE(organisationId);
                }

                return ROUTES_PATHS.CUSTOMER.CUSTOMERS;
            case View.manageService:
                if (this._isEndUserAdmin()) {
                    return ROUTES_PATHS.CUSTOMER.MANAGE_FLOW(organisationId, CustomerFlow.services);
                }

                return ROUTES_PATHS.SERVICES.BASE;
            case View.marketplaceOrder:
                return ROUTES_PATHS.MARKETPLACE.BASE;
            case View.billing:
                return ROUTES_PATHS.BILLING.BASE;
            case View.support:
                return ROUTES_PATHS.SUPPORT.BASE;
            default:
                return noAccessRoute;
        }
    }

    private _isEndUser(): boolean {
        const currentUserType: string | null = localStorage.getItem(STORAGE_KEYS.USER_TYPE);

        return currentUserType?.toLowerCase() === UserType.endUser.toLowerCase();
    }

    private _isEndUserAdmin(): boolean {
        const currentUserType: string | null = localStorage.getItem(STORAGE_KEYS.USER_TYPE);

        return currentUserType?.toLowerCase() === UserType.endUserAdmin.toLowerCase();
    }
}
