import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    Optional,
    Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appDigitsOnly]',
})
export class DigitsOnlyDirective implements OnChanges {
    @Input() allowDecimals: boolean = false;

    constructor(
        private _elementRef: ElementRef,
        @Optional() @Self() private _ngControl: NgControl
    ) {}

    ngOnChanges(): void {
        this.writeValue(this._sanitizeNumericValue(this.readValue()));
    }

    @HostListener('input', ['$event.target.value'])
    input(value: string): void {
        this.writeValue(this._sanitizeNumericValue(value));
    }

    @HostListener('blur', ['$event.target.value'])
    blur(value: string): void {
        this.writeValue(this._sanitizeNumericValueWithoutLastDot(value));
    }

    @HostListener('paste', ['$event'])
    paste(event: ClipboardEvent | Event): void {
        const dataTransfer: DataTransfer | null = this._getClipboardData(event);
        const pastedValue: string = dataTransfer ? dataTransfer.getData('text') : '';
        const valueWithDigitsOnly: string = this._sanitizeNumericValue(pastedValue);
        const input: HTMLInputElement = event.target as HTMLInputElement;
        const selectionStart: number = input.selectionStart || 0;
        const selectionEnd: number = input.selectionEnd || 0;
        const newCursorPosition: number = selectionStart + valueWithDigitsOnly.length;
        const newInputValue: string =
            input.value.substr(0, selectionStart) +
            valueWithDigitsOnly +
            input.value.substr(selectionEnd);

        event.preventDefault();

        this.writeValue(this._sanitizeNumericValue(newInputValue));

        /** IE fix **/
        setTimeout(() => input.setSelectionRange(newCursorPosition, newCursorPosition));
    }

    readValue(): string {
        return this._elementRef.nativeElement.value;
    }

    writeValue(newValue: string): void {
        if (this._ngControl && this._ngControl.control) {
            this._ngControl.control.setValue(this._commaToDotReplace(newValue));
        }

        this._elementRef.nativeElement.value = newValue;
    }

    private _sanitizeNumericValue(value: string): string {
        if (!this.allowDecimals) {
            return value
                .replace(/^(-?\d+)([.,]?)(\d*).*$/g, '$1') // remove after dot/comma value
                .replace(/[^\d]/g, ''); // remove any non-digit chars
        }

        const valueSanitized: string = value
            .replace(/[^\d.,\-]/g, '') // remove forbidden characters
            .replace(/(?!^-)-/g, '') // remove any "-" except the negative sign
            .replace(/^[.,]/, '') // remove leading "." if any
            .replace(/^(-?\d+)([.,]?)(\d*).*$/g, '$1$2$3'); // remove any "." except the first one
        // any anything beyond it
        const valueDotPosition: number = valueSanitized.indexOf('.');
        const twoDecimalsIndexIncrease: number = 3;

        return valueDotPosition > -1
            ? valueSanitized.substring(0, valueDotPosition + twoDecimalsIndexIncrease)
            : valueSanitized;
    }

    private _sanitizeNumericValueWithoutLastDot(value: string): string {
        return this._sanitizeNumericValue(value).replace(/(\d+)([.,])$/g, '$1');
    }

    private _getClipboardData(event: ClipboardEvent | Event): DataTransfer | null {
        if ('clipboardData' in event) {
            return event.clipboardData;
        }

        /** IE fix **/
        const windowWithClipboard: { clipboardData: DataTransfer | null } = window as any;

        if (windowWithClipboard.clipboardData) {
            return windowWithClipboard.clipboardData;
        }

        return null;
    }

    private _commaToDotReplace(value: string): string {
        return value.replace(/\,/g, '.');
    }
}
