import { Injectable } from '@angular/core';
import { IdToken } from '@auth0/auth0-spa-js';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { concatMap, filter, first, take } from 'rxjs/operators';

import { SsoAuthService } from '../../../../../core/services/sso-auth/sso-auth.service';
import { ILoginState } from '../../../interfaces/login-state.interface';
import { ILoginStrategy } from '../../../interfaces/login-strategy.interface';

import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root',
})
export class LoginSsoService implements ILoginStrategy {
    status$: Observable<ILoginState>;
    private _status$: BehaviorSubject<ILoginState> = new BehaviorSubject<ILoginState>({ isProcessed: false });
    constructor(
        private _ssoAuthService: SsoAuthService,
        private _loginService: LoginService,
    ) {
        this.status$ = this._status$.asObservable();
    }

    login$(): Observable<void> {
        return this._ssoAuthService
            .isAuthenticated$()
            .pipe(
                filter((isAuthenticated: boolean) => isAuthenticated),
                concatMap(() => this._ssoAuthService.getAccessTokenSilently$()),
                concatMap((token: string | null) => {
                    if (token) {
                        return this._getLoginDetailsForSsoLogin$();
                    }

                    this._ssoAuthService.setLegacyLoginFlow();

                    return of(null);
                })
            );
    }

    private _getLoginDetailsForSsoLogin$(): Observable<void> {
        return this._ssoAuthService.idTokenClaims$()
            .pipe(
                filter((idToken: IdToken) => !!idToken),
                first(),
                concatMap((idToken: IdToken) => {
                    this._status$.next({username: idToken.email});
                    this._ssoAuthService.setSsoFlowData(idToken);

                    return this._getExchangedToken$(idToken);
                }),
                take(1)
            );
    }

    private _getExchangedToken$(idToken: IdToken): Observable<void> {
        return this._loginService.getExchangedToken$(idToken.__raw);
    }
}
