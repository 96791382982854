import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[gcFocusable]',
})
export class FocusableDirective {
    constructor(private _elementRef: ElementRef) {}

    focusAndScrollTo(): void {
        this.focus();
        this.scrollTo();
    }

    focus(): void {
        this._elementRef.nativeElement.focus();
    }

    scrollTo(): void {
        this._elementRef.nativeElement.scrollIntoView();
    }
}
