import { Injectable } from '@angular/core';
import { NavigationExtras, Params, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import {  switchMap } from 'rxjs/operators';

import { SsoAuthService } from '../../../../../core/services/sso-auth/sso-auth.service';
import { ROUTES_PATHS } from '../../../../../shared/constants/routes.constants';
import { SSO } from '../../../../../shared/constants/sso.constants';
import { LoginService } from '../login-strategy/login.service';

@Injectable({
    providedIn: 'root',
})
export class AuthorisationPageSelectorService {
    constructor(
        private _router: Router,
        private _ssoAuthService: SsoAuthService,
        private _apiLoginService: LoginService
    ) {}


    redirectPage$(userName: string, returnQueryParams: Params): Observable<void | null> {
        return this._apiLoginService
            .getLoginFlow$(userName)
            .pipe(
                switchMap((loginFlow: string) => {
                    if (loginFlow === SSO.LOGIN_FLOW.SSO) {
                        return this._redirectForSso$(userName, returnQueryParams);
                    }

                    this._navigateToLegacy(userName, returnQueryParams);

                    return of(null);
                })
            );
    }

    private _navigateToLegacy(userName: string, returnQueryParams: Params): void {
        const navigationExtras: NavigationExtras = {
            state: { userName },
        };

        if (returnQueryParams) {
            navigationExtras.queryParams = returnQueryParams;
        }

        this._router.navigate([ROUTES_PATHS.SIGN_IN], navigationExtras);
    }

    private _redirectForSso$(userName: string, returnQueryParams: Params): Observable<void> {
        return this._ssoAuthService
            .loginWithRedirectWithDefaultOptions$({
                userName,
                returnQueryParams,
            });
    }

}
