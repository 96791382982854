export enum AddServiceContextEnum {
    customer = 0,
    user = 1,
}

export interface IAddServiceModel {
    OrganisationId?: string;
    UserId?: number;
    OrganisationName?: string;
    UserName?: string;
    IsRunAtSpecificTime: boolean;
    ProductPrice?: IProductPrice[];
    IsBackModel?: boolean;
    users?: any;
}

export interface ICiriusModel extends IAddServiceModel {
    ExistingUsers?: number;
    AddingUsers?: ICiriusUserViewModel[];
    UserRef?: IMailExchangeUserModel[];
}

export interface IProductPrice {
    Name: string;
    Price: number;
    PriceString: string;
}

export interface IMailExchangeUserModel {
    EmailAddress?: string;
    DisplayName?: string;
    Password?: string;
    IsRunLater?: boolean;
    IsRunAtSpecificTime?: boolean;
    RunLaterTime: string;
    Hidden: boolean;
}

export interface IMailExchangeUserViewModel {
    IsEditing: boolean;
    User: IMailExchangeUserModel;
    EmailAddress?: string;
    DisplayName?: string;
    IsExpanded: boolean;
}

export interface ICiriusUserViewModel extends IMailExchangeUserViewModel {
    IsDummyUser: boolean;
}

export interface IAddedServiceModel {
    Id: number;
    Name: string;
    Added: boolean;
    SubId: number;
}
