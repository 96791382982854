import { environment } from '../../../environments/environment';
import { CustomerFlow } from '../../pages/customer/customer-flow.enum';
import { BaseUrl } from '../urls/base.url';

export const ROUTES_PATHS = {
    ACCESS_DENIED: '403',
    ANALYTICS: {
        BASE: 'analytics',
    },
    AUTHENTICATOR: {
        MICROSOFT: 'https://www.microsoft.com/en-gb/security/mobile-authenticator-app',
        GOOGLE: 'https://support.google.com/accounts/answer/1066447',
        AUTHY: 'https://authy.com/download/',
    },
    BILLING: {
        BASE: 'billing',
        CREDIT: 'billing/credit',
        PAYMENT: 'billing/payment-options',
        ACCOUNT_TOTALS: 'billing/account-totals',
        PRICE_LIST: '/billing/pricing',
        PSA: {
            OVERVIEW: '/billing/psa/overview',
            MATCH: '/billing/psa/match',
            REPORT: '/billing/psa/report',
            SERVICE_URL: (url: string) => `/billing/psa/${url}`,
        },
        INVOICE: {
            VIEW: (invoiceNumber: string) => `/billing/invoices/invoice-detail/${invoiceNumber}`,
        },
        INVOICES: '/billing/invoices',
        CREDIT_MEMO: {
            VIEW: (creditMemoReferenceId: string) =>
                `/billing/invoices/credit-memo-detail/${creditMemoReferenceId}`,
        },
    },
    CUSTOMER: {
        BASE: 'customers/customer',
        ORGANISATION: {
            VIEW: (organisationId: string) => `/customers/customer/${organisationId}`,
        },
        ADD_SERVICES: {
            VIEW: (organisationId: string, serviceId: string) =>
                `customers/customer/${organisationId}/add-services/${serviceId}`,
        },
        ADD_ACRONIS_BACKUP: (navigationId: string) =>  `customers/customer/${navigationId}/add-service/acronis-backup`,
        ADD_APPINTERACT: (id: string) => `customers/customer/${id}/add-service/appinteract`,
        ADD_BITDEFENDER: (id: string) => `customers/customer/${id}/add-service/bitdefender`,
        ADD_EMAIL_SECURITY: (navigationId: string) => `customers/customer/${navigationId}/add-service/email-security`,
        ADD_HOSTED_EXCHANGE: (navigationId: string) => `customers/customer/${navigationId}/add-service/hosted-exchange`,
        ADD_HOSTED_VOICE: (id: string) => `customers/customer/${id}/add-service/hosted-voice`,
        ADD_SECURE_MESSAGE: (navigationId: string) => `customers/customer/${navigationId}/add-service/secure-message`,
        ADD_LEVEL: {
            LEVEL: (level: string) => `${level}`,
        },
        ADD_SERVICE: (id: string, serviceId: string, level: string) => 
            `customers/customer/${id}/add-services/${serviceId}/Add/${level}`,
        ADD_SERVICE_BY_ROUTE: (id: string, serviceRouteName: string) => `customers/customer/${id}/add-service/${serviceRouteName}`,
        NCE_QUESTION: (id: string) => `customers/customer/${id}/nce-question`,
        ACTIONS: {
            MANAGE_EXCHANGE: '',
            MANAGE_AZURE: '',
            DELETE_USER: (id: string) => `customers/customer/${id}/delete-service/delete`,
            FULFILL_SERVICE: (customerId: string, orderId: string) =>
                `customers/customer/${customerId}/cs/fulfill-service/${orderId}`,
            USER_DETAILS: '/customers/user-details',
            M365_ACCOUNT_LINK: (organisationId: string) =>
                `customers/customer/${organisationId}/view/${CustomerFlow.m365AccountLink}`,
            ADD_SERVICE: 'add-service',
            ADD: 'Add',
        },
        CUSTOMERS: '/customers',
        CUSTOMERS_ADD: '/customers/add',
        PRODUCT_LIST: {
            BASE: (organisationId: string, customerFlow: CustomerFlow) =>
                `customers/customer/${organisationId}/view/${customerFlow}`,
        },
        MANAGE: (organisationId: string) => `customers/customer/${organisationId}`,
        MANAGE_CLOUD_SERVER: (id: string) => `services/39/${id}/1`,
        MANAGE_ACRONIS_BACKUP_USER: (id: string, modelId: string | number) => 
            `customers/customer/${id}/manage-service/acronis-backup/${modelId}`,
        MANAGE_ACRONIS_FILES_CLOUD: (id: string) => `customers/customer/${id}/manage-service/acronis-files-cloud`,
        MANAGE_ACRONIS_FILES_CLOUD_USER: (id: string, userEmail: string) => 
            `customers/customer/${id}/manage-service/acronis-files-cloud/${userEmail}`,
        MANAGE_APP_INTERACT: (id: string) => `customers/customer/${id}/manage-service/app-interact`,
        MANAGE_AZURE: (id: string) => `customers/customer/${id}/manage-service/azure`,
        MANAGE_BITDEFENDER: (id: string) => `customers/customer/${id}/manage-service/bitdefender`,
        MANAGE_HOSTED_EXCHANGE: (navId: string) => `customers/customer/${navId}/manage-service/hosted-exchange`,
        MANAGE_HOSTED_EXCHANGE_USER: (navId: string, userEmail: string) => 
            `customers/customer/${navId}/manage-service/hosted-exchange/${userEmail}`,
        MANAGE_HOSTED_VOICE: (id: string) => `customers/customer/${id}/manage-service/hosted-voice`,
        MANAGE_HOSTED_VOICE_HARDWARE: (id: string) => `customers/customer/${id}/manage-service/hosted-voice-hardware`,
        MANAGE_EMAIL_SECURITY: (id: string) => `customers/customer/${id}/manage-service/email-security`,
        MANAGE_EMAIL_SECURITY_USER: (id: string, userEmail: string) => 
            `customers/customer/${id}/manage-service/email-security/${userEmail}`,
        MANAGE_FLOW: (organisationId: string, customerFlow: CustomerFlow) =>
            `customers/customer/${organisationId}/view/${customerFlow}`,
        MIGRATE_EXCHANGE: (id: string) => `customers/customer/${id}/migrate-exchange`,
        MODIFY_SERVICE: (id: string, listingId: string, cloudMarketId: string) => 
            `customers/customer/${id}/cs/modify-service/${listingId}/${cloudMarketId}`,
        DELETE_SERVICE: (id: string) => `customers/customer/${id}/delete-service`,
        DELETE_SERVICE_USER: (id: string, modelId: string | number) => `customers/customer/${id}/delete-service/${modelId}`,
        UPGRADE_ACRONIS_BACKUP: (navigationId: string, modelId: string | number) => 
            `customers/customer/${navigationId}/upgrade-service/acronis-backup/${modelId}`,
        UPGRADE_OFFICE365: (id: string) => 
            `customers/customer/${id}'/upgrade-service/office-365`,
        UPGRADE_OFFICE365_USER: (id: string, modelId: string | number) => 
            `customers/customer/${id}'/upgrade-service/office-365/${modelId}`,
        UPGRADE_CS_SERVICE: (id: string, listingId: string, cloudMarketId: string) => 
            `customers/customer/${id}/cs/upgrade-service/${listingId}/${cloudMarketId}`,
        UPGRADE_CS_SERVICE_BY_LISTING: (id: string, listingId: string) => 
            `customers/customer/${id}/cs/upgrade-service/${listingId}`,
        UPGRADE_CS_NCE_SERVICE_BY_LISTING: (id: string, listingId: string) => 
            `customers/customer/${id}/cs-nce/upgrade-service/${listingId}`,
        UPGRADE_EMAIL_SECURITY: (navigationId: string, modelId: string | number) => 
            `customers/customer/${navigationId}/upgrade-service/email-security/${modelId}`,
        UPGRADE_HOSTED_EXCHANGE: (navigationId: string, modelId: number) => 
            `customers/customer/${navigationId}/upgrade-service/hosted-exchange/${modelId}`,
        UPGRADE_SERVICE: (navigationId: string, serviceId: string, level: string) => 
            `customers/customer/${navigationId}/add-services/${serviceId}/Upgrade/${level}`
    },
    DASHBOARD: {
        PAGE: 'dashboard',
        NO_ACCESS_RESOURCES: 'no-access-resources',
    },
    DOMAIN: {
      CLOUD: 'cloud.market',
      PRE_PROD: 'preprod.cloud.market'
    },
    END_USER: {
        BASE: '/end-user',
        ACTIONS: {
            ACTIVITY: '/activity',
        },
    },
    EXTERNAL: {
        AZURE_PORTAL:
            'https://login.microsoftonline.com/common/oauth2/authorize?resource=https%3a%2f%2fmanagement.core.windows.net%2f&response_mode=form_post&response_type=code+id_token&scope=user_impersonation+openid&state=OpenIdConnect.AuthenticationProperties%3d0QgVhioYNyFZjF022NsD7iq3VXSoEDgjYGO6b45inJ1trrMRbGJSVH600sAg6z9jWbncwlYAGXJlURjFPBtg3b-JSodj3PkzRyU19Bf0vWN_lEG-ODKMZ9GdCAItqvSazhuaHp14z9I8-YsIVpTIn6oNcgFh6g4Bjj7dhAvsf8-w1M50&nonce=636358786202546765.MGUzMDE1ZjYtYWQyZS00NGU5LWE3YTUtMjQ2OGM4YWFmNjBmYWQ5MzBiMDEtNjA0NC00NzVkLWFhOTAtY2I2MWMxYmMxNmI2&client_id=c44b4083-3bb0-49c1-b47d-974e53cbdf3c&redirect_uri=https%3a%2f%2fportal.azure.com%2fsignin%2findex%2f%3fsignIn%3d1&site_id=501430',
        BITDEFENDER_SITE: 'https://gravityzone.bitdefender.com/',
        CIRIUS_PORTAL: 'https://webapp.secure-messaging.com/',
        DROP_SUITE_PORTAL: 'https://euw.dropmyemail.com/en/users/sign_in',
        DISTRIBUTION_PROD: 'https://distribution.cloud.market/portal',
        DISTRIBUTION_UAT: 'https://devdpp.digitalwholesalesolutions.com/stagingportal/',
        DISTRIBUTION_TRY: 'We dont have try page for distribution',
        COMMS_PROD: 'https://comms.cloud.market',
        COMMS_UAT: 'https://comms.uat.cloud.market',
        COMMS_TRY: '/try/comms',
        HARDWARE_PROD: 'https://hardware.cloud.market',
        HARDWARE_UAT: 'https://comms.uat.cloud.market',
        HARDWARE_TRY: '/try/hardware',
        EMS_PORTAL: 'https://login.microsoftonline.com/',
        EXCLAIMER_PORTAL: 'https://portal.exclaimer.com/',
        GIACOM_ACADEMY: '',
        GIACOM_COMMUNITY: 'https://forum.cloud.market/',
        GIACOM_KNOWLEDGEBASE: 'https://knowledgebase.cloud.market',
        GIACOM_BREXIT: 'http://cloudmarket.com/about/sst-faqs/',
        CLOUDMARKET_UAT_BASE_URL: 'https://cloud.uat.cloud.market',
        CLOUDMARKET_DEV_BASE_URL: 'https://cloud.dev.cloud.market',
        KNOWLEDGE_BASE: {
            HELPJUICE: 'https://helpjuice.com',
            CLOUDMARKET_KNOWLEDGEBASE_WITH_FALLBACK_GENERATED: (envUrlPart) => `https://knowledgebase${envUrlPart}.cloud.market`,
            ARTICLE: (
                helpJuiceUrl: string,
                subdomain: string,
                token: string,
                cloudMarketKnowledgeBaseUrl: string,
                articlePath: string
            ) => `${helpJuiceUrl}/jwt/${subdomain}?jwt=${token}&fallback_url=${cloudMarketKnowledgeBaseUrl}/${articlePath}`,
            HOMEPAGE: (
                helpJuiceUrl: string,
                subdomain: string,
                token: string,
                cloudMarketKnowledgeBaseUrl: string,
            ) => `${helpJuiceUrl}/jwt/${subdomain}?jwt=${token}&fallback_url=${cloudMarketKnowledgeBaseUrl}`,
            URL_WITH_INIT_FALLBACK_SET: (
                helpJuiceUrl: string,
                subdomain: string,
                token: string,
                fallbackUrl: string,
            ) => `${helpJuiceUrl}/jwt/${subdomain}?jwt=${token}&fallback_url=${fallbackUrl}`
        },
        MS_PORTAL: 'https://partner.microsoft.com/',
        MS_RENOWAL_KNOWLEDGE:
            'https://knowledgebase.cloud.market/63598-buy-and-configure/managing-your-microsoft-365-renewal',
        NIMBLE_PORTAL: 'https://app.nimble.com',
        SYMANTEC_SITE: 'https://clients.messagelabs.com/Dashboard',
        MICROSFT_PROMO_GUIDE:
            'https://partner.microsoft.com/resources/detail/operations-promo-guide-pdf',
        STATUS: 'https://status.cloud.market',
        W3SCHOOLS_COLOR_PICKER: 'https://www.w3schools.com/colors/colors_picker.asp',
        ACRONIS_EU_CLOUD_LOGIN: 'https://eu-cloud.acronis.com/login',
        FOOTER: {
            SOCIAL_MEDIA: {
                FACEBOOK: 'https://www.facebook.com/GiacomWorld',
                TWITTER: 'https://twitter.com/GiacomCM',
                LINKEDIN: 'https://www.linkedin.com/company/1505237/',
                YOUTUBE: 'https://www.youtube.com/channel/UCwjyeFJQzNaL_JtxqZkCSOA',
                VIMEO: 'https://vimeo.com/user75859235',
            },
            TRADING_ENTITIES: 'https://giacom.com/help/legal ',
            CODES_OF_PRACTICE: 'https://giacom.com/help/codes-of-practice',
            GROUP_TAX_STRATEGY: 'https://giacom.com/help/group-tax-strategy',
            SLAVERY_STATEMENT: 'https://giacom.com/pdf/slavery-statement',
            COPYRIGHT: 'https://giacom.com/help/copyright',
            PRIVACY_AND_COOKIES_POLICY: 'https://giacom.com/help/privacy-and-cookies-policy',
            ACCESSIBILITY: 'https://giacom.com/help/accessibility',
            LEGAL: 'https://giacom.com/help/legal',
            TERMS_AND_CONDITIONS: 'https://giacom.com/help/terms-and-conditions',
        },
        SECURE_COMPARISON: 'https://www.cloudmarket.com/pdfs/o365-secure/O365-Secure-Comparison-tables-with-prices.pdf',
        SINGLE_EXPERIENCE_DASHBOARD_ERROR: `${environment.singleExperienceDashboardUrl}/error`,
        SINGLE_EXPERIENCE_DASHBOARD: `${environment.singleExperienceDashboardUrl}`,
        SINGLE_EXPERIENCE_LOGIN_PAGE: `${environment.singleExperienceDashboardUrl}`,
        SINGLE_EXPERIENCE_DASHBOARD_ORGANISATION_MEMBERS: `${environment.singleExperienceDashboardUrl}/organisation/members`,

    },
    MARKETPLACE: {
        BASE: 'marketplace',
    },
    NOT_FOUND: '404',
    OPT_OUT: 'opt-out',
    SERVICES: {
        BASE: 'services',
        EXCLAIMER: 'exclaimer',
        MANAGE_SERVICES: (msServiceId: number, id: string) => `services/${msServiceId}/${id}/1`,
    },
    SETTINGS: {
        ACC: '/account-setting',
        ACTIONS: {
            ADD_USER_ADMIN: '/account-setting/manage-access/add-customer-admin/EndUserAdmin',
            ADD_USER_ENGINEER: '/account-setting/manage-access/add-engineer/Engineer',
        },
        AGREEMENT: '/account-setting/terms-conditions',
        REDIRECT_TO_AGREEMENTS: (agreementId: number) => `account-setting/terms-conditions/${agreementId}/false/false`,
        MANAGE_ACCESS: {
            USER_DETAILS: (userId: string) => `/account-setting/manage-access/${userId}/`,
            ROLES_EDITOR: (userId: string) =>
                `/account-setting/manage-access/${userId}/rolesEditor`,
            USER_PERMISSIONS: (userId: string, userType: string) =>
                `account-setting/manage-access/${userId}/permissions/${userType}`,
            ROLEBASEDACCESS: `${BaseUrl.baseUrl}/api/rolebasedaccess/settings`,
            SET_ADMIN_SUFFIX: (adminSuffix: string) =>
                `${BaseUrl.baseUrl}/api/rolebasedaccess/settings/suffix/${adminSuffix}`,
            MANAGE: '/account-setting/manage-access/',
        },
        MY_PROFILE: '/account-setting/manage-access/my-profile',
        MY_BUSINESS: '/my-business',
        SERVICE_AGREEMENTS: '/account-setting/service-agreements',
        WHITELABELLING: '/account-setting/whitelabelling',
        IP_ACCESS: '/account-setting/ip-access',
        INTEGRATION: '/account-setting/integration',
    },
    SSO_SETTINGS: {
        PREFIX: 'sso-account-setting',
        MANAGE_ACCESS: {
            ROLES_EDITOR: (userId: string) =>
                `/sso-account-setting/manage-access/${userId}/rolesEditor`
        }
    },
    SIGN_IN: 'sign-in',
    SWITCH_TO_MAILBOX: 'switch-mailbox-confirmation',
    CHANGE_PASSWORD: 'change-password',
    RESET_PASSWORD: '/reset-password',
    LOGIN: 'login',
    MS_LOGIN: {
        URL: (
            o365AuthClientId: string,
            siteBaseUrl: string,
            paramsAsBase64: string,
            o365AuthNonce: string
        ) => `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=${o365AuthClientId}
        &redirect_uri=${siteBaseUrl}/handle-ext-auth
        &response_mode=fragment
        &response_type=id_token
        &scope=openid%20profile
        &state=${paramsAsBase64}
        &nonce=${o365AuthNonce}`,
        LINK_ACCOUNT: 'link-account'
    },
    SUPPORT: {
        BASE: 'support',
        TICKETS: '/support/tickets',
        TICKET_DETAIL: '/support/ticket-detail',
        ADD_TICKET: '/support/add-new-ticket',
        ORDER: '/support/order',
    },
};
