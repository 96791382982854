import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { CardVariant } from './card-variant.enum';

@Component({
    selector: 'cm-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
    @HostBinding('class.h-100') stretchHostHeight: boolean = false;
    @Input() variant: CardVariant = CardVariant.default;
    @Input() grow: boolean = false;
    @Input() loading: boolean = false;
    cardVariant: typeof CardVariant = CardVariant;

    ngOnInit(): void {
        this.stretchHostHeight = this.grow;
    }
}
