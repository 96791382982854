import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ResetPasswordService } from 'app/shared/services/reset-password.service';

import { IExecutionResult } from '../../../../core/interfaces/execution-result.interface';
import { IResetPasswordModel } from '../../../../models/user/reset-password.interface';
import { ROUTES_PATHS } from '../../../../shared/constants/routes.constants';
import { GAFormDirective } from '../../../../shared/directives/ga-form/ga-form.directive';
import { AlertType } from '../../../../shared/enum/alert-type.enum';
import { PageViewMethod } from '../../../../shared/services/ga-service/ga-page-load';
import { GAService } from '../../../../shared/services/ga-service/ga-service';
import { AbstractPageLoad } from '../../../../shared/services/ga-service/shared-classes';

@Component({
    selector: 'app-giacom-reset-password',
    templateUrl: './reset-password.component.html',
    // eslint-disable-next-linerelative-url-prefix
    styleUrls: ['../auth.scss'],
})
export class ResetPasswordComponent extends AbstractPageLoad implements OnInit {
    @ViewChild(GAFormDirective) form: GAFormDirective;
    pageName: string = 'identity/reset-password';
    pageType: string = 'identity';
    pageViewMethod: PageViewMethod = PageViewMethod.page;
    resetPassModel: IResetPasswordModel = {
        UserName: '',
    };
    isProcessing: boolean = false;
    successfulMessage: string;
    submitted: boolean = false;
    invalidEmail: boolean = false;
    resetPasswordFailed: boolean = true;
    isRequestResetPassword: boolean = true;
    isChangingPassword: boolean = false;
    isCompleteChangedPassword: boolean = false;
    resetPasswordForm: UntypedFormGroup;
    emailValidationErrors: string[] = [];
    readonly alertType: typeof AlertType = AlertType;
    private readonly _defaultErrorMessage: string =
        'Please enter your login name or email address!';

    constructor(
        private _router: Router,
        protected _gaService: GAService,
        private _formBuilder: UntypedFormBuilder,
        private _resetPasswordService: ResetPasswordService
    ) {
        super(_gaService);
    }

    ngOnInit(): void {
        this.recordPageLoad();
        this.resetPasswordFormInit();
    }

    isValidForm(resetPassModel: IResetPasswordModel): boolean {
        if (!resetPassModel.UserName) {
            this.emailValidationErrors = [this._defaultErrorMessage];
            this.invalidEmail = true;

            return false;
        }
        this.invalidEmail = false;

        return true;
    }

    onSubmit(resetPassModel: IResetPasswordModel): void {
        this.submitted = true;
        this.resetPasswordFailed = false;

        if (this.isValidForm(resetPassModel)) {
            this.resetPassword(resetPassModel);
        } else {
            this.resetPasswordFailed = true;
        }
    }

    resetPassword(resetPasswordData: IResetPasswordModel): void {
        this.recordVirtualPageLoad({ path: 'reset-requested' });
        this.isProcessing = true;
        this._resetPasswordService.requestReset$(resetPasswordData).subscribe({
            next: (data: IExecutionResult<string, { EmailErrors: string[] }>) => {
                this.successfulMessage = null;
                if (!data.Succeed) {
                    this.resetPasswordFailed = true;
                    this.emailValidationErrors = data.Errors?.EmailErrors || [];
                } else {
                    this.resetPasswordFailed = false;
                    this.successfulMessage = data.Result;
                }
                this.isProcessing = false;
            },
            error: (tmpErr: { error_description: string }) => {
                this.emailValidationErrors = [];
                this.resetPasswordFailed = true;

                if (tmpErr) {
                    this.emailValidationErrors.push(tmpErr.error_description);
                }
                if (!this.emailValidationErrors.length) {
                    this.emailValidationErrors.push(this._defaultErrorMessage);
                }
            }
        });
    }

    resetPasswordFormInit(): void {
        this.resetPasswordForm = this._formBuilder.group({
            username: ['', Validators.required],
        });
    }

    goToSignIn(): void {
        this._router.navigateByUrl(ROUTES_PATHS.LOGIN);
    }

    handleFormSubmission(): void {
        this.submitted = true;
        this.resetPasswordFailed = false;
        this.emailValidationErrors = [];
        this.resetPassModel.UserName = this.resetPasswordForm.get('username').value;

        if (this.isValidForm(this.resetPassModel)) {
            this.resetPassword(this.resetPassModel);
        } else {
            this.resetPasswordFailed = true;
        }
    }
}
