import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AbstractPopup } from '../../../../feature-modules/popups/classes/abstract-popup.class';

import { IConfirmationParams } from './interface/confirmation.types';


@Component({
    selector: 'cm-confirmation',
    templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent extends AbstractPopup<IConfirmationParams> {
    isCloseable: boolean = true;
    isPrimaryButtonHidden: boolean = false;
    isSecondaryButtonHidden: boolean = false;
    title: string = this._translateService.instant('COMMONS.CONFIRMATION_MODAL.ARE_YOU_SURE');
    message: string = this._translateService.instant('COMMONS.CONFIRMATION_MODAL.MESSAGE');
    primaryButtonText: string = this._translateService.instant('COMMONS.CONFIRMATION_MODAL.PRIMARY_BUTTON_TEXT');
    secondaryButtonText: string = this._translateService.instant('COMMONS.CONFIRMATION_MODAL.SECONDARY_BUTTON_TEXT');
    onPrimaryButtonAction: () => void;
    onSecondaryButtonAction: () => void;
    constructor(
        private _translateService: TranslateService
    ) {
        super();
    }

    updateParams(params: IConfirmationParams): void {
        this.isCloseable = params.isCloseable !== undefined ? params.isCloseable : this.isCloseable;
        this.isPrimaryButtonHidden = params.isPrimaryButtonHidden;
        this.isSecondaryButtonHidden = params.isSecondaryButtonHidden;
        this.title = params.title || this.title;
        this.message = params.message || this.message;
        this.primaryButtonText = params.primaryButtonText || this.primaryButtonText;
        this.secondaryButtonText = params.secondaryButtonText || this.secondaryButtonText;
        this.onPrimaryButtonAction = params.primaryButtonAction;
        this.onSecondaryButtonAction = params.secondaryButtonAction ? params.secondaryButtonAction : () => close
    }

}
