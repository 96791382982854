import { Pipe, PipeTransform } from '@angular/core';

import { INotification } from '../interfaces/notification.interface';

@Pipe({
    name: 'FilterNotificationsByCategory',
})
export class FilterNotificationsByCategoryPipe implements PipeTransform {
    transform(items: INotification[], category: string): INotification[] {
        if (!items) {
            return [];
        }
        if (!category || category === 'show-all') {
            return items;
        }

        return items.filter(
            (item: INotification) => item.Category.replace(' ', '-').toLowerCase() === category
        );
    }
}
