import { Location } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Params, Router } from '@angular/router';
import { IUsersPermissionsResponse } from '@cloudmarket/permissions-contract';
import { Permission, View } from '@cloudmarket/permissions-contract/lib/permissions.enum';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DOCUMENT } from '../../../../core/injection-tokens/document.injection-token';
import { WINDOW } from '../../../../core/injection-tokens/window.injection-token';
import {
    IPartnerSystems,
    ISystemsLinkage,
    ISystemURLs
} from '../../../../core/interfaces/partner-systems.types';
import { IWindow } from '../../../../core/interfaces/window.interface';
import { AccountSettingsService } from '../../../../core/services/account-settings.service';
import { ApiFeatureFlagsService } from '../../../../core/services/api/api-feature-flags.service';
import { ApiPermissionsService } from '../../../../core/services/api/api-permissions.service';
import { DefaultPageRouteService } from '../../../../core/services/default-page-route.service';
import { DeviceScreenService } from '../../../../core/services/device-screen/device-screen.service';
import {
    GlobalEventService,
    GlobalEventSubscription,
} from '../../../../core/services/global-event.service';
import { PermissionsService } from '../../../../core/services/permissions.service';
import { Resource, UserInfo } from '../../../../core/services/resource.service';
import { SsoAuthService } from '../../../../core/services/sso-auth/sso-auth.service';
import {
    SwitchUserConfirmationHandlerService
} from '../../../../core/services/switch-user-types/switch-user-confirmation-handler.service';
import { ApiTryPagesService } from '../../../../core/services/try-pages/api-try-pages.service';
import {
    DEFAULT_SYSTEM_LINKAGE,
    DEFAULT_SYSTEM_URLS
} from '../../../../core/services/try-pages/default-system-urls.config';
import { IConfirmationParams } from '../../../../new-shared/components/modals/confirmation/interface/confirmation.types';
import { KNOWLEDGE_BASE_LINK } from '../../../../pages/customer/customer-shared/constants/knowledge-base-link.constants';
import { MarketplaceServiceType } from '../../../../pages/marketplace/pages/marketplace/marketplace.types';
import { RELEASE_VERSION } from '../../../../shared/constants/release-version.constants';
import { ROUTES_PATHS } from '../../../../shared/constants/routes.constants';
import { STORAGE_KEYS } from '../../../../shared/constants/storage-keys.constants';
import { WHITELABEL_VARIABLE_NAMES } from '../../../../shared/constants/whitelabel.constants';
import { ScreenSize } from '../../../../shared/enum/screen-size.enum';
import { UserType } from '../../../../shared/enum/user-type.enum';
import { IScreenSize } from '../../../../shared/interfaces/screen-size.interface';
import { WhitelabelService } from '../../../../shared/services/whitelabel.service';
import { NotificationService } from '../../../notifications/services/notification.service';
import { PopupService } from '../../../popups/services/popup.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterViewInit, OnInit, OnDestroy {
    get isHidePrice(): boolean {
        return this._accountSettingsService.hidePrice;
    }

    newNotificationsAmount$: Observable<number>;
    userName: string = '';
    isDisplayMenu: boolean = true;
    isDisplayHarmburgerMenu: boolean = false;
    releaseVersion: string = RELEASE_VERSION;
    organisationId: string;
    signedInUser: UserInfo;
    isEuroReseller: boolean;
    userType: string;
    switchUserConfirmationMessage: string = '';
    subPriceChange: GlobalEventSubscription;
    isUserMenuHighlight: boolean = false;
    myGiacomAcademyUrl: string;
    forumActive: boolean;
    routesPaths: typeof ROUTES_PATHS = ROUTES_PATHS;
    manageUsersQueryParams: Params;
    userMexId: string = '';
    showNewButton: boolean = false;
    showSupportDropdown: boolean = false;
    showProvisionManageServicesDropdown: boolean = false;
    systemsURLs: ISystemURLs = DEFAULT_SYSTEM_URLS;
    systemsLinkage: ISystemsLinkage = DEFAULT_SYSTEM_LINKAGE;
    hasAccessToMyBusiness: boolean = false;
    hasAccessToManageUsers: boolean = false;
    hasAccessToSettings: boolean = false;
    readonly view: typeof View = View;
    readonly permission: typeof Permission = Permission;
    readonly marketplaceServiceType: typeof MarketplaceServiceType = MarketplaceServiceType;
    private readonly _destroyTrigger$: Subject<void> = new Subject<void>();

    constructor(
        public whitelabelService: WhitelabelService,
        @Inject(DOCUMENT) private _document: Document,
        @Inject(WINDOW) private _window: IWindow,
        private _router: Router,
        private _popupService: PopupService,
        private _eventService: GlobalEventService,
        private _location: Location,
        private _accountSettingsService: AccountSettingsService,
        private _resource: Resource,
        private _notificationService: NotificationService,
        private _featureFlagsService: ApiFeatureFlagsService,
        private _deviceScreenService: DeviceScreenService,
        private _apiPermissionsService: ApiPermissionsService,
        private _permissionsService: PermissionsService,
        private _defaultPageRouteService: DefaultPageRouteService,
        private _ssoAuthService: SsoAuthService,
        private _switchUserConfirmationHandlerService: SwitchUserConfirmationHandlerService,
        private _apiTryPagesService: ApiTryPagesService,
        private _translateService: TranslateService
    ) {
        this.signedInUser = this._resource.user;
        this.isEuroReseller = this._resource.isEuroReseller;
    }

    goToDefaultPage(): void {
        this._defaultPageRouteService.goToDefaultPage();
    }

    prepareVisitorId(): void {
        const userMex: string = localStorage.getItem('userMex');

        this.userMexId = userMex ? `?visitor_id=${userMex}` : '';
    }

    ngOnInit(): void {
        this._setRbacAccess();

        this.fetchScreenInformations();

        this.getNotificationsAmount();
        this.getMyGiacomAcademy();

        this.userName = localStorage.getItem('username');
        this.userType = localStorage.getItem('userType');

        this.organisationId = localStorage.getItem('enduseradminId');
        this.subPriceChange = this._eventService.subscribe('event-hide-price', () => {});

        this.showUserMenuHighlight();
        this.setIsForumActive();
        this._setManageUsersQueryParams();
        this.prepareVisitorId();
        this._fetchSystemsInfo();
    }

    ngAfterViewInit(): void {
        this._setHeaderHeightProperty();
    }

    ngOnDestroy(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
        this.subPriceChange.unsubscribe();
    }
    redirectToComms(): void{
        this._window.location.href = `${
            this.systemsURLs.COMMS}${this.systemsLinkage.isCommsAccLinked ? '' : this.userMexId
        }`
    }

    redirectToHardware(): void{
        this._window.location.href = `${
            this.systemsURLs.HARDWARE}${this.systemsLinkage.isHardwareAccLinked ? '' : this.userMexId
        }`
    }

    fetchScreenInformations(): void {
        this._deviceScreenService
            .getScreenSizeFlags$()
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe((deviceScreenSizeFlags: IScreenSize) => {
                if (deviceScreenSizeFlags[ScreenSize.large]) {
                    this.isDisplayMenu = true;
                    this.isDisplayHarmburgerMenu = false;
                } else if (deviceScreenSizeFlags[ScreenSize.medium]) {
                    this.isDisplayMenu = false;
                    this.isDisplayHarmburgerMenu = false;
                } else {
                    this.isDisplayHarmburgerMenu = true;
                }

                this.whitelabelService.setLogo(deviceScreenSizeFlags[ScreenSize.small]);
            });
    }

    getNotificationsAmount(): void {
        this._notificationService.connectSignalR();
        this.newNotificationsAmount$ = this._notificationService.getNewNotificationsAmount$();
    }

    handleAddProductClick(): void {
        this._popupService.show(null, 'select-service', { noCustomerOrService: true });
    }

    handleAddSupportTicketClick(): void {
        this._router.navigate([this.routesPaths.SUPPORT.ADD_TICKET]);
    }

    showUserMenuHighlight(): void {
        this.isUserMenuHighlight = this._router.url.indexOf('customers/user-details') >= 0;
    }

    getMyGiacomAcademy(): void {
        this.myGiacomAcademyUrl = `${KNOWLEDGE_BASE_LINK}/academy`;

        return;
    }

    academyClick(): void {
        window.open(this.myGiacomAcademyUrl);
    }

    goToExternalPage(route: string): void {
        window.open(route);
    }

    logout(): void {
        const currentPath: string = this._location.path();
        const pages: string[] = ['add-service', 'configure-quick-links'];
        let isShowConfirmation: boolean = false;

        for (const page of pages) {
            if (currentPath.indexOf(page) >= 0) {
                isShowConfirmation = true;
            }
        }
        if (isShowConfirmation) {
            this.showConfirmationPopup();
        } else {
            this._ssoAuthService.logoutWithDefaultOptions();
        }
    }

    showConfirmationPopup(): void {
        this._popupService.show(
            null,
            'confirmation',
            {
                title: this._translateService.instant('HEADER.ARE_YOU_SURE_TO_SIGN_OUT'),
                primaryButtonAction: () => this._ssoAuthService.logoutWithDefaultOptions()
            } as IConfirmationParams
        )
    }

    showSwitchUserPopup(): void {
        const userType: string = localStorage.getItem(STORAGE_KEYS.USERTYPE);
        const previousType: string = localStorage.getItem(STORAGE_KEYS.PREVIOUS_TYPE);
        this.switchUserConfirmationMessage =
            (userType === UserType.endUser)
                ? (previousType === UserType.endUserAdmin
                    ? this._translateService.instant('SWITCH_MAILBOX_CONFIRMATION.CONFIRMATION_MODAL.TITLE_FOR_ADMIN_PANEL')
                    : this._translateService.instant('SWITCH_MAILBOX_CONFIRMATION.CONFIRMATION_MODAL.TITLE_FOR_MASTER_PANEL'))
                : this._translateService.instant('SWITCH_MAILBOX_CONFIRMATION.CONFIRMATION_MODAL.TITLE_FOR_MAILBOX_PANEL');

        this._popupService.show(
            null,
            'confirmation',
            {
                title: this._translateService.instant('SWITCH_MAILBOX_CONFIRMATION.CONFIRMATION_MODAL.SWITCH_USER'),
                message: this.switchUserConfirmationMessage,
                primaryButtonText: this._translateService.instant('CONTINUE'),
                secondaryButtonText: this._translateService.instant('CANCEL'),
                primaryButtonAction: () => (this.signedInUser = this._switchUserConfirmationHandlerService.doSwitchAndGiveUserInfo())
            } as IConfirmationParams
        )
    }

    switchUser(): void {
        this.showSwitchUserPopup();
    }

    setIsForumActive(): void {
        this.forumActive = this._featureFlagsService.isFeatureFlagEnabled('Forum');
    }

    private _fetchSystemsInfo(): void {
        this._apiTryPagesService.getPartnerAccessToSystemsInfo$().subscribe((systemsLinkedWithAccount: IPartnerSystems)=> {
            this.systemsURLs = this._apiTryPagesService.getSystemLinks(systemsLinkedWithAccount.systems)
            this.systemsLinkage = this._apiTryPagesService.verifyAccessToOtherPortals(systemsLinkedWithAccount.systems);
        })
    }

    private _hasAccessToViewAndIsNotEndUser(view: View): boolean {
        const hasAccessToView: boolean = this._apiPermissionsService
            .getUsersPermissions().views?.some(
                (userView: View) =>
                    userView === view
            );

        return hasAccessToView && !this.signedInUser.isEndUser;
    }

    private _setManageUsersQueryParams(): void {
        if (this._permissionsService.hasPermission(Permission.createResellerEngineer)) {
            this.manageUsersQueryParams = { tab: UserType.engineer.toLowerCase() };
        } else if (this._permissionsService.hasPermission(Permission.createCustomerAdmin)) {
            this.manageUsersQueryParams = { tab: UserType.endUserAdmin.toLowerCase() };
        }
    }

    private _setHeaderHeightProperty(): void {
        const body: HTMLElement = this._document.body;
        const logo: HTMLElement = this._document.querySelector('#loginUserLogo');
        const header: HTMLElement = this._document.querySelector('app-header');

        body.style.setProperty(WHITELABEL_VARIABLE_NAMES.headerHeight, `${header.offsetHeight}px`);

        logo?.addEventListener('load', () => {
            body.style.setProperty(
                WHITELABEL_VARIABLE_NAMES.headerHeight,
                `${header.offsetHeight}px`
            );
        });
    }

    private _setRbacAccess(): void {
        const userPermissions: IUsersPermissionsResponse = this._apiPermissionsService
            .getUsersPermissions();

        this.showNewButton = userPermissions.permissions?.some(
                (permission: Permission) =>
                    permission === Permission.addProducts ||
                    permission === Permission.addOrEditCustomers ||
                    permission === Permission.submitAndRespondToAllTickets
            );

        this.showSupportDropdown = userPermissions.views?.some(
                (view: View) =>
                    view === View.support ||
                    view === View.academy ||
                    view === View.knowledgeBase ||
                    view === View.community
            );

        this.showProvisionManageServicesDropdown = userPermissions.views?.some(
                (view: View) =>
                    view === View.marketplaceOrder ||
                    view === View.manageService ||
                    view === View.serviceAgreements
            );

        this.hasAccessToMyBusiness = this._hasAccessToViewAndIsNotEndUser(View.myBusiness);
        this.hasAccessToManageUsers = this._hasAccessToViewAndIsNotEndUser(View.manageUsers);
        this.hasAccessToSettings = this._hasAccessToViewAndIsNotEndUser(View.settings);
    }
}
