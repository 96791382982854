import { NgModule } from '@angular/core';

import { LayoutModule } from '../../../feature-modules/layout/layout.module';
import { SharedModule } from '../../../shared/shared.module';

import { PermissionErrorRoutingModule } from './permission-error-routing.module';
import { PermissionErrorComponent } from './permission-error.component';

@NgModule({
    imports: [LayoutModule, SharedModule, PermissionErrorRoutingModule],
    declarations: [PermissionErrorComponent],
})
export class PermissionErrorModule {}
