import { Injectable } from '@angular/core';
import JwtDecode from 'jwt-decode';

import { SharedSessionCookieStorageService } from '../cookie/shared-session-cookie-storage.service';

/**
 * Helper class to decode and find JWT expiration.
 */
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    get publicRoutes(): string[] {
        return ['sign-in', 'signup', 'forget-password', 'reset-password', '404', 'login'];
    }

    private _jwtDecode: (token: string) => { exp: number, RESELLER_CURRENCY_CODE: string } = JwtDecode;
    constructor(
        private _sharedSessionCookieStorageService: SharedSessionCookieStorageService,
    ) {}

    tokenNotExpired(): boolean {
        const token: string = this._sharedSessionCookieStorageService.getToken();

        if (!token) {
            return false;
        }

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return new Date().getTime() / 1000 < this._jwtDecode(token).exp;
    }

    tokenExpired(): boolean {
        return !this.tokenNotExpired();
    }

    isLoggedIn(url: string): boolean {
        return this.publicRoutes.indexOf(url) !== -1 || this.tokenNotExpired();
    }

    getToken(): string {
        return this._sharedSessionCookieStorageService.getToken();
    }
}
