import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { SharedSessionCookieStorageService } from '../../../feature-modules/auth/services/cookie/shared-session-cookie-storage.service';
import { SSO } from '../../../shared/constants/sso.constants';

@Injectable({
    providedIn: 'root',
})
export class SingleExperienceUserService implements OnDestroy {
    private readonly _destroyTrigger$: Subject<void> = new Subject();
    private _isSingleExperienceEnabledForUser$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private _sharedSessionCookieStorageService: SharedSessionCookieStorageService,
    ) {
        this._setSingleExperienceStateForUser();
    }

    ngOnDestroy(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }

    isEnabledForUser$(): Observable<boolean> {
        return this._isSingleExperienceEnabledForUser$.asObservable();
    };

    enable(): void {
        this._sharedSessionCookieStorageService.setUseSingleExperience(true);
        this._isSingleExperienceEnabledForUser$.next(true);
    }

    disable(): void {
        this._sharedSessionCookieStorageService.setUseSingleExperience(false);
        this._isSingleExperienceEnabledForUser$.next(false);
    }

    private _setSingleExperienceStateForUser(): void {
        this._isSingleExperienceEnabledForUser$.next(
            this._sharedSessionCookieStorageService.getUseSingleExperience()
            && localStorage.getItem(SSO.LOGIN_FLOW.KEY) === SSO.LOGIN_FLOW.SSO);
    }
}
