import { NgModule } from '@angular/core';

import { CmButtonModule } from '../../shared/components/cm-button/cm-button.module';
import { SharedModule } from '../../shared/shared.module';

import { RateItPopupComponent } from './components/rate-it-popup/rate-it-popup.component';
import { RateItPopupService } from './providers/rate-it-popup.service';

@NgModule({
    imports: [SharedModule, CmButtonModule],
    declarations: [RateItPopupComponent],
    providers: [RateItPopupService],
    exports: [RateItPopupComponent],
})
export class RateItPopupModule {}
