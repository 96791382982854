import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WaitSpinnerModule } from '../wait-spinner/wait-spinner.module';

import { CardContentComponent } from './card-content/card-content.component';
import { CardFooterComponent } from './card-footer/card-footer.component';
import { CardHeaderAddonsComponent } from './card-header-addons/card-header-addons.component';
import { CardSubtitleComponent } from './card-subtitle/card-subtitle.component';
import { CardTitleComponent } from './card-title/card-title.component';
import { CardComponent } from './card.component';

@NgModule({
    declarations: [
        CardComponent,
        CardContentComponent,
        CardFooterComponent,
        CardHeaderAddonsComponent,
        CardSubtitleComponent,
        CardTitleComponent,
    ],
    imports: [CommonModule, WaitSpinnerModule],
    exports: [
        CardComponent,
        CardContentComponent,
        CardFooterComponent,
        CardHeaderAddonsComponent,
        CardSubtitleComponent,
        CardTitleComponent,
    ],
})
export class CardModule {}
