import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { DefaultPageRouteService } from '../../../core/services/default-page-route.service';
import { SsoAuthService } from '../../../core/services/sso-auth/sso-auth.service';

@Injectable({
    providedIn: 'root',
})
export class NonSsoFlowGuard implements CanActivate {
    constructor(
        private _ssoAuthService: SsoAuthService,
        private _defaultPageRouteService: DefaultPageRouteService
    ) {}

    canActivate(): Observable<UrlTree> | boolean {
        if (this._ssoAuthService.isSsoLoginFlow) {
            this._defaultPageRouteService.goToDefaultPage();

            return false;
        }

        return true;
    }
}
