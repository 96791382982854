import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { IConfigureMFAData } from '../../../../../new-shared/components/modals/mfa/configure-mfa-data.interface';
import { SECRET_KEY_STEP } from '../../../../../new-shared/components/modals/mfa/constants/configure-mfa-steps.constants';
import { IMFAErrorData } from '../../../../../new-shared/components/modals/mfa/mfa-error-data.interface';
import {
    IMFAVerificationWindowData,
} from '../../../../../new-shared/components/modals/mfa/mfa-verification-window-data.interface';
import { MfaService } from '../../../../../new-shared/components/modals/mfa/services/mfa.service';
import { IErrorData } from '../../../../../shared/interfaces/error-data.interface';
import { ILogin } from '../../../interfaces/login.interface';

@Injectable({
    providedIn: 'root',
})
export class LoginMfaService {
    configureMFAData: IConfigureMFAData = {
        errorData: {
            isError: false,
            errorDescription: '',
        },
        manualEntryKey: '',
        qrCodeSetupImageUrl: '',
        showStep: SECRET_KEY_STEP,
    };
    mfaVerificationWindowData: IMFAVerificationWindowData = {
        errorData: {
            isError: false,
            errorDescription: '',
        },
        username: '',
        isVerifying: false,
    };

    loginModel: ILogin = {
        username: '',
        password: '',
        datetime: '',
        mfaKey: '',
        mfaToken: '',
    };
    private _loginCallBack: (loginData: ILogin) => Observable<void>;
    private _destroyTrigger$: Subject<void> = new Subject<void>();

    constructor(
        private _mfaService: MfaService,
    ) {
    }

    mfaTokenNeeded(loginData: ILogin): void {
        this.loginModel = loginData;
        this.mfaVerificationWindowData.username = this.loginModel.username;

        this._showMFAVerificationCodeModal();
    }

    mfaConfigNeeded(errorMessage: string, loginData: ILogin): void {
        this.loginModel = loginData;
        this.configureMFAData.manualEntryKey = errorMessage.split('|')[0];
        this.configureMFAData.qrCodeSetupImageUrl = errorMessage.split('|')[1];

        this._showMFAMoreInformationModal();
    }

    isMfaErrorHandled(error: IErrorData | null,
                      loginData: ILogin,
                      loginCallBack: (loginData: ILogin) => Observable<void>): boolean {
        this._loginCallBack = loginCallBack;
        const errorDescription: string = error?.error_description || '';
        const errorMessage: string = error?.error || '';

        if (errorMessage.startsWith('mfa_config_needed')) {
            this.mfaConfigNeeded(errorDescription, loginData);

            return true;
        }

        if (errorMessage.startsWith('mfa_token_needed')) {
            this.mfaTokenNeeded(loginData);

            return true;
        }

        return false;
    }

    private _showMFAMoreInformationModal(): void {
        this.configureMFAData.errorData.isError = false;
        this.configureMFAData.showStep = SECRET_KEY_STEP;

        this._mfaService
            .configurationNeeded$(this.loginModel.username, this.configureMFAData)
            .pipe(
                finalize(() => {
                    this._onMFACancel();
                }),
                takeUntil(this._destroyTrigger$)
            )
            .subscribe((code: string) => {
                this._onMFAConfigVerify(code);
            });
    }

    private _showMFAVerificationCodeModal(): void {
        this._mfaService
            .getVerificationCode$(this.mfaVerificationWindowData)
            .pipe(
                finalize(() => {
                    this._onMFACancel();
                }),
                takeUntil(this._destroyTrigger$)
            )
            .subscribe((code: string) => {
                this._onMFALoginVerify(code);
            });
    }

    private _onMFACancel(): void {
        this.loginModel.mfaKey = '';
        this.loginModel.mfaToken = '';
        this.configureMFAData.errorData.isError = false;
        this._destroySubscriptions();
    }

    private _onMFAConfigVerify(mfaVerificationCode: string): void {
        this.loginModel.mfaKey = this.configureMFAData.manualEntryKey ?? '';
        this.loginModel.mfaToken = mfaVerificationCode ?? '';

        this._mfaLogin(this.configureMFAData.errorData);
    }

    private _onMFALoginVerify(mfaVerificationCode: string): void {
        this.loginModel.mfaToken = mfaVerificationCode ?? '';
        this.mfaVerificationWindowData.isVerifying = true;

        this._mfaLogin(this.mfaVerificationWindowData.errorData);
    }

    private _mfaLogin(mfaErrorData: IMFAErrorData): void {
        mfaErrorData.isError = false;
        this._loginCallBack(this.loginModel)
            .subscribe(
                () => this._handleMFALoginSuccess(),
                (error: IErrorData) => this._handleMFALoginError(error, mfaErrorData),
            );
    }

    private _handleMFALoginSuccess(): void {
        this._mfaService.finish();
        this.mfaVerificationWindowData.isVerifying = false;
        this._destroySubscriptions();
    }

    private _handleMFALoginError(error: IErrorData, mfaErrorData: IMFAErrorData): void {
        if (!error) {
            return;
        }

        this.mfaVerificationWindowData.isVerifying = false;
        mfaErrorData.isError = true;
        mfaErrorData.errorDescription = error.error_description;
    }

    private  _destroySubscriptions(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }
}
