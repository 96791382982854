import { Injectable } from '@angular/core';

import { IExecutionResult } from '../../../core/interfaces/execution-result.interface';
import { AuthorizedHttpService } from '../../../core/services/http/authorized-http.service';
import { API_ENDPOINTS } from '../../../shared/constants/api/api-endpoints.constants';
import { IRateItResult } from '../interfaces/rate-it-result.interface';
import { IRateIt } from '../interfaces/rate-it.interface';

@Injectable()
export class RateItPopupService {
    show: boolean = false;
    model: IRateIt = null;
    private readonly _rateItSeenStorageKey: string = 'RateItSeen';

    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    checkForRateItPopup(): void {
        if (localStorage.getItem(this._rateItSeenStorageKey)) {
            return;
        }

        localStorage.setItem(this._rateItSeenStorageKey, JSON.stringify(true));
        this._authorizedHttpService
            .get$(API_ENDPOINTS.FEATURE_FLAGS.GET_REQUIRED_RATING)
            .subscribe((result: IExecutionResult<IRateIt>) => {
                if (result.Succeed) {
                    this.model = result.Result;
                    this.show = result.Result !== null;
                }
            });
    }

    saveRateItData(score: boolean = null, comments: string = null): void {
        const submitData: IRateItResult = {
            Comments: comments,
            FeatureId: this.model.FeatureId,
            Rating: score,
        };

        this._authorizedHttpService
            .post$(API_ENDPOINTS.FEATURE_FLAGS.SAVE_RATING, submitData)
            .subscribe();
    }

    hidePopup(): void {
        this.show = false;
    }
}
