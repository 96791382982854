import { ComponentRef } from '@angular/core';

import { GlobalDataService } from '../../../core/services/global-data.service';
import { IClosingEventArgs } from '../interfaces/closing-event-args.interface';
import { IPopupEventHandler } from '../interfaces/popup-event-handler.interface';

export abstract class AbstractPopup<T> {
    eventHandler: IPopupEventHandler;
    component: ComponentRef<T>;
    dataService: GlobalDataService;
    private _params: T;

    get params(): T {
        return this._params;
    }

    set params(val: T) {
        this._params = val;

        this.updateParams(val);
    }

    constructor() {
        this.dataService = new GlobalDataService();
    }

    executeCommand(command: string, data: unknown): void {
        if (this.eventHandler) {
            this.eventHandler.executeCommand(command, data);
        }
    }

    updateParams(params: T): void {}

    popupClosing(event: IClosingEventArgs): void {}

    close(data: unknown = null): void {
        const eventArgs: IClosingEventArgs = {
            cancel: false,
        };

        this.popupClosing(eventArgs);

        if (eventArgs.cancel) {
            return;
        }

        if (this.component) {
            this.component.destroy();
        }

        if (this.eventHandler && this.eventHandler.executeCommand) {
            this.eventHandler.executeCommand('close', data);
        }

        this.eventHandler = null;
        this.component = null;
    }
}
