import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, finalize, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { ApiFeatureFlagsService } from '../../core/services/api/api-feature-flags.service';
import { AzureManagement } from '../../pages/customer/manage-service/azure/azure.management';
import { HostedExchangeManagement } from '../../pages/customer/manage-service/hosted-exchange/hosted-exchange.management';
import { ServiceCustomerListType } from '../../pages/services/enums/service-customer-list-type.enum';
import { ServiceIds } from '../../pages/services/enums/service-ids.enum';
import { ICustomerDetailsAddModifyButtons } from '../../pages/services/interfaces/customer-details-add-modify-buttons.interface';
import { ICustomerDetailsListNames } from '../../pages/services/interfaces/customer-details-list-names.interface';
import { IServiceCustomerSchema } from '../../pages/services/interfaces/service-customer-schema.interface';
import { IServiceCustomer } from '../../pages/services/interfaces/service-customer.interface';
import { IServiceList } from '../../pages/services/interfaces/service-list.interface';
import { IServicesMenuItem } from '../../pages/services/interfaces/services-menu-item.interface';
import { ServicesListFacadeService } from '../../pages/services/services/services-list-facade.service';
import { FEATURE_FLAGS } from '../../shared/constants/features/feature-flags.constants';
import { ButtonStyle, ICloudMarketButton } from '../../shared/interfaces/cm-button.types';
import { IExecutionResult } from '../interfaces/execution-result.interface';
import { IService } from '../interfaces/service.interface';

import { ApiMyServicesService } from './api/api-my-services.service';
import { ServicesCustomersListFacadeService } from './services-customers-list-facade.service';

@Injectable()
export class ServicesCustomerDetailsFacadeService {
    bottomCustomControl: string = '';
    serviceCustomerDetailListItemChanged: EventEmitter<any> = new EventEmitter<any>();
    private readonly _fetchRequestsCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private readonly _reFetchDataTrigger$: Subject<void> = new Subject();
    private readonly _selectedService$: Observable<IService | null> =
        this._servicesListFacadeService.getSelectedService$();
    private readonly _selectedCustomer$: Observable<IServiceCustomer | null> =
        this._servicesCustomersListFacadeService.getSelectedCustomer$();
    private readonly _selectedListSchemaId$: BehaviorSubject<number | null> = new BehaviorSubject(
        null
    );
    private readonly _customerDetailsListType$: Observable<ServiceCustomerListType | null> =
        this._generateCustomerDetailsListTypeGetter$();
    private readonly _customerDetailsListNames$: Observable<ICustomerDetailsListNames | null> =
        this._generateCustomerDetailsListNames$();
    private readonly _serviceCustomerDetailList$: Observable<any[]> =
        this._generateServiceCustomerDetailListGetter$();
    private readonly _customControl$: Observable<
        HostedExchangeManagement | AzureManagement | null
    > = this._generateCustomControlGetter$();
    private readonly _addModifyButtons$: Observable<ICustomerDetailsAddModifyButtons> =
        this._generateAddButtonGetter$();
    private readonly _customerActionButtons$: Observable<ICloudMarketButton[]> =
        this._generateCustomerActionButtonsGetter$();
    private readonly _selectedSchemaList$: Observable<IServiceList | null> =
        this._generateSchemaListGetter$();
    private _fetchRequestsCount: number = 0;
    private readonly _shouldShowServiceCustomerDetailListCount$: Observable<boolean> =
        this._generateShouldShowServiceDetailListCountGetter$();
    private readonly _shouldShowAssignedLicences$: Observable<boolean> =
        this._generateShouldShowAssignedLicencesGetter$();

    private readonly _isFetchInProgress$: Observable<boolean> =
        this._generateFetchInProgressGetter$();

    constructor(
        private _servicesCustomersListFacadeService: ServicesCustomersListFacadeService,
        private _servicesListFacadeService: ServicesListFacadeService,
        private _apiMyServicesService: ApiMyServicesService,
        private _router: Router,
        private _apiFeatureFlagsService: ApiFeatureFlagsService,
    ) {}

    getSelectedListSchemaId$(): Observable<number | null> {
        return this._selectedListSchemaId$.asObservable();
    }

    getIsFetchInProgress$(): Observable<boolean> {
        return this._isFetchInProgress$;
    }

    getCustomerDetailsListType$(): Observable<ServiceCustomerListType | null> {
        return this._customerDetailsListType$;
    }

    getCustomerDetailsList$(): Observable<any[]> {
        return this._serviceCustomerDetailList$;
    }

    getCustomControl$(): Observable<HostedExchangeManagement | AzureManagement | null> {
        return this._customControl$;
    }

    getAddModifyButtons$(): Observable<ICustomerDetailsAddModifyButtons> {
        return this._addModifyButtons$;
    }

    getCustomerDetailsListNames$(): Observable<ICustomerDetailsListNames | null> {
        return this._customerDetailsListNames$;
    }

    getSelectedSchemaList$(): Observable<IServiceList | null> {
        return this._selectedSchemaList$;
    }

    getCustomerActionButtons$(): Observable<ICloudMarketButton[]> {
        return this._customerActionButtons$;
    }

    getShouldShowServiceCustomerDetailListCount$(): Observable<boolean> {
        return this._shouldShowServiceCustomerDetailListCount$;
    }

    getShouldShowAssignedLicences$(): Observable<boolean> {
        return this._shouldShowAssignedLicences$;
    }

    selectListSchemaViaId(listSchemaId: number | null): void {
        this._selectedListSchemaId$.next(listSchemaId);
    }

    setBottomCustomControl(bottomCustomControl: string): void {
        this.bottomCustomControl = bottomCustomControl;
    }

    setCustomerDetailListItem(item: any): void {
        this.serviceCustomerDetailListItemChanged.emit(item);
    }

    reFetchData(): void {
        this._reFetchDataTrigger$.next();
    }

    getCustomerDetailListWithAssignedLicences$(
        includeAssignedLicences: boolean
    ): Observable<any[] | null> {
        const debounceTimeValue: number = 20; // small debounce to avoid instant request cancel
        // before switching to another one

        return combineLatest([
            this._selectedService$,
            this._selectedCustomer$,
            this._selectedListSchemaId$,
        ]).pipe(
            debounceTime(debounceTimeValue),
            switchMap(
                ([selectedService, selectedCustomer, selectedListSchemaId]: [
                    IService | null,
                    IServiceCustomer | null,
                    number | null
                ]) => {
                    if (!selectedCustomer || !selectedService || !selectedListSchemaId) {
                        return of(null);
                    }

                    return this._getCustomerDetailList$(
                        selectedService,
                        selectedCustomer,
                        selectedListSchemaId,
                        includeAssignedLicences
                    );
                }
            ),
            shareReplay()
        );
    }

    private _getCustomerDetailList$(
        selectedService: IService,
        selectedCustomer: IServiceCustomer,
        selectedListSchemaId: number,
        includeAssignedLicences: boolean = false
    ): Observable<any[] | null> {
        return this._apiMyServicesService
            .getServiceCustomerDetailList$(
                selectedService.CloudMarketId,
                selectedCustomer.OrganisationId,
                selectedListSchemaId,
                includeAssignedLicences
            )
            .pipe(
                map((listResponse: IExecutionResult<any[]>) => {
                    if (listResponse.Succeed) {
                        return listResponse.Result;
                    }

                    return null;
                })
            );
    }

    private _generateServiceCustomerDetailListGetter$(): Observable<any[] | null> {
        const debounceTimeValue: number = 20; // small debounce to avoid instant request cancel
        // before switching to another one

        return combineLatest([
            this._selectedService$,
            this._selectedCustomer$,
            this._customerDetailsListType$,
            this._selectedListSchemaId$,
        ]).pipe(
            debounceTime(debounceTimeValue),
            switchMap(
                ([
                    selectedService,
                    selectedCustomer,
                    customerDetailsListType,
                    selectedListSchemaId,
                ]: [
                    IService | null,
                    IServiceCustomer | null,
                    ServiceCustomerListType | null,
                    number | null
                ]) => {
                    if (
                        !selectedCustomer ||
                        !selectedService ||
                        selectedListSchemaId === null ||
                        customerDetailsListType === null
                    ) {
                        return of(null);
                    }
                    if (customerDetailsListType === ServiceCustomerListType.custom) {
                        return of(null);
                    }

                    return this._applyCounterToHttpRequest$(
                        this._getCustomerDetailList$(
                            selectedService,
                            selectedCustomer,
                            selectedListSchemaId
                        )
                    );
                }
            ),
            shareReplay()
        );
    }

    private _generateFetchInProgressGetter$(): Observable<boolean> {
        return combineLatest([
            this._servicesCustomersListFacadeService.getIsFetchInProgress$(),
            this._fetchRequestsCount$.pipe(map((requestsCount: number) => requestsCount > 0)),
        ]).pipe(
            map(
                ([
                    isServiceCustomersFetchInProgress,
                    isCustomersFetchRequestInProgress,
                ]: boolean[]) =>
                    isServiceCustomersFetchInProgress || isCustomersFetchRequestInProgress
            ),
            shareReplay()
        );
    }

    private _generateCustomerActionButtonsGetter$(): Observable<ICloudMarketButton[]> {
        const debounceTimeValue: number = 20; // small debounce to avoid instant request cancel
        // before switching to another one

        return combineLatest([
            this._selectedService$,
            this._selectedCustomer$,
            this._selectedListSchemaId$,
        ]).pipe(
            debounceTime(debounceTimeValue),
            switchMap(
                ([selectedService, selectedCustomer, selectedListSchemaId]: [
                    IService | null,
                    IServiceCustomer | null,
                    number | null
                ]) => {
                    if (!selectedService || !selectedCustomer || selectedListSchemaId === null) {
                        return of([]);
                    }

                    return this._applyCounterToHttpRequest$(
                        this._apiMyServicesService.getServiceCustomerDetailsActionButtons$(
                            selectedService.CloudMarketId,
                            selectedCustomer.OrganisationId,
                            selectedListSchemaId
                        )
                    ).pipe(
                        map((fetchButtonsResponse: IExecutionResult<ICloudMarketButton[]>) =>
                            fetchButtonsResponse.Succeed ? fetchButtonsResponse.Result : []
                        )
                    );
                }
            ),
            shareReplay()
        );
    }

    private _generateSchemaListGetter$(): Observable<IServiceList | null> {
        const debounceTimeValue: number = 20; // small debounce to avoid instant request cancel
        // before switching to another one

        return combineLatest([
            this._selectedService$,
            this._selectedListSchemaId$,
            this._customerDetailsListType$,
        ]).pipe(
            debounceTime(debounceTimeValue),
            switchMap(
                ([selectedService, selectedListSchemaId, customerDetailsListType]: [
                    IService | null,
                    number | null,
                    ServiceCustomerListType | null
                ]) => {
                    if (
                        !selectedService ||
                        selectedListSchemaId === null ||
                        customerDetailsListType === null
                    ) {
                        return of(null);
                    }
                    if (customerDetailsListType === ServiceCustomerListType.custom) {
                        return of(null);
                    }

                    return this._applyCounterToHttpRequest$(
                        this._apiMyServicesService.getServiceCustomerListSchema$(
                            selectedService.CloudMarketId,
                            selectedListSchemaId
                        )
                    ).pipe(
                        map((listSchemaResponse: IExecutionResult<IServiceList>) =>
                            listSchemaResponse.Succeed ? listSchemaResponse.Result : null
                        )
                    );
                }
            ),
            shareReplay()
        );
    }

    private _generateAddButtonGetter$(): Observable<ICustomerDetailsAddModifyButtons> {
        const debounceTimeValue: number = 20; // small debounce to avoid instant request cancel
        // before switching to another one

        return combineLatest([
            this._selectedService$,
            this._selectedCustomer$,
            this._selectedListSchemaId$,
        ]).pipe(
            debounceTime(debounceTimeValue),
            switchMap(
                ([selectedService, selectedCustomer, selectedListSchemaId]: [
                    IService | null,
                    IServiceCustomer | null,
                    number | null
                ]) => {
                    if (!selectedService || !selectedCustomer || selectedListSchemaId === null) {
                        return of({
                            add: null,
                            modify: null,
                        });
                    }

                    return this._applyCounterToHttpRequest$(
                        this._apiMyServicesService.getAddModifyButtons$(
                            selectedService.CloudMarketId,
                            selectedCustomer.OrganisationId,
                            selectedListSchemaId
                        )
                    ).pipe(
                        map((res: IExecutionResult<ICloudMarketButton[]>) => {
                            if (res.Result) {
                                return {
                                    add: res.Result.find(
                                        (b: ICloudMarketButton) => b.Style === ButtonStyle.add
                                    ),
                                    modify: res.Result.find(
                                        (b: ICloudMarketButton) => b.Style === ButtonStyle.modify
                                    ),
                                };
                            }

                            return {
                                add: null,
                                modify: null,
                            };
                        })
                    );
                }
            ),
            shareReplay()
        );
    }

    private _generateCustomControlGetter$(): Observable<
        HostedExchangeManagement | AzureManagement | null
    > {
        return combineLatest([
            this._customerDetailsListType$,
            this._selectedService$,
            this._selectedCustomer$,
        ]).pipe(
            map(
                ([customerDetailsListType, selectedService, selectedCustomer]: [
                    ServiceCustomerListType | null,
                    IService | null,
                    IServiceCustomer | null
                ]) => {
                    let management: HostedExchangeManagement | AzureManagement;

                    if (customerDetailsListType === null || !selectedCustomer || !selectedService) {
                        return null;
                    }
                    if (customerDetailsListType !== ServiceCustomerListType.custom) {
                        return null;
                    }
                    if (selectedService.Id === ServiceIds.hostedExchange) {
                        management = new HostedExchangeManagement(this._router);
                    }
                    if (
                        selectedService.Id === ServiceIds.azure ||
                        selectedService.Id === ServiceIds.azurePlan
                    ) {
                        management = new AzureManagement();
                    }

                    management.serviceId = selectedService.ServiceId;
                    management.userId = 0;
                    management.organisationId = selectedCustomer.OrganisationId;

                    return management;
                }
            ),
            shareReplay()
        );
    }

    private _generateCustomerDetailsListTypeGetter$(): Observable<ServiceCustomerListType | null> {
        return this._generateSelectedCustomerListGetter$().pipe(
            map((selectedCustomerList: IServicesMenuItem | null) => {
                if (!selectedCustomerList) {
                    return null;
                }

                return selectedCustomerList.Type;
            })
        );
    }

    private _generateCustomerDetailsListNames$(): Observable<ICustomerDetailsListNames | null> {
        return this._generateSelectedCustomerListGetter$().pipe(
            map((selectedCustomerList: IServicesMenuItem | null) => {
                if (!selectedCustomerList) {
                    return null;
                }

                return {
                    singular: selectedCustomerList.SingularName,
                    plural: selectedCustomerList.Name,
                };
            })
        );
    }

    private _generateSelectedCustomerListGetter$(): Observable<IServicesMenuItem | null> {
        return combineLatest([
            this._selectedListSchemaId$,
            this._servicesCustomersListFacadeService.getSelectedCustomerSchema$(),
        ]).pipe(
            map(
                ([selectedListSchemaId, selectedCustomerSchema]: [
                    number | null,
                    IServiceCustomerSchema | null
                ]) => {
                    let selectedCustomerList: IServicesMenuItem | undefined;

                    if (!selectedCustomerSchema) {
                        return null;
                    }
                    if (selectedListSchemaId === null) {
                        return null;
                    }

                    // eslint-disable-next-line prefer-const
                    selectedCustomerList = selectedCustomerSchema.CustomerLists.find(
                        (customerList: IServicesMenuItem) =>
                            customerList.Id === selectedListSchemaId
                    );

                    if (!selectedCustomerList) {
                        return null;
                    }

                    if (selectedCustomerList.BottomCustomControl) {
                        this.bottomCustomControl = selectedCustomerList.BottomCustomControl;
                    }

                    return selectedCustomerList;
                }
            )
        );
    }

    private _generateShouldShowServiceDetailListCountGetter$(): Observable<boolean> {
        return this._selectedService$.pipe(
            map((selectedService: IService | null) => {
                if (!selectedService) {
                    return false;
                }

                return (
                    selectedService.Id !== ServiceIds.bitDefender &&
                    selectedService.Id !== ServiceIds.azure
                );
            }),
            shareReplay()
        );
    }

    private _generateShouldShowAssignedLicencesGetter$(): Observable<boolean> {
        if (
            !this._apiFeatureFlagsService.isFeatureFlagEnabled(FEATURE_FLAGS.NCE_ASSIGNED_LICENCES)
        ) {
            return of(false);
        }
        const listSchemaId: number = 1;

        return combineLatest([this._selectedListSchemaId$, this._selectedService$]).pipe(
            map(([selectedListSchemaId, selectedService]: [number | null, IService | null]) => {
                if (!selectedListSchemaId || !selectedService) {
                    return false;
                }

                return (
                    selectedService.Id === ServiceIds.microsoft &&
                    selectedListSchemaId === listSchemaId
                );
            }),
            shareReplay()
        );
    }

    private _applyCounterToHttpRequest$<T>(httpRequest$: Observable<T>): Observable<T> {
        return merge(of(true), this._reFetchDataTrigger$).pipe(
            tap(() => this._increaseFetchCounter()),
            switchMap(() => httpRequest$.pipe(finalize(() => this._decreaseFetchCounter())))
        );
    }

    private _increaseFetchCounter(): void {
        this._fetchRequestsCount += 1;
        this._fetchRequestsCount$.next(this._fetchRequestsCount);
    }

    private _decreaseFetchCounter(): void {
        this._fetchRequestsCount -= 1;
        this._fetchRequestsCount$.next(this._fetchRequestsCount);
    }
}
