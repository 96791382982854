import { Component } from '@angular/core';

import { SsoAuthService } from '../../../core/services/sso-auth/sso-auth.service';

@Component({
    selector: 'app-permission-error',
    templateUrl: './permission-error.component.html',
})
export class PermissionErrorComponent {
    constructor(private _ssoAuthService: SsoAuthService) {}

    logout(): void {
        this._ssoAuthService.logoutWithDefaultOptions();
    }
}
