import { Injectable } from '@angular/core';
import { productFruits } from 'product-fruits';

@Injectable({
    providedIn: 'root',
})
export class ProductFruitsService {
    initPF(userName: string): void {
        const workspaceCode: string = 'hU0UOgfdCn2xwxEL';

        productFruits.init(workspaceCode, 'en', { username: userName || 'unknown user' });
    }
}
