import { Pipe, PipeTransform } from '@angular/core';

import { IService } from '../../../core/interfaces/service.interface';
import { ApiFeatureFlagsService } from '../../../core/services/api/api-feature-flags.service';

@Pipe({
    name: 'filterOutDisabledServices',
    pure: false /** returns data from an async call */,
})
export class FilterOutDisabledServicesPipe implements PipeTransform {
    constructor(private _apiFeatureFlagsService: ApiFeatureFlagsService) {}

    transform(items: IService[]): IService[] {
        if (!items) {
            return items;
        }

        return items.filter((item: IService) =>
            this._apiFeatureFlagsService.isFeatureFlagEnabled(item.Name)
        );
    }
}
