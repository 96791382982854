import { environment } from '../../../environments/environment';

export class NotificationsUrl {
    static get getNegotiateUrl(): string {
        return environment.NegotiateFunctionUrl;
    }

    static get getHubUrl(): string {
        return environment.SignalRServiceUrl;
    }
}
