import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class GiacomDateService {
    transform(val: Date | string, todaysDateAsString: boolean = false): string {
        if (!val) {
            return;
        }
        const yearSubstr: number = -2;
        const dDate: Date = new Date(val);

        if (todaysDateAsString && dDate.toDateString() === new Date().toDateString()) {
            return 'Today';
        }
        const iDay: number = dDate.getDate();
        const sDay: string = this.ordinalSuffixOf(iDay);
        const iMonth: number = dDate.getMonth();
        const sYear: string = dDate.getFullYear().toString().substr(yearSubstr);

        return `${sDay} ${this.getMonthString(iMonth)} ${sYear}`;
    }

    ordinalSuffixOf(i: number): string {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        const j: number = i % 10;
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        const k: number = i % 100;

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (j === 1 && k !== 11) {
            return `${i}st`;
        }
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (j === 2 && k !== 12) {
            return `${i}nd`;
        }
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (j === 3 && k !== 13) {
            return `${i}rd`;
        }

        return `${i}th`;
    }

    getMonthString(iMonth: number): string {
        const month: string[] = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];

        return month[iMonth];
    }
}
