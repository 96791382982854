import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { PopUpDetails } from '../../../../feature-modules/popups/classes/pop-up-details.class';
import { PopupService } from '../../../../feature-modules/popups/services/popup.service';
import { FilterHiddenPipeModule } from '../../../pipes/filter-hidden/filter-hidden-pipe.module';
import { FilterOutDisabledServicesPipeModule } from '../../../pipes/filter-out-disabled-services/filter-out-disabled-services-pipe.module';
import { CmModalModule } from '../../cm-modal/modal.module';
import { WaitSpinnerModule } from '../../wait-spinner/wait-spinner.module';

import { SelectServiceModalComponent } from './select-service-modal.component';

@NgModule({
    declarations: [SelectServiceModalComponent],
    imports: [
        CommonModule,
        FilterOutDisabledServicesPipeModule,
        FilterHiddenPipeModule,
        TranslateModule,
        CmModalModule,
        WaitSpinnerModule,
    ],
    exports: [SelectServiceModalComponent],
})
export class SelectServiceModalModule {
    static popupRegistered: boolean = false;
    constructor(private _popupService: PopupService) {
        if (SelectServiceModalModule.popupRegistered) {
            return;
        }

        SelectServiceModalModule.popupRegistered = true;

        this._popupService.registerPopups({
            'select-service': new PopUpDetails(
                SelectServiceModalComponent,
                'shared',
                'miscellaneous'
            ),
        });
    }
}
