export enum PsaEndpoints {
    cmCustomers,
    cmProducts,
    psaCustomers,
    psaProducts,
    productMovementReport,
    productMovementDateRange,
    reconciliationReport,
    updateCustomerMappings,
    updateProductMappings,
    updateProductMovementReport,
    availableIntegrations,
    getSettings,
    setSettings,
    productMovementAsCSV,
    reconcoliationReportAsCSV,
    overview,
    updateReconciliationReport,
    customerMappings,
    serviceMappings,
    refreshCustomers,
    refreshServices,
    refreshProductMovementReport,
    refreshReconciliationReport,
    refreshAzureReport,
    refreshAll,
    psaContracts,
    refreshPsaContracts,
    synchronizationHub,
    updateIgnoreSalesOrders,
}
