import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_ENDPOINTS } from '../../shared/constants/api/api-endpoints.constants';

import { AuthorizedHttpService } from './http/authorized-http.service';

@Injectable({
    providedIn: 'root',
})
export class ForumService {
    readonly url: string =
        'https://giacom.vanillacommunities.com/entry/connect/JWTSSO/?authKey=JWTSSODefault&jwt=';

    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    redirectToForum(token: string): void {
        window.location.href = this.url + token;
    }

    getForumToken$(): Observable<string> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.FORUM.GET_TOKEN);
    }

    openForum(token: string): void {
        window.open(this.url + token);
    }
}
