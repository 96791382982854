import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { TogglePasswordVisibilityModule } from '../../directives/toggle-password-visibility/toggle-password-visibility.module';

import { PasswordComponent } from './password.component';

@NgModule({
    declarations: [PasswordComponent],
    imports: [CommonModule, TranslateModule, ReactiveFormsModule, TogglePasswordVisibilityModule],
    exports: [PasswordComponent],
})
export class PasswordModule {}
