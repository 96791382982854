import { Component } from '@angular/core';

@Component({
    selector: 'user-feedback',
    templateUrl: './user-feedback.component.html',
    styleUrls: ['./user-feedback.component.scss'],
})
export class UserFeedbackComponent {
    loading: boolean = true;
    error: boolean = false;

    processLoaded(success: boolean): void {
        this.loading = false;
        this.error = !success;
    }
}
