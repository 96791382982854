import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { ISelectInputOptions } from '../../../../feature-modules/design-system/components/forms/interfaces/select-input-options.interface';
import { AbstractControlValueAccessor } from '../../../../shared/classes/abstract-control-value-accessor.class';

@Component({
    selector: 'cm-select-input',
    templateUrl: './cm-select-input.component.html',
    styleUrls: ['./cm-select-input.component.scss'],
})
export class CmSelectInputComponent
    extends AbstractControlValueAccessor<string>
    implements OnInit, AfterViewInit, OnDestroy
{
    @Input() label: string = '';
    @Input() id: string = '';
    @Input() options: ISelectInputOptions;
    @Input() isFullWidth: boolean = false;
    @Input() hideOptionalLabel: boolean = false;
    @Input() required: boolean = false;
    @Input() withMargin: boolean = true;
    selectControl: UntypedFormControl;
    private _destroyTrigger$: Subject<void> = new Subject();

    constructor(private _ngControl: NgControl, private _formBuilder: UntypedFormBuilder) {
        super();

        this._ngControl.valueAccessor = this;
    }

    writeValue(value: string): void {
        super.writeValue(value);

        this.selectControl?.setValue(value, { emitEvent: false });
        this.selectControl?.updateValueAndValidity();
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.selectControl?.disable()
        } else {
            this.selectControl?.enable()
        }
    }

    ngOnInit(): void {
        this._initInputControl();
    }

    ngAfterViewInit(): void {
        this._handleSelectControlChanges();
    }

    ngOnDestroy(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }

    private _initInputControl(): void {
        this.selectControl = this._formBuilder.control(
            this.value,
            this._ngControl.control.validator,
            this._ngControl.control.asyncValidator
        );
    }

    private _handleSelectControlChanges(): void {
        this.selectControl.valueChanges
            .pipe(distinctUntilChanged(), takeUntil(this._destroyTrigger$))
            .subscribe((value: string) => this.onChange(value));
    }
}
