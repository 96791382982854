import { Inject, Injectable } from '@angular/core';
import dayjs from 'dayjs';

import { environment } from '../../../../../environments/environment';
import { WINDOW } from '../../../../core/injection-tokens/window.injection-token';
import { COOKIE_KEYS } from '../../../../shared/constants/storage-keys.constants';
import { CookieService } from '../../../../shared/services/cookie.service';

@Injectable({
    providedIn: 'root',
})
export class SharedSessionCookieStorageService {
    // The variable "_hostnameSignificantTokens" is set to 3 to handle cookie operations for subdomains under ".azurewebsites.net".
    // Due to browser security measures aimed at preventing attacks such as Cross-Site Request Forgery (CSRF),
    // cookies are treated as separate entities for each subdomain.
    // This allows us to effectively manage cookies for each individual subdomain under ".azurewebsites.net".
    // For a domain 'cloud.market', this setup will correctly return the domain name as '.cloud.market'.
    // https://stackoverflow.com/questions/47678302/asp-net-core-sharing-identity-cookie-across-azure-web-apps-on-default-domain
    // https://publicsuffix.org/list/effective_tld_names.dat
    private readonly _hostnameSignificantTokens: number = parseInt(environment.hostnameSignificantTokens, 10);

    constructor(
        private readonly _cookieService: CookieService,
        @Inject(WINDOW) private _window: Window
    ) {}

    setCloudToken(token: string, expires: string | Date): void {
        this._cookieService.set(COOKIE_KEYS.CLOUD_ACCESS_TOKEN, token, {
            expires,
            domain: `.${this._getCookieDomain()}`,
            SameSite: 'Lax',
        });
    }

    setOrganisationId(organisationId: string, expires: string | Date): void {
        this._cookieService.set(COOKIE_KEYS.ORGANISATION_ID, organisationId, {
            expires,
            domain: `.${this._getCookieDomain()}`,
            SameSite: 'Lax',
        });
    }

    setUseSingleExperience(useSE: boolean): void {
        const oneYearFromNow: Date = dayjs().add(1, 'year').toDate();

        this._cookieService.set(COOKIE_KEYS.USE_SE, !!useSE, {
            expires: oneYearFromNow,
            domain: `.${this._getCookieDomain()}`,
            SameSite: 'Lax',
        });
    }

    getUseSingleExperience(): boolean {
        return this._cookieService.get(COOKIE_KEYS.USE_SE) === 'true'
    }

    getToken(): string {
        return this._cookieService.get(COOKIE_KEYS.CLOUD_ACCESS_TOKEN);
    }

    getOrganisationId(): string {
        return this._cookieService.get(COOKIE_KEYS.ORGANISATION_ID);
    }

    removeCloudToken(): void {
        this._cookieService.remove(COOKIE_KEYS.CLOUD_ACCESS_TOKEN, {
            domain: `.${this._getCookieDomain()}`,
        });
    }

    removeHardwareSessionId(): void {
        this._cookieService.remove(COOKIE_KEYS.HARDWARE_SESSION_ID, {
            domain: `.${this._getCookieDomain()}`,
        });
    }

    removeOrganisationId(): void {
        this._cookieService.remove(COOKIE_KEYS.ORGANISATION_ID, {
            domain: `.${this._getCookieDomain()}`,
        });
    }

    removeUseSingleExperience(): void {
        this._cookieService.remove(COOKIE_KEYS.USE_SE, {
            domain: `.${this._getCookieDomain()}`,
        });
    }

    private _getCookieDomain(): string {
        const hostnameParts: string[] = this._window.location.hostname.split('.');

        return hostnameParts.slice(-this._hostnameSignificantTokens).join('.');
    }
}
