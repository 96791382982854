import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { CmAlertModule } from '../../../new-shared/components/cm-alert/cm-alert.module';
import { CardModule } from '../../../new-shared/components/cm-card/card.module';
import {
    DatetimePickerControlModule
} from '../../../new-shared/components/forms/form-controls/datetime-picker-control/datetime-picker-control.module';
import { WaitSpinnerModule } from '../../../new-shared/components/wait-spinner/wait-spinner.module';

import { EndUserActivityComponent } from './end-user-activity.component';

@NgModule({
    declarations: [EndUserActivityComponent],
    imports: [
        CommonModule,
        TranslateModule,
        CmAlertModule,
        FormsModule,
        CardModule,
        DatetimePickerControlModule,
        WaitSpinnerModule,
        BsDropdownModule
    ],
    exports: [EndUserActivityComponent],
})
export class EndUserActivityModule {}
