import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ITabFilterOption } from '../../interfaces/tab-filter-option.interface';

@Component({
    selector: 'app-tab-data-filters',
    templateUrl: './tab-data-filters.component.html',
    styleUrls: ['./tab-data-filters.component.scss'],
})
export class TabDataFiltersComponent<T> {
    @Input() titleTranslationKey: string;
    @Input() filterOptions: ITabFilterOption<T>[];
    @Input() activeFilter: T;
    @Output() readonly selectedFilteringOption: EventEmitter<ITabFilterOption<T>> =
        new EventEmitter();

    handleFilteringOption(filter: ITabFilterOption<T>): void {
        this.activeFilter = filter.option;
        this.selectedFilteringOption.emit(filter);
    }
}
