import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PaymentType } from '../enums/payment-type.enum';
import { WINDOW } from '../injection-tokens/window.injection-token';
import { IDirectDebitAuthHostedPageResponse } from '../interfaces/directdebit-auth-hosted-page-response';
import { IExecutionResult } from '../interfaces/execution-result.interface';
import { IWindow } from '../interfaces/window.interface';
import { IZuoraHostedPageBaseParams } from '../interfaces/zuora-hosted-page-base-params';
import { IZuoraHostedPageCardParams } from '../interfaces/zuora-hosted-page-card-params.types';
import { IZuoraHostedPageDirectDebitParams } from '../interfaces/zuora-hosted-page-direct-debit-params';

import { ApiFeatureFlagsService } from './api/api-feature-flags.service';
import { BillingService } from './api/billing.service';

/**
 * This class will be removed in CD-1522. It is kept here only for easy rollback in case of need.
 * Zuora wrap for interacting with their hosted payment page client-side api
 * See https://knowledgecenter.zuora.com/Zuora_Collect/Hosted_Payment_Pages/EA_Integrate_Payment_Pages_2.0 for documentation
 * Remove in story CD-1522.
 */
@Injectable({
    providedIn: 'root',
})
export class ZuoraHostedPageService {
    private readonly _supportedCurrency: string = 'GBP';
    private readonly _hostedPageWidthOverride: number = 500;

    constructor(
        @Inject(WINDOW) private _window: IWindow,
        private _billingService: BillingService,
        private _apiFeatureFlagsService: ApiFeatureFlagsService
    ) {}

    getDebugModeEnabled(): boolean {
        const shouldDebugZuora: string = localStorage.getItem('debug.zuora-hosted-page');

        return Boolean(shouldDebugZuora);
    }

    /**
     * Add the Zuora script to the dom if it doesn't already exist
     */
    addZuoraScript(): void {
        const zuoraScriptPresent: boolean = !!document.getElementById('zuoraScript');

        if (zuoraScriptPresent) {
            this._log('Unable to add Zuora script as it already exists');

            return;
        }

        this._log('Zuora add script');

        const zuoraScript: HTMLScriptElement = document.createElement('script');

        // There is a non-minified script also available for debugging - zuora.js
        zuoraScript.src = 'https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js';
        zuoraScript.id = 'zuoraScript';
        zuoraScript.type = 'text/javascript';
        zuoraScript.async = true;

        const firstDocumentScript: HTMLScriptElement = document.getElementsByTagName('script')[0];

        firstDocumentScript.parentNode.insertBefore(zuoraScript, firstDocumentScript);
    }

    // eslint-disable-next-linevalid-jsdoc
    /**
     * Display the secure iframe hosted by Zuora, which allows the user to set up a new Direct Debit
     * @param resellerMex - The reseller mex, which is not required but Zuora but is passed through to GoCardless
     * @param setupFailureCallback - The internal callback fired when rsa/security tokens could not be retrieved
     * @param serverCompleteCallback - The zuora api callback fired when the iframe submission suceeds/fails
     * @param hostedPageLoadedCallback - The zuora api callback fired when page is loaded (after rendering)
     */
    displayDirectDebitHostedPage(
        resellerMex: string,
        setupFailureCallback: Function,
        serverCompleteCallback: Function,
        hostedPageLoadedCallback: Function
    ): void {
        if (!this._window.Z) {
            this._log('Unable to display direct debit hosted page as Zuora script is not present');

            return;
        }

        this._log('Retrieving Zuora base params');

        this._getZuoraBaseParams$(PaymentType.directDebit).subscribe(
            (zuoraBaseParams: IZuoraHostedPageBaseParams) => {
                if (!zuoraBaseParams) {
                    setupFailureCallback();

                    return;
                }

                // The gateway options (gwOptions) params allow us to pass configuration directly to the gateway
                // It is anticipated that this mechanism will also be used for custom GoCardless fields, however
                // further changes may be needed depending on consultation with Zuora.
                // https://knowledgecenter.zuora.com/Zuora_Collect/Hosted_Payment_Pages/J_Implement_Payment_Pages_2.0_to_support_one-time_payment_flows/Implement_Payment_Pages_2.0_to_support_processing_payments_in_India#Procedure
                const zuoraDirectDebitParams: IZuoraHostedPageDirectDebitParams = {
                    ...zuoraBaseParams,
                    param_gwOptions_ResellerMEX: resellerMex,
                };

                // This is an almost infinite list of values which can be passed through to the form
                // depending on what we want to default in the future e.g. address, email etc.
                // eslint-disable-next-line @typescript-eslint/typedef
                const defaultFormValues = {};

                try {
                    this._log(zuoraDirectDebitParams);

                    this._window.Z.runAfterRender(hostedPageLoadedCallback);

                    this._window.Z.render(
                        zuoraDirectDebitParams,
                        defaultFormValues,
                        serverCompleteCallback,
                        this._hostedPageWidthOverride
                    );
                } catch (e) {
                    this._error(e);
                    setupFailureCallback();

                    return;
                }
            }
        );
    }

    /**
     * Display the secure iframe hosted by Zuora, which allows the user to use and store payment card
     * @param resellerMex - The reseller mex, which is not required but Zuora but is passed through to GoCardless
     * @param saveCard - boolean flag to indicate if the payment card should be stored
     * @param invoiceNumber - invoice which should be payed
     * @param setupFailureCallback - The internal callback fired when rsa/security tokens could not be retrieved
     * @param serverCompleteCallback - The zuora api callback fired when the iframe submission succeeds/fails
     * @param hostedPageLoadedCallback - The zuora api callback fired after rendering
     */
    displayCardHostedPage(
        resellerMex: string,
        saveCard: boolean,
        invoiceNumber: string,
        setupFailureCallback: Function,
        serverCompleteCallback: Function,
        hostedPageLoadedCallback: Function
    ): void {
        if (!this._window.Z) {
            this._log('Unable to display payment card hosted page as Zuora script is not present');

            return;
        }
        this._log('Retrieving Zuora base params for payment card hosted page');

        this._getZuoraBaseParams$(PaymentType.card).subscribe(
            (zuoraBaseParams: IZuoraHostedPageBaseParams) => {
                if (!zuoraBaseParams) {
                    this._error('Loading zuora hosted page authorization parameters failed');
                    setupFailureCallback();

                    return;
                }

                this._log('Base Zuora parameters:');
                this._log(zuoraBaseParams);

                const zuoraCardParams: IZuoraHostedPageCardParams = {
                    ...zuoraBaseParams,
                    param_gwOptions_ResellerMEX: resellerMex,
                    doPayment: true,
                    storePaymentMethod: saveCard,
                    param_supportedTypes: this.getSupportedCards(),
                    field_currency: this._supportedCurrency,
                    // Documents field has to have string type and this exact format (it cannot be json object).
                    // Multiple invoices are supported.
                    documents: `[{\"type\": \"invoice\", \"ref\": \"${invoiceNumber}\"}]`,
                };

                // This is an almost infinite list of values which can be passed through to the form
                // depending on what we want to default in the future e.g. address, email etc.
                // eslint-disable-next-line @typescript-eslint/tslint/config
                const defaultFormValues: Record<string, unknown> = {};

                try {
                    this._log('Full Zuora pay by card parameters:');
                    this._log(zuoraCardParams);

                    this._log(
                        saveCard
                            ? 'Save card option enabled. Payment Card will be stored in Zuora.'
                            : 'Save card option disabled. Payment Card will not be stored in Zuora.'
                    );

                    this._window.Z.runAfterRender(() => {
                        this._log('Calling hostedPageLoadedCallback.');
                        hostedPageLoadedCallback();
                        this._log('HostedPageLoadedCallback completed.');
                    });
                    this._log('Zuora RunAfterRender method completed.');

                    this._window.Z.render(
                        zuoraCardParams,
                        defaultFormValues,
                        serverCompleteCallback,
                        this._hostedPageWidthOverride
                    );

                    this._log('Zuora Render method completed.');
                } catch (e) {
                    this._error('Get Zuora Base Parameters failed.');
                    this._error(e);
                    setupFailureCallback();

                    return;
                }
            },
            (error: Error) => {
                this._error('Get Zuora Base Parameters failed.');
                this._error(error);
                setupFailureCallback();
            }
        );
    }

    /**
     * Get a list of supported cards for the hosted page based on the logged-in reseller.
     * Only specified resellers are allowed to use American Express.
     * Note: The Zuora Gateway needs configuring to support all card types returned here.
     * @returns Comma separated list of supported card types.
     */
    getSupportedCards(): string {
        const defaultSupportedCards: string = 'Visa,MasterCard';
        const amexEnabled: boolean = this._apiFeatureFlagsService.isFeatureFlagEnabled('BillingPlatformPaymentAmex');

        return amexEnabled ? `${defaultSupportedCards},AmericanExpress` : defaultSupportedCards; 
    }

    closeHostedPage(): void {
        this._window.Z.closeWindow();
    }

    /**
     * Sends Agreement to use a card to Zuora.
     * Agreement has to be sent every time otherwise zuora methods fail.
     * See https://knowledgecenter.zuora.com/Zuora_Collect/Hosted_Payment_Pages/EA_Integrate_Payment_Pages_2.0.
     * @param resellerMex - reseller that agrees with card usage in Zuora.
     */
    sendCardAgreement(resellerMex: string): void {
        this._window.Z.setAgreement(
            'External',
            'Recurring',
            'Visa,Mastercard',
            `${resellerMex} consented on ${new Date().toISOString()}`
        );

        this._log('Agreement for card was successfully sent.');
    }

    /*
     * Grab necessary variables from billing service (which talks to the zuora service) e.g. rsa key, pageId etc
     * These are required to launch the Zuora hosted page securely.
     * This params are generic to both direct debit and card payments.
     */
    private _getZuoraBaseParams$(paymentType: PaymentType): Observable<IZuoraHostedPageBaseParams> {
        return this._billingService.getPaymentHostedPageAuthorization$(paymentType).pipe(
            map(
                (
                    authorisationDataResponse: IExecutionResult<IDirectDebitAuthHostedPageResponse>
                ) => {
                    if (!authorisationDataResponse.Succeed) {
                        this._error(authorisationDataResponse.Error);

                        return null;
                    }

                    const zuoraBaseParams: IZuoraHostedPageBaseParams = {
                        signature: authorisationDataResponse.Result.Signature,
                        token: authorisationDataResponse.Result.Token,
                        key: authorisationDataResponse.Result.Key,
                        tenantId: authorisationDataResponse.Result.TenantId,
                        id: authorisationDataResponse.Result.PageId,
                        url: authorisationDataResponse.Result.Url,
                        field_accountId: authorisationDataResponse.Result.ZuoraAccountId,
                        style: 'overlay',
                        currency: this._supportedCurrency,
                    };

                    return zuoraBaseParams;
                }
            )
        );
    }

    private _log(logContent: string | object): void {
        if (this.getDebugModeEnabled() && logContent) {
            console.log(logContent);
        }
    }

    private _error(errorContent: string | object): void {
        if (this.getDebugModeEnabled() && errorContent) {
            console.error(errorContent);
        }
    }
}
