import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-status-dot',
    templateUrl: './status-dot.component.html',
    styleUrls: ['./status-dot.component.scss'],
})
export class StatusDotComponent {
    @Input() animateDot: boolean;
    @Input() status: string;

    @Input() set successStatus(value: string) {
        this.parsedSuccessStatus = value.split('|');
    }

    @Input() set warningStatus(value: string) {
        this.parsedWarningStatus = value.split('|');
    }

    @Input() set failStatus(value: string) {
        this.parsedFailStatus = value.split('|');
    }

    @Input() set disabledStatus(value: string) {
        this.parsedDisabledStatus = value.split('|');
    }

    @Input() set informationStatus(value: string) {
        this.parsedInformationStatus = value.split('|');
    }

    parsedWarningStatus: string[] = ['warning'];
    parsedSuccessStatus: string[] = ['success'];
    parsedFailStatus: string[] = ['fail'];
    parsedDisabledStatus: string[] = ['disabled'];
    parsedInformationStatus: string[] = ['information'];
}
