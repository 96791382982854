import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';

import { IExecutionResult } from '../../../../core/interfaces/execution-result.interface';
import { AuthorizedHttpService } from '../../../../core/services/http/authorized-http.service';
import { API_ENDPOINTS } from '../../../../shared/constants/api/api-endpoints.constants';
import { KeyboardKey } from '../../../../shared/enum/keyboard-key.enum';
import { AbstractPopup } from '../../../popups/classes/abstract-popup.class';
import { IPolicySection } from '../../interfaces/policy-section.interface';
import { IPolicy } from '../../interfaces/policy.interface';
import { PolicySectionComponent } from '../policy-section/policy-section.component';

@Component({
    selector: 'privacy-policy',
    templateUrl: './privacy-policy-popup.html',
    styleUrls: ['./privacy-policy-popup.scss'],
})
export class PrivacyPolicyPopupComponent
    extends AbstractPopup<any>
    implements OnInit, AfterViewInit
{
    @ViewChild('scrollHeaders') scrollHeaders: ElementRef;
    @ViewChildren(PolicySectionComponent) policySections: QueryList<PolicySectionComponent>;
    modalAnimation: boolean = false;
    model: IPolicy;
    policyId: number;
    scrollLinks: any;

    constructor(private _authorizedHttpService: AuthorizedHttpService) {
        super();
    }

    ngOnInit(): void {
        this._authorizedHttpService
            .get$(API_ENDPOINTS.FOOTER.GET_POLICY(this.policyId))
            .subscribe((res: IExecutionResult<IPolicy>) => {
                if (res.Succeed) {
                    this.model = res.Result;

                    this.scrollLinks = [];
                    this.model.Sections.forEach((section: IPolicySection) => {
                        if (section.AnchorValue) {
                            this.scrollLinks.push({
                                text: section.AnchorText,
                                element: section.AnchorValue,
                            });
                        }
                    });
                }
            });
    }

    ngAfterViewInit(): void {
        const animationDelay: number = 150;

        setTimeout(() => {
            if (!this.modalAnimation) {
                this.modalAnimation = true;
            }
        }, animationDelay);
    }

    cancel(): void {
        this.close();
    }

    updateParams(val: any): void {
        if (val) {
            this.policyId = val.PolicyId;
        }
    }

    getMaxHeight(): number {
        const h: number = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        const headerHeightAddition: number = 150;
        const headersHeight: number =
            (!this.scrollHeaders ? 0 : this.scrollHeaders.nativeElement.clientHeight) +
            headerHeightAddition;

        return h - headersHeight;
    }

    @HostListener('window:keydown', ['$event']) keyboardInput(event: KeyboardEvent): void {
        if (event.key === KeyboardKey.escape) {
            this.cancel();
        }
    }

    scrollTo(scrollLink: any): void {
        const section: PolicySectionComponent = this.policySections.find(
            (x: PolicySectionComponent) => x.section.AnchorValue === scrollLink.element
        );

        section.anchor.scrollIntoView();
    }
}
