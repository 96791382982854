import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { TypeaheadDirective } from 'ngx-bootstrap/typeahead';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

import {
    AbstractControlValueAccessor,
    abstractValueAccessorProvider,
} from '../../../../../../shared/classes/abstract-control-value-accessor.class';
import { IMultiselectInputOption } from '../../interfaces/multiselect-input-option.interface';

@Component({
    selector: 'cm-multiselect-input',
    templateUrl: './cm-multiselect-input.component.html',
    styleUrls: ['./cm-multiselect-input.component.scss'],
    providers: [abstractValueAccessorProvider(CmMultiselectInputComponent)],
})
export class CmMultiselectInputComponent
    extends AbstractControlValueAccessor<IMultiselectInputOption[]>
    implements OnChanges, OnInit, OnDestroy
{
    @Input() options: IMultiselectInputOption[] = [];
    @Input() typeaheadMinLength: number = 1;
    @Input() allowNewOptions: boolean = true;
    @Input() placeholder: string = '';
    @Input() chipsPlacement: 'vertical' | 'horizontal' = 'horizontal';
    @Input() inputCharsLimit: number = 0;
    @Input() label: string = '';
    @Input() id: string = '';
    @ViewChild(TypeaheadDirective) typeaheadDirective: TypeaheadDirective;
    inputValue: string = '';
    isInputValueNew: boolean = false;
    newOptions: IMultiselectInputOption[] = [];
    optionsWithTemporaryNewOne: IMultiselectInputOption[] = [];
    optionsRecalculationInProgress: boolean = false;
    readonly showTypeaheadOptionsDelay: number = 300;
    private readonly _recalculateOptionsTrigger$: Subject<void> = new Subject();
    private readonly _destroyTrigger$: Subject<void> = new Subject();

    ngOnInit(): void {
        const debounceDelay: number = 300;

        this._recalculateOptionsTrigger$
            .pipe(
                tap(() => {
                    this.optionsWithTemporaryNewOne = [];
                    this.optionsRecalculationInProgress = true;
                }),
                debounceTime(debounceDelay),
                takeUntil(this._destroyTrigger$)
            )
            .subscribe(() => {
                this._recalculateAvailableOptions();
                this.optionsRecalculationInProgress = false;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options || changes.allowNewOptions) {
            this._recalculateOptionsTrigger$.next();
        }
        if (changes.typeaheadMinLength && changes.typeaheadMinLength.currentValue === 0) {
            this.typeaheadMinLength = 1;
        }
    }

    ngOnDestroy(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }

    handleInputFocus(): void {
        this._recalculateOptionsTrigger$.next();

        if (this.inputValue && this.typeaheadDirective.matches.length) {
            this.typeaheadDirective.show();
        }
    }

    handleInputValueChange(): void {
        this.isInputValueNew =
            this.inputValue &&
            !(
                this._isLabelAvailableInOptions(this.inputValue) ||
                this._isValueAvailableInNewOptions(this.inputValue) ||
                // prevents new option with new label but already existing value to be added
                this._isValueAvailableInOptions(this.inputValue)
            );

        this._recalculateOptionsTrigger$.next();
    }

    handleUnselectOptionClick(optionToUnselect: IMultiselectInputOption): void {
        this._removeOptionFromNewOptionsList(optionToUnselect);
        this._removeOptionFromValue(optionToUnselect);
        this.handleInputValueChange();
    }

    handleTypeaheadSelect(match: TypeaheadMatch): void {
        const selectedOption: IMultiselectInputOption = match.item;

        if (!this._isValueAvailableInOptions(selectedOption.value)) {
            selectedOption.isNew = true;
            this.newOptions.push(selectedOption);
        }

        this._addOptionToValue(selectedOption);
    }

    private _isOptionSelected(optionToVerify: IMultiselectInputOption): boolean {
        return !!(this.value || []).find(
            (option: IMultiselectInputOption) => option.value === optionToVerify.value
        );
    }

    private _addOptionToValue(option: IMultiselectInputOption): void {
        this.writeValue([...(this.value || []), option]);
        this.inputValue = '';
    }

    private _removeOptionFromValue(optionToRemove: IMultiselectInputOption): void {
        this.writeValue(
            this.value.filter((option: IMultiselectInputOption) => option !== optionToRemove)
        );
    }

    private _removeOptionFromNewOptionsList(optionToRemove: IMultiselectInputOption): void {
        this.newOptions = this.newOptions.filter(
            (option: IMultiselectInputOption) => option !== optionToRemove
        );
    }

    private _isValueAvailableInOptions(optionValue: string | number): boolean {
        return !!this.options.find(
            (option: IMultiselectInputOption) => option.value === optionValue
        );
    }

    private _isValueAvailableInNewOptions(optionValue: string | number): boolean {
        return !!this.newOptions.find(
            (option: IMultiselectInputOption) => option.value === optionValue
        );
    }

    private _isLabelAvailableInOptions(optionLabelValue: string): boolean {
        return !!this.options.find(
            (option: IMultiselectInputOption) => option.label === optionLabelValue
        );
    }

    private _recalculateAvailableOptions(): void {
        const shouldShowNewOption: boolean = this.isInputValueNew && this.allowNewOptions;
        const optionsWithoutSelectedOnes: IMultiselectInputOption[] = this.options.filter(
            (option: IMultiselectInputOption) =>
                !this._isOptionSelected(option) &&
                option.label.toLowerCase().indexOf(this.inputValue.toLowerCase()) >= 0
        );

        this.optionsWithTemporaryNewOne = [
            ...(shouldShowNewOption ? [{ value: this.inputValue, label: this.inputValue }] : []),
            ...optionsWithoutSelectedOnes,
        ];
        // @ts-ignore
        this.typeaheadDirective.prepareMatches(this.optionsWithTemporaryNewOne); // bugfix, refresh
        // matches after
        // removing
        // selections
    }
}
