import { Component, ContentChild, Input, OnChanges, SimpleChanges } from '@angular/core';

import { TableRowDirective } from '../../directives/table-row.directive';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent<T extends { id: number }> implements OnChanges {
    @ContentChild(TableRowDirective) tmpl: TableRowDirective;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    @Input() maxHeight: number = 200;
    @Input() theme: 'transparent' | 'striped' = 'striped';
    @Input() disableScroll: boolean = false;
    @Input() expectedHeight: number = 100;
    @Input() rows: T[];
    @Input()
    sortByFn: (a: T, b: T) => number;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.rows && this.sortByFn && this.rows) {
            this.rows = [...this.rows].sort(this.sortByFn);
        }

        if (changes.sortByFn && this.sortByFn && this.rows) {
            this.rows = [...this.rows].sort(this.sortByFn);
        }
    }

    trackByFn(index: number, row: T): number {
        return row.id;
    }
}
