import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModalBodyComponent } from './cm-modal-body/modal-body.component';
import { ModalFooterComponent } from './cm-modal-footer/modal-footer.component';
import { ModalTitleComponent } from './cm-modal-title/modal-title.component';
import { ModalComponent } from './modal.component';

@NgModule({
    declarations: [ModalComponent, ModalBodyComponent, ModalFooterComponent, ModalTitleComponent],
    imports: [CommonModule],
    exports: [ModalComponent, ModalBodyComponent, ModalFooterComponent, ModalTitleComponent],
})
export class CmModalModule {}
