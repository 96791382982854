import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IValidationRule } from './validation-rule.interface';
import { CONFIG_OPTIONS } from './zxcvbn-options.config';

@Component({
    selector: 'app-password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit, OnDestroy {
    @Input() isOnChangePasswordModal: boolean = false;
    @Input() passwordFormControl: UntypedFormControl | null = null;
    @Input() validationRules: IValidationRule[];
    @Input() labelKey: string = 'PASSWORD';
    @Input() displayMeter: boolean = true;
    @Input() inputSizeLarge: boolean = false;
    requiredStrength: number;
    strength: number = 0;
    meterPercentage: number = 0;
    meterBarClass: string;
    private _destroyTrigger$: Subject<void> = new Subject();

    ngOnInit(): void {
        zxcvbnOptions.setOptions(CONFIG_OPTIONS);

        this.passwordFormControl.valueChanges
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe((value: string) => {
                const strengthRule: IValidationRule = this.validationRules
                    ? this.validationRules.find(
                          (rule: IValidationRule) => rule.name === 'minimumScore'
                      )
                    : null;

                if (strengthRule) {
                    this.requiredStrength = strengthRule.rule as number;
                }

                this.strength = zxcvbn(value).score;

                if (this.strength) {
                    const maxStrength: number = 4;

                    this.setMeterPercentage(this.strength, maxStrength);
                    this.setMeterClass(this.strength, this.requiredStrength);
                } else {
                    this.resetMeterToMinimum();
                }
                if (value === '') {
                    this.passwordFormControl.setErrors(null);
                    this.resetMeterToMinimum();
                }
            });
    }

    ngOnDestroy(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }

    setMeterPercentage(strength: number, maxStrength: number): void {
        const segment: number = 100 / maxStrength;

        this.meterPercentage = segment * strength;
    }

    setMeterClass(strength: number, requiredStrength: number): void {
        if (strength >= requiredStrength) {
            this.meterBarClass = 'strong';
        } else {
            this.meterBarClass = 'weak';
        }
    }

    resetMeterToMinimum(): void {
        this.meterPercentage = 0;
        this.meterBarClass = 'weak';
    }
}
