import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { IExecutionResult } from '../../../core/interfaces/execution-result.interface';
import { ArchiveService } from '../../../core/services/archive.service';
import { GlobalDataService } from '../../../core/services/global-data.service';
import { GlobalEventService } from '../../../core/services/global-event.service';
import { AuthorizedHttpService } from '../../../core/services/http/authorized-http.service';
import { PopupService } from '../../../feature-modules/popups/services/popup.service';
import {
    IArchiveListFilterModel,
    IEmailDetailsViewModel,
} from '../../../models/user/archive-list-filter.model';
import { WaitSpinnerVariant } from '../../../new-shared/components/wait-spinner/wait-spinner-variant';
import { UserFlow } from '../../../pages/customer/user/cust-user-detail/cust-user-detail.component';
import { API_ENDPOINTS } from '../../constants/api/api-endpoints.constants';
import { DATE_FORMAT, DATE_TIME } from '../../constants/datetime.constants';
import { ArchiveListView, ArchiveMailType } from '../../enum/email-archive/archive-email.enum';
import { PageViewMethod } from '../../services/ga-service/ga-page-load';
import { GAService } from '../../services/ga-service/ga-service';
import { AbstractPageLoad } from '../../services/ga-service/shared-classes';

@Component({
    selector: 'giacom-end-user-activity',
    styleUrls: ['./end-user-activity.component.scss'],
    templateUrl: './end-user-activity.component.html',
})
export class EndUserActivityComponent extends AbstractPageLoad implements OnInit, OnDestroy {
    pageName: string = 'end-user/activity';
    pageType: string = 'end-user';
    pageViewMethod: PageViewMethod = PageViewMethod.page;
    @Input()
    set emailAddress(value: string) {
        if (value) {
            this.setActiveUser(value);
        }
    }

    @Input() isResellerView: boolean = false;
    emailAddressSet: boolean = false;
    archiveEmailAddress: string;
    archiveMinDate: Date;
    archiveMaxDate: Date;
    archiveMailType: typeof ArchiveMailType = ArchiveMailType;
    archiveListView: typeof ArchiveListView = ArchiveListView;
    listLoaded: boolean = false;
    isExpress: boolean = true;
    sender: string = '';
    recipient: string = '';
    subject: string = '';
    from: Date;
    to: Date;
    spamFrom: number;
    spamTo: number;
    startDate: dayjs.Dayjs = dayjs();
    endDate: dayjs.Dayjs = dayjs();
    timeFrom: Date;
    timeTo: Date;
    minDateSet: boolean = false;
    isNoData: boolean;
    isShowSearch: boolean;
    emailArchiveList: IEmailDetailsViewModel[];
    pagingEmailArchiveList: IEmailDetailsViewModel[];
    selectedArchiveListView: number;
    dateFromEmails: string;
    dateToEmails: string;
    numberOfEmails: string;
    pageSize: number;
    pageIndex: number;
    pageAll: boolean;
    pageTotal: number;
    errorMessage: string;
    areEmailsLoading: boolean = true;
    readonly spinnerVariant: typeof WaitSpinnerVariant = WaitSpinnerVariant;

    private readonly _destroyTrigger$: Subject<void> = new Subject<void>();

    constructor(
        protected _gaService: GAService,
        private _authorizedHttpService: AuthorizedHttpService,
        private _router: Router,
        private _dataService: GlobalDataService,
        private _popupService: PopupService,
        private _archiveService: ArchiveService,
        private _activatedRoute: ActivatedRoute,
        private _eventService: GlobalEventService
    ) {
        super(_gaService);
    }

    ngOnInit(): void {
        const defaultPageSize: number = 50;

        this.recordPageLoad();
        if (!this.emailAddressSet) {
            const archiveUser: { archiveUserAddress: string } =
                this._dataService.getData('emailarchive-user');

            if (archiveUser) {
                this.setActiveUser(archiveUser.archiveUserAddress);
            }
        }

        this.getIsExpress$()
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe((isExpressResponse: IExecutionResult<boolean>) => {
                this.isExpress = isExpressResponse.Result;
                this.setArchiveListView(this.isExpress);
                this.getFilterMinDate();
                this.checkSavedSearch();
                this.pageSize = defaultPageSize;
                this.pageIndex = 1;
                this.pageAll = false;
                this.pageTotal = 1;
                this.isNoData = false;
                this.archiveMaxDate = new Date();
            });
    }

    ngOnDestroy(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }

    setActiveUser(user: string): void {
        this.archiveEmailAddress = user;
        this._dataService.setData('emailarchive-user', {
            archiveUserAddress: user,
        });
        this.emailAddressSet = true;
    }

    getIsExpress$(): Observable<IExecutionResult<boolean>> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.EMAIL_ARCHIVE.IS_EXPRESS(this.archiveEmailAddress));
    }

    setArchiveListView(isExpress: boolean): void {
        this.selectedArchiveListView = isExpress
            ? this.archiveListView.spamMail
            : this.archiveListView.allMail;
    }

    checkSavedSearch(): void {
        const savedSearch: IArchiveListFilterModel | null = this._archiveService.getSavedSearch();

        if (!savedSearch) {
            this.isShowSearch = false;

            return;
        }

        const timeFromParts: string[] = savedSearch.TimeFrom ? savedSearch.TimeFrom.split(':') : [];
        const timeToParts: string[] = savedSearch.TimeTo ? savedSearch.TimeTo.split(':') : [];

        this.sender = savedSearch.From;
        this.recipient = savedSearch.To;
        this.subject = savedSearch.Subject;
        this.from = savedSearch.DateFrom;
        this.to = savedSearch.DateTo;
        this.timeFrom = timeFromParts.length
            ? new Date(
                  0,
                  0,
                  0,
                  parseInt(timeFromParts[0], 10),
                  parseInt(timeFromParts[1], 10),
                  0,
                  0
              )
            : null;
        this.timeTo = timeToParts.length
            ? new Date(
                  0,
                  0,
                  0,
                  parseInt(timeFromParts[0], 10),
                  parseInt(timeFromParts[1], 10),
                  0,
                  0
              )
            : null;
        this.spamFrom = savedSearch.SpamFrom;
        this.spamTo = savedSearch.SpamTo;
        this.selectedArchiveListView = savedSearch.ArchiveListView;

        this.isShowSearch = true;
    }

    getFilterMinDate(): void {
        this._authorizedHttpService
            .get$(API_ENDPOINTS.EMAIL_ARCHIVE.GET_FILTER_MIN_DATE(this.archiveEmailAddress))
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe((result: IExecutionResult<number | string>) => {
                if (result.Succeed) {
                    this.archiveMinDate = new Date(result.Result);
                    this.minDateSet = true;
                    this.to = dayjs().date(new Date().getDate()).add(1, 'day').toDate();
                    this.from = dayjs()
                        .date(new Date().getDate())
                        .subtract(DATE_TIME.daysInWeek, 'day')
                        .toDate();
                } else {
                    const now: Date = new Date();

                    now.setDate(now.getDate() + 1);
                    this.archiveMinDate = now;
                    this.minDateSet = true;
                }
                this.loadEmailArchive();
            });
    }

    getFilter(): IArchiveListFilterModel {
        if (this.isShowSearch) {
            const filter: IArchiveListFilterModel = {
                From: this.sender,
                To: this.recipient,
                Subject: this.subject,
                DateFrom: this.from || null,
                DateTo: this.to || null,
                TimeFrom: this.timeFrom
                    ? `${new Date(this.timeFrom).getHours()}:${new Date(
                          this.timeFrom
                      ).getMinutes()}:00`
                    : null,
                TimeTo: this.timeTo
                    ? `${new Date(this.timeTo).getHours()}:${new Date(this.timeTo).getMinutes()}:00`
                    : null,
                SpamFrom: this.spamFrom,
                SpamTo: this.spamTo,
                ArchiveListView: this.selectedArchiveListView,
            };

            if (this.from !== null && isNaN(new Date(this.from).getTime())) {
                filter.From = dayjs(this.from).format(DATE_FORMAT.UTC);
            }
            if (this.from !== null && isNaN(new Date(this.from).getTime())) {
                filter.To = dayjs(this.from).format(DATE_FORMAT.UTC);
            }

            this._archiveService.setSavedSearch(filter);

            return filter;
        }
        if (this.minDateSet) {
            return {
                From: null,
                To: null,
                Subject: null,
                DateFrom: this.from ? this.from : null,
                DateTo: this.to ? this.to : null,
                TimeFrom: null,
                TimeTo: null,
                SpamFrom: null,
                SpamTo: null,
                ArchiveListView: this.selectedArchiveListView,
            };
        }

        return {
            From: null,
            To: null,
            Subject: null,
            DateFrom: null,
            DateTo: null,
            TimeFrom: null,
            TimeTo: null,
            SpamFrom: null,
            SpamTo: null,
            ArchiveListView: this.selectedArchiveListView,
        };
    }

    getDateTo(): Date | null {
        return this.to || null;
    }

    getDateFrom(): Date | null {
        return this.from || null;
    }

    getTimeTo(): Date | null {
        return this.timeTo || null;
    }

    getTimeFrom(): Date | null {
        return this.timeFrom || null;
    }

    loadEmailArchive(): void {
        this.listLoaded = false;
        this.areEmailsLoading = true;
        this.errorMessage = null;
        const filter: IArchiveListFilterModel = this.getFilter();

        this._authorizedHttpService
            .post$(
                API_ENDPOINTS.EMAIL_ARCHIVE.LOAD_EMAIL_ARCHIVE(this.archiveEmailAddress),
                filter
            )
            .pipe(
                takeUntil(this._destroyTrigger$),
                finalize(() => {
                    this.areEmailsLoading = false;
                })
            )
            .subscribe((res: IExecutionResult<IEmailDetailsViewModel[]>) => {
                if (res.Succeed) {
                    this.dateFromEmails = dayjs(filter.DateFrom).format(DATE_FORMAT.DD_MM_YYYY);
                    this.dateToEmails = dayjs(filter.DateTo).format(DATE_FORMAT.DD_MM_YYYY);
                    if (res.Result.length > 0) {
                        this.isNoData = false;
                        this.numberOfEmails = res.Result.length.toString();
                    } else {
                        this.isNoData = true;
                        this.numberOfEmails = '0';
                    }
                } else {
                    this.isNoData = true;
                    this.numberOfEmails = '0';
                    this.dateFromEmails = 'N/A';
                    this.dateToEmails = `N/A : ${res.Error}`;
                }
                this.listLoaded = true;
                this.pagingListEmailArchive(res.Result);
                this.pagingEmailArchiveList = res.Result;
                this.emailArchiveList = res.Result;
                this.errorMessage = res.Error;

                this.loadData();
            });
    }

    setFromDate(event: any): void {
        this.from = event;
    }

    setToDate(event: any): void {
        this.to = event;
    }

    setFromTime(event: any): void {
        this.timeFrom = event;
    }

    setToTime(event: any): void {
        this.timeTo = event;
    }

    viewEmailDetail(param: any): void {
        this._dataService.setData('emailarchive-data', {
            param,
            model: this.emailArchiveList.filter(
                (x: IEmailDetailsViewModel) => x.EmailId === param
            )[0],
            emailAddress: this.archiveEmailAddress,
        });

        if (this.isResellerView) {
            this._eventService.publish('switchArchiveView', UserFlow.viewEmail);

            return;
        }

        this._router.navigate(['view-email'], {
            relativeTo: this._activatedRoute,
        });
    }

    reDeliveryEmail(emailId: string): void {
        this._dataService.setData('emailarchive-data', {
            model: this.emailArchiveList.filter(
                (x: IEmailDetailsViewModel) => x.EmailId === emailId
            )[0],
            param: emailId,
        });
        this._popupService.show(null, 'end-user-re-deliver-email', this.archiveEmailAddress);
    }

    showSearch(isShowSearch: boolean): void {
        if (isShowSearch) {
            this.isShowSearch = false;
            this._archiveService.setSavedSearch(null);
            this.loadEmailArchive();
        } else {
            this.isShowSearch = true;
        }
    }

    loadNext(): void {
        this.pageIndex = this.pageIndex < this.pageTotal ? this.pageIndex + 1 : this.pageTotal;
        this.loadData();
    }

    pagingListEmailArchive(lstEmails: any): void {
        const emailBreakpoint: number = 50;

        this.pageIndex = 1;
        if (lstEmails.length > emailBreakpoint) {
            this.pageAll = false;
            const pagingLength: number =
                (lstEmails.length - (lstEmails.length % this.pageSize)) / this.pageSize;

            this.pageTotal = pagingLength + (lstEmails.length % this.pageSize > 0 ? 1 : 0);
        } else {
            this.pageAll = true;
            this.pageTotal = 1;
        }
    }

    loadData(): void {
        if (this.pageAll) {
            this.emailArchiveList = this.pagingEmailArchiveList;

            return;
        }
        if (this.pageIndex === this.pageTotal) {
            this.emailArchiveList = this.pagingEmailArchiveList;
            this.pageAll = true;

            return;
        }

        this.emailArchiveList = this.pagingEmailArchiveList.filter(
            (x: IEmailDetailsViewModel) => x.Index <= this.pageIndex * this.pageSize
        );
    }

    changeFilter(selectedFilter: number): void {
        this.selectedArchiveListView = selectedFilter;
        this.loadEmailArchive();
    }
}
