import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { ApiFeatureFlagsService } from '../../../core/services/api/api-feature-flags.service';

@Directive({
    selector: '[ifFeature],[ifFeatureDisabled],[notIfFeature]',
})
export class FeatureFlagDirective {
    private _hasView: boolean = false;

    constructor(
        private _featureFlagsService: ApiFeatureFlagsService,
        private _templateRef: TemplateRef<any>,
        private _viewContainerRef: ViewContainerRef
    ) {}

    @Input() set ifFeature(feature: string) {
        const featureEnabled: boolean = this._featureFlagsService.isFeatureFlagEnabled(feature);

        if (feature && featureEnabled && !this._hasView) {
            this._viewContainerRef.createEmbeddedView(this._templateRef);
            this._hasView = true;
        } else if (!feature && !featureEnabled && this._hasView) {
            this._viewContainerRef.clear();
            this._hasView = false;
        }
    }

    @Input() set ifFeatureDisabled(feature: string) {
        const featureEnabled: boolean = this._featureFlagsService.isFeatureFlagEnabled(feature);

        if (feature && !featureEnabled && !this._hasView) {
            this._viewContainerRef.createEmbeddedView(this._templateRef);
            this._hasView = true;
        } else if (!feature && !featureEnabled && this._hasView) {
            this._viewContainerRef.clear();
            this._hasView = false;
        }
    }

    @Input() set notIfFeature(feature: string) {
        const featureEnabled: boolean = this._featureFlagsService.isFeatureFlagEnabled(feature);

        if (feature && featureEnabled && this._hasView) {
            this._viewContainerRef.clear();
            this._hasView = false;
        } else if (feature && !featureEnabled && !this._hasView) {
            this._viewContainerRef.createEmbeddedView(this._templateRef);
            this._hasView = true;
        }
    }
}
