import { Router } from '@angular/router';

import { ApiDistributionGroupService } from '../../../../core/services/api/api-distribution-group.service';
import { ApiDomainService } from '../../../../core/services/api/api-domain.service';

export class EmailSecurityManagement {
    get hideSettingFlow(): boolean {
        return this.level === 'customer';
    }

    set organisationId(val: string) {
        this._organisationId = val;
        this.apiDistributionGroupService.getAccessInfo(val, (data: any) => {
            this.hideDns = !data.Result.DisplayDns;
            this.canAddDistributionGroup = data.Result.CanAdd;
        });
        this.apiDomainService.getAccessInfo(val, (data: any) => {
            this.canAddDomain = data.Result.CanAdd;
        });
    }

    get organisationId(): string {
        return this._organisationId;
    }

    set userId(val: string) {
        this._userId = val;
    }

    get userId(): string {
        return this._userId;
    }

    get aliasesEnabled(): boolean {
        return this.aliases !== undefined && this.aliases.length !== 0;
    }

    flow: typeof EmailSecurityManagementFlow = EmailSecurityManagementFlow;
    selectedFlow: EmailSecurityManagementFlow = EmailSecurityManagementFlow.settings;
    serviceId: any;
    level: string;
    mailboxAddress: string;
    hideDns: boolean = false;
    canAddDistributionGroup: boolean = false;
    canAddDomain: boolean = false;
    aliases: any;
    private _organisationId: any;
    private _userId: any;

    constructor(
        private _router: Router,
        public apiDistributionGroupService: ApiDistributionGroupService,
        public apiDomainService: ApiDomainService
    ) {}

    selectFlow(flow: EmailSecurityManagementFlow): void {
        this.selectedFlow = flow;
    }

    deleteUser(): void {
        const id: string = `${this.organisationId}+${this.userId}+${this.serviceId}`;

        this._router.navigate([`customers/customer/${id}/delete-service`]);
    }

    isHiddenUserFlow(): boolean {
        return true;
    }

    isSelectedUserFlow(): boolean {
        return false;
    }

    isSelectedSettingsFlow(): boolean {
        return this.selectedFlow === EmailSecurityManagementFlow.settings;
    }

    isSelectedDistributionGroupsFlow(): boolean {
        return this.selectedFlow === EmailSecurityManagementFlow.distributionGroups;
    }

    isSelectedDomainsFlow(): boolean {
        return this.selectedFlow === EmailSecurityManagementFlow.domains;
    }

    isSelectedDNSFlow(): boolean {
        return this.selectedFlow === EmailSecurityManagementFlow.dns;
    }

    isSelectedAliasesFlow(): boolean {
        return this.selectedFlow === EmailSecurityManagementFlow.aliases;
    }
}

export enum EmailSecurityManagementFlow {
    settings = 0,
    users = 1,
    aliases = 2,
    distributionGroups = 5,
    domains = 7,
    dns = 8,
}
