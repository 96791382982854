import { Component, Input } from '@angular/core';

@Component({
    selector: 'cm-tooltip',
    templateUrl: './cm-tooltip.component.html',
    styleUrls: ['./cm-tooltip.component.scss'],
})
export class ToolTipComponent {
    @Input() data: any;

    click($event: any): void {
        $event.stopPropagation();
    }
}
