import { Pipe, PipeTransform } from '@angular/core';

import { ButtonAction } from '../../../shared/interfaces/cm-button.types';

@Pipe({
    name: 'dropdownActionsFilter',
}) // todo: is Action ICustomerServiceProductAction type?
export class DropdownActionsFilterPipe<T extends { Action?: ButtonAction }>
    implements PipeTransform
{
    transform(items: T[] = []): T[] {
        return (items || []).filter(
            (item: T) => item.Action && item.Action !== ButtonAction.bottomPanel
        );
    }
}
