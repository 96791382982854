import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { sortBy } from 'lodash-es';
import { Subject } from 'rxjs';

import { DATE_FORMAT } from '../../../../shared/constants/datetime.constants';
import { INotification, INotificationCategory } from '../../interfaces/notification.interface';
import { NotificationService } from '../../services/notification.service';
import { NotificationStatus } from '../notification-status.enum';

@Component({
    selector: 'app-notifications-dropdown',
    templateUrl: './notifications-dropdown.component.html',
    styleUrls: ['./notifications-dropdown.component.scss'],
})
export class NotificationsDropdownComponent implements OnInit, OnDestroy {
    filteredNotifications: INotification[] = [];
    filterString: string = '';
    selectedCategory: string = 'show-all';
    categories: INotificationCategory[] = [];
    notificationsAmount: number = 0;
    viewNewNotifications: boolean = true;
    readonly notificationDateFormat: string = DATE_FORMAT.D_M_Y_H_MIN;
    private readonly _destroyTrigger$: Subject<void> = new Subject();

    constructor(private _notificationService: NotificationService, private _router: Router) {}

    markNotificationAsRead(event: MouseEvent, notificationItem: INotification): void {
        this._notificationService.markAsRead$(notificationItem.Id).subscribe();
    }

    toggleViewStatus(): void {
        this.viewNewNotifications = !this.viewNewNotifications;
        const statusFilter: NotificationStatus = this.viewNewNotifications
            ? NotificationStatus.new
            : NotificationStatus.read;

        this._notificationService.setNotificationsStatusFilter(statusFilter);
    }

    stopClickPropagation(event: MouseEvent): void {
        event.stopPropagation();
    }

    ngOnInit(): void {
        this.getNotificationsData();
    }

    ngOnDestroy(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }

    getNotificationsData(): void {
        this._notificationService
            .getFilteredNotifications$()
            .subscribe((notifications: INotification[]) => {
                this._setupNotificationsData(notifications);
            });
    }

    prepareCategories(notifications: INotification[]): INotificationCategory[] {
        const categories: string[] = notifications
            .map((notification: INotification) => notification.Category)
            .filter((item: string, i: number, ar: string[]) => ar.indexOf(item) === i);
        const defaultElement: INotificationCategory[] = [
            {
                value: 'show-all',
                name: 'Show All',
            },
        ];

        return defaultElement.concat(
            categories.map((singleCategory: string) => ({
                value: singleCategory.replace(' ', '-').toLowerCase(),
                name: singleCategory,
            }))
        );
    }

    trackByItem(index: number, item: INotification): string {
        return item.Id;
    }

    private _setupNotificationsData(notifications: INotification[]): void {
        this.notificationsAmount = notifications.length;
        this.filteredNotifications = this._sortNotifications(notifications);
        this.categories = this.prepareCategories(notifications);
    }

    private _sortNotifications(notifications: INotification[]): INotification[] {
        return sortBy(notifications, ['Created']).reverse();
    }
}
