import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IServiceCustomerSchema } from '../../../pages/services/interfaces/service-customer-schema.interface';
import { IServicesGlobalSetting } from '../../../pages/services/interfaces/service-global-settings.interface';
import { IServiceList } from '../../../pages/services/interfaces/service-list.interface';
import { API_ENDPOINTS } from '../../../shared/constants/api/api-endpoints.constants';
import { ICloudMarketButton } from '../../../shared/interfaces/cm-button.types';
import { IExecutionResult } from '../../interfaces/execution-result.interface';
import { IService } from '../../interfaces/service.interface';
import { AuthorizedHttpService } from '../http/authorized-http.service';

@Injectable({
    providedIn: 'root',
})
export class ApiMyServicesService {
    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    getServices$(): Observable<IExecutionResult<IService[]>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.MY_SERVICES.GET_SERVICES()
        );
    }

    getServiceCustomers$(serviceId: number): Observable<any> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.MY_SERVICES.GET_SERVICE_CUSTOMERS(serviceId)
        );
    }

    getServiceCustomerSchema$(
        productCloudMarketId: string
    ): Observable<IExecutionResult<IServiceCustomerSchema>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.MY_SERVICES.GET_SERVICE_CUSTOMER_SCHEMA(
                productCloudMarketId
            )
        );
    }

    getServiceSettings$(
        productCloudMarketId: string
    ): Observable<IExecutionResult<IServicesGlobalSetting[]>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.MY_SERVICES.GET_SERVICE_SETTINGS(productCloudMarketId)
        );
    }

    getServiceCustomerListSchema$(
        productCloudMarketId: string,
        listSchemaId: number
    ): Observable<IExecutionResult<IServiceList>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.MY_SERVICES.GET_SERVICE_CUSTOMER_LIST_SCHEMA(
                productCloudMarketId,
                listSchemaId
            )
        );
    }

    getAddModifyButtons$(
        productCloudMarketId: string,
        customerId: string,
        listSchemaId: number
    ): Observable<IExecutionResult<ICloudMarketButton[]>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.MY_SERVICES.GET_ADD_MODIFY_BUTTONS(
                productCloudMarketId,
                customerId,
                listSchemaId
            )
        );
    }

    getServiceCustomerDetailList$(
        productCloudMarketId: string,
        customerId: string,
        listSchemaId: number,
        includeAssignedLicences: boolean = false
    ): Observable<IExecutionResult<any[]>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.MY_SERVICES.GET_SERVICE_CUSTOMER_DETAIL_LIST(
                productCloudMarketId,
                customerId,
                listSchemaId,
                includeAssignedLicences
            )
        );
    }

    getServiceCustomerDetailsActionButtons$(
        productCloudMarketId: string,
        customerId: string,
        listSchemaId: number
    ): Observable<IExecutionResult<ICloudMarketButton[]>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.MY_SERVICES.GET_SERVICE_CUSTOMER_DETAILS_ACTION_BUTTONS(
                productCloudMarketId,
                customerId,
                listSchemaId
            )
        );
    }
}
