import { Injectable } from '@angular/core';
import { IUserProfile } from 'app/pages/your-account/interfaces/user-profile.interface';
import { API_ENDPOINTS } from 'app/shared/constants/api/api-endpoints.constants';
import { Observable } from 'rxjs';

import { IExecutionResult } from '../../../core/interfaces/execution-result.interface';
import { AuthorizedHttpService } from '../../../core/services/http/authorized-http.service';
import { IUserInterface } from '../../../feature-modules/auth/interfaces/user.interface';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    getLoginUser$(): Observable<IUserInterface> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.USER.LOGIN_USER);
    }

    getUser$(organisationId: string, userId: string): Observable<any> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.USER.GET_USER(organisationId, userId)
        );
    }

    getLoggedInUserData$(): Observable<IUserInterface> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.USER.GET_LOGGED_IN_USER_DATA);
    }

    getAvailableServices$(
        organisationId: string,
        userId: number
    ): Observable<IExecutionResult<any>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.USER.GET_AVAILABLE_SERVICES(organisationId, userId)
        );
    }

    getUserProfile$(id: number): Observable<IExecutionResult<IUserProfile>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.USER.GET_USER_PROFILE(id)
        );
    }

    getMyProfile$(): Observable<IExecutionResult<IUserProfile>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.USER.MY_PROFILE
        );
    }
}
