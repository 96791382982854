import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IExecutionResult } from '../../../core/interfaces/execution-result.interface';
import { UnauthorizedHttpService } from '../../../core/services/http/unauthorized-http.service';
import { SsoAuthService } from '../../../core/services/sso-auth/sso-auth.service';
import { API_ENDPOINTS } from '../../../shared/constants/api/api-endpoints.constants';


@Component({
    selector: 'app-verify-email',
    templateUrl: './verify-email-archive-access.component.html',
    styleUrls: ['./verify-email-archive-access.component.scss'],
})
export class VerifyEmailArchiveAccessComponent implements OnInit {
    accessType: string;
    token: string;
    loading: boolean = true;
    executionResult: IExecutionResult<boolean>;
    errors: string[] = [];

    constructor(
        private _unauthorizedHttpService: UnauthorizedHttpService,
        private _activatedRoute: ActivatedRoute,
        private _ssoAuthService: SsoAuthService
    ) {}

    ngOnInit(): void {
        this.accessType = this._activatedRoute.snapshot.params.ACCESSTYPE;
        this.token = this._activatedRoute.snapshot.params.TOKEN;

        if (!this.accessType || !this.token) {
            this._ssoAuthService.logoutWithDefaultOptions();
        }

        this._unauthorizedHttpService
            .get$(API_ENDPOINTS.VERIFY_EMAIL.EMAIL_ARCHIVE_ACCESS(this.accessType, this.token))
            .subscribe((result: IExecutionResult<boolean>) => {
                this.executionResult = result;
            });
    }
}
