import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[togglePasswordVisibility]',
})
export class TogglePasswordVisibilityDirective implements AfterViewInit {
    private _shown: boolean = false;

    constructor(private _elementRef: ElementRef<HTMLElement>) {}

    toggle(toggleEl: HTMLElement, passwordInput: HTMLElement): void {
        this._shown = !this._shown;

        if (this._shown) {
            passwordInput.setAttribute('type', 'text');
            toggleEl.classList.add('hide-icon');
            toggleEl.classList.remove('fa-eye');
        } else {
            passwordInput.setAttribute('type', 'password');
            toggleEl.classList.add('fa-eye');
            toggleEl.classList.remove('hide-icon');
        }
    }

    setup(): void {
        const parent: any = this._elementRef.nativeElement.parentNode;
        const passwordInput: any = parent?.querySelector('input[type="password"]');
        const toggleEl: any = this._elementRef.nativeElement;

        toggleEl.addEventListener('click', () => {
            this.toggle(toggleEl, passwordInput);
        });
    }

    ngAfterViewInit(): void {
        this.setup();
    }
}
