import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterHidden',
    pure: false,
})
export class FilterHiddenPipe implements PipeTransform {
    transform<T extends { hidden: boolean }>(items: T[]): T[] {
        if (!items) {
            return items;
        }

        return items.filter((item: T) => !item.hidden);
    }
}
