import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'rowExpanded',
})
export class RowExpandedPipe implements PipeTransform {
    transform(rowIndex: number, expandedRows: number[]): boolean {
        return expandedRows.includes(rowIndex);
    }
}
