import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IArticle } from '../../../../models/knowledgebase/article.interface';
import {
    IKnowledgeBaseResultModel,
    IKnowledgeBaseSearch,
} from '../../../../models/service/knowledgebase.model';
import { API_ENDPOINTS } from '../../../../shared/constants/api/api-endpoints.constants';
import { IExecutionResult } from '../../../interfaces/execution-result.interface';
import { AuthorizedHttpService } from '../../http/authorized-http.service';

@Injectable({
    providedIn: 'root',
})
export class ApiKnowledgeBaseArticlesService {
    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    getArticles$(searchData: IKnowledgeBaseSearch): Observable<IKnowledgeBaseResultModel[]> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.KNOWLEDGEBASE.GET_ARTICLES, searchData);
    }

    getSearchTermPages$(searchText: string): Observable<IExecutionResult<IArticle[]>> {
        const arr: string[] = searchText.match(/[\w\s]*/g);
        const all: string = arr.join(' ').trim();

        return this._authorizedHttpService.get$(
            API_ENDPOINTS.KNOWLEDGEBASE.SEARCH_ARTICLES(all)
        );
    }
}
