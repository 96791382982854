import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PopupService } from '../../../feature-modules/popups/services/popup.service';
import { IConfirmationParams } from '../../../new-shared/components/modals/confirmation/interface/confirmation.types';
import { STORAGE_KEYS } from '../../../shared/constants/storage-keys.constants';
import { UserType } from '../../../shared/enum/user-type.enum';
import { UserSwitcherService } from '../../../shared/services/user-switcher.service';
import { Resource, UserInfo } from '../resource.service';

@Injectable({
    providedIn: 'root',
})
export class SwitchUserConfirmationHandlerService {
    switchUserConfirmationMessage: string;

    constructor(
        private _popupService: PopupService,
        private _userSwitcherService: UserSwitcherService,
        private _translateService: TranslateService,
        private _resource: Resource
    ) {}

    doSwitchAndGiveUserInfo(): UserInfo {
        this._userSwitcherService.switchBetweenUserTypes();

        return this._resource.user;
    }

    showSwitchUserPopup(): void {
        const userType: string = localStorage.getItem(STORAGE_KEYS.USERTYPE);
        const previousType: string = localStorage.getItem(STORAGE_KEYS.PREVIOUS_TYPE);
        this.switchUserConfirmationMessage =
            (userType === UserType.endUser)
                ? (previousType === UserType.endUserAdmin
                    ? this._translateService.instant('SWITCH_MAILBOX_CONFIRMATION.CONFIRMATION_MODAL.TITLE_FOR_ADMIN_PANEL')
                    : this._translateService.instant('SWITCH_MAILBOX_CONFIRMATION.CONFIRMATION_MODAL.TITLE_FOR_MASTER_PANEL'))
                : this._translateService.instant('SWITCH_MAILBOX_CONFIRMATION.CONFIRMATION_MODAL.TITLE_FOR_MAILBOX_PANEL');

        this._popupService.show(
            null,
            'confirmation',
            {
                title: this._translateService.instant('SWITCH_MAILBOX_CONFIRMATION.CONFIRMATION_MODAL.SWITCH_USER'),
                message: this.switchUserConfirmationMessage,
                primaryButtonText: this._translateService.instant('CONTINUE'),
                secondaryButtonText: this._translateService.instant('CANCEL'),
                primaryButtonAction: () => this._userSwitcherService.switchBetweenUserTypes()
            } as IConfirmationParams
        )
    }
}
