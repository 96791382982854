import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
    APP_INITIALIZER,
    DEFAULT_CURRENCY_CODE,
    ErrorHandler,
    LOCALE_ID,
    NgModule,
} from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from 'environments/environment';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { CoreModule } from './core/core.module';
import { AuthModule } from './feature-modules/auth/auth.module';
import { SessionInterceptor } from './feature-modules/auth/interceptors/session.interceptor';
import { KnowledgeBaseModule } from './feature-modules/knowledge-base/knowledge-base.module';
import { LayoutModule } from './feature-modules/layout/layout.module';
import { PopupsModule } from './feature-modules/popups/popups.module';
import { RateItPopupModule } from './feature-modules/rate-it-popup/rate-it-popup.module';
import { ListCustomerModalModule } from './new-shared/components/modals/list-customer/list-customer-modal.module';
import { WaitSpinnerModule } from './new-shared/components/wait-spinner/wait-spinner.module';
import { NoAuthRoutingModule } from './no-auth.routing.module';
import { NoAccessResourcesComponent } from './pages/dashboard/pages/no-access-resources/no-access-resources.component';
import { AccessDeniedComponent } from './pages/direct-routes/access-denied/access-denied.component';
import { PageNotFoundComponent } from './pages/direct-routes/page-not-found/page-not-found.component';
import { SpamReleaseComponent } from './pages/direct-routes/spam-release/spam-release.component';
import { VerifyEmailArchiveAccessComponent } from './pages/direct-routes/verify-email/verify-email-archive-access.component';
import { SwitchingMailboxConfirmationModule } from './pages/switching-mailbox-confirmation/switching-mailbox-confirmation.module';
import { STORAGE_KEYS } from './shared/constants/storage-keys.constants';
import { CurrencyCode } from './shared/enum/currency-code.enum';
import { IntegrationResellerPsaService } from './shared/services/integration-reseller-psa.service';

// eslint-disable-next-line
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(
        http,
        './assets/i18n/',
        `.json?v=${environment.Translation.fileHash}`
    );
}

// eslint-disable-next-line
export function ResellerCurrencyFactory() {
    return localStorage.getItem(STORAGE_KEYS.CURRENCY_CODE) ?? CurrencyCode.GBP;
}

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        SpamReleaseComponent,
        AccessDeniedComponent,
        VerifyEmailArchiveAccessComponent,
        NoAccessResourcesComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AuthModule,
        AppRoutingModule,
        NoAuthRoutingModule,
        SwitchingMailboxConfirmationModule,
        CoreModule,
        ListCustomerModalModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        NgxExtendedPdfViewerModule,
        KnowledgeBaseModule,
        RateItPopupModule,
        LayoutModule,
        PopupsModule,
        WaitSpinnerModule,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SessionInterceptor,
            multi: true,
        },
        Title,
        IntegrationResellerPsaService,
        { provide: LOCALE_ID, useValue: 'en-GB' },
        { provide: DEFAULT_CURRENCY_CODE, useFactory: ResellerCurrencyFactory },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
