// eslint-disable-next-line max-classes-per-file
import { ArrayService } from '../../../../new-shared/services/array.service';
import {
    HostedVoiceHardwareCategoryModel,
    HostedVoiceHardwareProductModel,
    HostedVoiceHardwareServiceModel,
} from '../hosted-voice-hardware/hosted-voice-hardware.management';

export class HostedVoiceV2Management {
    mode: string; // = 'add';
    level: string = 'customer';
    total: number = 0;
    steps: Step[];
    selectedStepId: number = 1;
    startStep: number;
    endStep: number;
    completeStep: number;
    organisationId: string;
    organisationName: string;
    oldRequestSeat: number;

    products: HostedVoiceV2Package[];
    callReportingEmailAddress: string;
    bandwidthConfirmed: boolean;

    handsetsMitel: number = 0;
    handsetsOther: number = 0;

    sites: HostedVoiceV2Site[];

    orderId: number;

    hardwareServiceModel: HostedVoiceHardwareServiceModel;
    hardwareDeliveryOptions: any[];

    private _organisation: any;

    set organisation(val: any) {
        this.organisationId = val.Id;
        this.organisationName = val.Name;
        this._organisation = val;
    }

    get organisation(): any {
        return this._organisation;
    }

    get addServiceV2Data(): HostedVoiceV2AddModel {
        const model: HostedVoiceV2AddModel = new HostedVoiceV2AddModel();

        model.OrganisationID = this.organisationId;
        model.OrganisationName = this.organisationName;
        model.Sites = this.sites;
        model.SelectedProduct = this._arrayService.firstOrDefault(this.products, (product: HostedVoiceV2Package) => product.Selected);
        model.MitelLicences = this.handsetsMitel;
        model.NonMitelLicences = this.handsetsOther;
        model.CallReportingEmailAddress = this.callReportingEmailAddress;
        model.BandwidthConfirmed = this.bandwidthConfirmed;
        if (this.mode === 'modify-order') {
            model.OrderId = this.orderId;
        }

        return model;
    }

    constructor(private _arrayService: ArrayService) {}

    pushStep(
        startStep: number,
        endStep: number,
        completeStep?: number,
        hiddenSteps?: number[]
    ): void {
        this.steps = [];
        this.selectedStepId = startStep;
        this.startStep = startStep;
        this.endStep = endStep;
        this.completeStep = completeStep ? completeStep : -1;
        let name: number = 1;

        for (let step: number = startStep; step <= endStep; ++step) {
            const stepInfo: any = { id: step, name: name.toString(), hidden: false };

            if (hiddenSteps) {
                for (const item of hiddenSteps) {
                    if (step === item) {
                        stepInfo.hidden = true;
                        break;
                    }
                }

                name = stepInfo.hidden ? name : ++name;
            } else {
                ++name;
            }

            this.steps.push(stepInfo);
        }
    }

    containsStep(step: number): boolean {
        if (!this.steps) {
            return false;
        }

        for (const item of this.steps) {
            if (item.id === step) {
                return true;
            }
        }

        return false;
    }

    nextStepId(): void {
        if (this.selectedStepId < this.endStep) {
            this.selectedStepId++;
            for (const item of this.steps) {
                if (item.id === this.selectedStepId && item.hidden) {
                    this.nextStepId();

                    return;
                }
            }
        }
    }

    goNextStepId(step: number): void {
        if (this.startStep < step && step <= this.endStep) {
            if (this.selectedStepId < this.endStep) {
                this.selectedStepId = step;
            }
        }
    }

    previousStepId(): void {
        if (this.selectedStepId > this.startStep) {
            this.selectedStepId--;
        }
    }

    goPreviousStepId(step: number): void {
        if (this.startStep <= step && step < this.endStep) {
            if (this.selectedStepId > this.startStep) {
                this.selectedStepId = step;
            }
        }
    }

    selectStepId(step: number): void {
        if (this.selectedStepId >= this.startStep && this.selectedStepId <= this.endStep) {
            this.selectedStepId = step;
        }
    }

    getHardwareTotal(includeDelivery: boolean = false): number {
        let total: number = 0;

        if (this.hardwareServiceModel) {
            for (const item of this.hardwareServiceModel.OrderProducts) {
                for (const product of item.Products) {
                    total += product.Price * product.Quantity;
                }
            }
        }

        if (includeDelivery) {
            const selectedOption: any = this.getSelectedHardwareDeliveryOption();

            total += selectedOption.Cost;
        }

        return total;
    }

    hasHardwareProducts(): boolean {
        if (this.hardwareServiceModel) {
            return (
                this.hardwareServiceModel.OrderProducts.filter(
                    (orderProduct: HostedVoiceHardwareCategoryModel) =>
                        orderProduct.Products.filter((product: HostedVoiceHardwareProductModel) => product.Quantity > 0)
                            .length > 0
                ).length > 0
            );
        }

        return false;
    }

    getSelectedHardwareDeliveryOption(): any {
        return this._arrayService.firstOrDefault(
            this.hardwareDeliveryOptions,
            (option: any) => option.Id === this.hardwareServiceModel.DeliveryOption
        );
    }

    getSelectedHardwareDeliveryDescription(): string {
        return this.getSelectedHardwareDeliveryOption().Description;
    }

    clearHardwareAddress(): void {
        this.hardwareServiceModel.Address1 = null;
        this.hardwareServiceModel.Address2 = null;
        this.hardwareServiceModel.Address3 = null;
        this.hardwareServiceModel.TownCity = null;
        this.hardwareServiceModel.County = null;
        this.hardwareServiceModel.PostCode = null;
        this.hardwareServiceModel.Telephone = null;
    }
}

export class HostedVoiceV2AddModel {
    OrganisationID: string;
    OrganisationName: string;
    Sites: HostedVoiceV2Site[];
    SelectedProduct: HostedVoiceV2Package;
    MitelLicences: number;
    NonMitelLicences: number;
    CallReportingEmailAddress: string;
    BandwidthConfirmed: boolean;
    OrderId: number;
    OrderCreatedOn: Date;
    CreatedBy: string;
    EstimatedCompletionOn: Date;
    OrderType: number;
    OrderStatus: string;
    OrderProcessedBy: string;
}

export class HostedVoiceV2Site {
    SiteId: number;
    SiteName: string;
    Phone: string;
    Address1: string;
    Address2: string;
    Town: string;
    County: string;
    Postcode: string;
    NumberOfDDis: number;
    DDIAreaCode: string;
    PropertyType: number;
    Exists: boolean;
}

export enum HostedVoiceV2SitePropertyType {
    Commercial = 0,
    Residential = 1,
}

export class HostedVoiceV2Package {
    PackageId: number;
    PackageName: string;
    PackageNameSubText: string;
    ProductPrice: number;
    Quantity: number;
    Selected: boolean;
}

export class Step {
    id: number;
    name: string;
    hidden: boolean;
}
