import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import plugin from 'dayjs/plugin/advancedFormat';

import { DATE_FORMAT } from '../../../shared/constants/datetime.constants';

@Pipe({ name: 'giacomDateTime' })
export class GiacomDateTimePipe implements PipeTransform {
    transform(val: Date): string {
        dayjs.extend(plugin);

        return dayjs(val).format(DATE_FORMAT.DO_M_Y_H_MIN);
    }
}
