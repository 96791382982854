import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { DefaultPageRouteService } from '../../../../core/services/default-page-route.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class NonSsoSessionManagementService {
    constructor(
        private _authService: AuthService,
        private _defaultPageRouteService: DefaultPageRouteService,
    ) {}

    handleNonSsoLoginFlow$(url: string): Observable<boolean> {
        const cloudSessionExpired: boolean = this._authService.tokenExpired();

        if (cloudSessionExpired) {
            this._defaultPageRouteService.goToDefaultLoginPage(url)

            return of(false);
        }

        return of(true);
    }
}
