import { Component, Input, OnInit } from '@angular/core';

import { IExecutionResult } from '../../../../../core/interfaces/execution-result.interface';
import { GlobalEventService } from '../../../../../core/services/global-event.service';
import { AuthorizedHttpService } from '../../../../../core/services/http/authorized-http.service';
import { PopupService } from '../../../../../feature-modules/popups/services/popup.service';
import { API_ENDPOINTS } from '../../../../../shared/constants/api/api-endpoints.constants';
import { HostedExchangeManagement } from '../hosted-exchange.management';

@Component({
    selector: 'manage-hosted-exchange-addess-box',
    styleUrls: ['./address-box.component.scss'],
    templateUrl: './address-box.component.html',
})
export class HostedExchangeAddressBoxComponent implements OnInit {
    @Input() management: HostedExchangeManagement;
    addressBook: any;
    private _refreshSettingSub: any;

    constructor(
        private _authorizedHttpService: AuthorizedHttpService,
        private _popupService: PopupService,
        private _eventService: GlobalEventService
    ) {}

    ngOnInit(): void {
        this.getAddressBook();
        this._refreshSettingSub = this._eventService.subscribe(
            'refresh-hosted-exchange-addressbook',
            () => {
                this.getAddressBook();
            }
        );
    }

    editAddressBox(): void {
        this._popupService.show(null, 'manage-hosted-exchange-edit-address-box', this.management);
    }

    getAddressBook(): void {
        this._authorizedHttpService
            .get$(API_ENDPOINTS.MAILBOX.GET_ADRESS_BOOK(this.management.mailboxAddress))
            .subscribe((response: IExecutionResult<any>) => {
                if (response.Succeed) {
                    this.management.addressBook = response.Result;
                }
            });
    }
}
