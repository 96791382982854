import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IDictionary } from '../../../shared/interfaces/dictionary.interface';

import { AbstractHttpService } from './abstract-http.service';

@Injectable({
    providedIn: 'root',
})
export class UnauthorizedHttpService extends AbstractHttpService {
    protected readonly _shouldSendCredentials: boolean = false;

    constructor(protected _httpClient: HttpClient) {
        super(_httpClient);
    }

    protected _getDefaultHeaders(): IDictionary<string | string[]> {
        return {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
        };
    }
}
