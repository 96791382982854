import {
    Directive,
    ElementRef,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BlockUiService } from '../services/block-ui.service';

@Directive({
    selector: '[cmShowWhenRequest]',
})
export class ShowWhenRequestDirective implements OnInit, OnDestroy {
    private _isElementVisible: boolean = false;
    private _isRequestInProgress: boolean = false;
    private readonly _destroyTrigger$: Subject<void> = new Subject();

    constructor(
        private _blockUiService: BlockUiService,
        private _elementRef: ElementRef,
        private _templateRef: TemplateRef<any>,
        private _viewContainerRef: ViewContainerRef
    ) {}

    ngOnInit(): void {
        this._validateVisibility();

        this._blockUiService
            .getIsRequestInProgress$()
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe((isRequestInProgress: boolean) => {
                this._isRequestInProgress = isRequestInProgress;
                this._validateVisibility();
            });
    }

    ngOnDestroy(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }

    private _validateVisibility(): void {
        if (this._isRequestInProgress) {
            this._showElement();
        } else {
            this._hideElement();
        }
    }

    private _showElement(): void {
        if (this._isElementVisible) {
            return;
        }

        this._viewContainerRef.clear();
        this._viewContainerRef.createEmbeddedView(this._templateRef);
        this._isElementVisible = true;
    }

    private _hideElement(): void {
        if (!this._isElementVisible) {
            return;
        }
        this._viewContainerRef.clear();
        this._isElementVisible = false;
    }
}
