export class GADataMethods {
    static processData(data: any): void {
        const keys: string[] = Object.keys(data);

        for (const key of keys) {
            data[key] = GADataMethods.processDataItem(data[key], key);
        }
    }

    static processDataItem(dataItem: any, key?: string): any {
        if (dataItem === null) {
            return null;
        }

        if (key === 'ecommerce') {
            return dataItem;
        }

        let retValue: string = dataItem.toString().toLowerCase();

        retValue = retValue.replace('&', 'and').replace('_', ' ');

        if (key && (key === 'page_name' || key === 'tab' || key === 'path')) {
            retValue = retValue.split(' ').join('-');
            retValue = retValue.replace(/[^a-zA-Z0-9\-\/]+/g, '');
        } else {
            retValue = retValue.replace(/[^\w\s]+/g, '');
        }

        return retValue;
    }
}
