import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

import { ButtonSize } from '../../../../../shared/enum/button-size.enum';
import { IHtmlElementModifier } from '../../../enums/cm-block-modifier.enum';
import { IThemeColor } from '../../../enums/cm-theme-color.enum';

@Directive({
    selector: '[cm-button-new]', // TODO: remove the `-new` suffix after other buttons are removed
})
export class CmButtonDirective implements AfterViewInit, OnChanges {
    @Input()
    themeModifiers: IHtmlElementModifier[] = [];
    @Input()
    themeColor: IThemeColor = 'primary';
    @Input() size: ButtonSize = ButtonSize.regular;
    private _addedClasses: string[] = [];
    private readonly _htmlElementClassPrefix: string = 'cm-button-new';

    constructor(
        private _renderer2: Renderer2,
        private _elementRef: ElementRef<HTMLButtonElement>
    ) {}

    ngOnChanges(): void {
        this._refreshButtonClasses();
    }

    ngAfterViewInit(): void {
        this._renderer2.addClass(this._elementRef.nativeElement, this._htmlElementClassPrefix);
    }

    private _refreshButtonClasses(): void {
        // reset
        this._removeClassesFromElement();
        // add classes again
        if (this.themeModifiers?.length) {
            this.themeModifiers.forEach((themeModifier: IHtmlElementModifier) => {
                this._addClassToElement(`${this._htmlElementClassPrefix}--${themeModifier}`);
            });
        }

        if (this.themeColor) {
            this._addClassToElement(`${this._htmlElementClassPrefix}--${this.themeColor}`);
        }
        this._addClassToElement(`${this._htmlElementClassPrefix}--${this.size}`);
    }

    private _addClassToElement(className: string): void {
        this._renderer2.addClass(this._elementRef.nativeElement, className);
        this._addedClasses.push(className);
    }

    private _removeClassesFromElement(): void {
        this._addedClasses.forEach((addedClass: string) => {
            this._renderer2.removeClass(this._elementRef.nativeElement, addedClass);
        });
        this._addedClasses = [];
    }
}
