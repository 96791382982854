import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Permission } from '@cloudmarket/permissions-contract/lib/permissions.enum';

import { PermissionsService } from '../../../core/services/permissions.service';
import { BasePermissionDirective } from '../base-permission-directive';

@Directive({
    selector: '[ifHasPermission]',
})
export class IfHasPermissionDirective extends BasePermissionDirective {
    constructor(
        private _permissionService: PermissionsService,
        protected _templateRef: TemplateRef<Element>,
        protected _viewContainerRef: ViewContainerRef
    ) {
        super(_templateRef, _viewContainerRef);
    }

    @Input()
    set ifHasPermission(permission: Permission) {
        const hasAccess: boolean = this._permissionService.hasPermission(permission);

        this._setElement(hasAccess);
    }
}
