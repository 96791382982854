import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { View } from '@cloudmarket/permissions-contract/lib/permissions.enum';

import { PermissionsService } from '../../core/services/permissions.service';

import { BasePermissionDirective } from './base-permission-directive';

@Directive({
    selector: '[ifHasAccess]',
})
export class IfHasAccessDirective extends BasePermissionDirective {
    constructor(
        private _permissionService: PermissionsService,
        protected _templateRef: TemplateRef<Element>,
        protected _viewContainerRef: ViewContainerRef
    ) {
        super(_templateRef, _viewContainerRef);
    }

    @Input() set ifHasAccess(view: View) {
        const hasAccess: boolean = this._permissionService.hasAccessToView(view);

        this._setElement(hasAccess);
    }
}
