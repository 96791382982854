import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { finalize, switchMap, take } from 'rxjs/operators';

import { ROUTES_PATHS } from '../../../../shared/constants/routes.constants';
import { AlertType } from '../../../../shared/enum/alert-type.enum';
import { ButtonVariant } from '../../../../shared/interfaces/cm-button.types';
import { ILogin } from '../../interfaces/login.interface';
import {
    CloudLoginFinalizingService
} from '../../services/login/login-finalizing-strategy/cloud-login-finalizing.service';
import { LoginMs365Service } from '../../services/login/login-strategy/login-ms365.service';
import { LoginService } from '../../services/login/login-strategy/login.service';

@Component({
    selector: 'app-link-account',
    templateUrl: './link-account.component.html',
    // eslint-disable-next-linerelative-url-prefix
    styleUrls: ['./link-account.component.scss', '../auth.scss'],
})
export class LinkAccountComponent implements OnInit {
    linkAccountForm: UntypedFormGroup;
    loginModel: ILogin = {
        username: '',
        password: '',
        datetime: '',
    };
    username: string = '';
    password: string = '';
    return: string = '';
    errorMessage: string;
    isProcessing: boolean;
    parentObj: any;
    loginFailed: boolean = false;
    needHumanConfirmation: boolean = false;
    loggingIn: boolean = false;
    loginText: string = 'SIGN_IN.SIGN_IN';
    token: string;
    passwordVisible: boolean;
    failedLogins: number = 0;
    user: any;
    serverErrorText: string;
    defaultServerErrorText: string = 'The supplied details are incorrect';
    readonly alertType: typeof AlertType = AlertType;
    readonly buttonVariant: typeof ButtonVariant = ButtonVariant;
    private _authAPI: string = environment.authAPI;
    private _idToken: string = this._loginMs365Service.getIdToken();

    constructor(
        private _cloudLoginFinalizingService: CloudLoginFinalizingService,
        private _router: Router,
        public http: HttpClient,
        private _fb: UntypedFormBuilder,
        private _loginMs365Service: LoginMs365Service,
        private _loginService: LoginService,
    ) {}

    ngOnInit(): void {
        this.linkAccountFormInit();
    }

    cancelLinkAccount(): void {
        this._loginMs365Service.deleteIdToken();
        this._router.navigateByUrl(ROUTES_PATHS.LOGIN);
    }

    test(): void {
        this._router.navigate(['sign-in']);
    }

    linkAccountFormInit(): void {
        this.linkAccountForm = this._fb.group(
            {
                username: ['', Validators.required],
                password: ['', Validators.required],
                notRobot: false,
            },
            {
                updateOn: 'change',
            }
        );
    }

    handleFormSubmission(): void {
        if (!this.linkAccountForm.valid) {
            this.failedLogins += 1;
            const maxFailedLoginAttempts: number = 3;

            this.validateAllFields(this.linkAccountForm);
            if (this.failedLogins >= maxFailedLoginAttempts) {
                this.needHumanConfirmation = true;
                this.enableIsHumanValidation();
            }
        } else {
            this.linkAccount();
        }
    }

    linkAccount(): void {
        this.isProcessing = true;
        const httpErrorStatus: number = 400;
        const httpOKStatus: number = 200;

        this.setLoggingIn(true);
        this.loginModel.username = this.linkAccountForm.get('username').value;
        this.loginModel.password = this.linkAccountForm.get('password').value;
        this.serverErrorText = null;
        this.requestLinkAccountFromApi$(this.loginModel)
            .pipe(
                take(1),
                finalize(() => (this.isProcessing = false))
            )
            .subscribe({
                next: (response: HttpResponse<any>) => {
                    if (response.status === httpOKStatus) {
                        const idToken: string = this._loginMs365Service.getIdToken();

                        this.login$(idToken)
                            .pipe(switchMap(() => this._cloudLoginFinalizingService.finalizeLogin$()))
                        .subscribe();
                    }
                },
                error: (err: { status?: number; error: { Message: string } }) => {
                    if (err.status === httpErrorStatus) {
                        this.serverErrorText = err.error.Message || this.defaultServerErrorText;
                    }
                    this.setLoggingIn(false);
                }
            });
    }

    showPassword(): void {
        this.passwordVisible = !this.passwordVisible;
    }

    requestLinkAccountFromApi$(loginModel: ILogin): Observable<HttpResponse<any>> {
        const url: string = `${this._authAPI}/api/azuread-account-link`;

        const body: HttpParams = new HttpParams()
            .set('width', screen.width.toString())
            .set('height', screen.height.toString())
            .set('username', loginModel.username)
            .set('password', encodeURIComponent(loginModel.password))
            .set('adtoken', encodeURIComponent(this._idToken));

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Accept', 'application/x-www-form-urlencoded')
            .set('Cache-control', 'no-cache')
            .set('Pragma', 'no-cache');

        return this.http.post(url, body, { headers, observe: 'response' });
    }

    validateAllFields(formGroup: UntypedFormGroup): void {
        Object.keys(formGroup.controls).forEach((field: string) => {
            const control: AbstractControl = formGroup.get(field);

            if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFields(control);
            }
        });
    }

    enableIsHumanValidation(): void {
        this.linkAccountForm.get('notRobot').setValidators([Validators.requiredTrue]);
        this.linkAccountForm.get('notRobot').updateValueAndValidity();
    }

    goToResetPassword(): void {
        this._router.navigate(['/reset-password']);
    }

    login$(idToken: string): Observable<void> {
        return this._loginService.ms365Login$(idToken);
    }

    setLoggingIn(loggingIn: boolean): void {
        this.loggingIn = loggingIn;
        this.loginText = this.loggingIn ? 'SIGN_IN.SIGNING_IN' : 'SIGN_IN.SIGN_IN';
    }

    updateFailedLogins(): void {
        this.failedLogins++;
        this.loginFailed = true;
        const failedAttempt: number = 3;

        if (this.failedLogins >= failedAttempt) {
            this.needHumanConfirmation = true;
        }
    }
}
