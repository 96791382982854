// eslint-disable-next-line max-classes-per-file
import { Injectable } from '@angular/core';

import { IDictionary } from '../../shared/interfaces/dictionary.interface';

// todo: strong refactor needed
@Injectable({
    providedIn: 'root',
})
export class GlobalDataService {
    readonly alphaNumRegex: RegExp = /^[-a-zA-Z0-9\s\.]*$/;
    readonly numRegEx: RegExp = /^[0-9]*$/;
    private static _container: IDictionary<Data> = {};

    setData(name: string, content: any, count: number = 1, override: boolean = true): void {
        const container: IDictionary<Data> = GlobalDataService._container;

        if (!name) {
            throw new Error('must provide name');
        }
        if (count === 0) {
            // count < 0 -> it exists forever
            throw new Error('count mustn\'t be equal to 0');
        }
        if (container[name]) {
            if (override) {
                container[name].content = content;
                container[name].count = count;
            } else {
                throw new Error('data already exists');
            }
        } else {
            const obj: Data = new Data();

            obj.content = content;
            obj.count = count;
            container[name] = obj;
        }
    }

    getData(name: string, throwIfNotExist: boolean = false): any | null {
        if (!name) {
            throw new Error('must provide name');
        }
        const container: IDictionary<Data> = GlobalDataService._container;

        if (container[name]) {
            const obj: Data = container[name];

            if (obj.count > 0) {
                --obj.count;
                if (obj.count === 0) {
                    // eslint-disable-next-line @typescript-eslint/tslint/config
                    delete container[name];
                }
            }

            return obj.content;
        }
        if (throwIfNotExist) {
            throw new Error('data does not exist');
        }

        return null;
    }

    validateProperty(
        data: any,
        property: string,
        description: string,
        regEx: RegExp,
        checkBlank: boolean
    ): string {
        const checkVal: string = String(data[property]);

        return checkVal.length === 0 && checkBlank
            ? `<br />- Invalid ${description}.`
            : !checkVal.match(regEx)
            ? `<br />- Invalid characters in ${description}.`
            : '';
    }

    setStorageData(name: string, data: any, count: number = 1): void {
        if (!name) {
            throw new Error('must provide name');
        }
        localStorage.setItem(name, JSON.stringify({ data, count }));
    }

    getStorageData(name: string): any {
        if (!name) {
            throw new Error('must provide name');
        }
        const val: string = localStorage.getItem(name);

        if (val) {
            const result: any = JSON.parse(val);

            if (result.count > 0) {
                --result.count;
                if (result.count === 0) {
                    localStorage.removeItem(name);
                } else {
                    localStorage.setItem(
                        name,
                        JSON.stringify({ data: result.data, count: result.count })
                    );
                }
            }

            return result.data;
        }

        return null;
    }
}

class Data {
    count: number = 0;
    content: any;
}
