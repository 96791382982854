import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
    envName: 'live',
    production: true,
    clientId: '099153c2625149bc8ecb3e85e03f0022',
    serviceAPI: 'http://localhost:64477',
    authAPI: 'http://localhost:62237',
    psaIntegrationAPI: undefined,
    liveChatLicense: 10320492,
    NegotiateFunctionUrl: 'http://localhost:7071',
    SignalRServiceUrl: 'https://dev.service.signalr.net',
    CloudMarketServiceAPI: '[cloudmarket_service_api]',
    MarketPlaceAPIKey: '6f3027b9c5cc4b6dbfa9330051bab0f7',
    OrderAPIKey: '5962c0c8154b41fc9155c7f4635756a4',
    ApiManagementUrl: '[api_management_url]',
    umbracoSsoUrl: '[umbraco_sso_url]',
    academySelfServeUrl: '[academy_self_serve_url]',
    appInsights: {
        instrumentationKey: '1413ff26-7c15-41e4-992c-4a48ff1de457',
    },
    CloudServerUrl: '[cloud_server_url]',
    office365Auth: {
        clientId: 'office356Auth_clientId',
        nonce: 'office365Auth_nonce',
    },
    goCardless: {
        dropIn: {
            clientScriptUrl: '[gocardless_dropIn_clientscripturl]',
            key: '[gocardless_dropIn_key]',
        },
    },
    Translation: {
        fileHash: process.env.NG_APP_TRANSLATION_HASH,
    },
    Auth0Configuration: {
        domain: 'Auth0Configuration_domain',
        clientId: 'Auth0Configuration_clientId'
    },
    singleExperienceDashboardUrl: 'single-experience-dashboard-url',
    hostnameSignificantTokens: 'hostname-significant-tokens'
};
