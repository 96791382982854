// eslint-disable-next-line max-classes-per-file
import { Injectable } from '@angular/core';

import { STORAGE_KEYS } from '../../shared/constants/storage-keys.constants';
import { CurrencyCode } from '../../shared/enum/currency-code.enum';

import { GlobalDataService } from './global-data.service';

@Injectable({
    providedIn: 'root',
})
export class Resource {
    private _visibilityKey: string = '__visibility__';
    private _userKey: string = '__user__';
    private _dataKey: string = '__data__';

    constructor(private _globalDataService: GlobalDataService) {}

    get visibility(): VisibilityManager {
        let manager: VisibilityManager | undefined = this._globalDataService.getStorageData(
            this._visibilityKey
        );

        if (!manager) {
            manager = new VisibilityManager();
            this._globalDataService.setStorageData(this._visibilityKey, manager, -1);
        }

        return manager;
    }

    get user(): UserInfo {
        let user: UserInfo | undefined = this._globalDataService.getStorageData(this._userKey);

        if (!user) {
            user = new UserInfo();
            this._globalDataService.setStorageData(this._userKey, user, -1);
        }

        return user;
    }

    get isEuroReseller(): boolean {
        return localStorage.getItem(STORAGE_KEYS.CURRENCY_CODE) === CurrencyCode.EUR;
    }

    get data(): CommonData {
        let data: CommonData | undefined = this._globalDataService.getStorageData(this._dataKey);

        if (!data) {
            data = new CommonData();
            this._globalDataService.setStorageData(this._dataKey, data, -1);
        }

        return data;
    }

    beginUpdateUser(action: (user: UserInfo) => void): void {
        if (action) {
            const user: UserInfo | undefined = this.user;

            action(user);
            this._globalDataService.setStorageData(this._userKey, user, -1);
        }
    }

    beginUpdateVisibility(action: (visibility: VisibilityManager) => void): void {
        if (action) {
            const visibility: VisibilityManager | undefined = this.visibility;

            action(visibility);
            this._globalDataService.setStorageData(this._visibilityKey, visibility, -1);
        }
    }

    beginUpdateData(action: (data: CommonData) => void): void {
        if (action) {
            const data: CommonData | undefined = this.data;

            action(data);
            this._globalDataService.setStorageData(this._dataKey, data, -1);
        }
    }

    reset(): void {
        this._globalDataService.setStorageData(this._visibilityKey, new VisibilityManager(), -1);
        this._globalDataService.setStorageData(this._userKey, new UserInfo(), -1);
        this._globalDataService.setStorageData(this._dataKey, new CommonData(), -1);
    }
}

export class VisibilityManager {}

export class UserInfo {
    endUserAdminId: string = null;
    isEndUserAdmin: boolean = false;
    userName: string = null;
    userType: string = null;
    isEndUser: boolean = false;
    emailAddress: string = null;
    cloudMarketId: string = null;
}

export class CommonData {
    organisationName: string = null;
}
