import { NgModule } from '@angular/core';

import { DisableWhenRequestDirective } from './directives/disable-when-request.directive';
import { DisableWhenSubmitRequestDirective } from './directives/disable-when-submit-request.directive';
import { ShowWhenRequestDirective } from './directives/show-when-request.directive';
import { ShowWhenSubmitRequestDirective } from './directives/show-when-submit-request.directive';

@NgModule({
    declarations: [
        ShowWhenRequestDirective,
        ShowWhenSubmitRequestDirective,
        DisableWhenRequestDirective,
        DisableWhenSubmitRequestDirective,
    ],
    exports: [
        ShowWhenRequestDirective,
        ShowWhenSubmitRequestDirective,
        DisableWhenRequestDirective,
        DisableWhenSubmitRequestDirective,
    ],
})
export class BlockUiModule {}
