import { Component } from '@angular/core';

import { AbstractPopup } from '../../../../../../feature-modules/popups/classes/abstract-popup.class';
import { SignInCommand } from '../../sign-in-command.enum';

import { ISwitchUserPopupParams } from './switch-user-popup-params.interface';

@Component({
    selector: 'cm-switch-user-popup',
    templateUrl: './switch-user-popup.component.html',
})
export class SwitchUserPopupComponent extends AbstractPopup<ISwitchUserPopupParams> {
    masterControl: boolean = true;

    continue(): void {
        this.eventHandler.executeCommand(SignInCommand.switchUser, {
            masterControl: this.masterControl,
        });
        this.close();
    }
}
