import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_ENDPOINTS } from '../../shared/constants/api/api-endpoints.constants';
import { IDictionary } from '../../shared/interfaces/dictionary.interface';
import { GAService } from '../../shared/services/ga-service/ga-service';
import { InteractionType } from '../../shared/services/ga-service/ga-visitor-interaction';
import { IExecutionResult } from '../interfaces/execution-result.interface';

import { AuthorizedHttpService } from './http/authorized-http.service';

@Injectable({
    providedIn: 'root',
})
export class DownloadService {
    constructor(
        private _authorizedHttpService: AuthorizedHttpService,
        private _gaService: GAService
    ) {}

    generateGuid$(downloadId: number, userName: string): Observable<IExecutionResult<string>> {
        return this._authorizedHttpService.post$(API_ENDPOINTS.DOWNLOAD.GENERATE_GUID, {
            DownloadId: downloadId,
            UserName: userName,
        });
    }

    downloadWithUrl$(
        apiUrl: string,
        info: IDictionary<string | string[]>,
        fileName: string = ''
    ): void {
        this._authorizedHttpService.downloadBlobAsFile$(apiUrl, fileName, info).subscribe();
    }

    download(info: IDictionary<string | string[]>, fileName: string = ''): void {
        this._authorizedHttpService
            .downloadBlobAsFile$(API_ENDPOINTS.DOWNLOAD.DOWNLOAD, fileName, info)
            .subscribe(() => {
                this._gaService.visitorInteraction(InteractionType.downloadFile, {
                    file_type: 'file',
                    file_name: fileName,
                });
            });
    }
}

export interface IDownloadInfo {
    Id: number;
    Guid: string;
}
