import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { View } from '@cloudmarket/permissions-contract/lib/permissions.enum';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';

import { IPermissionsResponse } from '../../../../../core/interfaces/permissions-response.interface';
import { ApiPermissionsService } from '../../../../../core/services/api/api-permissions.service';
import { SsoAuthService } from '../../../../../core/services/sso-auth/sso-auth.service';
import { SSO } from '../../../../../shared/constants/sso.constants';
import { UserSwitcherService } from '../../../../../shared/services/user-switcher.service';
import { WhitelabelService } from '../../../../../shared/services/whitelabel.service';
import { PopupService } from '../../../../popups/services/popup.service';
import { ILoginState } from '../../../interfaces/login-state.interface';
import { ILoginStrategy } from '../../../interfaces/login-strategy.interface';
import { ILoginUserResponse } from '../../../interfaces/login-user-response.interface';
import { ILogin } from '../../../interfaces/login.interface';
import { SignInCommand } from '../../../pages/sign-in/sign-in-command.enum';
import { CloudLoginFinalizingService } from '../login-finalizing-strategy/cloud-login-finalizing.service';

import { LoginService } from './login.service';


@Injectable({
    providedIn: 'root',
})
export class LoginJumpService implements ILoginStrategy {
    status$: Observable<ILoginState>;
    private _params: Params;
    private _status$: BehaviorSubject<ILoginState> = new BehaviorSubject<ILoginState>({ isProcessed: false });

    constructor(
        private _ssoAuthService: SsoAuthService,
        private _apiPermissionsService: ApiPermissionsService,
        private _popupService: PopupService,
        private _loginService: LoginService,
        private _userSwitcherService: UserSwitcherService,
        private _whiteLabelService: WhitelabelService,
        private _loginCloudService: CloudLoginFinalizingService,
    ) {
        this.status$ = this._status$.asObservable();
    }

    login$(): Observable<void> {
        this._status$.next({ isProcessed: true });

        return this._handleSysOpsLogin$();
    }

    isSysOpsLogin(params: Params): boolean {
        const sysOpsTokenKey: string = 't';

        this._params = params;

        return Object.keys(params).indexOf(sysOpsTokenKey) !== -1;
    }

    private _handleSysOpsLogin$(): Observable<void> {
        const loginModel: ILogin = {
            username: '',
            password: '',
            datetime: '',
            mfaKey: '',
            mfaToken: '',
            cmToken: this._params.t,
            AdminId: this._params.a,
        };

        return this._onSubmitSysOpsToken$(loginModel);
    }

    private _onSubmitSysOpsToken$(loginData: ILogin): Observable<void> {
        return this._loginService
            .sysOpsLoginToken$(loginData)
            .pipe(
                switchMap(() => this._loginService.getLoginUser$()
                    .pipe(
                        tap((loginUserResponse: ILoginUserResponse) =>
                            this._status$.next({ isProcessed: true, username: loginUserResponse.userName })))),
                switchMap((loginUserResponse: ILoginUserResponse) =>
                    forkJoin([
                        of(loginUserResponse),
                        this._loginService.getLoginFlow$(loginUserResponse.userName),
                    ]),
                ),
                map(([loginUserResponse, loginFlow]: [ILoginUserResponse, string]) => {
                    if (loginFlow === SSO.LOGIN_FLOW.SSO) {
                        this._ssoAuthService.setSsoLoginFlowFromJump();
                    } else {
                        this._ssoAuthService.setLegacyLoginFlow();
                    }

                    this._checkHasAccessThenSwitchUserOrContinueLogin(loginUserResponse);

                    return null;
                }));
    }

    private _checkHasAccessThenSwitchUserOrContinueLogin(loginUserResponse: ILoginUserResponse): void {
        this._apiPermissionsService
            .getPermissions$()
            .subscribe((permissions: IPermissionsResponse) => {
                const hasAccessToSwitchUser: boolean = permissions.usersPermissions?.views.some(
                    (userView: View) => userView === View.switchUser,
                );

                this._switchUserOrContinueLogin(hasAccessToSwitchUser, loginUserResponse);
            });
    }

    private _switchUserOrContinueLogin(hasAccessToSwitchUser: boolean, loginUserResponse: ILoginUserResponse): void {
        if (hasAccessToSwitchUser) {
            this._open(loginUserResponse);

            return;
        }

        this._loginCloudService.finalizeLogin$(this._params)
            .pipe(
                finalize(() => this._status$.next(null)),
            )
            .subscribe();
    }

    private _open(user: ILoginUserResponse): void {
        this._popupService.show(
            {
                executeCommand: (command: string, data: { masterControl: boolean }) => {
                    if (command === SignInCommand.switchUser) {
                        this._continueSwitch(data.masterControl);
                    }
                },
            },
            'switch-user-popup',
            {
                user,
            },
        );
    }

    private _continueSwitch(masterControl: boolean): void {
        if (masterControl) {
            this._loginCloudService.finalizeLogin$(this._params)
                .pipe(
                    finalize(() => this._status$.next(null)),
                )
                .subscribe();

            return;
        }

        this._whiteLabelService.resolve()
            .subscribe(() => {
                this._userSwitcherService.switchBetweenUserTypes();
                this._status$.next(null);
            });
    }
}
