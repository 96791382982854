import { AfterViewInit, ChangeDetectorRef, Component, Input, Self } from '@angular/core';
import {
    AbstractControl,
    UntypedFormControl,
    NgControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

import { AbstractControlValueAccessor } from '../../../../../../shared/classes/abstract-control-value-accessor.class';
import { IRadioInputOptions } from '../../interfaces/radio-input-options.interface';

@Component({
    selector: 'cm-radios-input',
    templateUrl: './cm-radios-input.component.html',
    styleUrls: ['./cm-radios-input.component.scss'],
})
export class CmRadiosInputComponent
    extends AbstractControlValueAccessor<string | number>
    implements AfterViewInit
{
    @Input() label: string = 'field';
    @Input() id: string = '1';
    @Input() options: IRadioInputOptions;
    @Input() direction: 'vertical' | 'horizontal' = 'vertical';
    @Input() optionDescriptionAlignment: 'sameLine' | 'beneath' = 'beneath';
    @Input() description: string = '';
    @Input() isFullWidth: boolean = false;
    isValueOptional: boolean = false;

    constructor(
        @Self() public ngControl: NgControl,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super();

        if (ngControl) {
            ngControl.valueAccessor = this;
        }
    }

    ngAfterViewInit(): void {
        this.setIsValueOptional();
        this._changeDetectorRef.detectChanges();
    }

    setIsValueOptional(): void {
        const dummyControl: AbstractControl = new UntypedFormControl();
        const validatorFn: ValidatorFn | null = this.ngControl.control.validator;
        const validator: ValidationErrors = validatorFn ? validatorFn(dummyControl) : {};

        this.isValueOptional = !(validator && validator.required);
    }
}
