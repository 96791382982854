import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IPagedResult } from '../../../../models/paged-result.model';
import { IServiceListItem } from '../../../../pages/customer/user/cust-service-list/interfaces/customer-service-list.types';
import { API_ENDPOINTS } from '../../../../shared/constants/api/api-endpoints.constants';
import { IDictionary } from '../../../../shared/interfaces/dictionary.interface';
import { IExecutionResult } from '../../../interfaces/execution-result.interface';
import { AuthorizedHttpService } from '../../http/authorized-http.service';
import { IServiceModel } from '../types/service-model.interface';

import { IActivityLog } from './types/activity-log.interface';
import { IAllowedCustomers } from './types/allowed-customers.interface';
import { ICustomerBundledService } from './types/customer-bundled-service.interface';
import { ICustomerDetailsModel } from './types/customer-details-model.interface';
import { ICustomerDetails } from './types/customer-details.interface';
import { ICustomerRequest } from './types/customer-request.interface';
import { ICustomerResponse } from './types/customer-response.interface';
import { ICustomerSuggestion } from './types/customer-suggestion.interface';
import { ICustomer } from './types/customer.interface';

@Injectable({
    providedIn: 'root',
})
export class ApiCustomerService {
    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    getCustomers$(
        params: IDictionary<string | string[]>
    ): Observable<IExecutionResult<IPagedResult<ICustomerDetailsModel[]>>> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.CUSTOMERS.GET_CUSTOMERS, params);
    }

    getCustomerEndUserAdmin$(): Observable<IExecutionResult<ICustomerDetailsModel>> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.CUSTOMERS.GET_END_USER_ADMIN);
    }

    getCustomerSuggestions$(
        params: IDictionary<string | string[]>
    ): Observable<IExecutionResult<ICustomerSuggestion[]>> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.CUSTOMERS.GET_SUGGESTIONS, params);
    }

    getCustomersOfReseller$(productId: string | number): Observable<IAllowedCustomers> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.CUSTOMERS.GET_CUSTOMERS_OF_RESELLER(productId)
        );
    }

    getCustomerById$(organisationId: string): Observable<IExecutionResult<ICustomer>> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.CUSTOMERS.GET_CUSTOMER(organisationId));
    }

    getCustomerServices$(): Observable<IExecutionResult<string[]>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.CUSTOMERS.GET_CUSTOMER_SERVICES
        );
    }

    getServices$(id: string): Observable<IExecutionResult<IServiceModel[]>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.CUSTOMERS.GET_SERVICES(id)
        );
    }

    exportServices$(id: string): Observable<HttpResponse<Blob>> {
        return this._authorizedHttpService.downloadBlobAsFile$(
            API_ENDPOINTS.CUSTOMERS.EXPORT_SERVICES(id)
        );
    }

    checkIfCustomerCanBeDeleted$(
        organisationId: string
    ): Observable<IExecutionResult<void, IDictionary<string[]>>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.CUSTOMERS.CHECK_DELETE_CUSTOMER(organisationId)
        );
    }

    checkIfCustomerCanDeleteOffice365$(
        orgId: string,
        groupId: string
    ): Observable<IExecutionResult<string>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.CUSTOMERS.CHECK_DELETE_OFFICE365(orgId, groupId)
        );
    }

    getUserCountById$(organisationId: string): Observable<IExecutionResult<number>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.USER.GET_CUSTOMER_USER_COUNT(organisationId)
        );
    }

    suspendCustomer$(organisationId: string): Observable<IExecutionResult<ICustomer>> {
        return this._authorizedHttpService.post$(API_ENDPOINTS.CUSTOMERS.SUSPEND_CUSTOMER(organisationId), {});
    }

    reactivateCustomer$(organisationId: string): Observable<IExecutionResult<void>> {
        return this._authorizedHttpService.post$(API_ENDPOINTS.CUSTOMERS.REACTIVATE_CUSTOMER(organisationId), {});
    }

    hasOffice365Account$(id: string): Observable<IExecutionResult<boolean>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.CUSTOMERS.HAS_OFFICE365_ACCOUNT(id)
        );
    }

    hasAzure$(organisationId: string): Observable<IExecutionResult<boolean>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.CUSTOMERS.HAS_AZURE(organisationId)
        );
    }

    hasCustomerServicesByOrganisationId$(
        organisationId: string
    ): Observable<IExecutionResult<boolean>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.CUSTOMERS.HAS_ANY_SERVICES(organisationId)
        );
    }

    getCustomerServicesByOrganisationId$(
        organisationId: string
    ): Observable<IExecutionResult<ICustomerBundledService[]>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.CUSTOMERS.GET_ALL_CUSTOMER_SERVICES(organisationId)
        );
    }

    updateCustomerName$(
        selectedCustomerId: string,
        name: string
    ): Observable<IExecutionResult<boolean>> {
        return this._authorizedHttpService.post$(
            API_ENDPOINTS.CUSTOMERS.UPDATE_CUSTOMER_NAME(selectedCustomerId),
            { NewName: name }
        );
    }

    getActivityOfCustomers$(
        organisationid: string,
        userId?: string
    ): Observable<IExecutionResult<IActivityLog[]>> {
        const url: string =
            userId === undefined
                ? API_ENDPOINTS.CUSTOMERS.GET_ACTIVITY_LOG(organisationid)
                : API_ENDPOINTS.CUSTOMERS.GET_ACTIVITY_LOG_FOR_USER(organisationid, userId);

        return this._authorizedHttpService.get$(url);
    }

    getBundleProducts$(organisationId: string): Observable<IExecutionResult<boolean>> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.CUSTOMERS.GET_BUNDLE_PRODUCTS(organisationId))
    }

    getGroupServices$(organisationId: string):  Observable<IExecutionResult<IServiceModel[]>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.CUSTOMERS.GET_GROUP_SERVICES(organisationId)
        )
    }

    allowMigration$(organisationId: string): Observable<IExecutionResult<boolean>> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.CUSTOMERS.MIGRATION_ALLOW(organisationId))
    }

    pendingMigration$(organisationId: string): Observable<IExecutionResult<boolean>> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.CUSTOMERS.MIGRATION_PENDING(organisationId))
    }

    hasBundledEmailSecurity$(organisationId: string): Observable<IExecutionResult<boolean>> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.CUSTOMERS.HAS_BUNDLED_EMAIL_SECURITY(organisationId))
    }

    hasBundledEmailSecurityForUser$(organisationId: string, userId: string): Observable<IExecutionResult<boolean>> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.CUSTOMERS.HAS_BUNDLED_EMAIL_SECURITY_FOR_USER(organisationId, userId))
    }

    getCustomer$(customerID: string): Observable<ICustomerDetails> {
        return this._authorizedHttpService.get$<ICustomerDetails>(
            API_ENDPOINTS.CUSTOMERS_SERVICE.GET_CUSTOMER(customerID)
        );
    }

    createCustomer$(customer: ICustomerRequest): Observable<ICustomerDetails> {
        return this._authorizedHttpService
            .post$<ICustomerResponse>(API_ENDPOINTS.CUSTOMERS_SERVICE.CREATE_CUSTOMER, customer)
            .pipe(map((resp: ICustomerResponse) => this._createCustomerResponse(resp, customer)));
    }

    updateCustomer$(customerID: string, customer: ICustomerRequest): Observable<ICustomerDetails> {
        return this._authorizedHttpService
            .put$<ICustomerResponse>(API_ENDPOINTS.CUSTOMERS_SERVICE.UPDATE_CUSTOMER(customerID), customer)
            .pipe(map((resp: ICustomerResponse) => this._createCustomerResponse(resp, customer)));
    }

    getOffice365Products$(id: string, officeGroupId: number): Observable<IExecutionResult<IServiceListItem[]>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.CUSTOMERS.GET_CUSTOMER_OFFICE365_PRODUCTS(id, officeGroupId)
        )
    }

    private _createCustomerResponse(
        resp: ICustomerResponse,
        customer: ICustomerRequest
    ): ICustomerDetails {
        return {
            id: resp.organisationId,
            name: customer.name,
            resellerId: null,
            customerGuidId: resp.customerId,
            customerTypes: customer.organisationTypes,
        };
    }
}
