import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IExecutionResult } from '../../../../core/interfaces/execution-result.interface';
import { AuthorizedHttpService } from '../../../../core/services/http/authorized-http.service';
import { BaseUrl } from '../../../../shared/urls/base.url';

import { ICloudMarketPopupSchema } from './cm-popup.types';

@Injectable({
    providedIn: 'root',
})
export class CmPopupService {
    readonly apiUrl: string = `${BaseUrl.baseUrl}`;

    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    fetchData$(dataPath: string): Observable<unknown> {
        return this._authorizedHttpService
            .get$<IExecutionResult<unknown>>(`${BaseUrl.baseUrl}${dataPath}`)
            .pipe(
                this._authorizedHttpService.handleApiErrorOperator$,
                this._authorizedHttpService.mapResponseOperator$<unknown>()
            );
    }

    fetchSchema$(schemaPath: string): Observable<ICloudMarketPopupSchema> {
        return this._authorizedHttpService
            .get$<IExecutionResult<ICloudMarketPopupSchema>>(`${BaseUrl.baseUrl}${schemaPath}`)
            .pipe(
                this._authorizedHttpService.handleApiErrorOperator$,
                this._authorizedHttpService.mapResponseOperator$<ICloudMarketPopupSchema>()
            );
    }
}
