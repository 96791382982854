export enum AzureManageFlow {
    usage = 0,
    billing = 1,
    resourceGroup = 2,
}

export class AzureManagement {
    flow: typeof AzureManageFlow = AzureManageFlow;
    selectedFlow: AzureManageFlow = AzureManageFlow.usage;
    level: string;
    private _organisationId: any;
    private _userId: any;
    private _serviceId: any;

    set organisationId(val: string) {
        this._organisationId = val;
    }

    get organisationId(): string {
        return this._organisationId;
    }

    set userId(val: string) {
        this._userId = val;
    }

    get userId(): string {
        return this._userId;
    }

    set serviceId(val: string) {
        this._serviceId = val;
    }

    get serviceId(): string {
        return this._serviceId;
    }

    selectFlow(flow: AzureManageFlow): void {
        this.selectedFlow = flow;
    }

    isSelectedUsageFlow(): boolean {
        return this.selectedFlow === AzureManageFlow.usage;
    }

    isSelectedBillingFlow(): boolean {
        return this.selectedFlow === AzureManageFlow.billing;
    }

    isSelectedResourceGroupFlow(): boolean {
        return this.selectedFlow === AzureManageFlow.resourceGroup;
    }
}
