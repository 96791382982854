import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Permission } from '@cloudmarket/permissions-contract';
import { TranslateService } from '@ngx-translate/core';

import { ApiFeatureFlagsService } from '../../../../../core/services/api/api-feature-flags.service';
import { ApiCustomerService } from '../../../../../core/services/api/customer/api-customer.service';
import { IAllowedCustomers } from '../../../../../core/services/api/customer/types/allowed-customers.interface';
import { ICustomer } from '../../../../../core/services/api/customer/types/customer.interface';
import { GlobalDataService } from '../../../../../core/services/global-data.service';
import { GlobalEventService } from '../../../../../core/services/global-event.service';
import { PermissionsService } from '../../../../../core/services/permissions.service';
import { Resource, UserInfo } from '../../../../../core/services/resource.service';
import { ServiceService } from '../../../../../core/services/service.service';
import { AbstractPopup } from '../../../../../feature-modules/popups/classes/abstract-popup.class';
import { PopupService } from '../../../../../feature-modules/popups/services/popup.service';
import { IAddedServiceModel } from '../../../../../models/customer/added-service.interface';
import { ICustomerStatusModel } from '../../../../../models/customer/customer-status.model';
import { AddServiceContextEnum } from '../../../../../models/service/service.model';
import { NCE_PICKER_SERVICES } from '../../../../../shared/constants/nce-picker-services.constants';
import { ROUTES_PATHS } from '../../../../../shared/constants/routes.constants';
import { AlertType } from '../../../../../shared/enum/alert-type.enum';
import {
    ProductOrderLimit,
    Service,
    ServiceInstanceStatus,
} from '../../../../../shared/enum/service-type.enum';
import { IListCustomerParams } from '../interfaces/list-customer-params.interface';

import { CustomerStatusLabel } from './enums/item-status.enum';
import { LIST_CUSTOMER_FORM_FIELDS, listCustomerFormConfig } from './forms/list-customer-form.config';
import { AddServiceInput, ICustomerEntity } from './interfaces/list-customer.types';

@Component({
    selector: 'app-list-customer',
    styleUrls: ['./list-customer-modal.component.scss'],
    templateUrl: './list-customer-modal.component.html',
})
export class ListCustomerModalComponent
    extends AbstractPopup<IListCustomerParams>
    implements OnInit
{
    selectCustomerForm: FormGroup;
    existingCustomers: ICustomerEntity[];
    existingCustomersNamesList: string[];
    noResultsFound: boolean;
    customerStatus: typeof CustomerStatusLabel = CustomerStatusLabel;
    services: IAddedServiceModel[] = [];
    serviceName: string;
    selectedCustomerId: string;
    newCustomer: boolean = false;
    loading: boolean = false;
    errorMessage: string;
    addServiceInput: AddServiceInput;
    productId: number;
    serviceId: number = -1;
    subId: number = 0;
    isNewJourney: number = 0;
    journeyType: number | boolean;
    listingId: string;
    signedInUser: UserInfo;
    isReady: boolean = false;
    allowedToAdd: boolean = true;
    hasPermissionToAddCustomers: boolean;
    canShowContinueToAddProduct: boolean = true;
    canShowAddNewCustomer: boolean = false;
    hintPopoverText: string = '';
    labelText: string = '';
    readonly formFields: typeof LIST_CUSTOMER_FORM_FIELDS = LIST_CUSTOMER_FORM_FIELDS;
    readonly routesPaths: typeof ROUTES_PATHS = ROUTES_PATHS;
    readonly alertType: typeof AlertType = AlertType;
    private _originalList: ICustomerEntity[];

    constructor(
        private _router: Router,
        private _eventService: GlobalEventService,
        private _popupService: PopupService,
        private _dataService: GlobalDataService,
        private _serviceService: ServiceService,
        private _featureFlagService: ApiFeatureFlagsService,
        private _resource: Resource,
        private _apiCustomerService: ApiCustomerService,
        private _permissionsService: PermissionsService,
        private _translateService: TranslateService,
        private _fb: FormBuilder
    ) {
        super();
        this.signedInUser = this._resource.user;
    }

    updateParams(val: IListCustomerParams): void {
        if (!val) {
            return;
        }

        this.productId = val.id;
        this.serviceId = val.serviceId as number;
        this.subId = val.subId;
        this.serviceName = val.serviceName;
        this.isNewJourney = val.newJourney as number;
        this.journeyType = val.journeyType;
        this.listingId = val.listingId;
    }

    addNewCustomer(): void {
        const model: ICustomer = {
            Id: '',
            Name: '',
        };

        this.close();
        model.Name = this.selectCustomerForm.get(this.formFields.selectCustomerInput).value;
        this._dataService.setData('newCustomerData', model);
        this._dataService.setData('newCustomer', true);
        this._dataService.setData('addCustomerCallback', this.continueAddService.bind(this));
        this._router.navigate(['customers/add']);
    }

    continueAddService(): void {
        const crossSellFeatureFlag: boolean =
            this._featureFlagService.isFeatureFlagEnabled('CrossSell');
        const serviceRouteName: string = this._serviceService.getServiceRouteName(
            this.serviceId,
            this.subId
        );
        const listingId: string = this.listingId || '';
        const giacomElement: HTMLElement = document.getElementById('giacom');

        if (this.signedInUser.isEndUserAdmin) {
            this.selectedCustomerId = this.signedInUser.endUserAdminId;
        }

        const isNewCustomer: boolean = this._dataService.getData('newCustomer');

        if (isNewCustomer) {
            const model: ICustomer = this._dataService.getData('newCustomerData');

            this.selectedCustomerId = model.Id;
        }

        if (this.serviceId !== -1) {
            if (this.serviceId === Service.AcronisFilesCloud) {
                this._router.navigate([
                    'customers/customer',
                    this.selectedCustomerId,
                    'add-services',
                    this.serviceId,
                    'Add',
                    'customer',
                ]);
                this.close();

                return;
            }

            if (this.serviceId === Service.Exclaimer) {
                this._router.navigate([
                    this.routesPaths.CUSTOMER.ORGANISATION.VIEW(this.selectedCustomerId),
                    this.routesPaths.CUSTOMER.ACTIONS.ADD_SERVICE,
                    this.routesPaths.SERVICES.EXCLAIMER,
                ]);
                this.close();

                return;
            }

            this.addServiceInput.serviceid = this.serviceId;
            this.addServiceInput.id = this.selectedCustomerId;
            this.addServiceInput.customerId = this.selectedCustomerId;
            this.addServiceInput.context = AddServiceContextEnum.customer;
            this.addServiceInput.subId = this.subId;

            this._dataService.setData('addServiceContext', this.addServiceInput);
            this._dataService.setData('secure-email-exchange-model', {
                customerId: this.selectedCustomerId,
            });

            this._dataService.setData('add-bitdefender-service-data', {
                level: 'customer',
                organisationId: this.selectedCustomerId,
                userId: 0,
                mode: 'add',
            });

            const serviceSupportsNce: boolean = NCE_PICKER_SERVICES.includes(serviceRouteName);

            if (serviceSupportsNce) {
                this._router.navigate(
                    ['customers/customer', this.selectedCustomerId, 'nce-question'],
                    {
                        queryParams: {
                            listingId,
                            serviceRouteName,
                            isNewJourney: this.isNewJourney,
                        },
                    }
                );
            } else if (crossSellFeatureFlag && serviceRouteName === 'office-365') {
                this._router.navigate([
                    'customers/customer',
                    this.selectedCustomerId,
                    'cs',
                    'add-service',
                    'bde50b4e-7d3d-feac-5455-91dec1368d51',
                ]);
            } else if (serviceRouteName === 'cloud-server') {
                this._router.navigate([
                    'customers/customer',
                    this.selectedCustomerId,
                    'cs',
                    'add-service',
                    '10d0b1ee-d2d5-11e9-85c2-00155d806b15',
                ]);
            } else if (serviceRouteName === 'azure' && this.isNewJourney) {
                this._router.navigate([
                    'customers/customer',
                    this.selectedCustomerId,
                    'cs',
                    'add-service',
                    'aa51c0ad-46f7-4e68-afc6-d2d0be0e45b9',
                ]);
            } else if (this.isNewJourney && listingId) {
                this._router.navigate([
                    'customers/customer',
                    this.selectedCustomerId,
                    'cs',
                    'add-service',
                    listingId,
                ]);
            } else if (serviceRouteName) {
                this._router.navigate([
                    'customers/customer',
                    this.selectedCustomerId,
                    'add-service',
                    serviceRouteName,
                ]);
            }
        } else {
            this._popupService.show(null, 'select-service', this.selectedCustomerId);
        }

        if (giacomElement) {
            giacomElement.style.overflow = null;
        }

        this.close();
    }

    selectCustomer(object: ICustomerEntity): void {
        if (!object) {
            return;
        }

        this.selectCustomerForm.get(this.formFields.selectCustomerInput).setValue(object.name);
        this.selectedCustomerId = object.value;
        this.newCustomer = false;

        this._updateButtonsState();
    }

    showCustomerSelection(): boolean {
        return this.allowedToAdd;
    }

    onNoResultsFound(value: boolean): void{
        this.noResultsFound = value;
    }

    ngOnInit(): void {
        this._initForm();
        this._initSelectCustomerInputValueChangesWatcher();
        this._initData();
        this._initEvents();
    }

    private _initForm(): void {
        this.selectCustomerForm = this._fb.group(
            listCustomerFormConfig({
                selectCustomerInput: ''
            })
        );
    }

    private _initSelectCustomerInputValueChangesWatcher(): void {
        this.selectCustomerForm.get(this.formFields.selectCustomerInput).valueChanges
            .subscribe((customerName: string) => {
                this.newCustomer = !this.existingCustomersNamesList.includes(customerName);
                this._updateButtonsState();
            })
    }

    private _initData(): void {
        this._getCustomers();
        this.hasPermissionToAddCustomers = this._permissionsService.hasPermission(
            Permission.addOrEditCustomers
        );

        this.labelText = this.hasPermissionToAddCustomers
            ? this._translateService.instant('ADD_CUSTOMER.SELECT_OR_ADD_A_CUSTOMER')
            : this._translateService.instant('ADD_CUSTOMER.SELECT_A_CUSTOMER');

        this.hintPopoverText = this.hasPermissionToAddCustomers
            ? this._translateService.instant('ADD_CUSTOMER.HINT_SELECT_OR_ADD_A_CUSTOMER')
            : this._translateService.instant('ADD_CUSTOMER.HINT_SELECT_A_CUSTOMER');

        this.addServiceInput = { context: '', id: '', serviceid: '' };

        this.selectedCustomerId = '-1';
    }

    private _initEvents(): void {
        this._eventService.subscribe('marketplaceserviceid', (data: number) => {
            this.serviceId = data;
        });
    }

    private _getCustomers(): void {
        this._apiCustomerService
            .getCustomersOfReseller$(this.productId)
            .subscribe((response: IAllowedCustomers) => {
                this._originalList = response.Customers.map((item: ICustomerStatusModel) => {
                    const disabled: boolean =
                        item.Status !== null &&
                        (item.Status !== ServiceInstanceStatus.Active ||
                            (item.Status === ServiceInstanceStatus.Active &&
                                response.OrderLimit === ProductOrderLimit.OncePerCustomer));
                    const status: CustomerStatusLabel = this._getItemStatus(item);

                    return {
                        status,
                        name: item.Name,
                        value: item.Id,
                        isDisabled: disabled,
                    };
                });

                this.existingCustomers = this._originalList;
                this.existingCustomersNamesList = this.existingCustomers.map(
                    (customer: ICustomerEntity) => customer.name
                );
                this.allowedToAdd = response.AllowedToAdd;
                this.isReady = true;
            });
    }

    private _updateButtonsState(): void {
        this.canShowAddNewCustomer = this.hasPermissionToAddCustomers && this.newCustomer;

        this.canShowContinueToAddProduct = !this.canShowAddNewCustomer;
    }

    private _getItemStatus(item: ICustomerStatusModel): CustomerStatusLabel {
        switch (item.Status) {
            case ServiceInstanceStatus.Activating:
            case ServiceInstanceStatus.Deleting:
                return CustomerStatusLabel.amber;
            case ServiceInstanceStatus.ActivationFailed:
            case ServiceInstanceStatus.DeletionFailed:
                return CustomerStatusLabel.red;
            case ServiceInstanceStatus.Active:
                return CustomerStatusLabel.checked;
            default:
                return CustomerStatusLabel.empty;
        }
    }
}
