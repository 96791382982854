import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';

import { KeyboardKey } from '../../../../shared/enum/keyboard-key.enum';
import { AbstractPopup } from '../../../popups/classes/abstract-popup.class';

@Component({
    selector: 'gdpr-statement',
    templateUrl: './gdpr-statement-popup.html',
    styleUrls: ['./gdpr-statement-popup.scss'],
})
export class GdprStatementPopupComponent extends AbstractPopup<any> implements AfterViewInit {
    @ViewChild('Background') background: ElementRef;
    @ViewChild('PersonalData') personalData: ElementRef;
    @ViewChild('ThirdParties') thirdParties: ElementRef;
    @ViewChild('Controls') controls: ElementRef;
    @ViewChild('Summary') summary: ElementRef;
    modalAnimation: boolean = false;
    model: any;
    scrollLinks: { text: string; element: keyof GdprStatementPopupComponent }[] = [
        { text: 'Background', element: 'background' },
        { text: 'Personal Data', element: 'personalData' },
        { text: 'Third Parties', element: 'thirdParties' },
        { text: 'Technical and Organisational Controls', element: 'controls' },
        { text: 'Summary', element: 'summary' },
    ];

    constructor() {
        super();
    }

    ngAfterViewInit(): void {
        const animationDelay: number = 150;

        setTimeout(() => {
            if (!this.modalAnimation) {
                this.modalAnimation = true;
            }
        }, animationDelay);
    }

    cancel(): void {
        this.close();
    }

    updateParams(val: any): void {
        if (val) {
            this.model = val;
        }
    }

    getMaxHeight(): number {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - 200;
    }

    @HostListener('window:keydown', ['$event']) keyboardInput(event: KeyboardEvent): void {
        if (event.key === KeyboardKey.escape) {
            this.cancel();
        }
    }

    scrollTo(scrollLink: any): void {
        const el: ElementRef<HTMLElement> = this[scrollLink.element];

        el.nativeElement.scrollIntoView();
    }
}
