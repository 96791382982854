import { Pipe, PipeTransform } from '@angular/core';

import { GiacomDateService } from '../../../core/services/date-time/giacom-date.service';

@Pipe({ name: 'giacomDate' })
export class GiacomDatePipe implements PipeTransform {
    constructor(private _giacomDateService: GiacomDateService) {}

    transform(val: Date | string, todaysDateAsString: boolean = false): string {
        return this._giacomDateService.transform(val, todaysDateAsString);
    }
}
