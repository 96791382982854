import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { PopUpDetails } from '../../../../feature-modules/popups/classes/pop-up-details.class';
import { PopupService } from '../../../../feature-modules/popups/services/popup.service';
import { GAFormModule } from '../../../../shared/directives/ga-form/ga-form.module';
import { CmAlertModule } from '../../cm-alert/cm-alert.module';
import { CmModalModule } from '../../cm-modal/modal.module';
import { DatetimePickerControlModule } from '../../forms/form-controls/datetime-picker-control/datetime-picker-control.module';

import { EmailSecurityDisasterRecoveryModalComponent } from './email-security-disaster-recovery-modal.component';

@NgModule({
    declarations: [EmailSecurityDisasterRecoveryModalComponent],
    imports: [
        CmAlertModule,
        CmModalModule,
        CommonModule,
        ReactiveFormsModule,
        GAFormModule,
        TranslateModule,
        DatetimePickerControlModule,
    ],
    exports: [EmailSecurityDisasterRecoveryModalComponent],
})
export class EmailSecurityDisasterRecoveryModalModule {
    static popupRegistered: boolean = false;
    constructor(private _popupService: PopupService) {
        if (EmailSecurityDisasterRecoveryModalModule.popupRegistered) {
            return;
        }

        EmailSecurityDisasterRecoveryModalModule.popupRegistered = true;

        this._popupService.registerPopups({
            'email-security-disaster-recovery': new PopUpDetails(
                EmailSecurityDisasterRecoveryModalComponent,
                'services',
                'services/email-security/user/disaster-recovery'
            ),
        });
    }
}
