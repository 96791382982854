import { Component, Input, OnInit } from '@angular/core';

import { IExecutionResult } from '../../../core/interfaces/execution-result.interface';
import { GlobalEventService } from '../../../core/services/global-event.service';
import { AuthorizedHttpService } from '../../../core/services/http/authorized-http.service';
import { UserFlow } from '../../../pages/customer/user/cust-user-detail/cust-user-detail.component';
import { API_ENDPOINTS } from '../../constants/api/api-endpoints.constants';

import {
    ICustUserArchiveLoginRequestModel,
    ICustUserArchiveLoginSubmitModel,
} from './cust-user-archive-login.model';

@Component({
    selector: 'app-cust-user-archive-login',
    templateUrl: './cust-user-archive-login.component.html',
})
export class CustUserArchiveLoginComponent implements OnInit {
    @Input() suspended: boolean;

    @Input() set emailAddress(value: string) {
        this.archiveEmailAddress = value;
        this.requestModel.ArchiveAddress = value;
        this.submitModel.UserAddress = value;

        if (localStorage.getItem('username').toLowerCase() === value.toLowerCase()) {
            this.passwordValidated = true;
            this._eventService.publish('switchArchiveView', UserFlow.viewArchive);
        }
    }

    passwordError: boolean = false;
    requestSubmitted: boolean = false;
    isSuspended: boolean;
    archiveEmailAddress: string;
    passwordValidated: boolean = false;
    requestModel: ICustUserArchiveLoginRequestModel = {};
    submitModel: ICustUserArchiveLoginSubmitModel = {};

    constructor(
        private _authorizedHttpService: AuthorizedHttpService,
        private _eventService: GlobalEventService
    ) {}

    ngOnInit(): void {
        this.passwordError = false;
        this.requestSubmitted = false;
        this.isSuspended = this.suspended;
    }

    submitRequest(): void {
        this._authorizedHttpService
            .post$(
                API_ENDPOINTS.EMAIL_ARCHIVE.REQUEST_TEMPORARY_PASSWORD,
                this.requestModel
            )
            .subscribe((result: IExecutionResult<void>) => {
                if (result.Succeed) {
                    this.requestSubmitted = true;
                }
            });
    }

    submitLogin(): void {
        this._authorizedHttpService
            .post$(API_ENDPOINTS.EMAIL_ARCHIVE.SUBMIT_TEMPORARY_PASSWORD, this.submitModel)
            .subscribe((result: IExecutionResult<void>) => {
                if (result.Succeed) {
                    this.passwordValidated = true;
                    this._eventService.publish('switchArchiveView', UserFlow.viewArchive);
                } else {
                    this.passwordError = true;
                }
            });
    }
}
