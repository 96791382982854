import {
    AfterContentInit,
    ContentChildren,
    Directive,
    ElementRef,
    QueryList,
    Renderer2,
} from '@angular/core';

import { GAService } from '../../services/ga-service/ga-service';
import { InteractionType } from '../../services/ga-service/ga-visitor-interaction';

@Directive({
    selector: '[gaForm]',
})
export class GAFormDirective implements AfterContentInit {
    @ContentChildren('gaInput') elements: QueryList<ElementRef>;
    formName: string;
    clickEls: string[] = ['DIV', 'BUTTON'];

    constructor(el: ElementRef, private _gaService: GAService, private _renderer: Renderer2) {
        this.formName = el.nativeElement.getAttribute('ga-form-name');
    }

    ngAfterContentInit(): void {
        const els: ElementRef[] = this.elements.toArray();

        for (const el of els) {
            const nativeEl: HTMLElement = el.nativeElement;
            const trigger: string = this.getTrigger(nativeEl.tagName);

            this._renderer.listen(nativeEl, trigger, () => {
                const gaName: string = nativeEl.getAttribute('ga-input-name');

                if (!this.formName || !gaName) {
                    throw new Error('ga attributes not found');
                }

                this._gaService.visitorInteraction(InteractionType.formTracking, {
                    form_name: this.formName,
                    form_field: gaName,
                });
            });
        }
    }
    getTrigger(tagName: string): string {
        if (this.clickEls.indexOf(tagName) > 0) {
            return 'click';
        }

        return 'blur';
    }

    submit(): void {
        this._gaService.visitorInteraction(InteractionType.formSubmit, this.formName);
    }

    error(errorMessage: string): void {
        this._gaService.visitorInteraction(InteractionType.formError, {
            form_name: this.formName,
            form_message: errorMessage,
        });
    }
}
