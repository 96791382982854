import { IDictionary } from '../../interfaces/dictionary.interface';

export const REGEX: IDictionary<RegExp> = {
    onlyNumeric: /^[0-9]+$/,
    onlyNonNumeric: /[^0-9]/,
    atLeastOneLowerCaseLetter: /[a-z]/,
    atLeastOneUpperCaseLetter: /[A-Z]/,
    atLeastOneDigit: /\d/,
    validHexadecimalColorCode: /#([A-F]|[0-9]){6}$/i,
    emailAddressChars: /^[-0-9a-zA-Z._\']+@[-0-9a-zA-Z_\.]+\.[a-zA-Z]{2,15}(?:\.[a-zA-Z]{2,15})?$/,
    validAlphaNumericCharacter: /^[0-9a-zA-Z]$/,
    validAddress: /^([0-9]{1,3}\.){3}[0-9]{1,3}$/,
    spaceCharacters: / /g,
    alphaNumericCharacters: /^[a-zA-Z0-9]*$/,
    mfaManualEntryPart: /.{1,4}/g,
    phoneNumber: /^(0|(00|\+)[0-9]{1,4} ?)((([0-9]{4} )([0-9]{3} )([0-9]{3}$))|([0-9]{10}$))$/
};

export const REGEX_FACTORY: IDictionary<(...args: unknown[]) => RegExp> = {
    cookieName: (name: string) => new RegExp(`(?:^|; )${name}=([^;]*)`),
};

// See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
// Standard for specifying attachment names in the header
// Expected header value with format similar to:
// attachment; filename=myfile.pdf; filename*=UTF-8''myfile.pdf
// filename value might be enclosed in double quotes
export const REGEX_CONTENT_DISPOSITION_HEADER: IDictionary<RegExp> = {
    fileNameNoStarPart: /^(\s*filename\s*=\s*)"?([^"]+)"?/i,
    fileNameWithStarPart: /^(\s*filename\*\s*=\s*).*''"?([^"]+)"?$/i,
};
