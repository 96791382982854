import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CmTextHeaderComponent } from './text-header/cm-text-header.component';
import { CmTextHeaderSubtitleComponent } from './text-header-subtitle/cm-text-header-subtitle.component';

@NgModule({
    imports: [CommonModule],
    declarations: [CmTextHeaderComponent, CmTextHeaderSubtitleComponent],
    exports: [CmTextHeaderComponent, CmTextHeaderSubtitleComponent],
})
export class CmTypographyModule {}
