import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AbstractPopup } from '../../../../../../feature-modules/popups/classes/abstract-popup.class';
import { IPopupEventHandler } from '../../../../../../feature-modules/popups/interfaces/popup-event-handler.interface';
import { PopupService } from '../../../../../../feature-modules/popups/services/popup.service';
import { REGEX } from '../../../../../../shared/constants/regexp/regex.constants';
import { ROUTES_PATHS } from '../../../../../../shared/constants/routes.constants';
import { IConfirmationParams } from '../../../confirmation/interface/confirmation.types';
import { IConfigureMFAData } from '../../configure-mfa-data.interface';
import { SECRET_KEY_STEP, VERIFY_TOKEN_STEP } from '../../constants/configure-mfa-steps.constants';
import { MfaCommand } from '../../enums/mfa-command.enum';

import { IConfigureMfaPopupParams } from './configure-mfa-popup-params.interface';

@Component({
    selector: 'cm-configure-mfa-popup',
    templateUrl: './configure-mfa-popup.component.html',
    styleUrls: ['./configure-mfa-popup.component.scss'],
})
export class ConfigureMfaPopupComponent
    extends AbstractPopup<IConfigureMfaPopupParams>
{
    configureMFAData: IConfigureMFAData;
    mfaToken: string;
    isVerifying: boolean;
    secretKeyStep: number = SECRET_KEY_STEP;
    verifyTokenStep: number = VERIFY_TOKEN_STEP;
    formattedManualEntryKeyArray: string[] = [];
    eventHandler: IPopupEventHandler;
    authenticators: typeof ROUTES_PATHS.AUTHENTICATOR = ROUTES_PATHS.AUTHENTICATOR;

    constructor(
        private _popupService: PopupService,
        private _translateService: TranslateService
    ) {
        super();
    }

    updateParams(val: IConfigureMfaPopupParams): void {
        this.configureMFAData = val.configureMFAData;

        this.formattedManualEntryKeyArray = this._formatKey(
            this.configureMFAData.manualEntryKey
        ).split(' ');
    }

    onContinue(): void {
        this.mfaToken = '';
        this.isVerifying = false;
        this.configureMFAData.showStep = VERIFY_TOKEN_STEP;
    }

    onVerify(): void {
        if (!this.mfaToken?.trim()) {
            return;
        }

        this.eventHandler.executeCommand(MfaCommand.verify, this.mfaToken);

        this.isVerifying = true;
        this.configureMFAData.errorData.isError = false;
    }

    onCancel(): void {
        this._showConfirmationModal();
    }

    private _formatKey(val: string): string {
        if (!val) {
            return '';
        }

        let formattedKey: string = '';

        if (val.length > 0) {
            formattedKey = val.match(REGEX.mfaManualEntryPart).join(' ');
        }

        return formattedKey;
    }

    private _showConfirmationModal(): void {
        this._popupService.show(
            null,
            'confirmation',
            {
                message: this._translateService.instant('MFA.RECONFIGURE_CONFIRMATION_POPUP.DESCRIPTION'),
                primaryButtonAction: () => {
                    this.eventHandler.executeCommand(MfaCommand.cancel, null);
                    this.close();
                }
            } as IConfirmationParams
        )
    }
}
