import { Component, Input, OnInit } from '@angular/core';

import { ApiMailboxService } from '../../../../../../core/services/api/mailbox/api-mailbox.service';
import { IMailboxAlias } from '../../../../../../core/services/api/mailbox/types/api-mailbox.types';
import {
    GlobalEventService,
    GlobalEventSubscription,
} from '../../../../../../core/services/global-event.service';
import { PopupService } from '../../../../../../feature-modules/popups/services/popup.service';
import { HostedExchangeManagement } from '../../hosted-exchange.management';

@Component({
    selector: 'end-user-alias',
    templateUrl: './end-user-alias-component.html',
})
export class EndUserAliasComponent implements OnInit {
    @Input() management: HostedExchangeManagement;
    private _refreshSettingSub: GlobalEventSubscription;

    constructor(
        private _popupService: PopupService,
        private _eventService: GlobalEventService,
        private _apiMailboxService: ApiMailboxService
    ) {}

    ngOnInit(): void {
        this.getAliases();
        this._refreshSettingSub = this._eventService.subscribe(
            'refresh-hosted-exchange-aliases',
            () => {
                this.getAliases();
            }
        );
    }

    editAlias(): void {
        this._popupService.show(null, 'edit-alias', this.management);
    }

    getAliases(): void {
        this._apiMailboxService.getMailboxAliases$(this.management.mailboxAddress).subscribe(
            (aliases: IMailboxAlias[]) => {
                this.management.aliases = aliases;
            },
            () => {
                this.management.aliases = [];
            }
        );
    }

    deleteAlias(alias: string): void {
        this._apiMailboxService
            .deleteAlias$(this.management.mailboxAddress, alias)
            .subscribe(() => {
                this.getAliases();
            });
    }
}
