import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { RWD_BREAKPOINTS } from '../../../shared/constants/rwd-breakpoints.constants';
import { ScreenSize } from '../../../shared/enum/screen-size.enum';
import { IScreenSize } from '../../../shared/interfaces/screen-size.interface';

@Injectable({
    providedIn: 'root',
})
export class DeviceScreenService {
    private readonly _screenSizeFlags$: BehaviorSubject<IScreenSize> =
        new BehaviorSubject<IScreenSize>({
            [ScreenSize.small]: false,
            [ScreenSize.medium]: false,
            [ScreenSize.large]: false,
        });
    private _screenSizeFlags: IScreenSize = {
        [ScreenSize.small]: false,
        [ScreenSize.medium]: false,
        [ScreenSize.large]: false,
    };
    private _screenSizeChange$: Subject<void> = new Subject();
    private readonly _resizeDebounceTime: number = 200;

    constructor() {
        this._revalidateScreenSizeFlags();
        fromEvent(window, 'resize')
            .pipe(debounceTime(this._resizeDebounceTime))
            .subscribe(() => {
                this._revalidateScreenSizeFlags();
                this._tickScreenSizeChange();
            });
    }

    getScreenSizeFlags(): IScreenSize {
        return this._screenSizeFlags;
    }

    getScreenSizeFlags$(): Observable<IScreenSize> {
        return this._screenSizeFlags$.asObservable();
    }

    getScreenSizeChange$(): Observable<void> {
        return this._screenSizeChange$.asObservable();
    }

    private _revalidateScreenSizeFlags(): void {
        const screenWidth: number = document.documentElement.clientWidth;
        const mediumScreenLimit: number = RWD_BREAKPOINTS[ScreenSize.medium].to;
        const smallScreenLimit: number = RWD_BREAKPOINTS[ScreenSize.small].to;
        const screenSizesFlags: IScreenSize = {
            [ScreenSize.small]: false,
            [ScreenSize.medium]: false,
            [ScreenSize.large]: false,
        };

        if (screenWidth > mediumScreenLimit) {
            screenSizesFlags[ScreenSize.large] = true;
        } else if (screenWidth <= mediumScreenLimit && screenWidth > smallScreenLimit) {
            screenSizesFlags[ScreenSize.medium] = true;
        } else {
            screenSizesFlags[ScreenSize.small] = true;
        }

        this._screenSizeFlags = screenSizesFlags;
        this._screenSizeFlags$.next(this._screenSizeFlags);
    }

    private _tickScreenSizeChange(): void {
        this._screenSizeChange$.next();
    }
}
