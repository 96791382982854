import { ITableColumnSize } from '../interfaces/table-column-size.interface';

export const TABLE_GRID_COLUMN_SIZES: Record<ITableColumnSize, string> = {
    'auto': 'minmax(20px,max-content)',
    'rest': '1fr',
    'bootstrap-1': '1fr',
    'bootstrap-2': '2fr',
    'bootstrap-3': '3fr',
    'bootstrap-4': '4fr',
    'bootstrap-5': '5fr',
    'bootstrap-6': '6fr',
    'bootstrap-7': '7fr',
    'bootstrap-8': '8fr',
    'bootstrap-9': '9fr',
    'bootstrap-10': '10fr',
    'bootstrap-11': '11fr',
    'bootstrap-12': '12fr',
    '5-percent': '5%',
    '10-percent': '10%',
    '15-percent': '15%',
    '20-percent': '20%',
    '25-percent': '25%',
    '30-percent': '30%',
    '35-percent': '35%',
    '40-percent': '40%',
    '45-percent': '45%',
    '50-percent': '50%',
};
