import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DefaultPageRouteService } from '../../../core/services/default-page-route.service';
import { STORAGE_KEYS } from '../../../shared/constants/storage-keys.constants';
import { UserType } from '../../../shared/enum/user-type.enum';
import { AuthService } from '../services/auth/auth.service';
import { SessionManagementService } from '../services/session-management/session-management.service';

@Injectable({
    providedIn: 'root',
})
export class PathGuard implements CanActivate, CanActivateChild {
    constructor(
        private _router: Router,
        private _defaultPageRouteService: DefaultPageRouteService,
        private _authService: AuthService,
        private _sessionManagementService: SessionManagementService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | boolean {
        if (this._authService.tokenExpired()) {
            return this._sessionManagementService.getSessionStateForCanActive$(state);
        }

        if (this._isUserTypeDataIncorrect()) {
            return false;
        }

        return this._canActivateOrGetRedirectUrlTreeToDefaultPage$(state.url);
    }

    canActivateChild(): boolean {
        return this._isUserTypeDataCorrect();
    }

    private _canActivateOrGetRedirectUrlTreeToDefaultPage$(
        currentUrl: string
    ): Observable<boolean | UrlTree> | boolean {
        if (this._isEmptyUrl(currentUrl)) {
            this._defaultPageRouteService.goToDefaultPage();

            return false;
        }

        if (this._isEndUserOrEndUserAdmin()) {
            return this._defaultPageRouteService
                .getCloudDefaultPageUrl$()
                .pipe(
                    map((defaultPageUrl: string) =>
                        this._urlsAreEqual(currentUrl, defaultPageUrl)
                            ? true
                            : this._router.parseUrl(defaultPageUrl)
                    )
                );
        }

        return true;
    }

    private _isEmptyUrl(url: string): boolean {
        return url === '/' || url.length === 0;
    }

    private _urlsAreEqual(url1: string, url2: string): boolean {
        return (
            this._router.serializeUrl(this._router.parseUrl(url1)) ===
            this._router.serializeUrl(this._router.parseUrl(url2))
        );
    }

    private _isEndUserOrEndUserAdmin(): boolean {
        const currentUserType: string | null = localStorage.getItem(STORAGE_KEYS.USER_TYPE);

        return (
            currentUserType && (
                currentUserType.toLowerCase() === UserType.endUser.toLowerCase() ||
                currentUserType.toLowerCase() === UserType.endUserAdmin.toLowerCase()
            )
        );
    }

    private _isUserTypeDataCorrect(): boolean {
        const currentUserType: string | null = localStorage.getItem(STORAGE_KEYS.USER_TYPE);

        return (
            currentUserType
            && (currentUserType.toLowerCase() === UserType.endUser.toLowerCase() ||
                currentUserType.toLowerCase() === UserType.endUserAdmin.toLowerCase() ||
                currentUserType.toLowerCase() === UserType.admin.toLowerCase())
        );
    }

    private _isUserTypeDataIncorrect(): boolean {
        return !this._isUserTypeDataCorrect;
    }
}
