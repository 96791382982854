export const FEATURE_FLAGS = {
    ACTIONS: {
        M365_SECURE_UPGRADE: 'M365SecureUpgrade',
        MODIFY_JOURNEY: 'ModifyJourney',
        NCE_GLOBAL_MODIFY: 'NCEGlobalModify',
    },
    BILLING_PLATFORM_REPORTING: 'BillingPlatformReporting',
    PSA_OVERVIEW: 'PSAScreenOverview',
    PSA_NEW_SERVICE: 'UseNewPSAService',
    GDAP: 'GDAP',
    BILLING_DISABLE_ADD_CREDIT: 'BillingDisableAddCredit',
    BILLING_PLATFORM_PAYMENT: 'BillingPlatformPayment',
    M365_ACCOUNT_LINK: 'M365AccountLink',
    NEW_STATUS_PAGE: 'NewStatusPage',
    NCE_ASSIGNED_LICENCES: 'NCEAssignedLicences',
    DISABLE_PARTNER_LEVEL_WHITELABEL: 'DisablePartnerLevelWhitelabel',
    DISABLE_LEGACY_MICROSOFT_ORDERS: 'DisableLegacyMicrosoftOrders'
};
