import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GlobalSpinnerService {

    private _showGlobalSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    getShowSpinnerValue$(): Observable<boolean>{
        return this._showGlobalSpinner$.asObservable()
    }

    setShowSpinnerValue$(isSpinnerVisible: boolean): void{
        this._showGlobalSpinner$.next(isSpinnerVisible)
    }

}
