import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { API_ENDPOINTS } from '../../shared/constants/api/api-endpoints.constants';

import { AuthorizedHttpService } from './http/authorized-http.service';

@Injectable({
    providedIn: 'root',
})
export class CloudServerService {
    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    getToken$(): Observable<string> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.CLOUD_SERVER.GET_TOKEN);
    }

    redirectToCloudServer(token: string): void {
        window.location.href = `${environment.CloudServerUrl}?access_token=${token}`;
    }
}
