import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-sorting-button',
    templateUrl: './sorting-button.component.html',
    styleUrls: ['./sorting-button.component.scss'],
})
export class SortingButtonComponent {
    @Input() buttonName: string;
    @Input() ascendingOrder: boolean;
    @Input() keyForSorting: string;
    @Input() sortByColumnName: string;
    @Output() readonly handleSortingOrderAndName: EventEmitter<{
        column: string;
        sortAsc: boolean;
    }> = new EventEmitter();
    sortAsc: boolean | null = null;

    emitColumnNameAndSortingOrder(): void {
        this.sortAsc = !this.sortAsc;
        this.handleSortingOrderAndName.emit({
            column: this.sortByColumnName,
            sortAsc: this.sortAsc,
        });
    }
}
