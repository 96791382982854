import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_ENDPOINTS } from '../../../shared/constants/api/api-endpoints.constants';
import { IAnnouncement } from '../../interfaces/announcement/announcement.interface';
import { IExecutionResult } from '../../interfaces/execution-result.interface';
import { AuthorizedHttpService } from '../http/authorized-http.service';

@Injectable({
    providedIn: 'root',
})
export class ApiAnnouncementService {
    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    getAnnouncement$(announcementId: number): Observable<IExecutionResult<IAnnouncement>> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.ANNOUNCEMENT.GET_ANNOUCEMENT(announcementId));
    }

    doNotShowAgain$(announcementId: number): Observable<IExecutionResult<void>> {
        return this._authorizedHttpService.post$(
            API_ENDPOINTS.ANNOUNCEMENT.DO_NOT_SHOW_AGAIN(announcementId),
            null
        );
    }

    remindLater$(announcementId: number): Observable<IExecutionResult<void>> {
        return this._authorizedHttpService.post$(
            API_ENDPOINTS.ANNOUNCEMENT.REMIND_LATER(announcementId),
            null
        );
    }
}
