import { Component, Input } from '@angular/core';

@Component({
    selector: 'cm-icon',
    templateUrl: './cm-icon.component.html',
})
export class IconComponent {
    @Input() icon: string;
    @Input() duotone?: {
        primaryColor: string;
        secondaryColor: string;
    };
}
