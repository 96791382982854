import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

import { GiacomDateModule } from '../../../../../shared/pipes/giacom-date/giacom-date.module';

import { DatetimePickerControlComponent } from './datetime-picker-control.component';

@NgModule({
    declarations: [DatetimePickerControlComponent],
    imports: [
        CommonModule,
        TranslateModule,
        GiacomDateModule,
        FormsModule,
        BsDatepickerModule,
        TimepickerModule,
    ],
    exports: [DatetimePickerControlComponent],
})
export class DatetimePickerControlModule {}
