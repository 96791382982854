import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IExecutionResult } from '../../../core/interfaces/execution-result.interface';
import { UnauthorizedHttpService } from '../../../core/services/http/unauthorized-http.service';
import { SsoAuthService } from '../../../core/services/sso-auth/sso-auth.service';
import { API_ENDPOINTS } from '../../../shared/constants/api/api-endpoints.constants';

@Component({
    selector: 'app-spam-release',
    templateUrl: './spam-release.component.html',
    styleUrls: ['./spam-release.component.scss'],
})
export class SpamReleaseComponent implements OnInit {
    messageGuid: string;
    uId: string;
    loading: boolean = true;
    errors: string[] = [];

    constructor(
        private _unauthorizedHttpService: UnauthorizedHttpService,
        private _activatedRoute: ActivatedRoute,
        private _ssoAuthService: SsoAuthService
    ) {}

    ngOnInit(): void {
        this.messageGuid = this._activatedRoute.snapshot.paramMap.get('MID');
        this.uId = this._activatedRoute.snapshot.paramMap.get('UID');

        if (!this.messageGuid || !this.uId) {
            this._ssoAuthService.logoutWithDefaultOptions();
        }

        this._unauthorizedHttpService
            .get$(API_ENDPOINTS.SPAM_RELEASE.GET_SPAM_RELEASE(this.messageGuid, this.uId))
            .subscribe((result: IExecutionResult<any>) => {
                this.loading = false;
                if (!result.Succeed) {
                    this._ssoAuthService.logoutWithDefaultOptions();
                }
            });
    }
}
