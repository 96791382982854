import { Component, Inject, OnInit } from '@angular/core';
import { RedirectLoginOptions } from '@auth0/auth0-spa-js';

import { WINDOW } from '../../../../../core/injection-tokens/window.injection-token';
import { SsoAuthService } from '../../../../../core/services/sso-auth/sso-auth.service';
import { ROUTES_PATHS } from '../../../../../shared/constants/routes.constants';
import { AbstractPopup } from '../../../../popups/classes/abstract-popup.class';

import { IDisplaySsoLoginError } from './interfaces/display-sso-login-error.interface';

@Component({
    selector: 'cm-auth-error',
    templateUrl: './auth-error-modal.component.html',
    styleUrls: ['../../auth.scss'],
})
export class AuthErrorModalComponent extends AbstractPopup<Error> implements OnInit {
    serverErrorMessage: string;
    displayError: IDisplaySsoLoginError;
    private _defaultDisplayError: IDisplaySsoLoginError = {
        title: 'COMMONS.GENERIC_ERROR_MESSAGE',
        description: 'UNEXPECTED_ERROR',
        primaryActionMessage: 'COMMONS.BUTTONS.TRY_AGAIN',
        primaryAction: () => this.close()
    }

    constructor(
        @Inject(WINDOW) private _window: Window,
        private _ssoAuthService: SsoAuthService
    ) {
        super();
    }

    updateParams(error: Error): void {
        this.serverErrorMessage = error.message;
    }

    ngOnInit(): void {
         this._setDisplayErrors();
    }

    private _errorMessages(errorMessage: string): IDisplaySsoLoginError[] {
        return [
            {
                messageCondition: errorMessage.includes(
                    'client requires organization membership, but user does not belong to any organization',
                ),
                title: 'LOGIN.SSO_ERROR.ACCOUNT_NOT_LINKED_TO_ANY_ORG_TITLE',
                description: 'LOGIN.SSO_ERROR.ACCOUNT_NOT_LINKED_TO_ANY_ORG_DESC',
                primaryActionMessage: 'COMMONS.BUTTONS.SIGN_IN_WITH_ANOTHER_ACCOUNT',
                primaryAction: () => this.close(),
            },
            {
                messageCondition: errorMessage.includes('is not part of the'),
                title: 'LOGIN.SSO_ERROR.ACCOUNT_NOT_LINKED_TO_THE_ORG_TITLE',
                description: 'LOGIN.SSO_ERROR.ACCOUNT_NOT_LINKED_TO_THE_ORG_DESC',
                primaryActionMessage: 'COMMONS.BUTTONS.SIGN_IN_WITH_ANOTHER_ACCOUNT',
                primaryAction: () => this.close(),
            },
            {
                messageCondition: errorMessage.includes('Authentication with Microsoft required'),
                title: 'LOGIN.SSO_ERROR.AUTHENTICATION_WITH_MICROSOFT_REQUIRED_TITLE',
                description: 'LOGIN.SSO_ERROR.AUTHENTICATION_WITH_MICROSOFT_REQUIRED_DESC',
                primaryActionMessage: 'LOGIN.SSO_ERROR.AUTHENTICATION_WITH_MICROSOFT_REQUIRED_PRIMARY_ACTION',
                secondaryActionMessage: this._getSecondaryActionMessageForMicrosoftAccount(errorMessage),
                primaryAction: () => this._continueWithMicrosoft(errorMessage),
                secondaryAction: () => this._chooseAnotherOrganisation(),
            },
            {
                messageCondition: errorMessage.includes('Authentication with Microsoft prohibited'),
                title: 'LOGIN.SSO_ERROR.AUTHENTICATION_WITH_MICROSOFT_PROHIBITED_TITLE',
                description: 'LOGIN.SSO_ERROR.AUTHENTICATION_WITH_MICROSOFT_PROHIBITED_DESC',
                primaryActionMessage: 'LOGIN.SSO_ERROR.AUTHENTICATION_WITH_MICROSOFT_PROHIBITED_PRIMARY_ACTION',
                secondaryActionMessage: this._getSecondaryActionMessageForMicrosoftAccount(errorMessage),
                primaryAction: () => this._continueWithCloud(),
                secondaryAction: () => this._chooseAnotherOrganisation(),
            }];
    };

    private _continueWithMicrosoft(message: string): void {
        const loginOptions: RedirectLoginOptions = {
            authorizationParams: {
                connection: 'giacom-ms-entra',
                redirect_uri: `${this._window.location.origin}/${ROUTES_PATHS.LOGIN}`,
            }
        };
        const orgId: string = this._getOrgIdFromErrorMessage(message);

        if (orgId) {
            loginOptions.authorizationParams.organization = orgId;
        }

        this._ssoAuthService.loginWithRedirect$(loginOptions);
    }

    private _continueWithCloud(): void {
        this.close();
        this._ssoAuthService.redirectToLoginPageAfterClearAuth0LocalAndCloudSession();
    }

    private _chooseAnotherOrganisation(): void {
        const loginOptions: RedirectLoginOptions = {
            authorizationParams: {
                redirect_uri: `${this._window.location.origin}/${ROUTES_PATHS.LOGIN}`,
                prompt: 'select_account'
            }
        };

        this._ssoAuthService.loginWithRedirect$(loginOptions);
    }

    private _getSecondaryActionMessageForMicrosoftAccount(message: string): string | null {
        const orgId: string = this._getOrgIdFromErrorMessage(message);

        return orgId ? 'LOGIN.SSO_ERROR.AUTHENTICATION_WITH_MICROSOFT_SECONDARY_ACTION' : null;
    }

    private _getOrgIdFromErrorMessage(message: string): string | null {
        const splitMessage: string[] = message.split(';');

        return (splitMessage.length > 1 && splitMessage[1].trim()) ? splitMessage[1].trim() : null;
    }

    private _setDisplayErrors(): void {
        const errorMessages: IDisplaySsoLoginError[] = this._errorMessages(this.serverErrorMessage);

        const errorMessage: IDisplaySsoLoginError = errorMessages
            .find((message: IDisplaySsoLoginError) => message.messageCondition);

        if (errorMessage) {
            this._setPrimaryDisplayError(errorMessage);
            this._setSecondaryDisplayError(errorMessage);
        } else {
            this.displayError = this._defaultDisplayError;
        }
    }

    private _setPrimaryDisplayError(errorMessage: IDisplaySsoLoginError): void {
        this.displayError = {
            title: errorMessage.title,
            description: errorMessage.description,
            primaryActionMessage: errorMessage.primaryActionMessage,
            primaryAction: errorMessage.primaryAction,
        };
    }

    private _setSecondaryDisplayError(errorMessage: IDisplaySsoLoginError): void {
        if (errorMessage.secondaryActionMessage) {
            this.displayError.secondaryActionMessage = errorMessage.secondaryActionMessage;
            this.displayError.secondaryAction = errorMessage.secondaryAction;
        }
    }
}
