import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({ selector: '[autofocus]' })
export class InputFocusDirective implements AfterViewInit {
    constructor(private _elementRef: ElementRef) {}

    ngAfterViewInit(): void {
        this._elementRef.nativeElement.focus();
    }
}
