import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { concatMap, finalize } from 'rxjs/operators';

import { GlobalSpinnerService } from '../../../../core/services/global-spinner/global-spinner.service';
import { SsoAuthService } from '../../../../core/services/sso-auth/sso-auth.service';
import { IFinalizingLoginStrategy } from '../../interfaces/finalizing-login-strategy.interface';
import { AuthService } from '../auth/auth.service';

import { SessionRecreateService } from './session-recreate.service';

@Injectable({
    providedIn: 'root',
})
export class SsoSessionManagementService {
    constructor(
        private _ssoAuthService: SsoAuthService,
        private _globalSpinnerService: GlobalSpinnerService,
        private _authService: AuthService,
        private _sessionRecreateService: SessionRecreateService
    ) {
    }

    handleSsoSession$(finalizingLoginStrategy: IFinalizingLoginStrategy): Observable<boolean> {
        const cloudSessionNotExpired: boolean = this._authService.tokenNotExpired();

        return this._isExistSsoLocalSession$().pipe(
            concatMap((isExistSsoLocalSession: boolean) => {
                if (isExistSsoLocalSession && cloudSessionNotExpired) {
                    return of(true);
                }

                this._globalSpinnerService.setShowSpinnerValue$(true);

                return this._sessionRecreateService.checkOrRecreateSession$(finalizingLoginStrategy);
            }),
            finalize(() => {
                this._globalSpinnerService.setShowSpinnerValue$(false);
            }),
        );
    }

    private _isExistSsoLocalSession$(): Observable<boolean> {
        return this._ssoAuthService.isSsoUserAuthenticatedAndHasValidToken$();
    }
}
