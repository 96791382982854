import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { IExecutionResult } from '../../../core/interfaces/execution-result.interface';
import { GlobalDataService } from '../../../core/services/global-data.service';
import { GlobalEventService } from '../../../core/services/global-event.service';
import { AuthorizedHttpService } from '../../../core/services/http/authorized-http.service';
import { PopupService } from '../../../feature-modules/popups/services/popup.service';
import { IEmailDetailsViewModel } from '../../../models/user/archive-list-filter.model';
import { UserFlow } from '../../../pages/customer/user/cust-user-detail/cust-user-detail.component';
import { API_ENDPOINTS } from '../../constants/api/api-endpoints.constants';
import { ArchiveMailType } from '../../enum/email-archive/archive-email.enum';
import { PageViewMethod } from '../../services/ga-service/ga-page-load';
import { GAService } from '../../services/ga-service/ga-service';
import { AbstractPageLoad } from '../../services/ga-service/shared-classes';

@Component({
    selector: 'app-giacom-end-user-activity-view-email',
    templateUrl: './end-user-activity-view-email.component.html',
})
export class EndUserActivityViewEmailComponent extends AbstractPageLoad implements OnInit {
    pageName: string = 'end-user/end-user-activity-email';
    pageType: string = 'end-user';
    pageViewMethod: PageViewMethod = PageViewMethod.page;
    @Input() isResellerView: boolean = false;
    model: IEmailDetailsViewModel;
    sizeText: string;
    emailId: string;
    isShowDownload: boolean;
    errorMessage: string;
    archiveEmailAddress: string;
    htmlDisplay: any;
    downloads: any;
    loaded: boolean = false;
    sanitizer: DomSanitizer;
    preventClickOnContent: boolean = false;

    get displayContent(): boolean {
        return !(!this.model || this.model.MailType === ArchiveMailType.Quarantine);
    }

    constructor(
        protected _gaService: GAService,
        private _popupService: PopupService,
        private _authorizedHttpService: AuthorizedHttpService,
        private _router: Router,
        private _dataService: GlobalDataService,
        private _eventService: GlobalEventService,
        private _domSanitizer: DomSanitizer
    ) {
        super(_gaService);
        this.sanitizer = _domSanitizer;
    }

    ngOnInit(): void {
        const bInMb: number = 1000000;
        const kbInMb: number = 1000;
        const emailArchive: {
            model: IEmailDetailsViewModel;
            emailAddress: string;
            param: string;
        } = this._dataService.getData('emailarchive-data');

        this.recordPageLoad();
        this.errorMessage = '';
        this.isShowDownload = false;

        if (emailArchive) {
            const fractionDigits: number = 2;

            this.archiveEmailAddress = emailArchive.emailAddress;
            this.model = emailArchive.model;
            this.sizeText =
                this.model.Size > bInMb
                    ? `${(this.model.Size / bInMb).toFixed(fractionDigits)}mb`
                    : `${(this.model.Size / kbInMb).toFixed(fractionDigits)}kb`;
            this.emailId = emailArchive.param;
            const address: string =
                this.model.Direction === 'Inbound' ? this.model.EmailTo : this.model.EmailFrom;

            this._authorizedHttpService
                .post$(
                    API_ENDPOINTS.EMAIL_ARCHIVE.GET_EMAIL(this.archiveEmailAddress),
                    { EmailId: this.emailId, Address: address }
                )
                .subscribe((result: IExecutionResult<any>) => {
                    if (result.Succeed) {
                        this.htmlDisplay = result.Result.HtmlContent;
                        this.downloads = result.Result.Downloads;
                        this.preventClickOnContent = true;
                    }
                });
        } else {
            this.model = {};
            this.sizeText = '0kb';
            this.emailId = '';
        }
    }

    redeliverEmail(): void {
        this._dataService.setData('emailarchive-data', {
            model: this.model,
            param: this.emailId,
        });
        this._popupService.show(null, 'end-user-re-deliver-email', this.archiveEmailAddress);
    }

    backToList(): void {
        if (this.isResellerView) {
            this._eventService.publish('switchArchiveView', UserFlow.viewArchive);
        } else {
            this._router.navigate(['end-user/activity']);
        }
    }

    downloadFile(downloadDetails: any): void {
        this._authorizedHttpService
            .downloadBlobAsFile$(
                API_ENDPOINTS.EMAIL_ARCHIVE.DOWNLOAD_EMAIL_FILE(
                    this.archiveEmailAddress,
                    downloadDetails.EmailId,
                    downloadDetails.FileName
                ),
                downloadDetails.FileName
            )
            .subscribe();
    }

    handleClick(event: Event): void {
        if (this.preventClickOnContent) {
            event.stopPropagation();
            event.preventDefault();
        }
    }
}
