import { Inject, Injectable } from '@angular/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import Color from 'color';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { DOCUMENT } from '../../core/injection-tokens/document.injection-token';
import { IExecutionResult } from '../../core/interfaces/execution-result.interface';
import { DeviceScreenService } from '../../core/services/device-screen/device-screen.service';
import { AuthorizedHttpService } from '../../core/services/http/authorized-http.service';
import { ILoginUserWhiteLabel } from '../../models/your-account/whitelabel/login-user-whitelabel.interface';
import { IWhiteLabelLogo } from '../../models/your-account/whitelabel/white-label-logo.interface';
import { DefaultAccentColor } from '../../pages/your-account/enums';
import { API_ENDPOINTS } from '../constants/api/api-endpoints.constants';
import {
    WHITELABEL_ALPHA_FACTOR,
    WHITELABEL_LIGHTNESS_FACTOR,
    WHITELABEL_THEME_ATTRIBUTE,
    WHITELABEL_THEME_COLOR_CODES,
    WHITELABEL_VARIABLE_NAMES,
} from '../constants/whitelabel.constants';
import { ScreenSize } from '../enum/screen-size.enum';
import { WhitelabelingTheme } from '../enum/whitelabeling-theme.enum';
import { IWhitelabel } from '../interfaces/whitelabelling.interface';

@Injectable({
    providedIn: 'root',
})
export class WhitelabelService {
    imageEncodedDesktop: string;
    imageEncodedMobile: string;
    imageEncoded: string = '';
    custom: boolean = false;
    applied: boolean = false;
    svg: boolean = false;
    private _headerColor: string;

    whitelabel: ILoginUserWhiteLabel;
    userLogo: IWhiteLabelLogo;

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _deviceScreenService: DeviceScreenService,
        private _authorizedHttpService: AuthorizedHttpService
    ) {}

    resolve(): Observable<IWhitelabel> {
        return this._authorizedHttpService
            .get$(
                API_ENDPOINTS.SETTINGS_WHITELABELING.GET_LOGIN_USER_WHITE_LABEL
            )
            .pipe(
                tap((userWhiteLabel: IExecutionResult<ILoginUserWhiteLabel>): void => {
                    this.whitelabel = userWhiteLabel.Result;
                }),
                switchMap((userWhiteLabel: IExecutionResult<ILoginUserWhiteLabel>) =>
                    this._authorizedHttpService.get$(
                        API_ENDPOINTS.SETTINGS_WHITELABELING.GET_USER_LOGO(
                            userWhiteLabel.Result.WhiteLabelId
                        )
                    )
                ),
                tap((userLogo: IExecutionResult<IWhiteLabelLogo>) => {
                    this.userLogo = userLogo.Result;
                }),
                map(() => ({
                    whitelabel: this.whitelabel,
                    userLogo: this.userLogo,
                }))
            );
    }

    applyTheme(): void {
        const logo: Element = this._document.getElementById('loginUserLogo');
        const logoUrl: string = logo?.getAttribute('src');

        this._setWhitelabelingProperties(this.whitelabel);

        if (logoUrl === '') {
            return;
        }

        this.imageEncodedDesktop = this.userLogo.DesktopImageString;
        this.imageEncodedMobile = this.userLogo.MobileImageString;
        this.setLogo(this._deviceScreenService.getScreenSizeFlags()[ScreenSize.small]);
        this.custom = this.userLogo.IsCustom;
        this.applied = true;
        if (this.userLogo.IsSvg) {
            this.svg = true;
        }
    }

    resetTheme(): void {
        const body: HTMLElement = this._document.querySelector('body');

        body.removeAttribute(WHITELABEL_THEME_ATTRIBUTE);
        this.removeAccentColorProperties();
        this.applied = false;
    }

    setLogo(isSmallScreen: boolean): void {
        this.imageEncoded = isSmallScreen ? this.imageEncodedMobile : this.imageEncodedDesktop;
    }

    getThemeHeaderColor(): string {
        return this._headerColor;
    }

    setThemeProperty(whitelabel: ILoginUserWhiteLabel, selector: string = 'body'): void {
        const element: HTMLElement = this._document.querySelector(selector);
        const theme: WhitelabelingTheme = this._getTheme(
            whitelabel.MarketplaceWhitelabel.Color_1_1
        );

        element.setAttribute(WHITELABEL_THEME_ATTRIBUTE, theme);
        this._headerColor = whitelabel.MarketplaceWhitelabel.Color_2_1;
    }

    setAccentColorProperties(whitelabel: ILoginUserWhiteLabel, selector: string = 'body'): void {
        const element: HTMLElement = this._document.querySelector(selector);

        if (
            whitelabel.MarketplaceWhitelabel.Color_2_1.toLowerCase() ===
            DefaultAccentColor.default.toLowerCase()
        ) {
            this.removeAccentColorProperties();

            return;
        }

        element.style.setProperty(
            WHITELABEL_VARIABLE_NAMES.primary,
            Color(whitelabel.MarketplaceWhitelabel.Color_2_1).hsl()
        );
        element.style.setProperty(
            WHITELABEL_VARIABLE_NAMES.secondary,
            Color(whitelabel.MarketplaceWhitelabel.Color_2_1).alpha(WHITELABEL_ALPHA_FACTOR).hsl()
        );
        element.style.setProperty(
            WHITELABEL_VARIABLE_NAMES.primaryLighter,
            Color(whitelabel.MarketplaceWhitelabel.Color_2_1).lighten(WHITELABEL_LIGHTNESS_FACTOR)
        );
        element.style.setProperty(
            WHITELABEL_VARIABLE_NAMES.primaryDarker,
            Color(whitelabel.MarketplaceWhitelabel.Color_2_1).darken(WHITELABEL_LIGHTNESS_FACTOR)
        );
    }

    removeAccentColorProperties(selector: string = 'body'): void {
        const element: HTMLElement = this._document.querySelector(selector);

        element.style.removeProperty(WHITELABEL_VARIABLE_NAMES.primary);
        element.style.removeProperty(WHITELABEL_VARIABLE_NAMES.secondary);
        element.style.removeProperty(WHITELABEL_VARIABLE_NAMES.primaryLighter);
        element.style.removeProperty(WHITELABEL_VARIABLE_NAMES.primaryDarker);
    }

    private _setWhitelabelingProperties(whitelabel: ILoginUserWhiteLabel): void {
        this.setThemeProperty(whitelabel);
        this.setAccentColorProperties(whitelabel);
    }

    private _getTheme(color: string): WhitelabelingTheme {
        switch (color) {
            case WHITELABEL_THEME_COLOR_CODES.light:
                return WhitelabelingTheme.light;
            case WHITELABEL_THEME_COLOR_CODES.dark:
                return WhitelabelingTheme.dark;
            default:
                return WhitelabelingTheme.default;
        }
    }
}
