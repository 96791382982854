import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgChartsModule } from 'ng2-charts';

@NgModule({
    imports: [CommonModule, NgChartsModule],
    declarations: [],
    exports: [NgChartsModule],
})
export class CmChartsModule {}
