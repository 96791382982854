import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { CmFormsModule } from '../../../../../../feature-modules/design-system/components/forms/cm-forms.module';
import { PopUpDetails } from '../../../../../../feature-modules/popups/classes/pop-up-details.class';
import { PopupService } from '../../../../../../feature-modules/popups/services/popup.service';
import { CmAlertModule } from '../../../../../../new-shared/components/cm-alert/cm-alert.module';
import { CmModalModule } from '../../../../../../new-shared/components/cm-modal/modal.module';

import { SwitchUserPopupComponent } from './switch-user-popup.component';

@NgModule({
    declarations: [SwitchUserPopupComponent],
    imports: [
        CmAlertModule,
        CmModalModule,
        CommonModule,
        TranslateModule,
        FormsModule,
        CmFormsModule,
        PopoverModule,
    ],
    exports: [SwitchUserPopupComponent],
})
export class SwitchUserPopupModule {
    static popupRegistered: boolean = false;
    constructor(private _popupService: PopupService) {
        if (SwitchUserPopupModule.popupRegistered) {
            return;
        }

        SwitchUserPopupModule.popupRegistered = true;

        this._popupService.registerPopups({
            'switch-user-popup': new PopUpDetails(SwitchUserPopupComponent, 'sign in', 'sign-in'),
        });
    }
}
