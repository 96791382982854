import { KeyboardKey } from '../enum/keyboard-key.enum';

export const KEYBOARD_NUMBER_KEYS: KeyboardKey[] = [
    KeyboardKey.one,
    KeyboardKey.two,
    KeyboardKey.three,
    KeyboardKey.four,
    KeyboardKey.five,
    KeyboardKey.six,
    KeyboardKey.seven,
    KeyboardKey.eight,
    KeyboardKey.nine,
    KeyboardKey.zero,
];
