import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CmAlertModule } from '../../../new-shared/components/cm-alert/cm-alert.module';

import { CustUserArchiveLoginComponent } from './cust-user-archive-login.component';

@NgModule({
    declarations: [CustUserArchiveLoginComponent],
    imports: [CommonModule, TranslateModule, CmAlertModule, FormsModule],
    exports: [CustUserArchiveLoginComponent],
})
export class CustUserArchiveLoginModule {}
