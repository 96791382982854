export enum AzureUsagePeriod {
    last7Days = 7,
    last14Days = 14,
    last28Days = 28,
}

export enum AzureUsageBy {
    inTotal = 0,
    byGroup = 1,
    byResource = 2,
    byMeter = 3,
}
