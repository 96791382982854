import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IExecutionResult } from '../../../core/interfaces/execution-result.interface';
import { AuthorizedHttpService } from '../../../core/services/http/authorized-http.service';
import { API_ENDPOINTS } from '../../constants/api/api-endpoints.constants';

import { DynamicFormFieldType, IDynamicForm, IDynamicFormField } from './cm-dynamic-form-models';

@Component({
    selector: 'cm-dynamic-form',
    templateUrl: './cm-dynamic-form.component.html',
    styleUrls: ['./cm-dynamic-form.component.scss'],
})
export class CmDynamicFormComponent implements OnInit {
    @Input() model: any;
    @Output() readonly modelChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() valid: boolean;
    @Output() readonly validChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() set formId(value: number) {
        this._formId = value;
        this._updateForm();
    }

    dynamicFormFieldType: typeof DynamicFormFieldType = DynamicFormFieldType;
    schema: IDynamicForm;
    private _formId: number;

    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    ngOnInit(): void {
        this._updateForm();
    }

    get formId(): number {
        return this._formId;
    }

    changeValue(field: IDynamicFormField, value: string): void {
        this.model[field.PropertyName] = value;
        field.Touched = true;
        this.modelChange.emit(this.model);
        this.validChange.emit(this.isFormValid());
    }

    setTouched(field: IDynamicFormField): void {
        field.Touched = true;
    }

    isFormValid(): boolean {
        let valid: boolean = true;

        this.schema.Fields.forEach((element: IDynamicFormField) => {
            if (!this.isValid(element)) {
                valid = false;
            }
        });

        return valid;
    }

    isValid(field: IDynamicFormField): boolean {
        let valid: boolean = true;

        if (
            field.Mandatory &&
            (!this.model[field.PropertyName] || this.model[field.PropertyName] === '')
        ) {
            valid = false;
            field.Message = 'This is a mandatory field';
        }
        if (field.Regex) {
            const regex: RegExp = new RegExp(field.Regex);

            if (this.model[field.PropertyName]) {
                const value: string = String(this.model[field.PropertyName]);

                if (!value.match(regex)) {
                    valid = false;
                    field.Message = field.InvalidMessage;
                }
            }
        }
        field.Valid = valid;

        return valid;
    }

    private _updateForm(): void {
        this.model = {};
        this._authorizedHttpService
            .get$(API_ENDPOINTS.DYNAMIC_FORM.GET_FORM(this._formId))
            .subscribe((res: IExecutionResult<IDynamicForm>) => {
                this.schema = res.Result;
                this.schema.Fields.forEach((field: IDynamicFormField) => {
                    if (!this.model[field.PropertyName]) {
                        this.model[field.PropertyName] = null;
                    }
                    field.Touched = false;
                });
                this.modelChange.emit(this.model);
                this.validChange.emit(this.isFormValid());
            });
    }
}
