import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AgreementServiceResellerValidityFacadeService } from '../../../core/services/agreement-service-reseller-validity-facade.service';
import { ROUTES_PATHS } from '../../../shared/constants/routes.constants';
import { AuthService } from '../services/auth/auth.service';
import { SessionManagementService } from '../services/session-management/session-management.service';

@Injectable({
    providedIn: 'root',
})
export class AgreementGuard implements CanActivate, CanActivateChild, CanLoad {
    private readonly _agreementPath: string = `${ROUTES_PATHS.SETTINGS.AGREEMENT}/0/true/true`;
    constructor(
        private _router: Router,
        private _authService: AuthService,
        private _resellerAgreementValidityFacadeService: AgreementServiceResellerValidityFacadeService,
        private _sessionManagementService: SessionManagementService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        if (this._authService.tokenExpired()) {
            return this._sessionManagementService.getSessionStateForCanActive$(state);
        }

        return this._checkMustSignNowAgreementOrGetRedirectUrlToSign$(state.url);
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        if (this._authService.tokenExpired()) {
            return this._sessionManagementService.getSessionStateForCanActiveChild$(state);
        }

        return this._checkMustSignNowAgreementOrGetRedirectUrlToSign$(state.url);
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
        const relativePath: string = `/${segments
            .map((urlSegment: UrlSegment) => urlSegment.path)
            .join('/')}`;
        if (this._authService.tokenExpired()) {
            return this._sessionManagementService.getSessionStateForCanLoad$(segments);
        }

        return this._checkMustSignNowAgreementOrGetRedirectUrlToSign$(relativePath);
    }

    private _checkMustSignNowAgreementOrGetRedirectUrlToSign$(
        currentPath: string
    ): Observable<boolean | UrlTree> {
        return this._resellerAgreementValidityFacadeService.checkMustSignNow$().pipe(
            map((mustSignNow: boolean) => {
                if (mustSignNow && !currentPath.includes(this._agreementPath)) {
                    return this._router.parseUrl(this._agreementPath);
                }

                if (mustSignNow && currentPath.includes(this._agreementPath)) {
                    return true;
                }

                return true;
            })
        );
    }
}
