import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class BlockUiService {
    private _requestsCount: number = 0;
    private _requestsCount$: BehaviorSubject<number> = new BehaviorSubject(0);
    private _submitRequestsCount: number = 0;
    private _submitRequestsCount$: BehaviorSubject<number> = new BehaviorSubject(0);

    getIsRequestInProgress$(): Observable<boolean> {
        return this._requestsCount$.pipe(map((requestsCount: number) => requestsCount > 0));
    }

    getIsSubmitRequestInProgress$(): Observable<boolean> {
        return this._submitRequestsCount$.pipe(map((requestsCount: number) => requestsCount > 0));
    }

    increaseRequestCount(): void {
        this._requestsCount += 1;
        this._requestsCount$.next(this._requestsCount);
    }

    decreaseRequestCount(): void {
        this._requestsCount -= 1;
        this._requestsCount$.next(this._requestsCount);
    }

    increaseSubmitRequestCount(): void {
        this._submitRequestsCount += 1;
        this._submitRequestsCount$.next(this._submitRequestsCount);
    }

    decreaseSubmitRequestCount(): void {
        this._submitRequestsCount -= 1;
        this._submitRequestsCount$.next(this._submitRequestsCount);
    }
}
