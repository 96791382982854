import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { PopUpDetails } from '../../../../feature-modules/popups/classes/pop-up-details.class';
import { PopupService } from '../../../../feature-modules/popups/services/popup.service';
import { CmModalModule } from '../../cm-modal/modal.module';

import { ConfirmationModalComponent } from './confirmation-modal.component';


@NgModule({
    declarations: [ConfirmationModalComponent],
    imports: [
        CmModalModule,
        CommonModule,
        TranslateModule,
    ],
    exports: [ConfirmationModalComponent],
})
export class ConfirmationModalModule {
    static popupRegistered: boolean = false;
    constructor(private _popupService: PopupService) {
        if (ConfirmationModalModule.popupRegistered) {
            return;
        }

        ConfirmationModalModule.popupRegistered = true;

        this._popupService.registerPopups({
            confirmation: new PopUpDetails(ConfirmationModalComponent, 'shared', 'miscellaneous'),
        });
    }
}
