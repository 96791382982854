import { Injectable } from '@angular/core';
import { IUsersPermissionsResponse } from '@cloudmarket/permissions-contract';
import { Permission, View } from '@cloudmarket/permissions-contract/lib/permissions.enum';

import { ApiPermissionsService } from './api/api-permissions.service';

@Injectable({
    providedIn: 'root',
})
export class PermissionsService {
    constructor(private _apiPermissionsService: ApiPermissionsService) {}

    hasAccessToView(view: View): boolean {
        const userPermissions: IUsersPermissionsResponse =
            this._apiPermissionsService.getUsersPermissions();

        return userPermissions?.views.some((userView: View) => userView === view) ?? false;
    }

    hasPermission(permission: Permission): boolean {
        const userPermissions: IUsersPermissionsResponse =
            this._apiPermissionsService.getUsersPermissions();

        return (
            userPermissions?.permissions.some(
                (userPermission: Permission) => userPermission === permission
            ) ?? false
        );
    }
}
