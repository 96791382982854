import { NgModule } from '@angular/core';

import { CmModalModule } from '../../new-shared/components/cm-modal/modal.module';
import { SharedModule } from '../../shared/shared.module';

import { PopupContainerDirective } from './directives/popup-container.directive';

@NgModule({
    imports: [SharedModule, CmModalModule],
    declarations: [PopupContainerDirective],
    exports: [PopupContainerDirective],
})
export class PopupsModule {}
