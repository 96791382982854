import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { FileInputComponent } from './cm-file-input.component';

@NgModule({
    declarations: [FileInputComponent],
    imports: [CommonModule, FormsModule, TranslateModule],
    exports: [FileInputComponent],
})
export class FileInputModule {}
