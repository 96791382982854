import { ThemeMainColor } from '../../pages/your-account/enums';
import { WhitelabelingTheme } from '../enum/whitelabeling-theme.enum';

export const WHITELABEL_VARIABLE_NAMES: Record<string, string> = {
    primary: '--primary',
    secondary: '--secondary',
    primaryLighter: '--primary-lighter',
    primaryDarker: '--primary-darker',
    headerHeight: '--header-height',
};

export const WHITELABEL_THEME_COLOR_CODES: Record<WhitelabelingTheme, string> = {
    [WhitelabelingTheme.dark]: ThemeMainColor.dark,
    [WhitelabelingTheme.light]: ThemeMainColor.light,
    [WhitelabelingTheme.default]: ThemeMainColor.default,
};

export const WHITELABEL_THEME_ATTRIBUTE: string = 'theme';
export const WHITELABEL_LIGHTNESS_FACTOR: number = 0.2;
export const WHITELABEL_ALPHA_FACTOR: number = 0.15;
