import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CmInputValidationModule } from '../../../../feature-modules/design-system/components/forms/components/input-validation/cm-input-validation.module';

import { CmCheckBoxInputComponent } from './cm-check-box-input.component';

@NgModule({
    declarations: [CmCheckBoxInputComponent],
    imports: [
        CmInputValidationModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
    ],
    exports: [CmCheckBoxInputComponent],
})
export class CheckboxInputModule {}
