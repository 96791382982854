import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    Optional,
    Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appDecimalPrecision]',
})
export class DecimalPrecisionDirective implements OnChanges {
    @Input() readonly precision: number = 2;

    constructor(
        private _elementRef: ElementRef,
        @Optional() @Self() private _ngControl: NgControl
    ) {}

    ngOnChanges(): void {
        this.writeValue(this.readValue());
    }

    @HostListener('input', ['$event.target.value'])
    input(value: string): void {
        this.writeValue(value);
    }

    @HostListener('blur', ['$event.target.value'])
    blur(value: string): void {
        this.writeValue(value);
    }

    readValue(): string {
        return this._elementRef.nativeElement.value;
    }

    writeValue(newValue: string): void {
        if (!this._shouldFormatValue(newValue)) {
            return;
        }

        if (this._ngControl && this._ngControl.control) {
            this._ngControl.control.setValue(this._formatValue(newValue));
        }

        this._elementRef.nativeElement.value = newValue;
    }

    private _formatValue(value: string): string {
        return parseFloat(value).toFixed(this.precision);
    }

    private _shouldFormatValue(value: string): boolean {
        return this._getDecimalPlaces(value) > this.precision;
    }

    private _getDecimalPlaces(value: string): number {
        if (Number.isInteger(value) || value === null) {
            return 0;
        }

        if (value.includes('.')) {
            return value.split('.')[1].length;
        }

        if (value.includes(',')) {
            return value.split(',')[1].length;
        }

        return 0;
    }
}
