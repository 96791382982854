import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[gcDismiss]',
})
export class GcDismissDirective implements OnInit, OnChanges {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    @Input() gcDismissMilliseconds: number = 3000;
    @Input() gcDismiss: boolean;
    private _originElementDisplay: string;
    private _timer: number;

    constructor(private _elementRef: ElementRef) {
        this._originElementDisplay = _elementRef.nativeElement.style.display;
    }

    ngOnInit(): void {
        this._originElementDisplay = this._elementRef.nativeElement.style.display;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.gcDismiss) {
            return;
        }

        if (changes.gcDismiss.currentValue) {
            this._setupDismissCounter();
        } else {
            this._resetElementDisplay();
        }
    }

    private _setupDismissCounter(): void {
        const timerMillis: number =
            this.gcDismissMilliseconds <= 0 ? 0 : this.gcDismissMilliseconds;

        this._timer = window.setTimeout(() => {
            this._elementRef.nativeElement.style.display = 'none';
        }, timerMillis);
    }

    private _resetElementDisplay(): void {
        if (this._timer) {
            window.clearTimeout(this._timer);
        }
        this._elementRef.nativeElement.style.display = this._originElementDisplay;
    }
}
