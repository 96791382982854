import { AlertType } from '../../../shared/enum/alert-type.enum';
import { IDictionary } from '../../../shared/interfaces/dictionary.interface';

export const ALERT_CLASSES_BY_TYPE: IDictionary<string> = {
    [AlertType.primary]: 'alert-primary',
    [AlertType.secondary]: 'alert-secondary',
    [AlertType.success]: 'alert-success',
    [AlertType.danger]: 'alert-danger',
    [AlertType.error]: 'alert-danger',
    [AlertType.warning]: 'alert-warning',
    [AlertType.info]: 'alert-info',
    [AlertType.dark]: 'alert-dark',
    [AlertType.light]: 'alert-light',
};
export const ALERT_ICONS_BY_TYPE: IDictionary<string> = {
    [AlertType.primary]: 'circle-info',
    [AlertType.secondary]: 'circle-info',
    [AlertType.success]: 'circle-info',
    [AlertType.danger]: 'triangle-exclamation',
    [AlertType.error]: 'triangle-exclamation',
    [AlertType.warning]: 'triangle-exclamation',
    [AlertType.info]: 'circle-info',
    [AlertType.dark]: 'circle-info',
    [AlertType.light]: 'circle-info',
};
