import { AfterViewInit, Component, HostListener } from '@angular/core';

import { GlobalEventService } from '../../../../core/services/global-event.service';
import { AuthorizedHttpService } from '../../../../core/services/http/authorized-http.service';
import { AbstractPopup } from '../../../../feature-modules/popups/classes/abstract-popup.class';
import { HostedExchangeManagement } from '../../../../pages/customer/manage-service/hosted-exchange/hosted-exchange.management';
import { API_ENDPOINTS } from '../../../constants/api/api-endpoints.constants';
import { KeyboardKey } from '../../../enum/keyboard-key.enum';
import { GAService } from '../../../services/ga-service/ga-service';
import { InteractionType } from '../../../services/ga-service/ga-visitor-interaction';

@Component({
    selector: 'user-full-access',
    templateUrl: './user-full-access-popup.component.html',
    styleUrls: ['./user-full-access-popup.component.scss'],
})
export class UserFullAccessPopupComponent extends AbstractPopup<any> implements AfterViewInit {
    management: HostedExchangeManagement;
    modalAnimation: boolean = false;
    model: any;
    errorMessages: any;
    saving: boolean = false;
    isSelectedAllUsersWithNoPermissions: boolean = false;
    isSelectedAllUsersWithFullAccess: boolean = false;
    isSelectedAllUsersWithSendAs: boolean = false;

    constructor(
        private _eventService: GlobalEventService,
        private _authorizedHttpService: AuthorizedHttpService,
        private _gaService: GAService
    ) {
        super();
    }

    ngAfterViewInit(): void {
        const animationDelay: number = 150;

        setTimeout(() => {
            if (!this.modalAnimation) {
                this.modalAnimation = true;
            }
        }, animationDelay);
    }

    updateParams(params: any): void {
        this.management = params;
        this.model = {};
        for (const prop in params.permissionInfo) {
            if (params.permissionInfo.hasOwnProperty(prop)) {
                this.model[prop] = params.permissionInfo[prop];
            }
        }
    }

    cancel(): void {
        this.close();
    }

    selectAll(list: any[], checked: boolean): void {
        list.forEach((element: any) => {
            element.IsSelected = checked;
        });
    }

    move(sourceProp: string, targetProp: string): void {
        const source: any[] = [];
        const target: any[] = [];

        this.model[targetProp].forEach((element: any) => {
            target.push(element);
        });
        this.model[sourceProp].forEach((element: any) => {
            if (element.IsSelected) {
                target.push(element);
                element.IsSelected = false;
            } else {
                source.push(element);
            }
        });
        this.model[targetProp] = this.sortArrayByPropertyAsc(target, 'EmailAddress');
        this.model[sourceProp] = this.sortArrayByPropertyAsc(source, 'EmailAddress');
        this[`isSelectedAll${sourceProp}`] = false;
        this[`isSelectedAll${targetProp}`] = false;
    }

    sortArrayByPropertyAsc(array: any[], prop: string): any[] {
        return array.sort((a: any, b: any) => a[prop].localeCompare(b[prop]));
    }

    update(): void {
        const data: any = { sc_type: 'user', sc_name: 'exchange-mailbox-permissions' };

        this._gaService.visitorInteraction(InteractionType.saveChanges, data);

        this.saving = true;
        this._authorizedHttpService
            .put$(API_ENDPOINTS.EXCHANGE_PERMISSION.UPDATE_PERMISSION, this.model)
            .subscribe((json: any) => {
                if (json.Succeed) {
                    this.close();
                    this.errorMessages = null;
                    this._eventService.publish('refresh-exchange-permission');
                } else {
                    this.errorMessages = json.Messages;
                }

                this.saving = false;
            });
    }

    @HostListener('window:keydown', ['$event']) keyboardInput(event: KeyboardEvent): void {
        if (event.key === KeyboardKey.escape) {
            this.cancel();
        }
    }
}
