import { Inject, Injectable } from '@angular/core';

import { Browser } from '../../../shared/enum/browser.enum';
import { IDictionary } from '../../../shared/interfaces/dictionary.interface';
import { WINDOW } from '../../injection-tokens/window.injection-token';
import { IWindow } from '../../interfaces/window.interface';

@Injectable({
    providedIn: 'root',
})
export class BrowserService {
    constructor(@Inject(WINDOW) private _window: IWindow) {}

    getCurrentBrowser(): Browser {
        const userAgent: string = this._window.navigator.userAgent;
        const isCurrentBrowserIE: boolean = this._isCurrentBrowserIE(userAgent);
        const browsersRegExps: IDictionary<RegExp> = {
            [Browser.chrome]: /chrome/i,
            [Browser.safari]: /safari/i,
            [Browser.firefox]: /firefox/i,
            [Browser.opera]: /opera/i,
        };

        if (isCurrentBrowserIE) {
            return Browser.ie;
        }

        for (const key in browsersRegExps) {
            if (browsersRegExps[key].test(userAgent)) {
                return Browser[key];
            }
        }
    }

    private _isCurrentBrowserIE(userAgent: string): boolean {
        const msie: number = userAgent.indexOf('MSIE ');
        const trident: number = userAgent.indexOf('Trident/');
        const edge: number = userAgent.indexOf('Edge/');

        return msie > 0 || trident > 0 || edge > 0;
    }
}
