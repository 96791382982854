import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    IDomainChangeModel
} from '../../../../new-shared/components/modals/edit-domain-email-security/interfaces/domain-change-model.interface';
import {
    IDomainEmailSecurityModel
} from '../../../../new-shared/components/modals/edit-domain-email-security/interfaces/domain-email-security-model.types';
import {
    IEditDomainEmailSecurityParams
} from '../../../../new-shared/components/modals/edit-domain-email-security/interfaces/edit-domain-email-security-params.interface';
import {
    IDisasterRecoveryInfoModel,
    IDisasterRecoveryConfirmModel,
    IDisasterRecoveryModel
} from '../../../../new-shared/components/modals/email-security-disaster-recovery/interfaces/email-security-disaster-recovery-modal.types';
import { API_ENDPOINTS } from '../../../../shared/constants/api/api-endpoints.constants';
import { IExecutionResult } from '../../../interfaces/execution-result.interface';
import { AuthorizedHttpService } from '../../http/authorized-http.service';

import { IAvailableManagersModel, IExchangeDeleteModel, ISpamLevel } from './types/api-email-security.types';

@Injectable({
    providedIn: 'root',
})
export class ApiEmailSecurityService {
    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    setDKIMActivation$(
        params: IEditDomainEmailSecurityParams,
        model: IDomainEmailSecurityModel,
        active: boolean
    ): Observable<IExecutionResult<null>> {
        return this._authorizedHttpService.put$(
            API_ENDPOINTS.EXCHANGE_DOMAIN.SET_DKIM_ACTIVATION,
            {
                OrganisationId: params.management.organisationId,
                Domain: model.Name,
                Active: active,
            }
        );
    }

    setSPFActivation$(domainChangeModel: IDomainChangeModel): Observable<IExecutionResult<null>> {
        return this._authorizedHttpService.put$(
            API_ENDPOINTS.EXCHANGE_DOMAIN.SET_SPF_ACTIVATION,
            domainChangeModel
        );
    }

    setSpoofFilteringActivation$(
        domainChangeModel: IDomainChangeModel
    ): Observable<IExecutionResult<null>> {
        return this._authorizedHttpService.put$(
            API_ENDPOINTS.EXCHANGE_DOMAIN.SET_SPOOF_FILTERING_ACTIVATION,
            domainChangeModel
        );
    }

    getSpamLevels$(): Observable<ISpamLevel[]> {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.EMAIL_SECURITY.GET_SPAM_LEVELS)
            .pipe(
                this._authorizedHttpService.handleApiErrorOperator$,
                this._authorizedHttpService.mapResponseOperator$<ISpamLevel[]>()
            );
    }

    getRecoveryInfo$(
        disasterRecoveryModel: IDisasterRecoveryModel
    ): Observable<IExecutionResult<IDisasterRecoveryInfoModel>> {
        return this._authorizedHttpService.post$(
            API_ENDPOINTS.ES_DISASTER_RECOVERY.GET_DISASTER_RECOVERY_INFO,
            disasterRecoveryModel
        );
    }

    setRecoveryConfirmPage$(
        disasterRecoveryModel: IDisasterRecoveryModel
    ): Observable<IExecutionResult<IDisasterRecoveryConfirmModel>> {
        return this._authorizedHttpService.post$(
            API_ENDPOINTS.ES_DISASTER_RECOVERY.SET_RECOVERY_CONFIRM_PAGE,
            disasterRecoveryModel
        );
    }

    startRecovery$(
        disasterRecoveryModel: IDisasterRecoveryModel
    ): Observable<IExecutionResult<boolean>> {
        return this._authorizedHttpService.post$(
            API_ENDPOINTS.ES_DISASTER_RECOVERY.START_RECOVERY,
            disasterRecoveryModel
        );
    }

    prepareExchangeDelete$(organisationId:string, userRef: string | number): Observable<IExchangeDeleteModel> {
        return this._authorizedHttpService
            .post$(
                API_ENDPOINTS.EMAIL_SECURITY.PREPARE_EXCHANGE_DELETE(organisationId),
                { UserRef: userRef }
            ).pipe(
                this._authorizedHttpService.handleApiErrorOperator$,
                this._authorizedHttpService.mapResponseOperator$<IExchangeDeleteModel>()
            );
    }

    getAvailableManagers$(organisationId: string, userRef: string): Observable<IAvailableManagersModel> {
        return this._authorizedHttpService
            .get$(
                API_ENDPOINTS.EMAIL_SECURITY.GET_AVAILABLE_MANAGERS(organisationId),
                { UserRef: userRef }
            )
            .pipe(
                this._authorizedHttpService.handleApiErrorOperator$,
                this._authorizedHttpService.mapResponseOperator$<IAvailableManagersModel>()
            )
    }
}
