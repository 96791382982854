import { Component, EventEmitter, Input, Output } from '@angular/core';

import {
    AbstractControlValueAccessor,
    abstractValueAccessorProvider,
} from '../../../../../shared/classes/abstract-control-value-accessor.class';
import { ITableSortingHeaderValue } from '../../interfaces/table-sorting-header-value.interface';

@Component({
    selector: 'cm-sorting-header-cell',
    templateUrl: './cm-sorting-header-cell.component.html',
    styleUrls: ['./cm-sorting-header-cell.component.scss'],
    providers: [abstractValueAccessorProvider(CmSortingHeaderCellComponent)],
})
export class CmSortingHeaderCellComponent extends AbstractControlValueAccessor<ITableSortingHeaderValue> {
    @Input() header: string = '';
    @Output() readonly sortChange: EventEmitter<ITableSortingHeaderValue> = new EventEmitter();

    writeValue(value: ITableSortingHeaderValue): void {
        if (value !== this.value && value !== 0) {
            this.sortChange.emit(value);
        }

        super.writeValue(value);
    }

    sort(): void {
        if (this.value === 1) {
            this.writeValue(-1);
        } else if (this.value === 0) {
            this.writeValue(-1);
        } else {
            this.writeValue(1);
        }
    }
}
