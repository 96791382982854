import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { LoadingIndicatorService } from '../../services/loading-indicator.service';

@Component({
    selector: 'app-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent {
    isLoaderVisible$: Observable<boolean> = this._loadingIndicatorService.getLoaderVisibility$();

    constructor(private readonly _loadingIndicatorService: LoadingIndicatorService) {}
}
