import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CloudMarketPopupType, ICloudMarketPopupSchemaItem } from '../../cm-popup.types';

@Component({
    selector: 'cm-popup-text-input',
    templateUrl: './cm-popup-text-input.component.html',
})
export class CloudMarketPopupTextInputComponent {
    @Input() schemaItem: ICloudMarketPopupSchemaItem;
    @Input() data: string | number;
    @Input() isDisabled: boolean;
    @Output() readonly dataChange: EventEmitter<string | number> = new EventEmitter<
        string | number
    >();
    @Output() readonly validate: EventEmitter<ICloudMarketPopupSchemaItem> =
        new EventEmitter<ICloudMarketPopupSchemaItem>();

    changeValue(val: string): void {
        if (this.schemaItem.Type === CloudMarketPopupType.number && val !== '') {
            this.data = Number(val) || val;
        } else {
            this.data = val;
        }

        this.dataChange.emit(this.data);
        this.validate.emit(this.schemaItem);
    }
}
