import { IAdditionalServiceAgreement } from './interfaces/additional-service-agreement.interface';
import { IParentChildAgreement } from './interfaces/parent-child-agreement.interface';

export class ParentChildAgreementModel implements IParentChildAgreement {
    agreement: IAdditionalServiceAgreement;
    groupId?: number;
    parentId: number;

    constructor(agreement: IAdditionalServiceAgreement, parentId: number, groupId?: number) {
        this.agreement = agreement;
        this.groupId = groupId;
        this.parentId = parentId;
    }
}
