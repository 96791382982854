import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { LoginType } from '../../../../shared/enum/login/login-type.enum';
import { IFinalizingLoginStrategy } from '../../interfaces/finalizing-login-strategy.interface';
import { ILoginState } from '../../interfaces/login-state.interface';
import { ILoginStrategy } from '../../interfaces/login-strategy.interface';
import { ILogin } from '../../interfaces/login.interface';

import {
    LoginFinalizingStrategySelector
} from './login-finalizing-strategy/login-finalizing-strategy-selector.service';
import { LoginStrategySelector } from './login-strategy/login-strategy-selector.service';



@Injectable({
    providedIn: 'root',
})
export class LoginContext {
    loginStatus$: Observable<ILoginState>;
    private _loggingIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _loginStrategy: ILoginStrategy | null = null;
    private _loginStrategy$: BehaviorSubject<ILoginStrategy> = new BehaviorSubject<ILoginStrategy>(null);
    private _finalizingLoginStrategy: IFinalizingLoginStrategy;

    constructor(
        private _router: Router,
        private _loginStrategySelector: LoginStrategySelector,
        private _loginFinalizingStrategySelector: LoginFinalizingStrategySelector
    ) {
        this.loginStatus$ = this._getLoginStatusTrigger$();
    }

    setStrategy(loginType: LoginType): void {
        const loginStrategy: ILoginStrategy = this._loginStrategySelector.select(loginType);
        this._loginStrategy = loginStrategy;
        this._loginStrategy$.next(loginStrategy);
    }

    setFinalizingStrategy(finalizingLoginStrategy: IFinalizingLoginStrategy): void {
        this._finalizingLoginStrategy = finalizingLoginStrategy;
    }

    executeStrategy$(loginData?: ILogin): Observable<void> {
        return this._executeLogin$(loginData);
    }

    tryExecuteLoginStrategy$(): Observable<void> {
        return this._setLoginStrategy$(this._getParams())
            .pipe(
                switchMap(() => this._executeLogin$()));
    }

    private _executeLogin$(loginData?: ILogin): Observable<void> {
        if (!this._loginStrategy) {
            return EMPTY;
        }

        this._loggingIn$.next(true);

        return this._loginStrategy.login$(loginData)
            .pipe(
                switchMap(() => this._executeFinalizeLogin$()),
                take(1),
                finalize(() => {
                    this._loggingIn$.next(false);
                }),
            catchError((error: unknown) => throwError(error)));
    }

    private _executeFinalizeLogin$(): Observable<void> {
        if (this._finalizingLoginStrategy) {
            return this._finalizingLoginStrategy.finalizeLogin$(this._getParams());
        }

        return this._setFinalizingLoginStrategy$()
            .pipe(switchMap((finalizingLoginStrategy: IFinalizingLoginStrategy) => {
                if (finalizingLoginStrategy) {
                    return this._finalizingLoginStrategy.finalizeLogin$(this._getParams());
                }

                return of(null);
            }));
    }

    private _setLoginStrategy$(params: Params): Observable<ILoginStrategy> {
        return this._loginStrategySelector.autoSelect$(params)
            .pipe(
                tap((loginStrategy: ILoginStrategy) => {
                    this._loginStrategy = loginStrategy;
                    this._loginStrategy$.next(loginStrategy);
                }));
    }

    private _setFinalizingLoginStrategy$(): Observable<IFinalizingLoginStrategy> {
        return this._loginFinalizingStrategySelector.select$(this._getParams())
            .pipe(
                tap((finalizingLoginStrategy: IFinalizingLoginStrategy) => this._finalizingLoginStrategy = finalizingLoginStrategy)
            );
    }

    private _getLoginStatusTrigger$(): Observable<ILoginState> {
        return combineLatest([
            this._loginStrategy$,
            this._loggingIn$
        ])
            .pipe(
                switchMap(([loginStrategy, loggingIn]: [ILoginStrategy, boolean]) => {
                    if (loginStrategy?.status$) {
                        return loginStrategy.status$
                            .pipe(
                                map((loginState: ILoginState) => {
                                    const newStatus: ILoginState = {
                                        isProcessed: loggingIn ? true : loginState?.isProcessed,
                                        username: loginState?.username,
                                        error: loginState?.error,
                                    };

                                    return newStatus;
                                })
                            );
                    }

                    const status: ILoginState = {
                        isProcessed: loggingIn,
                    };

                    return of(status);

                }),
            );
    }

    private _getParams(): Params {
        return this._router.parseUrl(this._router.url).queryParams;
    }
}
