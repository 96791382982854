import {
    AfterViewInit,
    Component,
    ElementRef,
    NgZone,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { ROUTES_PATHS } from '../../../../shared/constants/routes.constants';
import { IPlatformModule, IRedirectLink } from '../../../../shared/interfaces/platform-module.interface';

declare let PlatformModule: IPlatformModule;

@Component({
    selector: 'cm-new-footer-wrapper',
    templateUrl: './new-footer-wrapper.component.html',
})
export class NewFooterWrapperComponent implements AfterViewInit, OnDestroy {
    @ViewChild('footerRoot', { static: true }) private _footerRoot: ElementRef;
    private _footerInstance: { unmount: () => void };

    constructor(private _router: Router, private _ngZone: NgZone) {}

    ngAfterViewInit(): void {
        this._render();
    }

    ngOnDestroy(): void {
        this._footerInstance?.unmount();
    }

    private _render(): void {
        PlatformModule.renderFooter({
            root: this._footerRoot.nativeElement,
            props: {
                onRedirect: this._redirectionHandler.bind(this),
            },
        }).then((footer: { unmount: () => void }) => this._footerInstance = footer);
    }

    private _redirectionHandler(redirectLink: IRedirectLink): void {
        this._ngZone.run(() => {
            const urls: string[] = ['', '/'];
            
            const fallbackUrl: string = urls.includes(redirectLink.url) ? `/${ROUTES_PATHS.DASHBOARD.PAGE}` : redirectLink.url;

            this._router.navigateByUrl(fallbackUrl).then();
        });
    }
}
