import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'giacomMoney' })
export class GiacomMoneyPipe implements PipeTransform {
    transform(val: string): string {
        if (val) {
            return `£${val}`;
        }
    }
}
