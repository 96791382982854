import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, timer } from 'rxjs';
import { distinctUntilChanged, map, skip, startWith, takeWhile } from 'rxjs/operators';

@Injectable()
export class LoadingIndicatorService {
    private readonly _flickerDelayDetectionDuration: number = 400;
    private readonly _flickerMinDuration: number = 1000;
    private _requestsCount: number = 0;
    private _requestsCount$: BehaviorSubject<number> = new BehaviorSubject(0);
    private _requestCountWithoutFlicker$: Observable<number> = this._nonFlickerLoader$(
        this._requestsCount$,
        this._flickerDelayDetectionDuration,
        this._flickerMinDuration
    );

    getLoaderVisibility$(): Observable<boolean> {
        return this._requestCountWithoutFlicker$.pipe(
            map((requestCount: number | null) => !!requestCount)
        );
    }

    getRequestsCount$(): Observable<number> {
        return this._requestsCount$.asObservable();
    }

    increaseRequestCount(): void {
        this._requestsCount += 1;
        this._requestsCount$.next(this._requestsCount);
    }

    decreaseRequestCount(): void {
        this._requestsCount -= 1;
        this._requestsCount$.next(this._requestsCount);
    }

    private _nonFlickerLoader$<T>(
        data$: Observable<T>,
        delay: number = 1000,
        duration: number = 1000
    ): Observable<T | null> {
        const loading$: Observable<boolean> = timer(delay, duration).pipe(
            map((timerValue: number) => !timerValue),
            takeWhile<boolean>(Boolean, true),
            startWith(false)
        );

        return combineLatest([data$.pipe(startWith(null)), loading$]).pipe(
            takeWhile(([data, loading]: [T, boolean]) => data !== null || loading, true),
            map(([data, loading]: [T, boolean]) => (loading ? null : data)),
            skip(1),
            distinctUntilChanged()
        );
    }
}
