import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IExecutionResult } from '../../../../../core/interfaces/execution-result.interface';
import { ApiCustomerService } from '../../../../../core/services/api/customer/api-customer.service';
import { ICustomerDetailsModel } from '../../../../../core/services/api/customer/types/customer-details-model.interface';
import { DefaultPageRouteService } from '../../../../../core/services/default-page-route.service';
import { GAService } from '../../../../../shared/services/ga-service/ga-service';
import { IFinalizingLoginStrategy } from '../../../interfaces/finalizing-login-strategy.interface';
import { ILoginUserResponse } from '../../../interfaces/login-user-response.interface';
import { LoginService } from '../login-strategy/login.service';
import { LoginStorageService } from '../storage/login-storage.service';


@Injectable({
    providedIn: 'root',
})
export class CloudLoginFinalizingService implements IFinalizingLoginStrategy {
    private _returnUrl: string = '';
    constructor(
        private _router: Router,
        private _gaService: GAService,
        private _apiCustomerService: ApiCustomerService,
        private _defaultPageRouteService: DefaultPageRouteService,
        private _loginStorageService: LoginStorageService,
        private _translateService: TranslateService,
        private _loginService: LoginService,
    ) {}

    finalizeLogin$(params?: Params): Observable<void> {
        this._returnUrl = params?.return || '';

        return this._processLogin$();
    }

    private _processLogin$(): Observable<void> {
        return this._getLoginDetails$()
            .pipe(
                switchMap((loginUserResponse: ILoginUserResponse) => {
                    this._gaService.setResellerData(loginUserResponse.mex, loginUserResponse.gaUserReference);

                    return this._finalizeLoginProcess$(loginUserResponse);
                }),
                catchError(() => throwError({error_description: this._translateService.instant('LOGIN.BACKEND_ERROR')}))
            )
    }

    private _finalizeLoginProcess$(loginUserResponse: ILoginUserResponse): Observable<void> {
        if (loginUserResponse.endUserAdmin) {
            return this._getEndCustomerFromAPIAndSetUserInLocalStorage$();
        }

        if (loginUserResponse.isEndUser) {
            return this._defaultPageRouteService.getCloudDefaultPageUrl$()
                .pipe(
                    map((url: string) => {
                        this._router.navigateByUrl(url)
                    })
                )
        }

        if (this._returnUrl) {
            this._router.navigateByUrl(this._returnUrl);

            return of(null);
        }

        return of(this._defaultPageRouteService.goToDefaultPage());
    }

    private _getLoginDetails$(): Observable<ILoginUserResponse> {
        return this._loginService.getLoginUser$();
    }

    private _getEndCustomerFromAPIAndSetUserInLocalStorage$(): Observable<void> {
        return this._apiCustomerService
            .getCustomerEndUserAdmin$()
            .pipe(
                switchMap((result: IExecutionResult<ICustomerDetailsModel>) => {
                    const organisationId: string = result.Result.Id;

                    this._loginStorageService.setOrganisationIdUserResourceInLocalStorage(organisationId);

                    return this._defaultPageRouteService.getCloudDefaultPageUrl$(organisationId);
                }),
                map((url: string) => {
                    this._router.navigateByUrl(url)
                }),
            );
    }
}
