import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

import {
    AbstractControlValueAccessor,
    abstractValueAccessorProvider,
} from '../../../../../../shared/classes/abstract-control-value-accessor.class';
import { KEYBOARD_NUMBER_KEYS } from '../../../../../../shared/constants/keyboard-number-keys.constants';
import { REGEX } from '../../../../../../shared/constants/regexp/regex.constants';
import { KeyboardKey } from '../../../../../../shared/enum/keyboard-key.enum';

@Component({
    selector: 'cm-qty-counter-input',
    templateUrl: './cm-qty-counter-input.component.html',
    styleUrls: ['./cm-qty-counter-input.component.scss'],
    providers: [abstractValueAccessorProvider(CmQtyCounterInputComponent)],
})
export class CmQtyCounterInputComponent
    extends AbstractControlValueAccessor<number>
    implements OnChanges
{
    @Input() textOnlyMode: boolean = false;
    @Input() isZeroAlwaysValid: boolean = false;
    @Input()
    min: number = 0;
    @Input()
    max: number | null = null;
    @Input()
    disabled: boolean = false;
    @ViewChild('qtyInput') qtyInput: ElementRef<HTMLInputElement>;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.min) {
            const valueAfterValidation: number = this._revalidateQuantityAgainstMin(this.value);

            if (valueAfterValidation !== this.value) {
                this.writeValue(valueAfterValidation);
            }
        }
        if (changes.max) {
            const valueAfterValidation: number = this._revalidateQuantityAgainstMax(this.value);

            if (valueAfterValidation !== this.value) {
                this.writeValue(valueAfterValidation);
            }
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: number): void {
        const revalidatedValue: number = this._revalidateValue(value);

        if (revalidatedValue !== value) {
            const qtyInput: HTMLInputElement | undefined = this.qtyInput?.nativeElement;

            if (qtyInput) {
                qtyInput.value = revalidatedValue.toString();
            }
        }

        super.writeValue(revalidatedValue || 0);
    }

    onIncrementQuantity(): void {
        if (this.disabled) {
            return;
        }

        if (this.max !== null && this.value >= this.max) {
            return;
        }

        if (this.value === 0 && this.min > 0) {
            this.writeValue(this.min);

            return;
        }

        this.value++;
    }

    onDecrementQuantity(): void {
        if (this.disabled) {
            return;
        }

        if (this.value <= this.min) {
            if (this.isZeroAlwaysValid) {
                this.writeValue(0);
            }

            return;
        }

        this.value--;
    }

    onBlur(event: FocusEvent): void {
        const eventTargetValue: string = (event.currentTarget as HTMLInputElement).value;

        if (eventTargetValue === '') {
            this.writeValue(0);
        }
    }

    onKeyUp(event: KeyboardEvent): void {
        const eventTargetValue: string = (event.currentTarget as HTMLInputElement).value;

        if (this.disabled) {
            return;
        }

        if (event.key === KeyboardKey.period) {
            event.preventDefault();
        } else if (!(this._isNumberKey(event.key) || this._isInvalidKey(event.key))) {
            event.preventDefault();
        } else if (event.key === KeyboardKey.backspace && eventTargetValue === '') {
            // setting value to 0 would automatically set it to min if the min was higher than 0 so
            // it is impossible to change value from 1-2 etc. (after removing 1 it would come back)
            // case now handled on blur instead
            // this.writeValue(0);
        } else {
            const newValueNumeric: number = Number(eventTargetValue);

            this.writeValue(
                Number.isInteger(newValueNumeric) ? newValueNumeric : Math.ceil(newValueNumeric)
            );
        }
    }

    private _isNumberKey(keyCode: string): boolean {
        return KEYBOARD_NUMBER_KEYS.some((numberKeyCode: string) => keyCode === numberKeyCode);
    }

    private _isInvalidKey(keyCode: string): boolean {
        return REGEX.onlyNonNumeric.test(keyCode);
    }

    private _revalidateValue(valueToValidate?: number): number {
        let valueAfterValidation: number | undefined = valueToValidate;

        if (typeof valueToValidate === 'undefined') {
            valueAfterValidation = this.value;
        }

        valueAfterValidation = this._revalidateQuantityAgainstMin(valueAfterValidation);
        valueAfterValidation = this._revalidateQuantityAgainstMax(valueAfterValidation);

        return valueAfterValidation;
    }

    private _revalidateQuantityAgainstMin(valueToValidate: number): number {
        if (this.min > 0 && valueToValidate < this.min) {
            if (this.isZeroAlwaysValid) {
                return 0;
            }

            return this.min;
        }

        return valueToValidate;
    }

    private _revalidateQuantityAgainstMax(valueToValidate: number): number {
        if (this.max !== null && valueToValidate > this.max) {
            return this.max;
        }

        return valueToValidate;
    }
}
