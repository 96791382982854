export enum RunningState {
    setUpInProgress = 0,
    runningNormally = 1,
    resellerSuspended = 2,
    giacomSuspended = 3,
    inMigration = 4,
    setUpFailed = 5,
    setupPending = 6,
    billingDisabled = 7,
    archiveOnly = 8,
    deleted = 9,
}
