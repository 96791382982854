import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable()
export class BaseUrl {
    static get baseUrl(): string {
        return environment.serviceAPI;
    }

    static get baseUrlCloudMarket(): string {
        return environment.CloudMarketServiceAPI;
    }

    static get authUrl(): string {
        return environment.authAPI;
    }

    static get psaIntegrationUrl(): string {
        return environment.psaIntegrationAPI;
    }
}
