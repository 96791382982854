import { Component, Input, OnInit } from '@angular/core';

import { IExecutionResult } from '../../../../../../core/interfaces/execution-result.interface';
import { ApiOnPremiseAliasService } from '../../../../../../core/services/api/on-premise-alias/api-on-premise-alias.service';
import { IOnPremiseAlias } from '../../../../../../core/services/api/on-premise-alias/types/api-on-premise-alias.types';
import {
    GlobalEventService,
    GlobalEventSubscription,
} from '../../../../../../core/services/global-event.service';
import { PopupService } from '../../../../../../feature-modules/popups/services/popup.service';
import { EmailSecurityManagement } from '../../email-security.management';

@Component({
    selector: 'end-user-on-premise-alias',
    templateUrl: './end-user-on-premise-alias-component.html',
})
export class EndUserOnPremiseAliasComponent implements OnInit {
    @Input() management: EmailSecurityManagement;
    userEmail: string;
    alias: string = '';
    isAdding: boolean = false;
    private _refreshSettingSub: GlobalEventSubscription;

    constructor(
        private _popupService: PopupService,
        private _eventService: GlobalEventService,
        private _apiOnPremiseAliasService: ApiOnPremiseAliasService
    ) {}

    ngOnInit(): void {
        this.getAliases();
        this._refreshSettingSub = this._eventService.subscribe('refresh-on-premise-aliases', () => {
            this.getAliases();
        });
    }

    editAlias(): void {
        this._popupService.show(null, 'edit-on-premise-alias', this.management);
    }

    getAliases(): void {
        this.management.aliases = [];
        this._apiOnPremiseAliasService
            .getMailboxAliases$(this.management.mailboxAddress)
            .subscribe((results: IOnPremiseAlias[]) => {
                this.management.aliases = results;
                this.alias = '';
                this.isAdding = false;
            });
    }

    delete(alias: string): void {
        this._apiOnPremiseAliasService
            .deleteAlias$(this.management.mailboxAddress, alias)
            .subscribe((result: IExecutionResult<void>) => {
                if (result.Succeed) {
                    this.getAliases();
                }
            });
    }
}
