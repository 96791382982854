import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DeviceScreenService } from '../../../core/services/device-screen/device-screen.service';
import { ScreenSize } from '../../enum/screen-size.enum';
import { IScreenSize } from '../../interfaces/screen-size.interface';

@Injectable()
export class SubMenuFacadeService<T extends { routerLink: string; title: string }> {
    isScreenSmall$: Observable<boolean> = this._deviceScreenService
        .getScreenSizeFlags$()
        .pipe(map((deviceScreenSizeFlags: IScreenSize) => deviceScreenSizeFlags[ScreenSize.small]));

    constructor(private _deviceScreenService: DeviceScreenService, private _router: Router) {}

    getSelectedLinkName(links: T[]): string {
        const selectedLink: T = links.find((link: T) => link.routerLink.includes(this._router.url));

        return selectedLink ? selectedLink.title : '';
    }
}
