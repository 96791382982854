import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_ENDPOINTS } from '../../../../shared/constants/api/api-endpoints.constants';
import { IExecutionResult } from '../../../interfaces/execution-result.interface';
import { AuthorizedHttpService } from '../../http/authorized-http.service';

import { IOnPremiseAlias } from './types/api-on-premise-alias.types';

@Injectable({
    providedIn: 'root',
})
export class ApiOnPremiseAliasService {

    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    getMailboxAliases$(emailAddress: string): Observable<IOnPremiseAlias[]> {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.ON_PREMISE_ALIAS.GET_MAILBOX_ALIASES(emailAddress))
            .pipe(
                this._authorizedHttpService.handleApiErrorOperator$,
                this._authorizedHttpService.mapResponseOperator$<IOnPremiseAlias[]>()
            );
    }

    getOrganisationDomains$(emailAddress: string): Observable<string[]> {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.ON_PREMISE_ALIAS.GET_ORGANISATION_DOMAINS(emailAddress))
            .pipe(
                this._authorizedHttpService.handleApiErrorOperator$,
                this._authorizedHttpService.mapResponseOperator$<string[]>()
            );
    }

    deleteAlias$(emailAddress: string, alias: string): Observable<null> {
        return this._authorizedHttpService
            .delete$(API_ENDPOINTS.ON_PREMISE_ALIAS.DELETE_ALIAS(emailAddress, alias))
            .pipe(
                this._authorizedHttpService.handleApiErrorOperator$,
                this._authorizedHttpService.mapResponseOperator$<null>()
            );
    }

    addAlias$(emailAddress: string, alias: string): Observable<IExecutionResult<null>> {
        return this._authorizedHttpService.post$(
            API_ENDPOINTS.ON_PREMISE_ALIAS.ADD_ALIAS,
            {
                UserEmail: emailAddress,
                Alias: alias,
            }
        );
    }
}
