/* eslint-disable */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular-ivy';

import 'hammerjs';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
    dsn: 'https://b923468824604a279b2fa10160163cea@o240624.ingest.sentry.io/4505159577960448',
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new Sentry.Replay(),
    ],
    // on below line the value for key "enabled" is temporarily set to false in order to disable Sentry to inspect if issue from task will still persist - "23988-Long time for refresh page during M365 order" (https://giacomdev.visualstudio.com/Giacom%20Development/_boards/board/t/Aphrodite/Stories/?workitem=23988)
    enabled: false,
    // please restore value from below line once the task is completed;
    // enabled: !(window.location.hostname === 'localhost'),
    // Performance Monitoring
    sampleRate: 0.7,
    maxBreadcrumbs: 50,
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(
        () => {
            console.log('Angular finished bootstrapping your application!');
        },
        (error) => {
            console.warn('Angular was not able to bootstrap your application.');
            console.error(error);
        }
    );
