import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { SsoAuthService } from '../../../../../core/services/sso-auth/sso-auth.service';
import { LoginType } from '../../../../../shared/enum/login/login-type.enum';
import { ILoginStrategy } from '../../../interfaces/login-strategy.interface';
import { RedirectionServiceManagementService } from '../../redirect/redirection-service-management.service';

import { LoginJumpService } from './login-jump.service';
import { LoginMs365Service } from './login-ms365.service';
import { LoginNonSsoService } from './login-non-sso.service';
import { LoginSsoService } from './login-sso.service';

@Injectable({
    providedIn: 'root',
})
export class LoginStrategySelector {
    constructor(
        private _loginNonSsoService: LoginNonSsoService,
        private _ssoAuthService: SsoAuthService,
        private _loginSsoService: LoginSsoService,
        private _loginJumpService: LoginJumpService,
        private _loginMs365Service: LoginMs365Service,
        private _redirectionServiceManagementService: RedirectionServiceManagementService
    ) {
    }

    select(loginType: LoginType): ILoginStrategy {
        const loginStrategy: ILoginStrategy = this._select(loginType);

        return loginStrategy;
    }

    autoSelect$(params: Params): Observable<ILoginStrategy> {
        return this._redirectionServiceManagementService.getLoginType$(params)
            .pipe(
                switchMap((loginType: LoginType | null) => {
                   if (loginType) {
                       return of(this._select(loginType));
                   }

                   return this._ssoAuthService.isAuthenticated$()
                       .pipe(
                           map((isSsoAuthenticated: boolean) => {
                               if (this._loginJumpService.isSysOpsLogin(params)) {
                                   return this._select(LoginType.adminJump);
                               }

                               if (isSsoAuthenticated) {
                                   return this._select(LoginType.sso);
                               }

                               return null;
                           })
                       )
                })
            );
    }

    private _select(loginType: LoginType): ILoginStrategy {
        switch (loginType) {
            case LoginType.nonSso:
                return this._loginNonSsoService;
            case LoginType.ms365:
                return this._loginMs365Service;
            case LoginType.adminJump:
                return this._loginJumpService;
            case LoginType.sso:
                return this._loginSsoService;
            default:
                throw new Error(`Unknown type of login: ${loginType}`);
        }
    }
}
