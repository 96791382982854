import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { PermissionErrorComponent } from './permission-error.component';

const routes: Route[] = [{ path: 'permission-error', component: PermissionErrorComponent }];

@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class PermissionErrorRoutingModule {}
