import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'friendlyDateTime',
})
export class FriendlyDateTimePipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private _localeId: string) {}

    // @ts-ignore
    transform(value: string | Date): string | null {
        const datePipe: DatePipe = new DatePipe(this._localeId);

        if (value) {
            const date: Date = new Date(value);

            if (this.isToday(date)) {
                return datePipe.transform(date, 'h:mm a');
            }

            if (this.isCurrentYear(date)) {
                return datePipe.transform(date, 'd MMM');
            }

            return datePipe.transform(date, 'd/M/yy');
        }

        return value as string;
    }

    isToday(dateValue: Date): boolean {
        const today: Date = new Date();

        return (
            dateValue.getDate() === today.getDate() &&
            dateValue.getMonth() === today.getMonth() &&
            dateValue.getFullYear() === today.getFullYear()
        );
    }

    isCurrentYear(dateValue: Date): boolean {
        const currentYear: number = new Date().getFullYear();

        return dateValue.getFullYear() === currentYear;
    }
}
