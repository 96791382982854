import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { LiveChatComponent } from './live-chat.component';

@NgModule({
    imports: [SharedModule],
    declarations: [LiveChatComponent],
    exports: [LiveChatComponent],
})
export class LiveChatModule {}
