import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IFeatureFlag } from '../../interfaces/feature-flag.interface';

import { ApiPermissionsService } from './api-permissions.service';


@Injectable({
    providedIn: 'root',
})
export class ApiFeatureFlagsService {
    constructor(private _apiPermissionsService: ApiPermissionsService) {}

    isFeatureFlagEnabled(featureName: string): boolean {
        const featuresFlags: IFeatureFlag[] = this._apiPermissionsService.getFeatureFlags();

        return this._checkFlag(featuresFlags, featureName);
    }

    isFeatureFlagEnabled$(featureName: string): Observable<boolean> {
        return this._apiPermissionsService.getFeatureFlags$()
            .pipe(
                map((featuresFlags: IFeatureFlag[]) => this._checkFlag(featuresFlags, featureName))
            )
    }

    private _checkFlag(flags: IFeatureFlag[], featureName: string): boolean {
        return flags.some(
            (featureFlag: IFeatureFlag) =>
                featureFlag.featureName === featureName && featureFlag.enabled
        )
    }
}
