import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import { AuthService } from '../../feature-modules/auth/services/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class ResourceHubService {
    constructor(private _authService: AuthService) {}

    getToken(): string {
        return this._authService.getToken();
    }

    redirectToResourceHub(token: string, returnUrl: string): void {
        window.location.href = `${environment.umbracoSsoUrl}?token=${token}&returnUrl=${returnUrl}`;
    }
}
