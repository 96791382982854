export enum CustomerFlow {
    users = 1,
    services = 2,
    activity = 3,
    billing = 4,
    cloudMarketAccess = 5,
    editStreamAccess = 6,
    manage = 7,
    m365AccountLink = 8,
}
