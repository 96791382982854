import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Optional,
    Output,
    Self,
} from '@angular/core';
import {
    AbstractControl,
    UntypedFormControl,
    NgControl,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';

import { AbstractControlValueAccessor } from '../../../../../../shared/classes/abstract-control-value-accessor.class';

@Component({
    selector: 'cm-text-input',
    templateUrl: './cm-text-input.component.html',
    styleUrls: ['./cm-text-input.component.scss'],
})
export class CmTextInputComponent
    extends AbstractControlValueAccessor<string>
    implements AfterViewInit
{
    @Input() label: string = 'field';
    @Input() guidance: string = '';
    @Input() id: string = '';
    @Input() autoFocus: boolean = false;
    @Input() autoWidth: boolean = false;
    @Input() required: boolean = false;
    @Input() type: string = 'text';
    @Input() labelColor: string = '';
    @Input() hideRequiredLabel: boolean = false;
    @Input() prefix: string;
    @Input() suffix: string;
    @Input() inputSizeLarge: boolean = false;
    @Input() placeholder: string = '';
    @Input() tooltipData: string = '';
    @Output() readonly inInputValueChange: EventEmitter<string> = new EventEmitter<string>();
    isValueOptional: boolean = false;
    defaultControl: UntypedFormControl = new UntypedFormControl('', [Validators.required]);

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        protected _changeDetectorRef: ChangeDetectorRef
    ) {
        super();

        if (ngControl) {
            ngControl.valueAccessor = this;
        }
    }

    ngAfterViewInit(): void {
        this.setIsValueOptional();
        this._changeDetectorRef.detectChanges();
    }

    handleInputChange(newInputValue: string): void {
        if (this.value !== newInputValue) {
            this.writeValue(newInputValue);
            this.inInputValueChange.emit(newInputValue);
        }
    }

    setIsValueOptional(): void {
        const dummyControl: AbstractControl = new UntypedFormControl();
        const validatorFn: ValidatorFn | null = (this.ngControl?.control || this.defaultControl)
            .validator;
        const validator: ValidationErrors = validatorFn ? validatorFn(dummyControl) : {};

        this.isValueOptional = !validator?.required;
    }
}
