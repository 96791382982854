import { Component, OnDestroy, OnInit } from '@angular/core';
import { Permission } from '@cloudmarket/permissions-contract';
import { Observable, of, Subject } from 'rxjs';

import { AccountSettingsService } from '../../../../core/services/account-settings.service';
import { ApiFeatureFlagsService } from '../../../../core/services/api/api-feature-flags.service';
import { PermissionsService } from '../../../../core/services/permissions.service';
import { SingleExperienceUserService } from '../../../../core/services/single-experience/single-experience-user.service';
import { SsoAuthService } from '../../../../core/services/sso-auth/sso-auth.service';
import { IUserSettingsModel } from '../../../../models/user/user-settings.model';
import { FEATURE_FLAGS } from '../../../../shared/constants/features/feature-flags.constants';
import { STORAGE_KEYS } from '../../../../shared/constants/storage-keys.constants';
import { UserType } from '../../../../shared/enum/user-type.enum';
import { WhitelabelService } from '../../../../shared/services/whitelabel.service';
import { LayoutService } from '../../providers/layout.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {    
    destroyTrigger$: Subject<void> = new Subject();
    isPreviousUserTypeExists$: Observable<boolean> = of(false);
    isSysOpsAdmin: boolean = this._ssoAuthService.isAdminJump;
    isSingleExperienceEnabled$: Observable<boolean> = this._singleExperienceUserService.isEnabledForUser$();

    get userSettings(): IUserSettingsModel {
        return this._accountSettingsService.userSettingsModel;
    }

    /**
     * Resellers and Engineers with the ability to raise
     * support tickets are allowed to use live chat
     */
    get showChat(): boolean {
        return this._permissionsService.hasPermission(Permission.liveChat);
    }

    constructor(
        private _accountSettingsService: AccountSettingsService,
        private _permissionsService: PermissionsService,
        private _layoutService: LayoutService,
        private _ssoAuthService: SsoAuthService,
        private _apiFeatureFlagService: ApiFeatureFlagsService,
        private _whitelabelService: WhitelabelService,
        private _singleExperienceUserService: SingleExperienceUserService
    ) {}

    ngOnInit(): void {
        this._accountSettingsService.getUserSettings();
        const userType: string = localStorage.getItem(STORAGE_KEYS.USERTYPE);
        this.isPreviousUserTypeExists$ = this._layoutService.getIsPreviousTypePresent$();

        // Once feature flag is set to true on live environment, we can drop it
        if(
            !this._apiFeatureFlagService.isFeatureFlagEnabled(FEATURE_FLAGS.DISABLE_PARTNER_LEVEL_WHITELABEL) || 
            (
                userType !== UserType.reseller && 
                userType !== UserType.engineer
            )
        ) {
            this._applyTheme();
        }
    }

    ngOnDestroy(): void {
        this.destroyTrigger$.next();
        this.destroyTrigger$.complete();
    }

    private _applyTheme(): void {
        this._whitelabelService.applyTheme();
    }
}
