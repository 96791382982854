import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';

import { IExecutionResult } from '../../../../core/interfaces/execution-result.interface';
import { ApiEmailSecurityService } from '../../../../core/services/api/email-security/api-email-security.service';
import { AbstractPopup } from '../../../../feature-modules/popups/classes/abstract-popup.class';
import { DATE_FORMAT } from '../../../../shared/constants/datetime.constants';
import { AlertType } from '../../../../shared/enum/alert-type.enum';
import { ModalVariant } from '../../cm-modal/modal.types';

import {
    DISASTER_RECOVERY_INFO_MODEL_INIT_VALUES,
    DISASTER_RECOVERY_MODEL_INIT_VALUES,
    DISASTER_RECOVERY_CONFIRM_MODEL_INIT_VALUES
} from './config/email-security-disaster-recovery-models.config';
import { EmailSecurityDisasterRecoveryActiveStep } from './enums/email-security-disaster-recovery-active-step.enum';
import {
    DISASTER_RECOVERY_FIRST_STEP_FORM_FIELDS,
    DISASTER_RECOVERY_SECOND_STEP_FORM_FIELDS,
    disasterRecoveryFirstStepFormConfig,
    disasterRecoverySecondStepFormConfig,
} from './forms/email-security-disaster-recovery-form.config';
import {
    IDisasterRecoveryConfirmModel,
    IDisasterRecoveryInfoModel,
    IDisasterRecoveryModel,
    IEmailSecurityDisasterRecoveryParams,
} from './interfaces/email-security-disaster-recovery-modal.types';

@Component({
    selector: 'app-email-security-disaster-recovery',
    styleUrls: ['./email-security-disaster-recovery-modal.component.scss'],
    templateUrl: './email-security-disaster-recovery-modal.component.html',
})
export class EmailSecurityDisasterRecoveryModalComponent extends AbstractPopup<IEmailSecurityDisasterRecoveryParams> implements OnInit {
    disasterRecoveryFirstStepForm: UntypedFormGroup;
    disasterRecoverySecondStepForm: UntypedFormGroup;
    isPasswordVisible: boolean = false;
    activeStep: EmailSecurityDisasterRecoveryActiveStep =
        EmailSecurityDisasterRecoveryActiveStep.first;
    address: string;
    disasterRecoveryModel: IDisasterRecoveryModel = DISASTER_RECOVERY_MODEL_INIT_VALUES;
    disasterRecoveryInfoModel: IDisasterRecoveryInfoModel = DISASTER_RECOVERY_INFO_MODEL_INIT_VALUES;
    disasterRecoveryConfirmModel: IDisasterRecoveryConfirmModel = DISASTER_RECOVERY_CONFIRM_MODEL_INIT_VALUES;
    minDate: Date = new Date();
    maxDate: Date = new Date();
    errorMessages: string[];
    readonly alertType: typeof AlertType = AlertType;
    readonly modalVariant: typeof ModalVariant = ModalVariant;
    readonly steps: typeof EmailSecurityDisasterRecoveryActiveStep =
        EmailSecurityDisasterRecoveryActiveStep;

    readonly firstStepFormFields: typeof DISASTER_RECOVERY_FIRST_STEP_FORM_FIELDS =
        DISASTER_RECOVERY_FIRST_STEP_FORM_FIELDS;
    readonly secondStepFormFields: typeof DISASTER_RECOVERY_SECOND_STEP_FORM_FIELDS =
        DISASTER_RECOVERY_SECOND_STEP_FORM_FIELDS;

    constructor(
        private _translateService: TranslateService,
        private _apiEmailSecurityService: ApiEmailSecurityService,
        private _fb: UntypedFormBuilder
    ) {
        super();
    }

    updateParams(params: IEmailSecurityDisasterRecoveryParams): void {
        this.address = params.address;
    }

    emailsDisasterRecovery(): void {
        this.errorMessages = [];

        const startDate: dayjs.Dayjs = this._convertEpValueToDayJsObject(this.disasterRecoveryConfirmModel.EpFrom)
        const endDate: dayjs.Dayjs = this._convertEpValueToDayJsObject(this.disasterRecoveryConfirmModel.EpFrom)

        this.disasterRecoveryModel.Password = this.disasterRecoverySecondStepForm.get(
            this.secondStepFormFields.password
        ).value;

        this.disasterRecoveryModel.StartDate = startDate;
        this.disasterRecoveryModel.EndDate = endDate;
        this._apiEmailSecurityService.startRecovery$(this.disasterRecoveryModel).subscribe({
            next: (result: IExecutionResult<boolean>) => {
                if (result.Result) {
                    this.activeStep = this.steps.third;
                } else {
                    this.errorMessages.push(result.Error);
                }
            },
            error: () => {
                this.errorMessages.push(
                    this._translateService.instant('COMMONS.GENERIC_ERROR_MESSAGE_ALT')
                );
            }
        });
    }

    showPassword(): void {
        this.isPasswordVisible = !this.isPasswordVisible;
    }

    goToFirstStep(): void {
        this.errorMessages = [];
        this.activeStep = this.steps.first;
    }

    next(): void {
        this.disasterRecoveryModel = {
            ...this.disasterRecoveryModel,
            StartDate: this.disasterRecoveryFirstStepForm.get(
                this.firstStepFormFields.startDate
            ).value,
            EndDate: this.disasterRecoveryFirstStepForm.get(
                this.firstStepFormFields.endDate
            ).value,
            Deliver: this.disasterRecoveryFirstStepForm.get(
                this.firstStepFormFields.deliver
            ).value,
        };
        this._apiEmailSecurityService.setRecoveryConfirmPage$(this.disasterRecoveryModel).subscribe({
            next: (result: IExecutionResult<IDisasterRecoveryConfirmModel>) => {
                if (result.Succeed) {
                    this.disasterRecoveryConfirmModel = result.Result;
                    this.activeStep = this.steps.second;
                } else {
                    this.errorMessages = result.Messages;
                }
            },
            error: () => {
                this.errorMessages.push(
                    this._translateService.instant('COMMONS.GENERIC_ERROR_MESSAGE_ALT')
                );
            }
        });
    }

    ngOnInit(): void {
        this._initForms();
        this._loadData();
    }

    private _initForms(): void {
        this.disasterRecoveryFirstStepForm = this._fb.group(
            disasterRecoveryFirstStepFormConfig({
                startDate: '',
                endDate: '',
                deliver: '',
            })
        );

        this.disasterRecoverySecondStepForm = this._fb.group(
            disasterRecoverySecondStepFormConfig({
                password: '',
            })
        );
    }

    private _loadData(): void {
        this.errorMessages = [];
        this.disasterRecoveryModel.Address = this.address;
        this._apiEmailSecurityService.getRecoveryInfo$(this.disasterRecoveryModel).subscribe({
            next: (result: IExecutionResult<IDisasterRecoveryInfoModel>) => {
                this.disasterRecoveryInfoModel = result.Result;
                this.minDate = new Date(this.disasterRecoveryInfoModel.MinDate);
                this.maxDate = new Date(this.disasterRecoveryInfoModel.MaxDate);
                this.disasterRecoveryModel.Deliver = this.disasterRecoveryInfoModel.Deliver;
                this._setFirstStepInitValues();
            },
            error: () => {
                this.errorMessages.push(
                    this._translateService.instant('COMMONS.GENERIC_ERROR_MESSAGE_ALT')
                );
            }
        });
    }

    private _setFirstStepInitValues(): void {
        this.disasterRecoveryFirstStepForm
            .get(this.firstStepFormFields.startDate)
            .setValue(dayjs(new Date()).format(DATE_FORMAT.DD_MM_YYYY_HH_MM));
        this.disasterRecoveryFirstStepForm
            .get(this.firstStepFormFields.endDate)
            .setValue(dayjs(new Date()).format(DATE_FORMAT.DD_MM_YYYY_HH_MM));
        this.disasterRecoveryFirstStepForm
            .get(this.firstStepFormFields.deliver)
            .setValue(this.disasterRecoveryModel.Deliver);
    }

    private _convertEpValueToDayJsObject(epValue: string): dayjs.Dayjs {
        const unixTimestampLength: number = 13;
        const timestampStringDate: string = this._convertToFixedLengthZerosPadded(
            epValue.substring(
                1, this.disasterRecoveryConfirmModel.EpFrom.length - 1),
                unixTimestampLength
        );

        return dayjs(new Date(Number(timestampStringDate)))
    }
    private _convertToFixedLengthZerosPadded(value: string, length: number): string {
        const numbersOfZerosToAdd: number = length - value.length;

        return value + '0'.repeat(numbersOfZerosToAdd)
    }
}
