import { TemplateRef, ViewContainerRef } from '@angular/core';

export class BasePermissionDirective {
    protected _hasView: boolean = false;

    constructor(
        protected _templateRef: TemplateRef<Element>,
        protected _viewContainerRef: ViewContainerRef
    ) {}

    protected _setElement(hasAccess: boolean): void {
        if (hasAccess && !this._hasView) {
            this._viewContainerRef.createEmbeddedView(this._templateRef);
            this._hasView = true;
        } else if (!hasAccess && this._hasView) {
            this._viewContainerRef.clear();
            this._hasView = false;
        }
    }
}
