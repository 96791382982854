import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CardVariant } from '../../../new-shared/components/cm-card/card-variant.enum';
import { IGuideHelp } from '../../../pages/your-account/interfaces';

@Component({
    selector: 'app-integration-guide',
    templateUrl: './integration-guide.component.html',
    styleUrls: ['./integration-guide.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationGuideComponent {
    @Input() guideSteps: IGuideHelp;
    @Input() currentStep: number;
    @Input() guideLink: string;
    @Input() headerTitle: string;
    showContent: boolean = false;
    readonly contentVisibleButtonIcon: string = 'fas fa-minus';
    readonly contentHiddenButtonIcon: string = 'fas fa-plus';
    readonly cardVariant: CardVariant = CardVariant.small;
    buttonIcon$: BehaviorSubject<string> = new BehaviorSubject<string>(
        this.contentHiddenButtonIcon
    );

    toggleGuideContent(): void {
        this.showContent = !this.showContent;

        this.buttonIcon$.next(
            this.showContent ? this.contentVisibleButtonIcon : this.contentHiddenButtonIcon
        );
    }

    openKnowledgeBase(): void {
        window.open(this.guideLink);
    }
}
