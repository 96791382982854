import { ButtonVariant } from '../interfaces/cm-button.types';

export const BUTTON_STYLE_CLASSES: Record<ButtonVariant, string> = {
    [ButtonVariant.primary]: 'btn btn-primary',
    [ButtonVariant.secondary]: 'btn btn-outline-secondary',
    [ButtonVariant.light]: 'btn btn-light',
    [ButtonVariant.dark]: 'btn btn-dark',
    [ButtonVariant.link]: '',
    [ButtonVariant.block]: 'btn btn-primary btn-block',
    [ButtonVariant.outlineDanger]: 'btn btn-outline-danger',
};
