// TODO: update naming convention, properties are accessed dynamically via data provided via backend
// eslint-disable:naming-convention
export enum ArchiveListView {
    allMail = 0,
    receivedMail = 1,
    sentMail = 2,
    spamMail = 3,
    virusMail = 4,
}

export enum ArchiveMailType {
    Received = 0,
    Sent = 1,
    Spam = 2,
    Quarantine = 3,
}
