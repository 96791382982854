import { ROUTES_PATHS } from '../../../shared/constants/routes.constants';
import { ISystemsLinkage, ISystemURLs } from '../../interfaces/partner-systems.types';

export const DEFAULT_SYSTEM_URLS: ISystemURLs = {
    COMMS: ROUTES_PATHS.EXTERNAL.COMMS_PROD,
    DISTRIBUTION: ROUTES_PATHS.EXTERNAL.DISTRIBUTION_PROD,
    HARDWARE: ROUTES_PATHS.EXTERNAL.HARDWARE_PROD
}

export const DEFAULT_SYSTEM_LINKAGE: ISystemsLinkage = {
    isCommsAccLinked: false,
    isDistributionAccLinked: false,
    isHardwareAccLinked: false,
}
