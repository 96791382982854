import {
    AfterViewInit,
    Component,
    ElementRef,
    NgZone,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../../../environments/environment';
import { SsoAuthService } from '../../../../core/services/sso-auth/sso-auth.service';
import {
    SwitchUserConfirmationHandlerService
} from '../../../../core/services/switch-user-types/switch-user-confirmation-handler.service';
import { ROUTES_PATHS } from '../../../../shared/constants/routes.constants';
import { COOKIE_KEYS } from '../../../../shared/constants/storage-keys.constants';
import { IPlatformModule, IRedirectLink } from '../../../../shared/interfaces/platform-module.interface';
import { CookieService } from '../../../../shared/services/cookie.service';

declare let PlatformModule: IPlatformModule;

@Component({
    selector: 'cm-new-header-wrapper',
    templateUrl: './new-header-wrapper.component.html',
})
export class NewHeaderWrapperComponent implements AfterViewInit, OnDestroy {
    @ViewChild('navigationRoot', { static: true }) private _navigationRoot: ElementRef;
    private _navigationInstance: { unmount: () => void };

    constructor(
        private _router: Router,
        private _ngZone: NgZone,
        private _switchUserTypesService: SwitchUserConfirmationHandlerService,
        private _cookieService: CookieService,
        private _ssoAuthService: SsoAuthService) {}

    ngAfterViewInit(): void {
        this._render();
    }

    ngOnDestroy(): void {
        this._navigationInstance?.unmount();
    }

    private _render(): void {
        const organisationId: string = this._cookieService.get(COOKIE_KEYS.ORGANISATION_ID);

        PlatformModule.renderNavigation({
            root: this._navigationRoot.nativeElement,
            props: {
                onCompanySwitch: this._companySwitchHandler.bind(this),
                onLogout: this._logoutHandler.bind(this),
                onRedirect: this._redirectionHandler.bind(this),
                isAuth0Enabled: true,
                domains: ['Cloud'],
                auth0Config: {
                    redirectUri: `${window.location.origin}/${ROUTES_PATHS.LOGIN}`,
                    clientId: environment.Auth0Configuration.clientId,
                    organisationId
                }
            },
        }).then((navigation: { unmount: () => void }) => this._navigationInstance = navigation);
    }

    private _companySwitchHandler(): void {
        this._ssoAuthService.clearLocalSession();
    }

    private _logoutHandler(): void {
        this._ssoAuthService.logoutFromTradingPortals();
    }

    private _redirectionHandler(redirectLink: IRedirectLink): void {
        this._ngZone.run(() => {
            if(redirectLink.url.includes(ROUTES_PATHS.SWITCH_TO_MAILBOX)){
                this._switchUserTypesService.showSwitchUserPopup();

                return;
            }

            const urls: string[] = ['', '/'];
            const fallbackUrl: string = urls.includes(redirectLink.url) ? `${ROUTES_PATHS.EXTERNAL.SINGLE_EXPERIENCE_DASHBOARD}` : redirectLink.url;

            this._router.navigateByUrl(fallbackUrl).then();
        });
    }
}
