import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IOrganisationUserModel } from '../../../../new-shared/components/modals/add-customer-user-modal/interfaces/add-cust-user.types';
import {
    ICreateUserResponse
} from '../../../../new-shared/components/modals/add-customer-user-modal/interfaces/create-user-response.interface';
import { IServiceListItem } from '../../../../pages/customer/user/cust-service-list/interfaces/customer-service-list.types';
import { API_ENDPOINTS } from '../../../../shared/constants/api/api-endpoints.constants';
import { BaseUrl } from '../../../../shared/urls/base.url';
import { IExecutionResult } from '../../../interfaces/execution-result.interface';
import { IGetCustomerUsersResponse } from '../../../interfaces/get-customer-users-response.interface';
import { ISsoLoginFlow } from '../../../interfaces/login-flow.interface';
import { AuthorizedHttpService } from '../../http/authorized-http.service';
import { UnauthorizedHttpService } from '../../http/unauthorized-http.service';
import { IServiceModel } from '../types/service-model.interface';

@Injectable({
    providedIn: 'root',
})
export class ApiUserService {
    private readonly _serviceV1Url: string =
        `${BaseUrl.baseUrlCloudMarket}${API_ENDPOINTS.API_MANAGEMENT.USER_V1}${API_ENDPOINTS.USER.SERVICE_BASE}`;

    constructor(
        private _authorizedHttpService: AuthorizedHttpService,
        private _unauthorizedHttpService: UnauthorizedHttpService
    ) {}

    createUser$(user: IOrganisationUserModel): Observable<ICreateUserResponse> {
        const url: string = `${this._serviceV1Url}`;

        return this._authorizedHttpService.post$(url, user);
    }

    downloadTemplate$(): Observable<HttpResponse<Blob>> {
        return this._authorizedHttpService.downloadBlobAsFile$(
            API_ENDPOINTS.USER.DOWNLOAD_TEMPLATE,
            'template.csv'
        );
    }

    importUsersFromFile$(
        organisationId: string,
        dataFileUpload: FormData
    ): Observable<IExecutionResult<unknown>> {
        return this._authorizedHttpService.post$(
            API_ENDPOINTS.USER.IMPORT(organisationId),
            dataFileUpload
        );
    }

    deleteUser$(userId: string | number): Observable<IExecutionResult<void>> {
        return this._authorizedHttpService.delete$(API_ENDPOINTS.USER.DELETE_USER(userId));
    }

    getCustomerUsers$(cloudMarketId: string): Observable<IGetCustomerUsersResponse> {
        return this._authorizedHttpService.get$(
            `${this._serviceV1Url}${API_ENDPOINTS.USER.CUSTOMER}/${cloudMarketId}`
        );
    }

    getLoginFlow$(userName: string): Observable<string> {
        return this._unauthorizedHttpService
            .get$(
                `${BaseUrl.baseUrlCloudMarket}${
                    API_ENDPOINTS.API_MANAGEMENT.USER_V1
                }${API_ENDPOINTS.USER.LOGIN_FLOW(userName)}`
            )
            .pipe(map((response: ISsoLoginFlow) => response.loginFlow));
    }

    getBundleProducts$(userId: string): Observable<IExecutionResult<boolean>> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.USER.GET_BUNDLE_PRODUCTS(userId));
    }

    getGroupServices$(organisationId: string):  Observable<IExecutionResult<IServiceModel[]>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.USER.GET_GROUP_SERVICES(organisationId)
        )
    }

    getOffice365Products$(id: string, officeGroupId: number): Observable<IExecutionResult<IServiceListItem[]>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.USER.GET_OFFICE365_PRODUCTS(id, officeGroupId)
        )
    }
}
