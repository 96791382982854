import { Directive, ViewContainerRef } from '@angular/core';

import { PopupService } from '../services/popup.service';

@Directive({
    selector: '[popupContainer]',
})
export class PopupContainerDirective {
    constructor(
        private _viewContainerRef: ViewContainerRef
    ) {
        PopupService.container = this._viewContainerRef;
    }
}
