import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBoxComponent<T extends { Name: string }> implements OnInit, OnDestroy {
    @Input() showLoader: boolean;
    @Input() disabled: boolean;

    @Input() set suggestions(suggestions: T[]) {
        this._suggestions = suggestions;
        this.isLoading$.next(false);
    }
    get suggestions(): T[] {
        return this._suggestions;
    }
    @Input() set clearValue(value: boolean) {
        if (value) {
            this._suggestions = null;
            this.searchValue = null;
            this.isDisplayClearIcon = false;
        }
    }
    @Input() searchPlaceholder: string;
    @Output() readonly displayedSuggestion: EventEmitter<string> = new EventEmitter();
    @Output() readonly selectedSearchValue: EventEmitter<string> = new EventEmitter();
    @Output() readonly clearedSearchValue: EventEmitter<void> = new EventEmitter();
    isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    searchValue: string;
    isDisplaySuggestion: boolean = false;
    isDisplayClearIcon: boolean = false;
    private _suggestions: T[];
    private _initialPlaceholder: string;
    private readonly _destroyTrigger$: Subject<void> = new Subject();

    constructor(private _changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this._initialPlaceholder = this.searchPlaceholder;
    }

    ngOnDestroy(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }

    displaySuggestion(suggestion: string): void {
        const minChars: number = 2;

        this.isLoading$.next(false);
        this._suggestions = null;
        this.isDisplaySuggestion = false;
        this.searchValue = suggestion;
        this.isDisplayClearIcon = suggestion && suggestion.length > 0;

        if (suggestion && suggestion.length >= minChars) {
            this.isDisplaySuggestion = true;
            this.isLoading$.next(true);
            this.displayedSuggestion.next(suggestion);
        }
    }

    handleOnFocusSuggestionList(): void {
        this.searchPlaceholder = '';
        this.isDisplaySuggestion = true;
    }

    search(): void {
        if (this.searchValue) {
            this.isDisplayClearIcon = true;
            this.isLoading$.next(false);
            this.selectedSearchValue.next(this.searchValue);
        } else {
            this.isDisplayClearIcon = false;
            this.selectedSearchValue.next(null);
        }
        this.isDisplaySuggestion = false;
    }

    hideSuggestionList(): void {
        const delay: number = 300;

        timer(delay)
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe(() => {
                this.isDisplaySuggestion = false;
                this._changeDetectorRef.detectChanges();
            });

        this.searchPlaceholder = this._initialPlaceholder;
    }

    clearSearchValue(): void {
        this.searchValue = null;
        this._suggestions = null;
        this.isDisplayClearIcon = false;
        this.isDisplaySuggestion = false;
        this.clearedSearchValue.next();
    }

    searchSelectedValue(searchValue: string): void {
        this.searchValue = searchValue;
        this.isDisplayClearIcon = true;
        this.isDisplaySuggestion = false;
        this.selectedSearchValue.next(this.searchValue);
    }
}
