import { IDictionary } from '../interfaces/dictionary.interface';

export const DATE_TIME: IDictionary<number> = {
    monthsInYear: 12,
    daysInWeek: 7,
    hoursInDay: 24,
    minutesInHour: 60,
    secondsInMinute: 60,
    milliSecondsInSecond: 1000,
};

export const DATE_FORMAT: IDictionary<string> = {
    UTC: 'YYYY-MM-DDT00:00:00.000',
    D_M_Y_H_MIN: 'dd MMMM YYYY HH:mm',
    DO_M_Y_H_MIN: 'Do MMMM YYYY HH:mm',
    DO_MMM_Y_H_MIN: 'Do MMM YYYY HH:mm',
    DD_MM_YYYY: 'DD/MM/YYYY',
    dd_MM_yyyy: 'dd/MM/yyyy',
    DD_MM_YYYY_HH_MM: 'DD/MM/YYYY HH:mm',
    DD_MM_YY: 'DD/MM/YY',
    MMM_yyyy: 'MMM yyyy',
    yyyy_MM_dd: 'yyyy-MM-dd',
    DD_MMMM_YYYY: 'dd MMMM YYYY',
    d_M_yy: 'd/M/yy',
};
