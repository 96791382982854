import { Inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { WINDOW } from '../../../../../core/injection-tokens/window.injection-token';
import { AnalyticsService } from '../../../../../core/services/analytics.service';
import { CloudServerService } from '../../../../../core/services/cloud-server.service';
import { ForumService } from '../../../../../core/services/forum.service';
import { ResourceHubService } from '../../../../../core/services/resource-hub.service';
import { STORAGE_KEYS } from '../../../../../shared/constants/storage-keys.constants';
import { KnowledgeBaseService } from '../../../../knowledge-base/services/knowledge-base/knowledge-base.service';
import { IFinalizingLoginStrategy } from '../../../interfaces/finalizing-login-strategy.interface';

@Injectable({
    providedIn: 'root',
})
export class RedirectFinalizingService implements IFinalizingLoginStrategy {
    private _siteBaseUrl$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(
        @Inject(WINDOW) private _window: Window,
        private _knowledgeBaseService: KnowledgeBaseService,
        private _forumService: ForumService,
        private _cloudServerService: CloudServerService,
        private _analyticsService: AnalyticsService,
        private _resourceHubService: ResourceHubService,
    ) {
    }

    finalizeLogin$(): Observable<void> {
        this._siteBaseUrl$.next(this._window.location.origin);

        return this._handleRedirectionService$()
            .pipe(
                tap(() => {
                    localStorage.removeItem(STORAGE_KEYS.SERVICE);
                }));
    }

    private _handleRedirectionService$(): Observable<void> {
        const params: Params = {
            service: localStorage.getItem(STORAGE_KEYS.SERVICE),
        };

        return this._selectServiceToRedirect$(params)
            .pipe(take(1));
    }

    private _selectServiceToRedirect$(params: Params): Observable<void> {
        const service: string = params.service;
        let isRedirected$: Observable<void> = of(null);

        switch (service) {
            case 'helpjuice':
                isRedirected$ = this._knowledgeBaseService.getHelpJuiceToken$().pipe(
                    map((token: string) => {
                        if (token) {
                            this._knowledgeBaseService.redirectToHelpJuice(
                                token,
                                this._siteBaseUrl$,
                            );
                        }

                        return null;
                    }),
                );
                break;
            case 'vanilla':
                isRedirected$ = this._forumService.getForumToken$().pipe(
                    map((forumToken: string) => {
                        if (forumToken) {
                            this._analyticsService.gaVirtualPageView(
                                'redirect/vanilla',
                                'redirect',
                            );
                            this._forumService.redirectToForum(forumToken);
                        }

                        return null;
                    }),
                );
                break;
            case 'cloudserver':
                isRedirected$ = this._cloudServerService.getToken$().pipe(
                    map((cloudServerToken: string) => {
                        if (cloudServerToken) {
                            this._cloudServerService.redirectToCloudServer(cloudServerToken);
                        }

                        return null;
                    }),
                );
                break;
            case 'resource-hub':
                const resourceHubToken: string = this._resourceHubService.getToken();
                const returnUrl: string = params.returnUrl;

                if (resourceHubToken && returnUrl) {
                    this._resourceHubService.redirectToResourceHub(resourceHubToken, returnUrl);
                }
                break;
            default:
                break;
        }

        return isRedirected$;
    }
}
