import { Injectable } from '@angular/core';
import { RouterStateSnapshot, UrlSegment } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';

import { SsoAuthService } from '../../../../core/services/sso-auth/sso-auth.service';
import { ROUTES_PATHS } from '../../../../shared/constants/routes.constants';
import { STORAGE_KEYS } from '../../../../shared/constants/storage-keys.constants';
import { IFinalizingLoginStrategy } from '../../interfaces/finalizing-login-strategy.interface';

import {
    SessionRecreateFinalizingService,
} from './finalizing-strategy/session-recreate-finalizing.service';
import {
    SessionRefreshTokenFinalizingService,
} from './finalizing-strategy/session-refresh-token-finalizing.service';
import { NonSsoSessionManagementService } from './non-sso-session-management.service';
import { SsoSessionManagementService } from './sso-session-management.service';

@Injectable({
    providedIn: 'root',
})
export class SessionManagementService {
    constructor(
        private _ssoAuthService: SsoAuthService,
        private _sessionRefreshTokenFinalizingService: SessionRefreshTokenFinalizingService,
        private _sessionRecreateFinalizingService: SessionRecreateFinalizingService,
        private _nonSsoSessionManagementService: NonSsoSessionManagementService,
        private _ssoSessionManagementService: SsoSessionManagementService,
    ) {
    }

    getSessionStateForCanLoad$(segments: UrlSegment[]): Observable<boolean> {
        const url: string = segments.map((segment: UrlSegment) => segment.path).join('/');

        return this._handleLoginFlow$(this._sessionRecreateFinalizingService, url);
    }

    getSessionStateForCanActive$(state: RouterStateSnapshot): Observable<boolean> {
        return this._handleLoginFlow$(this._sessionRecreateFinalizingService, state.url);
    }

    getSessionStateForCanActiveChild$(state: RouterStateSnapshot): Observable<boolean> {
        return this._handleLoginFlow$(this._sessionRefreshTokenFinalizingService, state.url);
    }

    private _handleLoginFlow$(
        finalizingLoginStrategy: IFinalizingLoginStrategy,
        url: string
    ): Observable<boolean> {
        if (!this._isSsoLoginFlowWithoutAdminJump()) {
            return this._nonSsoSessionManagementService.handleNonSsoLoginFlow$(url);
        }

        return this._ssoSessionManagementService.handleSsoSession$(finalizingLoginStrategy)
            .pipe(
                switchMap((isSsoSession: boolean) => {
                    if (isSsoSession) {
                        return of(true);
                    }

                    return this._redirectToCloudLoginOrDashboardPage$(url);
                }),
                catchError(() => this._redirectToCloudLoginOrDashboardErrorPage$(url)),
            );
    }

    private _redirectToCloudLoginOrDashboardErrorPage$(stateUrl: string): Observable<false> {
        return this._redirectToPage$(stateUrl, ROUTES_PATHS.EXTERNAL.SINGLE_EXPERIENCE_DASHBOARD_ERROR);
    }

    private _redirectToCloudLoginOrDashboardPage$(stateUrl: string): Observable<false> {
        return this._redirectToPage$(stateUrl, ROUTES_PATHS.EXTERNAL.SINGLE_EXPERIENCE_DASHBOARD);
    }

    private _redirectToPage$(stateUrl: string, redirectUrl: string): Observable<false> {
        this._ssoAuthService.redirectToLoginPageAfterClearAuth0LocalAndCloudSession(stateUrl, redirectUrl);

        return of(false);
    }

    private _isSsoLoginFlowWithoutAdminJump(): boolean {
        if (this._ssoAuthService.isAdminJump) {
            return false;
        }

        return this._ssoAuthService.isSsoLoginFlow
            && localStorage.getItem(STORAGE_KEYS.CLOUD_ORGANISATION_ID) !== null;
    }
}
