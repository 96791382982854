import { Component, Input, OnChanges } from '@angular/core';

import { SubscriptionTerm } from '../../../../../core/enums/subscription-term.enum';
import {
    IMicrosoft365SubscriptionCoterminateOption
} from '../../../../../core/interfaces/microsoft365-subscription-coterminate-option.interface';
import {
    AbstractControlValueAccessor,
    abstractValueAccessorProvider,
} from '../../../../../shared/classes/abstract-control-value-accessor.class';

@Component({
    selector: 'app-coterminate-form-control',
    templateUrl: './coterminate-form-control.component.html',
    styleUrls: ['./coterminate-form-control.component.scss'],
    providers: [abstractValueAccessorProvider(CoterminateFormControlComponent)],
})
export class CoterminateFormControlComponent
    extends AbstractControlValueAccessor<IMicrosoft365SubscriptionCoterminateOption>
    implements OnChanges
{
    @Input() term: SubscriptionTerm = SubscriptionTerm.monthly;
    @Input() coterminateOptions: IMicrosoft365SubscriptionCoterminateOption[] = [];
    @Input() isNoCoterminateOptionAvailable: boolean = false;
    visibleCoterminateOptions: (IMicrosoft365SubscriptionCoterminateOption | null)[] = [];
    fieldsetId: string = this._generateRandomFieldsetId();
    isDisabled: boolean = false;

    ngOnChanges(): void {
        this.visibleCoterminateOptions = [];

        const coterminateOptions: IMicrosoft365SubscriptionCoterminateOption[] =
            this.coterminateOptions.filter(
                (option: IMicrosoft365SubscriptionCoterminateOption) => option.Term === this.term
            );

        if (this.isNoCoterminateOptionAvailable && this.coterminateOptions.length) {
            coterminateOptions.push(null);
        }

        this.visibleCoterminateOptions = coterminateOptions;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    private _generateRandomFieldsetId(): string {
        const maxLengthOfId: number = 7;

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return (Math.random() + 1).toString(36).substring(maxLengthOfId);
    }
}
