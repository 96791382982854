import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'giacomMonthName' })
export class GiacomMonthNamePipe implements PipeTransform {
    constructor(private _translateService: TranslateService) {}

    transform(val: Date): string {
        if (!val) {
            return;
        }

        const iMonth: number = val.getMonth();
        const iYear: number = val.getFullYear();
        const currentDateMonth: number = new Date().getMonth();
        const currentDateYear: number = new Date().getFullYear();

        if (iMonth === currentDateMonth && iYear === currentDateYear) {
            return this._translateService.instant('MONTHS.THIS_MONTH');
        }
        if (iYear === currentDateYear) {
            return this._getMonthString(iMonth);
        }

        return `${this._getMonthString(iMonth)} ${iYear}`;
    }

    private _getMonthString(iMonth: number): string {
        const month: string[] = [
            this._translateService.instant('MONTHS.MONTH_01'),
            this._translateService.instant('MONTHS.MONTH_02'),
            this._translateService.instant('MONTHS.MONTH_03'),
            this._translateService.instant('MONTHS.MONTH_04'),
            this._translateService.instant('MONTHS.MONTH_05'),
            this._translateService.instant('MONTHS.MONTH_06'),
            this._translateService.instant('MONTHS.MONTH_07'),
            this._translateService.instant('MONTHS.MONTH_08'),
            this._translateService.instant('MONTHS.MONTH_09'),
            this._translateService.instant('MONTHS.MONTH_10'),
            this._translateService.instant('MONTHS.MONTH_11'),
            this._translateService.instant('MONTHS.MONTH_12'),
        ];

        return month[iMonth];
    }
}
