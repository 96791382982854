import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IUserSettingsModel } from '../../models/user/user-settings.model';
import { API_ENDPOINTS } from '../../shared/constants/api/api-endpoints.constants';
import { UserType } from '../../shared/enum/user-type.enum';
import { IExecutionResult } from '../interfaces/execution-result.interface';

import { AuthorizedHttpService } from './http/authorized-http.service';
import { Resource } from './resource.service';

@Injectable({
    providedIn: 'root',
})
export class AccountSettingsService {
    userSettingsModel: IUserSettingsModel;

    get hidePrice(): boolean {
        if (this._resource.user.userType === UserType.reseller) {
            return false;
        }

        if (
            !this.userSettingsModel ||
            this._resource.user.isEndUser ||
            this._resource.user.isEndUserAdmin
        ) {
            return true;
        }

        return this.userSettingsModel.HidePricing;
    }

    get hideBanner(): boolean {
        if (!this.userSettingsModel) {
            return true;
        }

        return this.userSettingsModel.HideBanner;
    }

    constructor(
        private _resource: Resource,
        private _authorizedHttpService: AuthorizedHttpService
    ) {}

    getUserSettings(): void {
        if (this.userSettingsModel) {
            return;
        }

        this._authorizedHttpService
            .get$(API_ENDPOINTS.ACCOUNT_SETTINGS.GET_DISPLAY_SETTINGS)
            .subscribe((res: IExecutionResult<IUserSettingsModel>) => {
                this.userSettingsModel = res.Result;
            });
    }

    updateUserSettings$(): Observable<IExecutionResult<boolean>> {
        return this._authorizedHttpService.post$(
            API_ENDPOINTS.ACCOUNT_SETTINGS.UPDATE_DISPLAY_SETTINGS,
            this.userSettingsModel
        );
    }
}
