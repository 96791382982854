import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { WINDOW } from '../../core/injection-tokens/window.injection-token';
import { IWindow } from '../../core/interfaces/window.interface';
import { STORAGE_KEYS } from '../../shared/constants/storage-keys.constants';

@Component({
    selector: 'app-live-chat',
    templateUrl: './live-chat.component.html',
    styleUrls: ['./live-chat.component.scss'],
})
export class LiveChatComponent implements OnInit, OnDestroy {
    constructor(@Inject(WINDOW) private _window: IWindow) {}

    ngOnInit(): void {
        if (this._getLiveChatApi()) {
            this._minimiseLiveChat();
        } else {
            this._addLiveChat();
        }
    }

    ngOnDestroy(): void {
        this._hideLiveChat();
    }

    private _getLiveChatApi(): any {
        // The window.LC_API object will exist once the live chat script has loaded from livechatinc.com
        return this._window.LC_API;
    }

    private _addLiveChat(): void {
        try {
            // set custom variables
            const internalId: string | null = localStorage.getItem(STORAGE_KEYS.VISITOR_ID);
            const emailAddress: string | null = JSON.parse(localStorage.getItem(STORAGE_KEYS.USER))
                ?.data?.emailAddress;
            const userName: string | null = localStorage.getItem(STORAGE_KEYS.USER_NAME);
            const displayName: string | null = localStorage.getItem(STORAGE_KEYS.DISPLAY_NAME);
            const customVariables: { name: string; value: string }[] = [
                { name: 'Account Id', value: internalId },
                { name: 'Email', value: emailAddress },
                { name: 'Username', value: userName },
                { name: 'Name', value: displayName },
            ];
            const lc: HTMLScriptElement = document.createElement('script');
            const liveChatContainer: HTMLElement = document.getElementById('live-chat-container');

            // Set the licence on the window
            this._window.__lc = this._window.__lc || {};
            this._window.__lc.license = environment.liveChatLicense;
            this._window.__lc.params = customVariables;
            // Create a script element with the live chat script
            lc.type = 'text/javascript';
            lc.async = true;
            lc.src = 'https://cdn.livechatinc.com/tracking.js';
            // Add it to the dom if the container exists
            if (liveChatContainer) {
                liveChatContainer.appendChild(lc);
            }
        } catch (err) {
            // This will error if livechatinc.com has been blocked by the client.
            // Suppress this error so we don't prevent cloud.market loading.
        }
    }

    private _minimiseLiveChat(): void {
        const liveChatApi: any = this._getLiveChatApi();

        try {
            if (liveChatApi.is_loaded()) {
                liveChatApi.minimize_chat_window();
            }
        } catch (err) {
            // Suppress any errors so we don't prevent cloud.market loading.
        }
    }

    private _hideLiveChat(): void {
        const liveChatApi: any = this._getLiveChatApi();

        if (!liveChatApi) {
            return;
        }

        try {
            liveChatApi.hide_chat_window();
        } catch (err) {
            // This will error if LC_Invite is not defined.
            // We need to perform some further investigation on why this is happening.
            // For now supress this error so we don't prevent cloud.market loading.
        }
    }
}
