export interface IServiceStepModel {
    ServiceCode: ServiceCode;
    Step: number;
}

export enum ServiceCode {
    hostedExchange = 1,
    sharePoint = 2,
    emailSecurity = 3,
    secureEmailMessage = 4,
    azure = 6,
    office365 = 8,
    backupAcronis = 9,
    bitdefender = 12,
    hostedVoice = 13,
    appInteract = 14,
    hostedVoiceHardware = 15,
    messageLabs = 16,
    exclaimer = 17,
    acronisFilesCloud = 18,
    cloudTalk = 19,
    skykick = 21,
    azureRI = 22,
    dropSuite = 23,
    azureSS = 26,
    vade = 27,
    cloudServer = 30,
}
