import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TimepickerConfig } from 'ngx-bootstrap/timepicker';

import { DATE_FORMAT } from '../../../../../shared/constants/datetime.constants';

@Component({
    selector: 'app-datetime-picker-control',
    templateUrl: './datetime-picker-control.component.html',
    styleUrls: ['./datetime-picker-control.component.scss'],
})
export class DatetimePickerControlComponent implements ControlValueAccessor {
    @Input() config: Partial<TimepickerConfig>;
    @Input() hideDateInput: boolean = false;
    @Input() hideTimeInput: boolean = false;
    readonly dateFormat: typeof DATE_FORMAT = DATE_FORMAT;

    value: Date;

    constructor(public control: NgControl) {
        control.valueAccessor = this;
    }

    onDateChange: (arg?: Date) => void = () => {};
    onTouched: () => void = () => {};

    writeValue(value: Date): void {
        if (value) {
            this.value = value;
        }
    }

    registerOnChange(fn: () => void): void {
        this.onDateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    onTimeChange(value: Date): void {
        this.value = value;
        this.onDateChange(value);
        this.onTouched();
    }
}
