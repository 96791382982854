import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES_PATHS } from 'app/shared/constants/routes.constants';
import { forkJoin, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IExecutionResult } from '../../../../core/interfaces/execution-result.interface';
import { IMarketplaceService } from '../../../../core/interfaces/marketplace-service.interface';
import { AgreementService } from '../../../../core/services/agreement.service';
import { ApiFeatureFlagsService } from '../../../../core/services/api/api-feature-flags.service';
import { ApiMicrosoft365Service } from '../../../../core/services/api/microsoft365/api-microsoft365.service';
import { ApiMarketplaceServicesService } from '../../../../core/services/api/services/api-marketplace-services.service';
import { GlobalDataService } from '../../../../core/services/global-data.service';
import { GlobalEventService } from '../../../../core/services/global-event.service';
import { AuthorizedHttpService } from '../../../../core/services/http/authorized-http.service';
import { Resource, UserInfo } from '../../../../core/services/resource.service';
import { ServiceService } from '../../../../core/services/service.service';
import { AbstractPopup } from '../../../../feature-modules/popups/classes/abstract-popup.class';
import { IDialogEventHandler } from '../../../../feature-modules/popups/interfaces/dialog-event-handler.interface';
import { PopupService } from '../../../../feature-modules/popups/services/popup.service';
import { IAdditionalServiceAgreement } from '../../../../models/agreement/interfaces/additional-service-agreement.interface';
import { IMultipleAgreementServiceDetails } from '../../../../models/agreement/interfaces/multiple-agreement-service-details.interface';
import { IMultipleAgreements } from '../../../../models/agreement/interfaces/multiple-agreements.interface';
import { IParentChildAgreement } from '../../../../models/agreement/interfaces/parent-child-agreement.interface';
import { ISignedService } from '../../../../models/agreement/interfaces/signed-service.interface';
import { ParentChildAgreementModel } from '../../../../models/agreement/parent-child-agreement.model';
import { ICustomers } from '../../../../models/customer/customers.interface';
import { IAddedServiceModel } from '../../../../models/service/added-service.model';
import { IAllServiceModel } from '../../../../models/service/all-service.model';
import { AddServiceContextEnum } from '../../../../models/service/service.model';
import { API_ENDPOINTS } from '../../../../shared/constants/api/api-endpoints.constants';
import { NCE_PICKER_SERVICES } from '../../../../shared/constants/nce-picker-services.constants';
import {
    Office365ProductGroups,
    ProductOrderLimit,
    Service,
    ServiceInstanceStatus,
} from '../../../../shared/enum/service-type.enum';
import { ListCustomerModalComponent } from '../list-customer/components/list-customer-modal.component';

@Component({
    selector: 'cm-select-service',
    templateUrl: './select-service-modal.component.html',
    styleUrls: ['./select-service-modal.component.scss'],
    providers: [ListCustomerModalComponent],
})
export class SelectServiceModalComponent
    extends AbstractPopup<any>
    implements OnInit, IDialogEventHandler, OnDestroy
{
    customers: ICustomers[] = [];
    // We need to refactor this to use a slimmed down IService interface with new concrete class to
    // represent ServiceModel.cs on API side The IService interface should represent
    // IServiceModel.cs with some added common properties
    services: any;
    selectedCustomerId: string;
    selectedUserId: number;
    addServiceInput: any;
    selectedEndUserId: string;
    level: string = 'customer';
    signedInUser: UserInfo;
    noCustomerOrService: boolean = false;
    agreeSub: any;
    routesPaths: typeof ROUTES_PATHS = ROUTES_PATHS;

    private readonly _destroyTrigger$: Subject<void> = new Subject<void>();

    constructor(
        private _router: Router,
        private _featureFlagService: ApiFeatureFlagsService,
        private _dataService: GlobalDataService,
        private _serviceService: ServiceService,
        private _eventService: GlobalEventService,
        private _lstCustomerComponent: ListCustomerModalComponent,
        private _resource: Resource,
        private _popupService: PopupService,
        private _agreementService: AgreementService,
        private _apiMarketplaceServicesService: ApiMarketplaceServicesService,
        private _authorizedHttpService: AuthorizedHttpService,
        private _apiMicrosoft365: ApiMicrosoft365Service
    ) {
        super();
        this.signedInUser = this._resource.user;
    }

    get serviceUrl(): string {
        if (this.signedInUser.isEndUserAdmin) {
            return API_ENDPOINTS.SERVICES.GET_SERVICES_OF_END_USER_ADMIN(
                this.selectedCustomerId
            );
        }

        return API_ENDPOINTS.SERVICES.GET_ALL_SERVICES_OF_RESELLER(
            this.selectedCustomerId
        );
    }

    get endUserServiceUrl(): string {
        return API_ENDPOINTS.END_USER.SERVICE(
            this.selectedCustomerId,
            this.selectedEndUserId
        );
    }

    cancel(): void {
        const element: HTMLElement | null = document.getElementById('giacom');

        this.close();

        if (element) {
            element.style.overflow = null;
        }
    }

    loadServices(): void {
        if (this.signedInUser.isEndUserAdmin) {
            forkJoin([
                this._apiMarketplaceServicesService.getServicesByEndUserAdmin$(),
                this._apiMarketplaceServicesService.getServiceOfCustomer$(
                    this.signedInUser.endUserAdminId
                ),
            ])
                .pipe(takeUntil(this._destroyTrigger$))
                .subscribe(
                    (
                        resultList: [
                            IExecutionResult<IMarketplaceService[]>,
                            IExecutionResult<IAddedServiceModel[]>
                        ]
                    ) => {
                        const result: IMarketplaceService[] = resultList[0].Result;
                        const services: IAddedServiceModel[] = resultList[1].Result;

                        this.services = result;

                        for (const item of this.services) {
                            item.Icon = item.ImageSrc;
                        }

                        const azure: IAddedServiceModel = services.find(
                            (product: IAddedServiceModel) =>
                                product.Id === Service.Microsoft365 &&
                                product.SubId === Office365ProductGroups.Azure
                        );

                        if (azure && azure.Added) {
                            const azureMarket: IMarketplaceService = this.services.find(
                                (product: IMarketplaceService) =>
                                    product.Name.toLowerCase() === 'azure'
                            );

                            if (azureMarket) {
                                azureMarket.Added = true;
                            }
                        }
                        const bitDefender: IAddedServiceModel = services.find(
                            (product: IAddedServiceModel) => product.Id === Service.BitDefender
                        );

                        if (bitDefender && bitDefender.Added) {
                            const bitDefenderMarket: IMarketplaceService = this.services.find(
                                (product: IMarketplaceService) =>
                                    product.Name.toLowerCase() === 'bitdefender'
                            );

                            if (bitDefenderMarket) {
                                bitDefenderMarket.Added = true;
                            }
                        }
                    }
                );
        } else {
            this._apiMarketplaceServicesService
                .getServices$()
                .pipe(takeUntil(this._destroyTrigger$))
                .subscribe((data: IExecutionResult<IMarketplaceService[]>) => {
                    this.services = data.Result;

                    for (const item of this.services) {
                        item.Icon = item.ImageSrc;
                    }
                });
        }
    }

    loadServicesForCustomer(): void {
        this.addServiceInput.context = AddServiceContextEnum.customer;
        this.addServiceInput.id = this.selectedCustomerId;
        this.addServiceInput.customerId = this.selectedCustomerId;

        this._authorizedHttpService
            .get$(this.serviceUrl)
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe((result: IExecutionResult<IAllServiceModel[]>) => {
                const services: IAllServiceModel[] = result.Result;

                for (const item of services) {
                    item.Icon = item.ImageSrc;
                }
                this.services = services;
            });
    }

    loadServicesForUser(): void {
        this.addServiceInput.context = AddServiceContextEnum.user;
        this.addServiceInput.id = this.selectedEndUserId;
        this.addServiceInput.customerId = this.selectedCustomerId;
        this.addServiceInput.userId = this.selectedEndUserId;
        const servicesReq$: Observable<IExecutionResult<IAllServiceModel>> =
            this._authorizedHttpService.get$<IExecutionResult<IAllServiceModel>>(this.serviceUrl);
        const endUserServicesReq$: Observable<IExecutionResult<IAddedServiceModel>> =
            this._authorizedHttpService.get$<IExecutionResult<IAddedServiceModel>>(
                this.endUserServiceUrl
            );

        forkJoin([servicesReq$, endUserServicesReq$])
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe((result: IExecutionResult<any>[]) => {
                const services: IAllServiceModel[] = result[0].Result;
                const endUserServices: IAddedServiceModel[] = result[1].Result;

                services.forEach((service: IAllServiceModel) => {
                    const endUserService: IAddedServiceModel = endUserServices.find(
                        (product: IAddedServiceModel) =>
                            (product.Id === Service.Microsoft365 &&
                                product.SubId === service.SubId) ||
                            (product.Id !== Service.Microsoft365 && product.Id === service.Id)
                    );

                    service.Added = endUserService ? endUserService.Added : false;
                    service.Icon = service.ImageSrc;
                    this._hideServiceAtEndUser(service);
                });
                this.services = services;
            });
    }

    updateParams(val: any): void {
        if (val.noCustomerOrService) {
            this.noCustomerOrService = val.noCustomerOrService;

            return;
        }

        if (typeof val === 'boolean') {
            this.noCustomerOrService = true;

            return;
        }

        if (typeof val === 'string') {
            this.selectedCustomerId = val;

            return;
        }

        this.level = val.level;
        if (val.level === 'user') {
            this.selectedCustomerId = val.organisationId;
            this.selectedEndUserId = val.userId;
        } else {
            this.selectedCustomerId = val.organisationId;
        }
    }

    // Fix the param type once we have refactored IService
    selectService(service: any): void {
        const crossSellFeatureFlag: boolean =
            this._featureFlagService.isFeatureFlagEnabled('CrossSell');

        if (this.isDisabled(service)) {
            return;
        }

        this.addServiceInput.serviceid = service.Id;
        this.addServiceInput.subId = service.SubId;
        this.addServiceInput.serviceName = service.Name;

        if (
            crossSellFeatureFlag &&
            service.Id === Service.Microsoft365 &&
            service.SubId === Office365ProductGroups.Office365Core
        ) {
            service.NewJourney = true;
        }

        if (service.NewJourney) {
            if (!this.noCustomerOrService) {
                if (this._performRouting(service)) {
                    this.close();
                }

                return;
            }
            if (this.signedInUser.isEndUserAdmin) {
                this._lstCustomerComponent.addServiceInput = { context: '', id: '', serviceid: '' };
                this._lstCustomerComponent.serviceId = service.Id;
                this._lstCustomerComponent.subId = service.SubId;
                this._lstCustomerComponent.productId = service.MarketplaceServiceId;
                this._lstCustomerComponent.isNewJourney = service.NewJourney;
                this._lstCustomerComponent.journeyType = service.JourneyType;
                this._lstCustomerComponent.listingId = service.ListingId;
                this._lstCustomerComponent.continueAddService();

                this.close();
            } else {
                this._popupService.show(null, 'list-customer', {
                    id: service.MarketplaceServiceId,
                    serviceId: service.Id,
                    subId: service.SubId || service.Office365GroupId,
                    newJourney: service.NewJourney,
                    journeyType: service.JourneyType,
                    listingId: service.ListingId,
                });
                this.close();
            }

            return;
        }

        // probably unreachable
        if (service.Id === Service.Microsoft365) {
            const idExpiredValue: number = 2;

            this._apiMicrosoft365
                .hasPartnerId$()
                .pipe(takeUntil(this._destroyTrigger$))
                .subscribe((data: IExecutionResult<number>) => {
                    if (data.Result === 1 || data.Result === idExpiredValue) {
                        this.close();
                    } else {
                        this._agreementService
                            .getLinkedAgreementsAsync$(service.Id, service.SubId, true)
                            .pipe(takeUntil(this._destroyTrigger$))
                            .subscribe(
                                (result: IExecutionResult<IMultipleAgreementServiceDetails[]>) => {
                                    this._checkSignedAgreements(result.Result, service);
                                }
                            );
                    }
                });
        } else {
            this._checkSignedAgreement(service);
        }
    }

    isDisabled(service: any): boolean {
        if (service.Added) {
            if (this.level === 'user') {
                return (
                    service.Id !== Service.SharePoint &&
                    service.Id !== Service.Backup &&
                    service.Id !== Service.BackupAcronis &&
                    service.Id !== Service.Microsoft365
                );
            }
            if (
                (service.Id === Service.Microsoft365 &&
                    service.SubId === Office365ProductGroups.Azure) ||
                service.Id === Service.BitDefender ||
                service.Id === Service.AppInteract ||
                service.Id === Service.Vade
            ) {
                return true;
            }

            if (
                service.ServiceInstanceStatus !== null &&
                (service.ServiceInstanceStatus !== ServiceInstanceStatus.Active ||
                    this._isServiceActiveAndLimited(service))
            ) {
                return true;
            }
        }

        return false;
    }

    isServiceChangingState(service: any): boolean {
        return (
            service.ServiceInstanceStatus === ServiceInstanceStatus.Activating ||
            service.ServiceInstanceStatus === ServiceInstanceStatus.Deleting
        );
    }

    isServiceStateChangeFailed(service: any): boolean {
        return (
            service.ServiceInstanceStatus === ServiceInstanceStatus.ActivationFailed ||
            service.ServiceInstanceStatus === ServiceInstanceStatus.DeletionFailed
        );
    }

    isServiceAdded(service: any): boolean {
        return (
            service.Added &&
            !this.isServiceChangingState(service) &&
            !this.isServiceStateChangeFailed(service)
        );
    }

    executeCommand(command: string, data: any): void {
        if (command === 'yes') {
            this.close();
            this._dataService.setData('your-account-data', { activeFlow: 'service agreement' });
            this._router.navigate(['/account-setting/service-agreements']);
        }
        const element: HTMLElement = document.getElementById('giacom');

        if (element) {
            element.style.overflow = null;
        }
    }

    ngOnInit(): void {
        this.addServiceInput = { context: '', id: '', serviceid: '', subId: 0, serviceName: '' };

        if (this.noCustomerOrService) {
            this.loadServices();
        } else if (this.level === 'customer') {
            this.loadServicesForCustomer();
        } else {
            this.loadServicesForUser();
        }

        this.agreeSub = this._eventService.subscribe('agree-popup', async (data: any) => {
            if (data.hasMoreAgreements) {
                return;
            }

            if (!this.noCustomerOrService) {
                const serviceId: number = data.service.Id;
                const subId: number = data.service.subId;
                let service: IAddedServiceModel;

                if (subId) {
                    service = this.services.filter(
                        (product: IAddedServiceModel) =>
                            product.Id === serviceId && product.SubId === subId
                    )[0];
                } else {
                    service = this.services.filter(
                        (product: IAddedServiceModel) => product.Id === serviceId
                    )[0];
                }
                this._performRouting(service);
            } else if (this.signedInUser.isEndUserAdmin) {
                this._lstCustomerComponent.addServiceInput = { context: '', id: '', serviceid: '' };
                this._lstCustomerComponent.serviceId = +data.service.Id;
                this._lstCustomerComponent.subId = +data.service.subId;
                this._lstCustomerComponent.continueAddService();
            } else {
                this._popupService.show(null, 'list-customer', {
                    id: data.service.marketplaceServiceId,
                    serviceId: data.service.Id,
                    subId: data.service.subId,
                    newJourney: data.service.NewJourney,
                });
            }
            this.close();
        });
    }

    ngOnDestroy(): void {
        this.agreeSub.unsubscribe();
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }

    private _hideServiceAtEndUser(service: any): void {
        if (
            (service.Id === Service.Microsoft365 &&
                service.SubId === Office365ProductGroups.Azure) ||
            service.Id === Service.BitDefender ||
            service.Id === Service.AppInteract ||
            service.Id === Service.Exclaimer ||
            service.Id === Service.MessageLabs ||
            service.Id === Service.Vade ||
            service.Id === Service.AzureRI ||
            service.Id === Service.AzureSS ||
            service.Id === Service.Vade
        ) {
            service.hidden = true;
        }
    }

    private _checkSignedAgreements(
        multipleAgreements: IMultipleAgreementServiceDetails[],
        service: any
    ): void {
        this._agreementService
            .checkSignedAgreements$(multipleAgreements)
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe((response: IExecutionResult<IMultipleAgreements>) => {
                const agreements: number[] = [];
                const result: any = response.Result;

                result.multipleAgreementDetails.forEach(
                    (agreement: IMultipleAgreementServiceDetails) => {
                        agreements.push(agreement.serviceId);
                    }
                );

                if (agreements.length > 0) {
                    this._agreementService
                        .signAgreements$(agreements)
                        .subscribe((results: IExecutionResult<IAdditionalServiceAgreement[]>) => {
                            const responses: IAdditionalServiceAgreement[] = results.Result;
                            const resultsList: IParentChildAgreement[] = responses.map(
                                (additionalServiceAgreementModel: IAdditionalServiceAgreement) =>
                                    new ParentChildAgreementModel(
                                        additionalServiceAgreementModel,
                                        service.Id,
                                        service.Office365GroupId
                                    )
                            );

                            this._popupService.show(this, 'terms-popup', {
                                agreements: resultsList,
                            });
                        });
                } else {
                    this._checkSignedAgreement(service);
                }
            });
    }

    private _checkSignedAgreement(service: any): void {
        this._agreementService
            .checkSignedAgreement$(service.Id, service.SubId)
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe((response: IExecutionResult<ISignedService>) => {
                const isSigned: boolean = response.Result.signed;
                const canClose: boolean = false;

                if (!isSigned) {
                    this._agreementService
                        .signAgreement$(service.Id, service.MarketplaceServiceId, service.SubId)
                        .subscribe(
                            (
                                responses: [boolean, IExecutionResult<IAdditionalServiceAgreement>]
                            ) => {
                                if (responses[0]) {
                                    const agreement: IAdditionalServiceAgreement =
                                        responses[1].Result;

                                    this._popupService.show(this, 'terms-popup', {
                                        agreement,
                                        serviceId: service.Id,
                                        marketplaceServiceId: service.MarketplaceServiceId,
                                        groupId: service.SubId,
                                    });
                                } else {
                                    this.close();
                                    this._agreementService.showNoPermissionToAgreeDialog();
                                }
                            }
                        );
                } else if (!this.noCustomerOrService) {
                    if (this._performRouting(service)) {
                        this.close();
                    }
                } else if (this.signedInUser.isEndUserAdmin) {
                    this._lstCustomerComponent.addServiceInput = {
                        context: '',
                        id: '',
                        serviceid: '',
                    };
                    this._lstCustomerComponent.serviceId = +service.Id;
                    this._lstCustomerComponent.subId = +service.SubId;
                    this._lstCustomerComponent.continueAddService();

                    this.close();
                } else {
                    this._popupService.show(null, 'list-customer', {
                        id: service.MarketplaceServiceId,
                        serviceId: service.Id,
                        subId: service.SubId || service.Office365GroupId,
                        newJourney: service.NewJourney,
                        listingId: service.ListingId,
                    });
                    this.close();
                }

                if (canClose) {
                    this.close();
                }
            });
    }

    private _isServiceActiveAndLimited(service: any): boolean {
        return (
            service.ServiceInstanceStatus === ServiceInstanceStatus.Active &&
            (service.OrderLimit === ProductOrderLimit.OncePerCustomer ||
                service.OrderLimit === ProductOrderLimit.OncePerReseller)
        );
    }

    private _performRouting(service: any): boolean {
        const customerUserId: string =
            this.level === 'user'
                ? `${this.selectedCustomerId}+${this.selectedEndUserId}`
                : this.selectedCustomerId;
        const serviceRouteName: string = this._serviceService.getServiceRouteName(
            service.Id,
            service.SubId
        );

        switch (service.Id) {
            case Service.Exchange:
                this._dataService.setData('add-hosted-exchange-service-data', {
                    level: this.level,
                    organisationId: this.selectedCustomerId,
                    userId: this.selectedEndUserId,
                    mode: this.selectedEndUserId && service.Added ? 'modify' : 'add',
                });
                break;

            case Service.Microsoft365:
                if (service.Added && service.SubId === Office365ProductGroups.Azure) {
                    return false;
                }
                this._dataService.setData('addServiceContext', this.addServiceInput);
                break;

            case Service.SecureEmailMessaging:
            case Service.Cirius:
                this._dataService.setData('secure-email-exchange-model', {
                    IsBackModel: false,
                    level: this.level,
                    customerId: this.selectedCustomerId,
                    userId: this.selectedEndUserId,
                    mode: this.selectedEndUserId && service.Added ? 'modify' : 'add',
                });
                break;

            case Service.AdvancedSecurity:
                this._dataService.setData('add-email-security-service-data', {
                    level: this.level,
                    organisationId: this.selectedCustomerId,
                    userId: this.selectedEndUserId,
                    mode: this.selectedEndUserId && service.Added ? 'modify' : 'add',
                });
                break;

            case Service.SharePoint:
                this._dataService.setData('add-share-point-service-data', {
                    level: this.level,
                    organisationId: this.selectedCustomerId,
                    userId: this.selectedEndUserId,
                    mode: this.selectedEndUserId && service.Added ? 'add' : 'add', // sharepoint is
                    // always add
                    // service
                });
                break;
            case Service.Backup:
            case Service.BackupAcronis:
                this._dataService.setData('add-acronis-backup-service-data', {
                    level: this.level,
                    organisationId: this.selectedCustomerId,
                    userId: this.selectedEndUserId,
                    mode: 'add',
                });
                break;
            case Service.BitDefender:
                this._dataService.setData('add-bitdefender-service-data', {
                    level: this.level,
                    organisationId: this.selectedCustomerId,
                    userId: this.selectedUserId,
                    mode: this.selectedUserId && service.Added ? 'modify' : 'add',
                });
                break;
            case Service.AppInteract:
                this._dataService.setData('add-appinteract-service-data', {
                    level: this.level,
                    organisationId: this.selectedCustomerId,
                    userId: this.selectedUserId,
                    mode: this.selectedUserId && service.Added ? 'modify' : 'add',
                });
                break;
            case Service.HostedVoiceHardware:
                this._dataService.setData('add-hosted-voice-hardware-service-data', {
                    level: this.level,
                    organisationId: this.selectedCustomerId,
                    userId: this.selectedUserId,
                    mode: this.selectedUserId && service.Added ? 'modify' : 'add',
                });
                break;
            case Service.MessageLabs:
                this._dataService.setData('add-message-labs-service-data', {
                    level: this.level,
                    organisationId: this.selectedCustomerId,
                    userId: this.selectedUserId,
                    mode: this.selectedUserId && service.Added ? 'modify' : 'add',
                });
                break;
            case Service.CloudTalk:
                this._dataService.setData('add-cloudtalk-service-data', {
                    level: this.level,
                    organisationId: this.selectedCustomerId,
                    userId: this.selectedUserId,
                    mode: this.selectedUserId && service.Added ? 'modify' : 'add',
                });
                break;
            case Service.Skykick:
                this._dataService.setData('add-skykick-service-data', {
                    level: this.level,
                    organisationId: this.selectedCustomerId,
                    userId: this.selectedUserId,
                    mode: this.selectedUserId && service.Added ? 'modify' : 'add',
                });
                break;
            case Service.DropSuite:
                this._dataService.setData('add-dropsuite-service-data', {
                    level: this.level,
                    organisationId: this.selectedCustomerId,
                    userId: this.selectedUserId,
                    mode: this.selectedUserId && service.Added ? 'modify' : 'add',
                });
                break;
            case Service.Exclaimer: {
                this._router.navigate([
                    this.routesPaths.CUSTOMER.ORGANISATION.VIEW(customerUserId),
                    this.routesPaths.CUSTOMER.ACTIONS.ADD_SERVICE,
                    this.routesPaths.SERVICES.EXCLAIMER,
                ]);

                return true;
            }
            case Service.AcronisFilesCloud: {
                this._router.navigate([
                    'customers/customer',
                    customerUserId,
                    'add-services',
                    service.Id,
                    'Add',
                    this.level,
                ]);

                return true;
            }
            case Service.Vade:
                this._dataService.setData('add-vade-service-data', {
                    level: this.level,
                    organisationId: this.selectedCustomerId,
                    userId: this.selectedUserId,
                    mode: 'add',
                });
                break;
            case Service.AzureRI:
            case Service.AzureSS:
                this._dataService.setData('add-one-time-purchase-service-data', {
                    level: this.level,
                    organisationId: this.selectedCustomerId,
                    userId: this.selectedUserId,
                    mode: this.selectedUserId && service.Added ? 'modify' : 'add',
                });
                break;
            default:
                break;
        }

        const crossSellFeatureFlag: boolean =
            this._featureFlagService.isFeatureFlagEnabled('CrossSell');

        // Cross-sell feature hijack
        if (
            crossSellFeatureFlag &&
            service.Id === Service.Microsoft365 &&
            service.SubId === Office365ProductGroups.Office365Core
        ) {
            service.NewJourney = true;
        }

        const serviceSupportsNce: boolean = NCE_PICKER_SERVICES.includes(serviceRouteName);

        if (serviceSupportsNce) {
            this._router.navigate(['customers/customer', this.selectedCustomerId, 'nce-question'], {
                queryParams: {
                    serviceRouteName,
                    listingId: service.ListingId,
                    isNewJourney: service.NewJourney,
                },
            });
        } else if (service.NewJourney) {
            let listingId: string = '';

            switch (serviceRouteName) {
                case 'office-365':
                    listingId = 'bde50b4e-7d3d-feac-5455-91dec1368d51';
                    break;
                case 'cloud-server':
                    listingId = '10d0b1ee-d2d5-11e9-85c2-00155d806b15';
                    break;
                case 'azure':
                    listingId = 'aa51c0ad-46f7-4e68-afc6-d2d0be0e45b9';
                    break;
                default:
                    if (service.ListingId) {
                        listingId = service.ListingId;
                    }
                    break;
            }

            this._router.navigate([
                'customers/customer',
                this.selectedCustomerId,
                'cs',
                'add-service',
                listingId,
            ]);
        } else if (
            this._router.url
                .toString()
                .search(`customers/customer/${customerUserId}/add-service/${serviceRouteName}`) < 0
        ) {
            this._router.navigate([
                'customers/customer',
                customerUserId,
                'add-service',
                serviceRouteName,
            ]);
        } else {
            this._eventService.publish('refresh-add-service');
        }

        return true;
    }
}
