import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

import { ApiMfaService } from '../../../../../core/services/api/mfa/api-mfa.service';
import { AbstractPopup } from '../../../../../feature-modules/popups/classes/abstract-popup.class';
import { PopupService } from '../../../../../feature-modules/popups/services/popup.service';
import { IConfigureMFAData } from '../configure-mfa-data.interface';
import { MfaCommand } from '../enums/mfa-command.enum';
import { MfaModal } from '../enums/mfa-modal.enum';
import { IMFAVerificationWindowData } from '../mfa-verification-window-data.interface';

@Injectable({
    providedIn: 'root',
})
export class MfaService {
    private _popup: AbstractPopup<unknown>;

    constructor(private _popupService: PopupService, private _apiMfaService: ApiMfaService) {}

    getVerificationCode$(
        mfaVerificationWindowData: IMFAVerificationWindowData
    ): Observable<string> {
        const token$: ReplaySubject<string> = new ReplaySubject();

        this._popup = this._popupService.show(
            {
                executeCommand: (command: string, data: unknown) => {
                    if (command === MfaCommand.verify) {
                        token$.next(data as string);
                    }

                    if (command === MfaCommand.cancel) {
                        token$.complete();
                    }
                },
            },
            MfaModal.verificationCode,
            {
                mfaVerificationData: mfaVerificationWindowData,
            }
        );

        return token$;
    }

    configurationNeeded$(
        username: string,
        configureMFAData: IConfigureMFAData
    ): Observable<string> {
        const token$: ReplaySubject<string> = new ReplaySubject();

        this._popup = this._popupService.show(
            {
                executeCommand: (moreInformationPopupCommand: string) => {
                    if (moreInformationPopupCommand === MfaCommand.continue) {
                        this._popup = this._popupService.show(
                            {
                                executeCommand: (configurePopupCommand: string, data: unknown) => {
                                    if (configurePopupCommand === MfaCommand.verify) {
                                        token$.next(data as string);
                                    }

                                    if (configurePopupCommand === MfaCommand.cancel) {
                                        token$.complete();
                                    }
                                },
                            },
                            MfaModal.configure,
                            {
                                configureMFAData,
                            }
                        );
                    }

                    if (moreInformationPopupCommand === MfaCommand.cancel) {
                        token$.complete();
                    }
                },
            },
            MfaModal.moreInformation,
            {
                userName: username,
            }
        );

        return token$;
    }

    configure$(configureMFAData: IConfigureMFAData): Observable<string> {
        const token$: ReplaySubject<string> = new ReplaySubject();

        this._popup = this._popupService.show(
            {
                executeCommand: (command: string, data: unknown) => {
                    if (command === MfaCommand.verify) {
                        token$.next(data as string);
                    }
                },
            },
            MfaModal.configure,
            {
                configureMFAData,
            }
        );

        return token$;
    }

    reset$(mfaUserName: string): Observable<void> {
        const action$: Subject<void> = new Subject();

        this._popup = this._popupService.show(
            {
                executeCommand: (command: string) => {
                    if (command === MfaCommand.reset) {
                        this._apiMfaService.disableMfaForUser$(mfaUserName).subscribe(() => {
                            action$.next();
                            action$.complete();
                        });
                    }

                    if (command === MfaCommand.cancel) {
                        action$.complete();
                    }
                },
            },
            MfaModal.reset,
            {}
        );

        return action$;
    }

    finish(): void {
        this._popup?.close();
    }
}
