import { Pipe, PipeTransform } from '@angular/core';
import PrettyBytes from 'pretty-bytes-es5';

@Pipe({
    name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
    transform(value: number): string {
        return PrettyBytes(value);
    }
}
