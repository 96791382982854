import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CmButtonDirective } from './directives/cm-button.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [CmButtonDirective],
    exports: [CmButtonDirective],
})
export class CmButtonDirectiveModule {}
