import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_ENDPOINTS } from '../../../shared/constants/api/api-endpoints.constants';
import { ICloudMarketButton } from '../../../shared/interfaces/cm-button.types';
import { IExecutionResult } from '../../interfaces/execution-result.interface';
import { IOptOuts } from '../../interfaces/opt-outs.interface';
import { AuthorizedHttpService } from '../http/authorized-http.service';

@Injectable({
    providedIn: 'root',
})
export class ApiFooterService {
    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    getOptOuts$(): Observable<IExecutionResult<IOptOuts>> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.FOOTER.GET_OPT_OUTS);
    }

    updateOptOuts$(optOuts: IOptOuts): Observable<IExecutionResult<IOptOuts>> {
        return this._authorizedHttpService.put$(API_ENDPOINTS.FOOTER.UPDATE_OPT_OUTS, optOuts);
    }

    getFooterButtons$(): Observable<IExecutionResult<ICloudMarketButton[]>> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.FOOTER.GET_FOOTER_BUTTONS);
    }
}
