import { ModuleWithProviders, NgModule } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ROUTES_PATHS } from '../../../shared/constants/routes.constants';

import { Auth0ClientFactory, Auth0ClientService } from './sso-auth-client.factory';
import { SsoAuthService } from './sso-auth.service';
import { AuthClientConfig, AuthConfigService } from './types/sso-auth-client.config';
import { IAuthConfig } from './types/sso-auth-config.interface';

@NgModule()
export class SsoAuthModule {
    static forRoot(config?: IAuthConfig): ModuleWithProviders<SsoAuthModule> {
        const environmentConfiguration: IAuthConfig = {
            domain: environment.Auth0Configuration.domain,
            clientId: environment.Auth0Configuration.clientId,
            authorizationParams: {
                redirect_uri: `${window.location.origin}/${ROUTES_PATHS.LOGIN}`,
            },
        };

        return {
            ngModule: SsoAuthModule,
            providers: [
                SsoAuthService,
                {
                    provide: AuthConfigService,
                    useValue: config ?? environmentConfiguration,
                },
                {
                    provide: Auth0ClientService,
                    useFactory: Auth0ClientFactory.createClient,
                    deps: [AuthClientConfig],
                },
            ],
        };
    }
}
