import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { IWhitelabel } from '../../../shared/interfaces/whitelabelling.interface';
import { WhitelabelService } from '../../../shared/services/whitelabel.service';

@Injectable({
    providedIn: 'root',
})
export class WhitelabellingResolver implements Resolve<IWhitelabel> {
    constructor(
        private _whiteLabelService: WhitelabelService
    ) {}

    resolve(): Observable<IWhitelabel> {
        return this._whiteLabelService.resolve()
    }
}
