import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { KnowledgeBaseComponent } from './components/knowledge-base/knowledge-base.component';
import { KnowledgebaseLinkDirective } from './directives/knowledge-base-link/knowledge-base-link.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [KnowledgeBaseComponent, KnowledgebaseLinkDirective],
    exports: [KnowledgeBaseComponent, KnowledgebaseLinkDirective],
})
export class KnowledgeBaseModule {}
