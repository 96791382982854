import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../cm-icon/cm-icon.module';

import { AlertActionsComponent } from './alert-actions/alert-actions.component';
import { AlertContentComponent } from './alert-content/alert-content.component';
import { AlertTitleComponent } from './alert-title/alert-title.component';
import { CmAlertComponent } from './cm-alert.component';

@NgModule({
    imports: [CommonModule, IconModule],
    declarations: [
        CmAlertComponent,
        AlertActionsComponent,
        AlertContentComponent,
        AlertTitleComponent,
    ],
    exports: [CmAlertComponent, AlertActionsComponent, AlertContentComponent, AlertTitleComponent],
})
export class CmAlertModule {}
