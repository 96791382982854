import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-search-box-filter',
    templateUrl: './search-box-filter.component.html',
    styleUrls: ['./search-box-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBoxFilterComponent {
    @Input() placeholder: string;
    @Input() filterValue: string;
    @Output() readonly searchText: EventEmitter<string> = new EventEmitter<string>();

    filterValueChange(): void {
        this.searchText.emit(this.filterValue);
    }
}
