import { Pipe, PipeTransform } from '@angular/core';

import { INotification } from '../../feature-modules/notifications/interfaces/notification.interface';

@Pipe({
    name: 'FilterNotificationsByString',
})
export class FilterNotificationsByStringPipe implements PipeTransform {
    transform(items: INotification[], searchTerm: string): INotification[] {
        if (!items) {
            return [];
        }
        if (!searchTerm) {
            return items;
        }

        const formatterSearchTerm: string = searchTerm.toLowerCase();

        return items.filter((item: INotification) =>
            item.Content.toLowerCase().includes(formatterSearchTerm)
        );
    }
}
