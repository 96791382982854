import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { finalize, map, shareReplay, takeUntil } from 'rxjs/operators';

import { IExecutionResult } from '../../../core/interfaces/execution-result.interface';
import { IService } from '../../../core/interfaces/service.interface';
import { ApiMyServicesService } from '../../../core/services/api/api-my-services.service';

@Injectable()
export class ServicesListFacadeService {
    private readonly _services$: BehaviorSubject<IService[]> = new BehaviorSubject<IService[]>([]);
    private readonly _servicesShared$: Observable<IService[]> = this._services$.pipe(shareReplay());
    private readonly _selectedServiceId$: BehaviorSubject<number | null> = new BehaviorSubject<
        number | null
    >(null);
    private readonly _selectedService$: Observable<IService | null> =
        this._generateServiceByIdGetter$();
    private readonly _isFetchInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    constructor(private _apiMyServicesService: ApiMyServicesService) {}

    fetchServices(destroyTrigger$: Subject<void>): void {
        this._services$.next([]);
        this._isFetchInProgress$.next(true);

        this._apiMyServicesService
            .getServices$()
            .pipe(
                map((res: IExecutionResult<IService[]>) => {
                    if (!res.Succeed) {
                        return;
                    }

                    this._services$.next(res.Result);
                }),
                takeUntil(destroyTrigger$),
                finalize(() => this._isFetchInProgress$.next(false))
            )
            .subscribe();
    }

    getServices$(): Observable<IService[]> {
        return this._servicesShared$;
    }

    getSelectedService$(): Observable<IService | null> {
        return this._selectedService$;
    }

    getIsFetchInProgress$(): Observable<boolean> {
        return this._isFetchInProgress$.asObservable();
    }

    // this should be called only from services.service.ts, will work if called from other places
    // but wont take care of clearing following up selections e.g. selected service customer
    selectServiceViaId(serviceId: number | null): void {
        this._selectedServiceId$.next(serviceId);
    }

    private _generateServiceByIdGetter$(): Observable<IService | null> {
        return combineLatest([this._servicesShared$, this._selectedServiceId$]).pipe(
            map(
                ([services, selectedServiceId]: [IService[], number | null]) =>
                    services.find((service: IService) => service.Id === selectedServiceId) || null
            )
        );
    }
}
