import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractControlValueAccessor } from 'app/shared/classes/abstract-control-value-accessor.class';

import { IMultiselectInputOption } from '../../interfaces/multiselect-input-option.interface';

@Component({
    selector: 'multiselect-chips-container',
    templateUrl: './multiselect-chips-container.component.html',
    styleUrls: ['./multiselect-chips-container.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiselectChipsContainerComponent),
            multi: true,
        },
    ],
})
export class MultiselectChipsContainerComponent extends AbstractControlValueAccessor<
    IMultiselectInputOption[]
> {
    @Input() chipsPlacement: 'horizontal' | 'vertical';
    @Input() removeButtonEnabled: boolean = true;
    @Output() readonly unselectOptionClick: EventEmitter<IMultiselectInputOption> =
        new EventEmitter();

    onClick(option: IMultiselectInputOption): void {
        if (this.unselectOptionClick) {
            this.unselectOptionClick.emit(option);
        }

        this.value = this.value.filter((entry: IMultiselectInputOption) => entry !== option);
        this.onChange(this.value);
    }
}
