import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Permission } from '@cloudmarket/permissions-contract/lib/permissions.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccountLevel } from '../../../../core/enums/account-level.enum';
import { IExecutionResult } from '../../../../core/interfaces/execution-result.interface';
import { ApiFeatureFlagsService } from '../../../../core/services/api/api-feature-flags.service';
import { ApiCustomerService } from '../../../../core/services/api/customer/api-customer.service';
import { ICustomer } from '../../../../core/services/api/customer/types/customer.interface';
import { ApiMfaService } from '../../../../core/services/api/mfa/api-mfa.service';
import { ApiUserService } from '../../../../core/services/api/user/api-user.service';
import { DeviceScreenService } from '../../../../core/services/device-screen/device-screen.service';
import { GlobalDataService } from '../../../../core/services/global-data.service';
import { GlobalEventService } from '../../../../core/services/global-event.service';
import { AuthorizedHttpService } from '../../../../core/services/http/authorized-http.service';
import { PermissionsService } from '../../../../core/services/permissions.service';
import { Resource, UserInfo } from '../../../../core/services/resource.service';
import { PopupService } from '../../../../feature-modules/popups/services/popup.service';
import { RunningState } from '../../../../models/customer/running-state.enum';
import { UserType } from '../../../../models/permission/user-type.enum';
import { BreadcrumbsService } from '../../../../new-shared/components/cm-breadcrumbs/services/breadcrumbs.service';
import { MfaService } from '../../../../new-shared/components/modals/mfa/services/mfa.service';
import { API_ENDPOINTS } from '../../../../shared/constants/api/api-endpoints.constants';
import { ROUTES_PATHS } from '../../../../shared/constants/routes.constants';
import { ScreenSize } from '../../../../shared/enum/screen-size.enum';
import { IDictionary } from '../../../../shared/interfaces/dictionary.interface';
import { IScreenSize } from '../../../../shared/interfaces/screen-size.interface';
import { PageViewMethod } from '../../../../shared/services/ga-service/ga-page-load';
import { GAService } from '../../../../shared/services/ga-service/ga-service';
import { AbstractPageLoad } from '../../../../shared/services/ga-service/shared-classes';
import {
    IResetPasswordPayload
} from '../../../services/components/exchange/tabs/reset-password-tab/reset-password-payload.interface';
import { CustomerCommandsService } from '../../customer-commands.service';
import { IListedUser } from '../../interfaces/listed-user.interface';
import { IUserDetailModel } from '../../interfaces/user-detail-model.interface';

@Component({
    selector: 'app-cust-user-detail',
    styleUrls: ['./cust-user-detail.component.scss'],
    templateUrl: './cust-user-detail.component.html',
})
export class CustUserDetailComponent
    extends AbstractPageLoad
    implements OnInit, OnDestroy
{
    pageName: string = 'customers/user-dashboard';
    pageType: string = 'customers';
    readonly accountLevel: typeof AccountLevel = AccountLevel;
    pageViewMethod: PageViewMethod = PageViewMethod.page;
    userFlow: typeof UserFlow = UserFlow;
    isDisplayFullActions: boolean = true;
    screenType: IScreenSize;
    organisationId: string;
    userId: number;
    currentFlow: UserFlow;
    user: IUserDetailModel = {
        Id: 0,
        Name: '',
        UserType: '',
        DisplayName: '',
        IsProductUser: false,
    };
    customerName: string;
    addServiceInput: any;
    signedInUser: UserInfo;
    isEuroReseller: boolean;
    isSuspended: boolean;
    initialised: boolean = false;
    organisation: ICustomer = {
        Id: '',
        Name: '',
    };
    isCustomerAdmin: boolean = false;
    isEnabledResetMfaConfiguration: boolean = false;
    hasPermissionsToAddProduct: boolean;
    hasAccessToDeleteUser: boolean;
    hasAccessToResetPassword: boolean;
    hasAccessToAdminPermissions: boolean;
    isDifferentUserThanUserLoggedIn: boolean = false;
    readonly ctaActions: IDictionary<string> = {
        deleteUser: 'deleteUser',
    };
    private _sub: any;
    private readonly _destroyTrigger$: Subject<void> = new Subject<void>();

    constructor(
        private _breadcrumbsService: BreadcrumbsService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _eventService: GlobalEventService,
        private _popupService: PopupService,
        private _resource: Resource,
        private _dataService: GlobalDataService,
        protected _gaService: GAService,
        private _deviceScreenService: DeviceScreenService,
        private _apiCustomerService: ApiCustomerService,
        private _apiUserService: ApiUserService,
        private _authorizedHttpService: AuthorizedHttpService,
        private _apiMfaService: ApiMfaService,
        private _apiFeatureFlagsService: ApiFeatureFlagsService,
        private _permissionsService: PermissionsService,
        private _customerCommandsService: CustomerCommandsService,
        private _mfaService: MfaService
    ) {
        super(_gaService);
        this.signedInUser = this._resource.user;
        this.isEuroReseller = this._resource.isEuroReseller
    }

    ngOnInit(): void {
        this._setAccessToUserActions();

        this._eventService.subscribe('switchArchiveView', (data: UserFlow) => {
            this.currentFlow = data;
        });

        this._sub = this._route.params.subscribe((params: Params) => {
            const id: string = params.id;

            if (id) {
                const ids: string[] = decodeURIComponent(id).split('+');

                this.userId = parseInt(ids[0], 10);
                this.organisationId = ids[1];
                /* eslint-disable @typescript-eslint/no-magic-numbers */
                this.isSuspended = ids[2] === 'true';

                this.changeFlow(UserFlow.services);

                this.initialised = true;
                this._authorizedHttpService
                    .get$(API_ENDPOINTS.USER.GET_USER(this.organisationId, this.userId))
                    .pipe(takeUntil(this._destroyTrigger$))
                    .subscribe((result: IExecutionResult<IUserDetailModel>) => {
                        this.user = result.Result;

                        this._updateDataOnSuccessfulGetUser();
                    });
                this._apiCustomerService
                    .getCustomerById$(this.organisationId)
                    .pipe(takeUntil(this._destroyTrigger$))
                    .subscribe((data: IExecutionResult<ICustomer>) => {
                        this.customerName = data.Result.Name;

                        this._breadcrumbsService.addExternalData({
                            customerName: data.Result.Name
                        })
                    });
            }
        });

        this._apiCustomerService
            .getCustomerById$(this.organisationId)
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe((result: IExecutionResult<ICustomer>) => {
                this.organisation.Id = result.Result.Id;
                this.organisation.Name = result.Result.Name;
                this.organisation.RunningStateId = result.Result.RunningStateId;
                this.organisation.SuspendTime = result.Result.SuspendTime;
                this.organisation.SuspendTimeGiacomFormat = result.Result.SuspendTimeGiacomFormat;
                this.isSuspended = result.Result.RunningStateId === RunningState.resellerSuspended;

                this.initialised = true;
            });

        this._deviceScreenService
            .getScreenSizeFlags$()
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe((deviceScreenSizeFlags: IScreenSize) => {
                this.screenType = deviceScreenSizeFlags;
                this.isDisplayFullActions = !deviceScreenSizeFlags[ScreenSize.small];
            });
    }

    ngOnDestroy(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }

    checkPermissionsToAddProduct(): boolean {
        if (this.isSuspended) {
            return false;
        }

        return this._permissionsService.hasPermission(Permission.addProducts);
    }

    deleteUser(userId: string): void {
        this._apiUserService.deleteUser$(userId).subscribe((result: IExecutionResult<void>) => {
            if (!result.Succeed) {
                return;
            }

            this._router.navigate([`/${ROUTES_PATHS.CUSTOMER.BASE}/${this.organisationId}`]);
        });
    }

    showUserDeleteModal(): void {
        this._authorizedHttpService
            .get$(API_ENDPOINTS.HOSTED_EXCHANGE.IS_USER_MIGRATING(this.user.Id))
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe((result: IExecutionResult<boolean>) => {
                this._popupService.show(
                    {
                        executeCommand: (command: string, userId: string) => {
                            if (command === this.ctaActions.deleteUser) {
                                this.deleteUser(userId);
                            }
                        },
                    },
                    'delete-user-popup',
                    {
                        isMigrating: result.Result,
                        user: this.user,
                    }
                );
            });
    }

    addService(): void {
        this._popupService.show(null, 'select-service', {
            level: 'user',
            userId: this.userId,
            organisationId: this.organisationId,
        });
        this._eventService.publish('addServiceContext', this.addServiceInput);
        setTimeout(() => {
            this._eventService.publish('addServiceContext', this.addServiceInput);
        }, 500);
    }

    assignAdminPermission(): void {
        const listedUser: IListedUser = {
            name: this.user.Name,
            userType: this.user.UserType,
            id: this.user.Id,
            displayName: this.user.DisplayName,
            isProductUser: this.user.IsProductUser,
        };

        this._dataService.setData('cust-user-ref-data', listedUser);

        this._customerCommandsService.assignAdminPermission(listedUser, this.organisationId);
    }

    removeAdminPermission(): void {
        this._popupService.show(null, 'revert-customer-admin', {
            Id: this.user.Name,
            FullAccountName: this.user.Name,
        });
    }

    resetPassword(): void {
        const resetPasswordPayload: IResetPasswordPayload = {
            user_id: this.userId,
            user_name: this.user.Name,
            user_type: this.user.UserType,
            is_product_user_context: this.user.IsProductUser
        };

        this._popupService.show(null, 'reset-end-user-password', resetPasswordPayload);
    }

    changeFlow(flow: UserFlow): void {
        this.currentFlow = flow;
        this.doVirtualPageLoad(flow);
    }

    doVirtualPageLoad(flow: UserFlow): void {
        let path: string = '';

        switch (flow) {
            case UserFlow.services:
                path = 'services';
                break;
            case UserFlow.activity:
                path = 'activity';
                break;
            case UserFlow.profile:
                path = 'profile';
                break;
            case UserFlow.manageAccess:
                path = 'manage-access';
                break;
            case UserFlow.passwordRequest:
                path = 'password-request';
                break;
            case UserFlow.viewArchive:
                path = 'view-archive';
                break;
            case UserFlow.viewEmail:
                path = 'view-email';
                break;
            default:
                break;
        }

        const data: any = { path, user_id: this.userId.toString() };

        this.recordVirtualPageLoad(data);
    }

    getMfaConfigurationForUser(userName: string): void {
        if (this._apiFeatureFlagsService.isFeatureFlagEnabled('MFA')) {
            this._apiMfaService
                .isEnabledForUser$(userName)
                .pipe(takeUntil(this._destroyTrigger$))
                .subscribe((isEnabled: boolean) => {
                    if (isEnabled) {
                        this.isEnabledResetMfaConfiguration = isEnabled;
                    }
                });
        }
    }

    onResetMfaConfiguration(): void {
        this._mfaService.reset$(this.user.Name).subscribe(() => {
            this.isEnabledResetMfaConfiguration = false;
        });
    }

    private _setAccessToUserActions(): void {
        this.hasAccessToDeleteUser = this._getHasAccessToDeleteUser();
        this.hasAccessToResetPassword = this._getHasAccessToResetPassword();
        this.hasAccessToAdminPermissions = this._getHasAccessToAdminPermissions();
        this.hasPermissionsToAddProduct = this.checkPermissionsToAddProduct();
    }

    private _getHasAccessToDeleteUser(): boolean {
        return this._getHasAccess(Permission.accessCustomerUsers);
    }

    private _getHasAccessToResetPassword(): boolean {
        return this._getHasAccess(Permission.accessCustomerUsers);
    }

    private _getHasAccessToAdminPermissions(): boolean {
        return this._getHasAccess(Permission.createCustomerAdmin);
    }

    private _getHasAccess(rbacPermission: Permission): boolean {
        return this._permissionsService.hasPermission(rbacPermission);
    }

    private _isDifferentUserThanUserLoggedIn(): boolean {
        return this.user.Name.toLowerCase().trim() !== this.signedInUser.userName.toLowerCase().trim();
    }

    private _updateDataOnSuccessfulGetUser(): void {
        if (this.user.UserType === UserType.customerAdmin) {
            this.isCustomerAdmin = true;
        }

        this.getMfaConfigurationForUser(this.user.Name);

        this.isDifferentUserThanUserLoggedIn = this._isDifferentUserThanUserLoggedIn();
    }
}

export enum UserFlow {
    services = 1,
    activity = 2,
    profile = 3,
    manageAccess = 4,
    passwordRequest = 5,
    viewArchive = 6,
    viewEmail = 7,
}
