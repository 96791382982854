import { IActionButtonCore } from '../../core/interfaces/action-button.interface';

export interface ICloudMarketButton extends IActionButtonCore {
    AnalyticsData?: IAnalyticsData;
    ColourClass?: string;
    Text: string;
    DataTestId?: string;
}

export interface IAnalyticsData {
    Action: string;
    Page: string;
    Campaign: string;
}

export interface IPopUpData {
    PopUp: string;
    Payload: any;
}

export interface ISsoData {
    Service: number;
    UserLevel: boolean;
    CustomerLevel: boolean;
    ResellerLevel: boolean;
    Id: any;
}

export enum ButtonAction {
    none = 1,
    popUp = 2,
    navigate = 3,
    download = 4,
    login = 5,
    externalUrl = 6,
    knowledgeBase = 7,
    addService = 8,
    distributionGroupPopUp = 9,
    publishEvent = 10,
    hostedExchangePopUps = 11,
    downloadFile = 12,
    sso = 13,
    export = 14,
    modify = 15,
    announcementAction = 16,
    bottomPanel = 17,
}

export enum ButtonStyle {
    primary = 1,
    secondary = 2,
    landingPage = 3,
    bannerTransparent = 4,
    bannerColor1 = 5,
    marketplaceLink = 6,
    add = 7,
    dropDown = 8,
    hidden = 9,
    dropDownSingle = 10,
    marketplaceView = 11,
    marketplaceAddNow = 12,
    link = 13,
    stackedImage = 14,
    dashboard = 15,
    announcement = 16,
    announcementRemind = 17,
    announcementDoNot = 18,
    announcementDoNotWhite = 19,
    modify = 20,
}

export enum HostedExchangePopUps {
    setMailboxSize = 1,
    setOutlookRuleSize = 2,
    manageSignatures = 3,
    manageDisclaimer = 4,
    activatePublicFolder = 5,
    deletePublicFolder = 6,
    synchronisationSettings = 7,
    clientMobileAccess = 8,
    editMailboxGeneral = 9,
    editMailboxForwarding = 10,
    editMailboxDistribution = 11,
    editMailboxPublicFolders = 12,
    editRoomMailboxGeneral = 13,
    activateAll = 14,
    emailRoutes = 15,
    activateApplicationImpersonation = 16,
    activateSharedMailbox = 17,
    deleteSharedMailbox = 18,
}

export enum ButtonVariant {
    dark = 'dark',
    light = 'light',
    link = 'link',
    primary = 'primary',
    secondary = 'secondary',
    block = 'block',
    outlineDanger = 'outlineDanger',
}
