import { Injectable } from '@angular/core';
import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { concatMap, finalize, map, shareReplay, take, tap } from 'rxjs/operators';

import { SsoAuthService } from '../../../../core/services/sso-auth/sso-auth.service';
import { LoginType } from '../../../../shared/enum/login/login-type.enum';
import { IFinalizingLoginStrategy } from '../../interfaces/finalizing-login-strategy.interface';
import { AuthService } from '../auth/auth.service';
import { SharedSessionCookieStorageService } from '../cookie/shared-session-cookie-storage.service';
import { LoginContext } from '../login/login-context';

@Injectable({
    providedIn: 'root',
})
export class SessionRecreateService {
    private _sessionState$: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
    private _fetchRequestInProgress: boolean = false;
    private _fetchRequest$: Observable<boolean>;

    constructor(
        private _ssoAuthService: SsoAuthService,
        private _authService: AuthService,
        private _sharedSessionCookieStorageService: SharedSessionCookieStorageService,
        private _loginContext: LoginContext,
    ) {
    }

    checkOrRecreateSession$(finalizingLoginStrategy: IFinalizingLoginStrategy): Observable<boolean> {
        return this._sessionState$
            .pipe(
                concatMap(() => {
                    const cloudSessionNotExpired: boolean = this._authService.tokenNotExpired();

                    if (cloudSessionNotExpired) {
                        return of(true);
                    }

                    if (this._fetchRequestInProgress) {
                        return this._fetchRequest$;
                    }

                    return this._createFetchRequest$(finalizingLoginStrategy);
                }),
                take(1),
            );
    }

    private _createFetchRequest$(finalizingLoginStrategy: IFinalizingLoginStrategy): Observable<boolean> {
        this._fetchRequestInProgress = true;

        this._fetchRequest$ = this._checkOrRecreateSession$(finalizingLoginStrategy)
            .pipe(
                tap((sessionState: boolean) => {
                    this._sessionState$.next(sessionState);
                }),
                shareReplay(),
                finalize(() => (this._fetchRequestInProgress = false)),
            );

        return this._fetchRequest$;
    }

    private _checkOrRecreateSession$(finalizingLoginStrategy: IFinalizingLoginStrategy): Observable<boolean> {
        return this._isSsoUserAuthorised$()
            .pipe(
                concatMap((isAuthorised: boolean) => {
                    if (isAuthorised) {
                        return this._recreateCloudSession$(finalizingLoginStrategy)
                            .pipe(map(() => true));
                    }

                    return of(false);
                }),
            );
    }

    private _recreateCloudSession$(finalizingLoginStrategy: IFinalizingLoginStrategy): Observable<void> {
        this._loginContext.setStrategy(LoginType.sso);
        this._loginContext.setFinalizingStrategy(finalizingLoginStrategy);

        return this._loginContext.executeStrategy$();
    }

    private _isSsoUserAuthorised$(): Observable<boolean> {
        const checkSessionParam: GetTokenSilentlyOptions = {
            cacheMode: 'off',
            authorizationParams: {
                organization: this._sharedSessionCookieStorageService.getOrganisationId(),
            },
        };

        return this._ssoAuthService.getAccessTokenSilently$(checkSessionParam)
            .pipe(map((token: string) => !!token));
    }
}
