import { Component, Input } from '@angular/core';

@Component({
    selector: 'cm-text-header-subtitle',
    templateUrl: './cm-text-header-subtitle.component.html',
    styleUrls: ['./cm-text-header-subtitle.component.scss'],
})
export class CmTextHeaderSubtitleComponent {
    @Input() showBorder: boolean = true;
    @Input() smallFont: boolean = false;
}
