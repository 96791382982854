import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { API_ENDPOINTS } from '../../../../shared/constants/api/api-endpoints.constants';
import { AuthorizedHttpService } from '../../http/authorized-http.service';
import { SsoAuthService } from '../../sso-auth/sso-auth.service';

import { IMfaSetup } from './types/api-mfa.types';

@Injectable({
    providedIn: 'root',
})
export class ApiMfaService {

    constructor(
        private _authorizedHttpService: AuthorizedHttpService,
        private _ssoAuthService: SsoAuthService
    ) {}

    setupUser$(): Observable<IMfaSetup> {
        return this._authorizedHttpService.post$(
            API_ENDPOINTS.MFA.USER_SETUP,
            null
        );
    }

    isEnabledForUser$(userName: string): Observable<boolean> {
        if (this._ssoAuthService.isSsoLoginFlow) {
            return of(false);
        }

        return this._authorizedHttpService.post$(
            API_ENDPOINTS.MFA.USER_IS_ENABLED(userName),
            null
        );
    }

    isEnabledForReseller$(): Observable<boolean> {
        if (this._ssoAuthService.isSsoLoginFlow) {
            return of(false);
        }

        return this._authorizedHttpService.post$(
            API_ENDPOINTS.MFA.RESELLER_IS_ENABLED,
            null
        );
    }

    verifyUser$(mfaToken: string): Observable<boolean> {
        return this._authorizedHttpService.post$(
            API_ENDPOINTS.MFA.VERIFY_USER(mfaToken),
            null
        );
    }

    verifyToken$(mfaKey: string, mfaToken: string): Observable<boolean> {
        return this._authorizedHttpService.post$(
            API_ENDPOINTS.MFA.VERIFY_TOKEN(mfaKey, mfaToken),
            null
        );
    }

    configureuser$(mfaKey: string): Observable<boolean> {
        return this._authorizedHttpService.put$(
            API_ENDPOINTS.MFA.CONFIGURE_USER(mfaKey),
            null
        );
    }

    disableMfaForUser$(userName: string): Observable<void> {
        return this._authorizedHttpService.put$(
            API_ENDPOINTS.MFA.DISABLE_FOR_USER(userName),
            null
        );
    }
}
