import { Component, Input, OnChanges } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { IDictionary } from '../../../../../../shared/interfaces/dictionary.interface';

@Component({
    selector: 'cm-input-validation',
    templateUrl: './cm-input-validation.component.html',
    styleUrls: ['./cm-input-validation.component.scss'],
})
export class CmInputValidationComponent implements OnChanges {
    @Input() validationErrors: ValidationErrors | null = null;
    @Input() fieldName: string = 'Field';
    @Input() customMessage: string;
    errorMessage: string | null = null;
    private readonly _errorMessages: IDictionary<
        (
            error:
                | boolean
                | { requiredLength: number }
                | { min: number }
                | { max: number }
                | { message: string }
        ) => string
    > = {
        required: () => `${this.fieldName} is required`,
        email: () => 'Must be a valid email address',
        maxlength: (error: { requiredLength: number }) =>
            `Must be a maximum of ${error.requiredLength} character(s)`,
        minlength: (error: { requiredLength: number }) =>
            `Must be a minimum of ${error.requiredLength} character(s)`,
        pattern: () => 'Must be the correct format',
        numberOnly: () => 'Must contain only digits',
        min: (error: { min: number }) => `Must be at least ${error.min}`,
        max: (error: { max: number }) => `Must be at most ${error.max}`,
        customerNameInvalid: () => 'invalid characters',
        customerNameEmpty: () => 'cannot be empty',
        response: (error: { message: string }) => error.message,
    };

    ngOnChanges(): void {
        this.errorMessage = this.getErrorMessage();
    }

    getErrorMessage(): string | null {
        const errors: ValidationErrors = this.validationErrors;
        const errorKeys: string[] = Object.keys(errors);

        if (errors) {
            if (this.customMessage) {
                return this.customMessage;
            }

            return this._errorMessages[errorKeys[0]](errors[errorKeys[0]]) || null;
        }

        return null;
    }
}
