import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { API_ENDPOINTS } from '../../../shared/constants/api/api-endpoints.constants';
import { IExecutionResult } from '../../interfaces/execution-result.interface';
import { AuthorizedHttpService } from '../http/authorized-http.service';

@Injectable({ providedIn: 'root' })
export class CommsSessionManagementService {
    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    terminateCommsSession$(): Observable<IExecutionResult<boolean>> {
        return this._authorizedHttpService.post$(API_ENDPOINTS.LOGOUT.COMMS_LOGOUT, null).pipe(
            catchError((error: unknown) => {
                console.error('Comms logout failed');
                console.error(error);

                return of({ Succeed: false, Result: false });
            }),
            map((response: IExecutionResult<boolean>) => {
                if (!response.Succeed || !response.Result) {
                    console.error(`Comms logout failed: ${response.Error}`);
                    console.error(response);
                }

                return response;
            })
        );
    }
}
