import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { NotificationsDropdownComponent } from './components/notifications-dropdown/notifications-dropdown.component';
import { FilterNotificationsByCategoryPipe } from './pipes/filter-by-category.pipe';

@NgModule({
    imports: [SharedModule],
    declarations: [NotificationsDropdownComponent, FilterNotificationsByCategoryPipe],
    exports: [NotificationsDropdownComponent],
})
export class NotificationsModule {}
