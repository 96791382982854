import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IExecutionResult } from '../../core/interfaces/execution-result.interface';
import { AuthorizedHttpService } from '../../core/services/http/authorized-http.service';
import { UnauthorizedHttpService } from '../../core/services/http/unauthorized-http.service';
import { IResetPasswordModel } from '../../models/user/reset-password.interface';
import { API_ENDPOINTS } from '../constants/api/api-endpoints.constants';

@Injectable({
    providedIn: 'root',
})
export class ResetPasswordService {
    constructor(
        private _authorizedHttpService: AuthorizedHttpService,
        private _unauthorizedHttpService: UnauthorizedHttpService)  {}

    requestReset$(
        resetPasswordData: IResetPasswordModel
    ): Observable<IExecutionResult<string, { EmailErrors: string[] }>> {
        return this._unauthorizedHttpService.post$(
            API_ENDPOINTS.RESET_PASSWORD.RESET_URL,
            resetPasswordData
        );
    }

    requestResetForAnotherUser$(
        resetPasswordData: IResetPasswordModel
    ): Observable<IExecutionResult<string, { EmailErrors: string[] }>> {
        return this._authorizedHttpService.post$(
            API_ENDPOINTS.RESET_PASSWORD.RESET_ANOTHER_USER_URL,
            resetPasswordData
        );
    }

    requestAnotherUserSsoPasswordReset$(userCloudMarketId: string)
    : Observable<boolean> {
        return this._authorizedHttpService.post$(
            API_ENDPOINTS.RESET_PASSWORD.RESET_ANOTHER_USER_SSO_PASSWORD_URL(userCloudMarketId), null
        );
    }
}
