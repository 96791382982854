import { Injectable } from '@angular/core';
import { IUsersPermissionsResponse } from '@cloudmarket/permissions-contract';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { API_ENDPOINTS } from '../../../shared/constants/api/api-endpoints.constants';
import { IFeatureFlag } from '../../interfaces/feature-flag.interface';
import { IPermissionsResponse } from '../../interfaces/permissions-response.interface';
import { AuthorizedHttpService } from '../http/authorized-http.service';

@Injectable({
    providedIn: 'root',
})
export class ApiPermissionsService {
    private _permissions: IPermissionsResponse = {
        usersPermissions: null,
        featureFlags: [],
    };
    private _permissions$: BehaviorSubject<IPermissionsResponse | null> = new BehaviorSubject(null);
    private _fetchRequest$: Observable<IPermissionsResponse>;
    private _fetchRequestInProgress: boolean = false;

    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    getFeatureFlags(): IFeatureFlag[] {
        return this._permissions.featureFlags;
    }

    getFeatureFlags$(): Observable<IFeatureFlag[]> {
        return this.getPermissions$().pipe(
            map((permissions: IPermissionsResponse) => permissions?.featureFlags)
        )
    }

    getUsersPermissions(): IUsersPermissionsResponse {
        return this._permissions.usersPermissions;
    }

    clearPermissions(): void {
        this._permissions$.unsubscribe();
        this._permissions$ = new BehaviorSubject(null);
        this._permissions = {
            usersPermissions: null,
            featureFlags: [],
        };
    }

    getRefreshedPermissions$(): Observable<IPermissionsResponse> {
        this.clearPermissions();

        return this.getPermissions$();
    }

    getPermissions$(): Observable<IPermissionsResponse> {
        return this._permissions$.pipe(
            switchMap((permissions: IPermissionsResponse) => {
                // permissions already fetched from BE
                if (permissions) {
                    return of(permissions);
                }

                // fetching in progress, share response
                if (this._fetchRequestInProgress) {
                    return this._fetchRequest$;
                }

                // permissions not yet fetched, no fetch request in progress
                return this._createFetchRequest$();
            }),
            take(1)
        );
    }

    private _createFetchRequest$(): Observable<IPermissionsResponse> {
        this._fetchRequestInProgress = true;

        this._fetchRequest$ = this._authorizedHttpService
            .get$<IPermissionsResponse>(API_ENDPOINTS.PERMISSION.GET_PERMISSIONS)
            .pipe(
                tap((permissions: IPermissionsResponse) => {
                    this._permissions = permissions;
                    this._permissions$.next(permissions);
                }),
                finalize(() => (this._fetchRequestInProgress = false))
            );

        return this._fetchRequest$;
    }
}
