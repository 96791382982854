import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Auth0Client } from '@auth0/auth0-spa-js';

import { IAuthConfig } from './sso-auth-config.interface';

@Injectable({ providedIn: 'root' })
export class AuthClientConfig {
    private _config?: IAuthConfig;

    constructor(@Optional() @Inject(AuthConfigService) config?: IAuthConfig) {
        if (config) {
            this.set(config);
        }
    }

    set(config: IAuthConfig): void {
        this._config = config;
    }

    get(): IAuthConfig {
        return this._config as IAuthConfig;
    }
}

// tslint:disable-next-line:naming-convention
export const AuthConfigService: InjectionToken<Auth0Client> = new InjectionToken<IAuthConfig>(
    'auth0-angular.config'
);
