import { Component, ElementRef, Input, ViewChild } from '@angular/core';

import {
    AbstractControlValueAccessor,
    abstractValueAccessorProvider,
} from '../../../../../../shared/classes/abstract-control-value-accessor.class';

@Component({
    selector: 'cm-radio-input',
    templateUrl: './cm-radio-input.component.html',
    styleUrls: ['./cm-radio-input.component.scss'],
    providers: [abstractValueAccessorProvider(CmRadioInputComponent)],
})
export class CmRadioInputComponent extends AbstractControlValueAccessor<string | number> {
    @Input() inputName: string;
    @Input() inputId: string;
    @Input() inputText: string;
    @Input() inputDescription: string;
    @Input() inputValue: string | number;
    @Input() inputDescriptionAlignment: 'sameLine' | 'beneath' = 'beneath';
    @ViewChild('radioInput') radioInput: ElementRef<HTMLInputElement>;
    isDisabled: boolean = false;
    randomInputId: string = this._generateRandomInputId();

    setDisabledState(isDisabled: boolean): void {
        const radioInput: HTMLInputElement | undefined = this.radioInput?.nativeElement;

        if (radioInput) {
            this.isDisabled = isDisabled;
            radioInput.disabled = isDisabled;
        }
    }

    private _generateRandomInputId(): string {
        const maxLengthOfId: number = 7;

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return (Math.random() + 1).toString(36).substring(maxLengthOfId);
    }
}
