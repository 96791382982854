import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { IResellerAgreementValidity } from '../interfaces/reseller-agreement-validity.interface';

import { ApiAgreementService } from './api/agreement/api-agreement.service';

@Injectable({
    providedIn: 'root',
})
export class AgreementServiceResellerValidityFacadeService {
    private _resellerAgreementValidity$: BehaviorSubject<IResellerAgreementValidity | null> =
        new BehaviorSubject(null);
    private _fetchRequestAgreementValidity$: Observable<IResellerAgreementValidity>;
    private _fetchRequestAgreementValidityInProgress: boolean = false;

    constructor(private _apiAgreementService: ApiAgreementService) {}

    clearAgreementState(): void {
        this._resellerAgreementValidity$.unsubscribe();
        this._resellerAgreementValidity$ = new BehaviorSubject(null);
    }

    checkMustSignNow$(): Observable<boolean> {
        return this.getAgreementState$().pipe(
            map((agreementValidity: IResellerAgreementValidity) => agreementValidity.MustSignNow)
        );
    }

    setMustSignNow(mustSignNow: boolean): void {
        this._resellerAgreementValidity$.next({
            MustSignNow: mustSignNow
        });
    }

    getAgreementState$(): Observable<IResellerAgreementValidity> {
        return this._resellerAgreementValidity$.pipe(
            switchMap((agreementValidity: IResellerAgreementValidity) => {
                if (agreementValidity) {
                    return of(agreementValidity);
                }

                if (this._fetchRequestAgreementValidityInProgress) {
                    return this._fetchRequestAgreementValidity$;
                }

                return this._createFetchRequestAgreementValidity$();
            }),
            take(1)
        );
    }

    private _createFetchRequestAgreementValidity$(): Observable<IResellerAgreementValidity> {
        this._fetchRequestAgreementValidityInProgress = true;

        this._fetchRequestAgreementValidity$ = this._apiAgreementService
            .getResellerAgreementValidity$()
            .pipe(
                tap((agreementValidity: IResellerAgreementValidity) => {
                    this._resellerAgreementValidity$.next(agreementValidity);
                }),
                finalize(() => (this._fetchRequestAgreementValidityInProgress = false))
            );

        return this._fetchRequestAgreementValidity$;
    }
}
