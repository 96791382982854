import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CmInputValidationComponent } from './cm-input-validation.component';

@NgModule({
    declarations: [CmInputValidationComponent],
    imports: [CommonModule],
    exports: [CmInputValidationComponent],
})
export class CmInputValidationModule {}
