import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash-es';

@Pipe({
    name: 'searchFilter',
})
export class SearchFilterPipe<T> implements PipeTransform {
    transform(items: T[], searchString: string, searchPath: string): T[] {
        if (!searchString || searchString === '') {
            return items;
        }

        return items.filter(
            (x: T) =>
                this._getObjectPropertyByPath(x, searchPath)
                    .toLowerCase()
                    .indexOf(searchString.toLowerCase()) !== -1
        );
    }

    private _getObjectPropertyByPath(searchedObject: T, path: string): string {
        return _.get(searchedObject, path);
    }
}
