import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Event, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { isEqual } from 'lodash-es'
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { SingleExperienceUserService } from '../../../../core/services/single-experience/single-experience-user.service';
import { ROUTES_PATHS } from '../../../../shared/constants/routes.constants';
import { IBreadcrumb } from '../types/breadcrumb.interface';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbsService {
    private _isSingleExperience: boolean = false;
    private readonly _listOfBreadcrumbs$: BehaviorSubject<IBreadcrumb[]> = new BehaviorSubject<
        IBreadcrumb[]
    >([]);
    private readonly _data$: BehaviorSubject<{ [key: string]: unknown }> = new BehaviorSubject<{ [key: string]: unknown }>({})

    constructor(
        private _router: Router,
        private _singleExperienceUserService: SingleExperienceUserService,
    ) {
        this._router.events
            .pipe(
                filter((event: Event) => event instanceof NavigationEnd),
            )
            .subscribe(() => {
                const root: ActivatedRouteSnapshot = this._router.routerState.snapshot.root; 
                const breadcrumbs: IBreadcrumb[] = []; 
                this._generateBreadcrumb(root, [], breadcrumbs); 

                this._listOfBreadcrumbs$.next(breadcrumbs);
            })
        
        this.getBreadcrumbList$()
            .pipe(pairwise())
            .subscribe(([previous, current]: IBreadcrumb[][]) => {
                if(this._didBreadcrumbsChanged(previous, current)) {
                    this._data$.next({})
                } 
            })

        this._singleExperienceUserService.isEnabledForUser$()
            .subscribe((isEnabledForUser: boolean) => {
                this._isSingleExperience = isEnabledForUser;
            })
    }

    getBreadcrumbList$(): Observable<IBreadcrumb[]> {
        return this._listOfBreadcrumbs$.asObservable();
    }

    getExternalData$(): Observable<{ [key: string]: unknown }> {
        return this._data$.asObservable();
    }

    addExternalData(externalData: {[key: string]: unknown}): void {
        this._data$.next(externalData);
    }

    private _generateBreadcrumb(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbs: IBreadcrumb[]): void {
        if(!route) {
            return;
        }

        const routeUrl: string[] = parentUrl.concat(route.url.map((url: UrlSegment) => url.path));

        if(route.data.breadcrumb && route.data.breadcrumb !== route.parent.data.breadcrumb) {
            const path: string = breadcrumbs.length === 0 
                ? this._isSingleExperience ? environment.singleExperienceDashboardUrl : ROUTES_PATHS.DASHBOARD.PAGE
                : `/${routeUrl.join('/')}`

            const breadcrumb: IBreadcrumb = {
                label: route.data.breadcrumb,
                external: path.includes('://'),
                route: path
            }

            breadcrumbs.push(breadcrumb)
        }

        this._generateBreadcrumb(route.firstChild, routeUrl, breadcrumbs); 
    }

    private _didBreadcrumbsChanged(previous: IBreadcrumb[], current: IBreadcrumb[]): boolean {
        return !isEqual(
            previous.map((item: IBreadcrumb) => item.label),
            current?.map((item: IBreadcrumb) => item.label)
        )
    }
}
