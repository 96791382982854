import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { IAuthorizeResponse } from '../../../../feature-modules/auth/interfaces/authorize-response.interface';
import { ILogin } from '../../../../feature-modules/auth/interfaces/login.interface';
import { API_ENDPOINTS } from '../../../../shared/constants/api/api-endpoints.constants';
import { UnauthorizedHttpService } from '../../http/unauthorized-http.service';

@Injectable({
    providedIn: 'root',
})
export class ApiAuthenticationService {
    private readonly _clientId: string = environment.clientId;

    constructor(
        private _unauthorizedHttpService: UnauthorizedHttpService,
    ) {}

    sysOpsLoginToken$(loginModel: ILogin): Observable<IAuthorizeResponse> {
        const width: number = screen.width;
        const height: number = screen.height;

        return this._unauthorizedHttpService.post$(
            API_ENDPOINTS.LOGIN.OAUTH2_TOKEN,
            [
                `grant_type=password`,
                `width=${width}`,
                `height=${height}`,
                `client_id=${this._clientId}`,
                `cmtoken=${loginModel.cmToken}`,
                `adminid=${loginModel.AdminId}`,
            ].join('&'),
            {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        );
    }

    login$(loginModel: ILogin): Observable<IAuthorizeResponse> {
        const width: number = screen.width;
        const height: number = screen.height;

        return this._unauthorizedHttpService.post$(
            API_ENDPOINTS.LOGIN.OAUTH2_TOKEN,
            [
                `grant_type=password`,
                `width=${width}`,
                `height=${height}`,
                `client_id=${encodeURIComponent(this._clientId)}`,
                `username=${encodeURIComponent(loginModel.username)}`,
                `password=${encodeURIComponent(loginModel.password)}`,
                `mfaKey=${encodeURIComponent(loginModel.mfaKey)}`,
                `mfaToken=${encodeURIComponent(loginModel.mfaToken)}`,
            ].join('&'),
            {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        );
    }

    getExchangedToken$(ssoIdToken: string): Observable<IAuthorizeResponse> {
        const width: number = screen.width;
        const height: number = screen.height;

        return this._unauthorizedHttpService.post$(
            API_ENDPOINTS.LOGIN.OAUTH2_TOKEN,
            [
                `grant_type=sso`,
                `width=${width}`,
                `height=${height}`,
                `client_id=${this._clientId}`,
                `id_token=${ssoIdToken}`,
            ].join('&'),
            {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        );
    }

    ms365Login$(idToken: string): Observable<IAuthorizeResponse> {
        const width: number = screen.width;
        const height: number = screen.height;

        return this._unauthorizedHttpService.post$(
            API_ENDPOINTS.LOGIN.OAUTH2_TOKEN,
            [
                `grant_type=aad`,
                `width=${width}`,
                `height=${height}`,
                `client_id=${encodeURIComponent(this._clientId)}`,
                `adtoken=${encodeURIComponent(idToken)}`,
            ].join('&'),
            {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        );
    }
}
