import { NgModuleFactory, NgModule, Type } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Permission, View } from '@cloudmarket/permissions-contract/lib/permissions.enum';

import { PermissionsResolver } from './core/resolvers/permissions-resolver';
import { AgreementGuard } from './feature-modules/auth/guards/agreement.guard';
import { NonSsoFlowGuard } from './feature-modules/auth/guards/non-sso-flow.guard';
import { PathGuard } from './feature-modules/auth/guards/path.guard';
import { PermissionGuard } from './feature-modules/auth/guards/permission.guard';
import { SessionGuard } from './feature-modules/auth/guards/session.guard';
import { LayoutComponent } from './feature-modules/layout/components/layout/layout.component';
import { AnalyticsModule } from './pages/analytics/analytics.module';
import { BillingModule } from './pages/billing/billing.module';
import { CustomerModule } from './pages/customer/customer.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { NoAccessResourcesComponent } from './pages/dashboard/pages/no-access-resources/no-access-resources.component';
import { EndUserModule } from './pages/end-user/end-user.module';
import { MarketplaceModule } from './pages/marketplace/marketplace.module';
import { MyBusinessModule } from './pages/my-business/my-business.module';
import { OptOutFormModule } from './pages/opt-out-form/opt-out-form.module';
import { ServicesModule } from './pages/services/services.module';
import { SupportModule } from './pages/support/support.module';
import { SwitchingMailboxConfirmationComponent } from './pages/switching-mailbox-confirmation/switching-mailbox-confirmation.component';
import { WhitelabellingResolver } from './pages/your-account/whitelabelling/whitelabelling.resolver';
import { YourAccountModule } from './pages/your-account/your-account.module';
import { ROUTES_PATHS } from './shared/constants/routes.constants';

const appRoutes: Routes = [
    {
        path: '',
        resolve: [PermissionsResolver, WhitelabellingResolver],
        canLoad: [SessionGuard],
        canActivate: [SessionGuard, AgreementGuard, PathGuard],
        canActivateChild: [SessionGuard, AgreementGuard, PathGuard],
        component: LayoutComponent,
        data: {
            breadcrumb: 'P_HOME.BREADCRUMB_LABEL',
        },
        children: [
            {
                path: ROUTES_PATHS.DASHBOARD.PAGE,
                loadChildren: () =>
                    import('./pages/dashboard/dashboard.module').then(
                        (module: {
                            DashboardModule:
                                | Type<DashboardModule>
                                | NgModuleFactory<DashboardModule>;
                        }) => module.DashboardModule
                    ),
                canLoad: [SessionGuard],
                canActivate: [PermissionGuard.forView(View.home), NonSsoFlowGuard],
            },
            {
                path: 'customers',
                loadChildren: () =>
                    import('./pages/customer/customer.module').then(
                        (module: {
                            CustomerModule: Type<CustomerModule> | NgModuleFactory<CustomerModule>;
                        }) => module.CustomerModule
                    ),
                canLoad: [SessionGuard],
                canActivate: [PermissionGuard.forView(View.customers)],
                data: {
                    breadcrumb: 'P_CUSTOMERS.BREADCRUMB_LABEL',
                },
            },
            {
                path: 'services',
                loadChildren: () =>
                    import('./pages/services/services.module').then(
                        (module: {
                            ServicesModule: Type<ServicesModule> | NgModuleFactory<ServicesModule>;
                        }) => module.ServicesModule
                    ),
                canLoad: [SessionGuard],
                canActivate: [PermissionGuard.forView(View.manageService)],
                data: {
                    breadcrumb: 'P_MANAGE_SERVICES.BREADCRUMB_LABEL',
                },
            },
            {
                path: 'marketplace',
                loadChildren: () =>
                    import('./pages/marketplace/marketplace.module').then(
                        (module: {
                            MarketplaceModule:
                                | Type<MarketplaceModule>
                                | NgModuleFactory<MarketplaceModule>;
                        }) => module.MarketplaceModule
                    ),
                canLoad: [SessionGuard],
                canActivate: [PermissionGuard.forView(View.marketplaceOrder)],
            },
            {
                path: 'billing',
                loadChildren: () =>
                    import('./pages/billing/billing.module').then(
                        (module: {
                            BillingModule: Type<BillingModule> | NgModuleFactory<BillingModule>;
                        }) => module.BillingModule
                    ),
                canLoad: [SessionGuard],
                canActivate: [PermissionGuard.forView(View.billing, View.pSA)],
                data: {
                    pageWrapperClass: 'giacom-page-wrapper--custom',
                },
            },
            {
                path: 'account-setting',
                loadChildren: () =>
                    import('./pages/your-account/your-account.module').then(
                        (module: {
                            YourAccountModule:
                                | Type<YourAccountModule>
                                | NgModuleFactory<YourAccountModule>;
                        }) => module.YourAccountModule
                    ),
                canLoad: [SessionGuard],
            },
            {
                path: 'sso-account-setting',
                loadChildren: () =>
                    import('./pages/your-account/your-account.module').then(
                        (module: {
                            YourAccountModule:
                                | Type<YourAccountModule>
                                | NgModuleFactory<YourAccountModule>;
                        }) => module.YourAccountModule
                    ),
                canLoad: [SessionGuard],
            },
            {
                path: 'my-business',
                loadChildren: () =>
                    import('./pages/my-business/my-business.module').then(
                        (module: {
                            MyBusinessModule:
                                | Type<MyBusinessModule>
                                | NgModuleFactory<MyBusinessModule>;
                        }) => module.MyBusinessModule
                    ),
                canLoad: [SessionGuard],
                canActivate: [PermissionGuard.forView(View.myBusiness)],
            },
            {
                path: 'support',
                loadChildren: () =>
                    import('./pages/support/support.module').then(
                        (module: {
                            SupportModule: Type<SupportModule> | NgModuleFactory<SupportModule>;
                        }) => module.SupportModule
                    ),
                canLoad: [SessionGuard],
                canActivate: [PermissionGuard.forView(View.support)],
                data: {
                    theme: 'secondary',
                    breadcrumb: 'P_SUPPORT.BREADCRUMB_LABEL',
                },
            },
            {
                path: 'end-user',
                loadChildren: () =>
                    import('./pages/end-user/end-user.module').then(
                        (module: {
                            EndUserModule: Type<EndUserModule> | NgModuleFactory<EndUserModule>;
                        }) => module.EndUserModule
                    ),
                canLoad: [SessionGuard],
                canActivate: [
                    PermissionGuard.forPermission(
                        Permission.hostedExchange,
                        Permission.advancedSecurity
                    ),
                ],
            },
            {
                path: 'analytics',
                loadChildren: () =>
                    import('./pages/analytics/analytics.module').then(
                        (module: {
                            AnalyticsModule:
                                | Type<AnalyticsModule>
                                | NgModuleFactory<AnalyticsModule>;
                        }) => module.AnalyticsModule
                    ),
                canLoad: [SessionGuard],
            },
            {
                path: 'opt-out',
                loadChildren: () =>
                    import('./pages/opt-out-form/opt-out-form.module').then(
                        (module: {
                            OptOutFormModule:
                                | Type<OptOutFormModule>
                                | NgModuleFactory<OptOutFormModule>;
                        }) => module.OptOutFormModule
                    ),
                canLoad: [SessionGuard],
            },
            {
                path: ROUTES_PATHS.SWITCH_TO_MAILBOX,
                component: SwitchingMailboxConfirmationComponent,
            },
            {
                path: ROUTES_PATHS.DASHBOARD.NO_ACCESS_RESOURCES,
                component: NoAccessResourcesComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { enableTracing: false })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
