// eslint-disable-next-line max-classes-per-file
import { Component, Directive, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { IExecutionResult } from '../../../../core/interfaces/execution-result.interface';
import { IOptOuts } from '../../../../core/interfaces/opt-outs.interface';
import { ApiFooterService } from '../../../../core/services/api/api-footer.service';
import { PolicySectionType } from '../../enums/policy-section-type.enum';
import { IPolicySection } from '../../interfaces/policy-section.interface';

@Directive({
    selector: '[anchor]',
})
export class AnchorDirective {
    constructor(private _elementRef: ElementRef) {}

    scrollIntoView(): void {
        this._elementRef.nativeElement.scrollIntoView();
    }
}

@Component({
    selector: 'app-policy-section',
    templateUrl: './policy-section.component.html',
    styleUrls: ['./policy-section.component.scss'],
})
export class PolicySectionComponent implements OnInit {
    policySectionType: typeof PolicySectionType = PolicySectionType;
    optOuts: IOptOuts;
    @Input() section: IPolicySection;
    @ViewChild(AnchorDirective) anchor: AnchorDirective;

    constructor(private _apiFooterService: ApiFooterService) {}

    ngOnInit(): void {
        if (this.section.Type === PolicySectionType.custom && this.section.Content === 'OptOuts') {
            this._apiFooterService.getOptOuts$().subscribe((res: IExecutionResult<IOptOuts>) => {
                if (res.Succeed) {
                    this.optOuts = res.Result;
                }
            });
        }
    }

    splitContentWithPipe(content: string): string[] {
        return content.split('|');
    }

    getIndent(): number {
        const nestingLevelPixelMultiplier: number = 10;

        return this.section.NestingLevel * nestingLevelPixelMultiplier;
    }
}
