import { Injectable } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { AuthorizedHttpService } from '../../core/services/http/authorized-http.service';
import { UnauthorizedHttpService } from '../../core/services/http/unauthorized-http.service';
import {
    IChangePasswordModel,
    IGeneratePasswordModel,
    IResetPasswordModel,
    ResetPasswordStateModel,
} from '../../models/user/password.model';
import { IValidationRule } from '../../new-shared/components/password/validation-rule.interface';
import { API_ENDPOINTS } from '../constants/api/api-endpoints.constants';
import { BaseUrl } from '../urls/base.url';
import { PasswordValidator } from '../validators/password-validator.validator';

@Injectable()
export class PasswordService {
    private readonly _serviceUrl: string = `${BaseUrl.baseUrlCloudMarket}${API_ENDPOINTS.API_MANAGEMENT.USER_V1}`;
    private _passwordState: ResetPasswordStateModel | null;

    constructor(
        private _authorizedHttpService: AuthorizedHttpService,
        private _unauthorizedHttpService: UnauthorizedHttpService
    ) {}

    clearState(): void {
        this._passwordState = null;
    }

    getPasswordState(): ResetPasswordStateModel {
        return this._passwordState;
    }

    setPasswordState(newPasswordState: ResetPasswordStateModel): void {
        this._passwordState = newPasswordState;
    }

    updatePassword$(model: IResetPasswordModel): Observable<void> {
        const url: string = `${this._serviceUrl}${API_ENDPOINTS.USER.UPDATE_PASSWORD(
            model.userType,
            model.userName
        )}`;

        return this._authorizedHttpService.put$(url, {
            oldPassword: model.existingPassword,
            newPassword: model.newPassword,
        });
    }

    changePassword$(model: IChangePasswordModel): Observable<void> {
        const url: string = `${this._serviceUrl}${API_ENDPOINTS.USER.CHANGE_PASSWORD(
            model.userType,
            model.userName
        )}`;

        return this._unauthorizedHttpService.put$(url, {
            key: model.key,
            oldPassword: model.existingPassword,
            newPassword: model.newPassword,
        });
    }

    resetUsersPassword(model: IResetPasswordModel): any {
        const url: string = `${this._serviceUrl}${API_ENDPOINTS.USER.RESET_PASSWORD(
            model.userType,
            model.userName
        )}`;
        const data: any = {
            oldPassword: model.existingPassword,
            newPassword: model.newPassword,
        };

        return this._authorizedHttpService.put$(url, data);
    }

    generatePassword$(model: IGeneratePasswordModel): Observable<string> {
        const url: string = `${this._serviceUrl}${API_ENDPOINTS.USER.GENERATE_PASSWORD}`;

        return this._authorizedHttpService.post$(url, model);
    }

    getValidationRules$(): Observable<IValidationRule[]> {
        const url: string = `${this._serviceUrl}${API_ENDPOINTS.USER.VALIDATION_RULES}`;

        return this._unauthorizedHttpService.get$(url);
    }

    buildValidationRules(validationRules: IValidationRule[]): ValidatorFn[] {
        const ngRules: ValidatorFn[] = [];

        validationRules.forEach((validationRule: IValidationRule) => {
            let rule: ValidatorFn;

            switch (validationRule.type) {
                case 'required':
                    rule = Validators.required;
                    break;
                case 'pattern':
                    const regex: RegExp = new RegExp(validationRule.rule as RegExp);

                    rule = Validators.pattern(regex);
                    break;
                case 'minLength':
                    rule = Validators.minLength(validationRule.rule as number);
                    break;
                case 'MinimumScore':
                    const score: number = +validationRule.rule;

                    rule = PasswordValidator.minimumScore(score);
                    break;
                case 'MinimumLength':
                    const len: number = +validationRule.rule;

                    rule = PasswordValidator.minimumLength(len);
                    break;
                case 'MultiplePattern':
                    rule = PasswordValidator.multiplePattern(validationRule);
                    break;
                default:
                    break;
            }

            if (rule) {
                ngRules.push(rule);
            }
        });

        return ngRules;
    }
}
