import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { STORAGE_KEYS } from '../../../../shared/constants/storage-keys.constants';
import { UserType } from '../../../../shared/enum/user-type.enum';
import { BreadcrumbsService } from '../services/breadcrumbs.service';
import { IBreadcrumb } from '../types/breadcrumb.interface';

@Component({
    selector: 'cm-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
    breadcrumbs$: Observable<IBreadcrumb[]> = this._breadcrumbsService.getBreadcrumbList$();
    data$: Observable<{ [key: string]: unknown }> = this._breadcrumbsService.getExternalData$();
    showBreadcrumbs: boolean = true;
    private _destroyTrigger$: Subject<void> = new Subject();

    constructor(
        private _breadcrumbsService: BreadcrumbsService,
        private _router: Router
    ) {}

    trackByRoute(index: number, item: IBreadcrumb): string {
        return item.route;
    }

    ngOnInit(): void {
        this._router.events.pipe(
            filter((event: Event) => event instanceof NavigationEnd),
            takeUntil(this._destroyTrigger$)
        ).subscribe(() => {
            const userType: string = localStorage.getItem(STORAGE_KEYS.USERTYPE);

            this.showBreadcrumbs = userType === UserType.reseller || userType === UserType.engineer
        });
    }

    ngOnDestroy(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }
}
