import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthService } from '../../../feature-modules/auth/services/auth/auth.service';
import { STORAGE_KEYS } from '../../../shared/constants/storage-keys.constants';
import { UserType } from '../../../shared/enum/user-type.enum';
import { IDictionary } from '../../../shared/interfaces/dictionary.interface';

import { AbstractHttpService } from './abstract-http.service';

@Injectable({
    providedIn: 'root',
})
export class AuthorizedHttpService extends AbstractHttpService {

    constructor(protected _httpClient: HttpClient, private _authService: AuthService) {
        super(_httpClient);
    }

    protected _getDefaultHeaders(): IDictionary<string | string[]> {
        const loggedInUserType: string = localStorage.getItem(STORAGE_KEYS.USER_TYPE);
        let cloudActorId: string = null;

        if (loggedInUserType){
            cloudActorId = (loggedInUserType.toLowerCase() === UserType.endUser.toLowerCase()) ?
                localStorage.getItem(STORAGE_KEYS.USER_NAME) :
                localStorage.getItem(STORAGE_KEYS.EMAIL_ADDRESS);
        }

        return {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Authorization': `bearer ${this._authService.getToken()}`,
            ...(cloudActorId && { 'Cloud-Actor-Id': cloudActorId })
        };
    }
}
