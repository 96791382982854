// todo: fix eslint
// eslint-disable-next-line max-classes-per-file
export class PsaIntegration {
    psaId: number;
    name: string;
    credentialFormId: number;
}

export class PsaIntegrationSettings {
    enabled: boolean;
    psaId: number;
    credentials: any;
    storedCredentials: boolean;
    name: string;
    reconReportAvailable: boolean;
    productMovementAvailable: boolean;
    reconActionAvailable: boolean;
    productMovementActionAvailable: boolean;
    cacheValidityInMinutes: number;
    ignoreSalesOrders: boolean;
    credentialFormId: number;
}

export interface IPSADataCacheInfo {
    createdAt: Date;
    downloadTimeInSeconds: number;
    psaDataCacheSyncTriggerType: PSADataSyncTriggerType;
    psaDataCacheSyncStatus: PSADataSyncStatus;
}

export interface IUpdateIgnoreSalesOrdersModel {
    ignoreSalesOrders: boolean;
}

export class PsaIntegrationConstants {
    static readonly psaDataSyncStatusCheckIntervalInMilliseconds: number = 30000;
    static readonly psaDataCacheExpiryThresholdFactor: number = 0.75;
}

export enum PSADataSyncStatus {
    new = 'New',
    completed = 'Completed',
    failed = 'Failed',
    inProgress = 'InProgress',
    unKnown = 'Unknown',
}

export enum PSADataSyncTriggerType {
    manual = 'Manual',
    setup = 'Setup',
}

export enum PSADataSyncNoticeType {
    inProgress = 'inprogress',
    failed = 'failed',
    invalid = 'invalid',
    completed = 'completed',
    aboutToExpire = 'abouttoexpire',
    expired = 'expired',
}

export enum PSAProviderEnum {
    autoTask = 1,
    connectWise = 6,
}
