import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { WhitelabelService } from '../../../shared/services/whitelabel.service';

import { WaitSpinnerVariant } from './wait-spinner-variant';

@Component({
    selector: 'wait-spinner',
    templateUrl: './wait-spinner.component.html',
    styleUrls: ['./wait-spinner.component.scss'],
})
export class WaitSpinnerComponent implements OnInit, AfterViewInit {
    @ViewChild('cloudRef', { read: ElementRef }) cloudRef: ElementRef;
    @ViewChild('independentSpinnerRef', { read: ElementRef }) independentSpinnerRef: ElementRef;
    @ViewChild('cloudSpinner', { read: ElementRef }) cloudSpinner: ElementRef;
    @Input() mode: WaitSpinnerVariant = WaitSpinnerVariant.small;
    @Input() message: string;
    @Input() show: boolean;
    @Input() inverse: boolean = false;
    @Input() isDisplayedInPopup: boolean;
    @Input() containedByParent: boolean;
    @Input() position: 'relative' | 'absolute' = 'absolute';

    spinnerVariant: typeof WaitSpinnerVariant = WaitSpinnerVariant;

    constructor(private _whitelabelService: WhitelabelService) {}

    ngOnInit(): void {
        if (this.message === undefined) {
            this.message = 'Loading...';
        }
    }

    ngAfterViewInit(): void {
        if (this._whitelabelService.getThemeHeaderColor() !== undefined) {
            if (this.independentSpinnerRef !== undefined) {
                this.independentSpinnerRef.nativeElement.style.stroke =
                    this._whitelabelService.getThemeHeaderColor();
            }
            if (this.cloudSpinner !== undefined) {
                this.cloudSpinner.nativeElement.style.stroke =
                    this._whitelabelService.getThemeHeaderColor();
            }
        }
    }
}
