import { Directive, HostBinding, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BlockUiService } from '../services/block-ui.service';

@Directive({
    selector: '[cmDisableWhenSubmitRequest]',
})
export class DisableWhenSubmitRequestDirective implements OnInit, OnDestroy, OnChanges {
    @HostBinding('disabled') isElementDisabled: boolean = false;
    @Input() cmDisableWhenSubmitRequest: boolean | (() => boolean) | undefined;
    private _isRequestInProgress: boolean = false;
    private readonly _destroyTrigger$: Subject<void> = new Subject();

    constructor(private _blockUiService: BlockUiService) {}

    ngOnInit(): void {
        this._validateDisabledState();

        this._blockUiService
            .getIsSubmitRequestInProgress$()
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe((isRequestInProgress: boolean) => {
                this._isRequestInProgress = isRequestInProgress;
                this._validateDisabledState();
            });
    }

    ngOnChanges(): void {
        this._validateDisabledState();
    }

    ngOnDestroy(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }

    private _validateDisabledState(): void {
        if (this._isRequestInProgress) {
            this.isElementDisabled = true;

            return;
        }
        if (!this.cmDisableWhenSubmitRequest) {
            this.isElementDisabled = false;

            return;
        }
        if (typeof this.cmDisableWhenSubmitRequest === 'boolean') {
            this.isElementDisabled = this.cmDisableWhenSubmitRequest;

            return;
        }

        this.isElementDisabled = this.cmDisableWhenSubmitRequest();
    }
}
