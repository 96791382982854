import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_ENDPOINTS } from '../../../../shared/constants/api/api-endpoints.constants';
import { IExecutionResult } from '../../../interfaces/execution-result.interface';
import { IServiceListingResult } from '../../../interfaces/service-listing.interface';
import { AuthorizedHttpService } from '../../http/authorized-http.service';

@Injectable({
    providedIn: 'root',
})
export class ApiServiceListingService {
    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    getServiceListingId$(
        organisationServiceId: number,
        officeGroupId?: number
    ): Observable<IExecutionResult<IServiceListingResult>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.SERVICES.GET_SERVICE_LISTING_ID(organisationServiceId, officeGroupId)
        );
    }
}
