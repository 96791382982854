import { NgModule } from '@angular/core';

import { BreadcrumbsModule } from '../../new-shared/components/cm-breadcrumbs/breadcrumbs.module';
import { FeatureFlagModule } from '../../shared/directives/feature-flag/feature-flag.module';
import { PermissionVerificationModule } from '../../shared/directives/permission-verification/permission-verification.module';
import { SharedModule } from '../../shared/shared.module';
import { LiveChatModule } from '../live-chat/live-chat.module';
import { LoadingIndicatorModule } from '../loading-indicator/loading-indicator.module';
import { NotificationsModule } from '../notifications/notifications.module';
import { PopUpDetails } from '../popups/classes/pop-up-details.class';
import { PopupService } from '../popups/services/popup.service';

import { FooterComponent } from './components/footer/footer.component';
import { GdprStatementPopupComponent } from './components/gdpr-statement-popup/gdpr-statement-popup';
import { HeaderComponent } from './components/header/header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { NewFooterWrapperComponent } from './components/new-footer/new-footer-wrapper.component';
import { NewHeaderWrapperComponent } from './components/new-header/new-header-wrapper.component';
import {
    AnchorDirective,
    PolicySectionComponent,
} from './components/policy-section/policy-section.component';
import { PrivacyPolicyPopupComponent } from './components/privacy-policy-popup/privacy-policy-popup';

@NgModule({
    imports: [
        BreadcrumbsModule,
        FeatureFlagModule,
        PermissionVerificationModule,
        LiveChatModule,
        LoadingIndicatorModule,
        NotificationsModule,
        SharedModule,
    ],
    declarations: [
        AnchorDirective,
        FooterComponent,
        GdprStatementPopupComponent,
        HeaderComponent,
        LayoutComponent,
        NewFooterWrapperComponent,
        NewHeaderWrapperComponent,
        PolicySectionComponent,
        PrivacyPolicyPopupComponent,
    ],
    exports: [
        FooterComponent,
        GdprStatementPopupComponent,
        HeaderComponent,
        LayoutComponent,
        PrivacyPolicyPopupComponent,
    ],
})
export class LayoutModule {
    constructor(private _popupService: PopupService) {
        this._popupService.registerPopups({
            'gdpr-statement': new PopUpDetails(
                GdprStatementPopupComponent,
                'administration',
                'gdpr-statement'
            ),
            'privacy-policy': new PopUpDetails(
                PrivacyPolicyPopupComponent,
                'administration',
                'privacy-policy'
            ),
        });
    }
}
