import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { CmSortingHeaderCellComponent } from './components/sorting-header-cell/cm-sorting-header-cell.component';
import { CmTableComponent } from './components/table/cm-table.component';
import { CmTableColumnComponent } from './components/table-column/cm-table-column.component';
import { RowExpandedPipe } from './pipes/row-expanded.pipe';

@NgModule({
    declarations: [
        CmTableColumnComponent,
        CmTableComponent,
        RowExpandedPipe,
        CmSortingHeaderCellComponent,
    ],
    imports: [CommonModule, TranslateModule, NgScrollbarModule],
    exports: [CmTableColumnComponent, CmTableComponent, CmSortingHeaderCellComponent],
})
export class CmTableModule {}
