export class PopUpDetails {
    pageType: string;
    pageName: string;
    component: any;

    constructor(component: any, pageType: string, pageName: string) {
        this.component = component;
        this.pageType = pageType;
        this.pageName = pageName;
    }
}
