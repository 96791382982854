import {
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    Self,
    SimpleChanges,
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { IHtmlElementModifier } from '../../../../feature-modules/design-system/enums/cm-block-modifier.enum';
import { IThemeColor } from '../../../../feature-modules/design-system/enums/cm-theme-color.enum';
import { AbstractControlValueAccessor } from '../../../../shared/classes/abstract-control-value-accessor.class';

@Component({
    selector: 'cm-checkbox-input',
    templateUrl: './cm-check-box-input.component.html',
    styleUrls: ['./cm-check-box-input.component.scss'],
})
export class CmCheckBoxInputComponent
    extends AbstractControlValueAccessor<boolean>
    implements OnChanges, OnInit
{
    @Input() label: string = '';
    @Input() id: string = '';
    @Input() validationMessage: string = 'This is required';
    @Input() themeModifiers: IHtmlElementModifier[] = [];
    @Input() themeColor: IThemeColor = 'primary';
    themeModifiersClasses: string = '';
    themeColorClass: string = '';

    constructor(
        @Self() public ngControl: NgControl,
        protected _changeDetectorRef: ChangeDetectorRef
    ) {
        super();

        if (ngControl) {
            ngControl.valueAccessor = this;
        }
    }

    ngOnInit(): void {
        this._assignThemeClass();
        this._assignThemeModifiersClasses();
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges.themeColor) {
            this._assignThemeClass();
        }
        if (simpleChanges.themeModifiers) {
            this._assignThemeModifiersClasses();
        }
    }

    handleControlChange(newInputValue: boolean): void {
        if (newInputValue !== this.value) {
            this.writeValue(newInputValue);
        }
    }

    private _assignThemeClass(): void {
        this.themeColorClass = `control--${this.themeColor}`;

        this._changeDetectorRef.markForCheck();
    }

    private _assignThemeModifiersClasses(): void {
        this.themeModifiersClasses = this.themeModifiers
            .map((themeModifier: IHtmlElementModifier) => `control--${themeModifier}`)
            .join(' ');

        this._changeDetectorRef.markForCheck();
    }
}
