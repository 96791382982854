import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class WindowScrollService {
    disable(): void {
        document.body.classList.add('disableBodyScroll');
    }

    enable(): void {
        document.body.classList.remove('disableBodyScroll');
    }
}
