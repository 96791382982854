import { Location } from '@angular/common';
import { AfterViewInit, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { DefaultPageRouteService } from '../../../core/services/default-page-route.service';
import { ROUTES_PATHS } from '../../../shared/constants/routes.constants';
import { PageViewMethod } from '../../../shared/services/ga-service/ga-page-load';
import { GAService } from '../../../shared/services/ga-service/ga-service';
import { AbstractPageLoad } from '../../../shared/services/ga-service/shared-classes';

@Component({
    selector: 'access-denied',
    templateUrl: './access-denied.component.html',
    styleUrls: ['./access-denied.component.scss'],
})
export class AccessDeniedComponent extends AbstractPageLoad implements AfterViewInit {
    readonly pageName: string = 'error/403';
    readonly pageType: string = 'error';
    readonly pageViewMethod: PageViewMethod = PageViewMethod.page;
    readonly routes: typeof ROUTES_PATHS = ROUTES_PATHS;
    private readonly _title: string = '403 Access Denied';

    constructor(
        private _titleService: Title,
        protected _gaService: GAService,
        private _location: Location,
        private _defaultPageRouteService: DefaultPageRouteService
    ) {
        super(_gaService);
        this._titleService.setTitle(this._title);
    }

    goHome(): void {
        this._defaultPageRouteService.goToDefaultPage();
    }

    goBack(): void {
        this._location.back();
    }

    ngAfterViewInit(): void {
        this.recordPageLoad();
    }
}
