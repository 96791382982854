import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IAgreementDetailsModel } from '../../../../models/agreement/agreement-details.model';
import { IMultipleAgreementServiceDetails } from '../../../../models/agreement/interfaces/multiple-agreement-service-details.interface';
import { IMultipleAgreements } from '../../../../models/agreement/interfaces/multiple-agreements.interface';
import { ISignedService } from '../../../../models/agreement/interfaces/signed-service.interface';
import { API_ENDPOINTS } from '../../../../shared/constants/api/api-endpoints.constants';
import { IAgreementInfo } from '../../../interfaces/agreement-info.interface';
import { IExecutionResult } from '../../../interfaces/execution-result.interface';
import { IResellerAgreementValidity } from '../../../interfaces/reseller-agreement-validity.interface';
import { AuthorizedHttpService } from '../../http/authorized-http.service';

import { SignedAgreementModel } from './types/api-agreement.types';

@Injectable({
    providedIn: 'root',
})
export class ApiAgreementService {
    private readonly _defaultFileName: string = 'agreementdocument';

    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    getCanAgree$(): Observable<boolean> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.AGREEMENT.CAN_AGREE
        );
    }

    listAgreements$(): Observable<IExecutionResult<IAgreementDetailsModel[]>> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.AGREEMENT.LIST_AGREEMENTS);
    }

    getAgreements$(
        agreementList: number[],
        getUnsignedOnly: boolean
    ): Observable<IExecutionResult<IMultipleAgreements>> {
        return this._authorizedHttpService.post$(
            API_ENDPOINTS.AGREEMENT.GET_AGREEMENTS(getUnsignedOnly),
            agreementList
        );
    }

    getAgreement$(agreementId: string): Observable<IExecutionResult<IAgreementDetailsModel>> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.AGREEMENT.GET_AGREEMENT(agreementId));
    }

    getAgreementInfo$(agreementId: number): Observable<IExecutionResult<IAgreementInfo>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.AGREEMENT.GET_AGREEMENTS_INFO(agreementId)
        );
    }

    getLinkedAgreements$(
        serviceId: number,
        groupId?: number,
        unsignedOnly?: boolean
    ): Observable<IExecutionResult<IMultipleAgreementServiceDetails[]>> {
        let url: string = '';

        if (groupId === null) {
            url = API_ENDPOINTS.AGREEMENT.GET_LINKED_AGREEMENTS_NON_OFFICE(serviceId);
        } else {
            url = API_ENDPOINTS.AGREEMENT.GET_LINKED_AGREEMENTS(serviceId, groupId);
        }

        if (unsignedOnly) {
            url = `${url}/true`;
        }

        return this._authorizedHttpService.get$(url);
    }

    downloadAgreement$(
        id: number,
        fileName: string = this._defaultFileName
    ): Observable<HttpResponse<Blob>> {
        return this._authorizedHttpService.downloadBlobAsFile$(
            API_ENDPOINTS.AGREEMENT.DOWNLOAD(id),
            fileName
        );
    }

    checkSignedServiceId$(
        serviceId: string | number
    ): Observable<IExecutionResult<ISignedService>> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.AGREEMENT.CHECK_SIGNED_SERVICE_ID(serviceId)
        );
    }

    getResellerAgreementValidity$(): Observable<IResellerAgreementValidity> {
        return this._authorizedHttpService.get$(
            API_ENDPOINTS.AGREEMENT.RESELLER_AGREEMENT_VALIDITY
        );
    }

    getSignatureImageString$(model: SignedAgreementModel): Observable<string> {
        return this._authorizedHttpService.post$(
            API_ENDPOINTS.AGREEMENT.GET_SIGNATURE_IMAGE_STRING,
            model
        )
    }

    sign$(model: SignedAgreementModel): Observable<IExecutionResult<void>> {
        return this._authorizedHttpService.post$(
            API_ENDPOINTS.AGREEMENT.SIGN,
            model
        )
    }
}
