import { Component, Input, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

import { AbstractControlValueAccessor } from '../../../../../../shared/classes/abstract-control-value-accessor.class';
import { IRatingRadiosInputOptions } from '../../interfaces/rating-radios-input-options.interface';

@Component({
    selector: 'cm-rating-radios-input',
    templateUrl: './cm-rating-radios-input.component.html',
    styleUrls: ['./cm-rating-radios-input.component.scss'],
})
export class CmRatingRadiosInputComponent extends AbstractControlValueAccessor<boolean> {
    @Input() label: string = 'field';
    @Input() id: string = '1';
    @Input() options: IRatingRadiosInputOptions;

    constructor(@Self() public ngControl: NgControl) {
        super();

        if (ngControl) {
            ngControl.valueAccessor = this;
        }
    }
}
