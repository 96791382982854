export interface ICloudMarketPopup {
    Data: unknown;
    Title: string;
    Schema: ICloudMarketPopupSchema;
    ApiGetSchemaPath: string;
    ApiGetDataPath: string;
    ApiValidatePath: string;
    ApiSubmitPath: string;
    ApiSubmitRequestMethod: RequestMethod;
}

export interface ICloudMarketPopupSchema {
    MaxWidth: number;
    HideCancelButton: boolean;
    UpdateEvent: string;
    Items: ICloudMarketPopupSchemaItem[];
    PropertyDependencies: ICloudMarketPopupPropertyDependency[];
}

export interface ICloudMarketPopupSchemaItem {
    Type: CloudMarketPopupType;
    Regex: string;
    RegexError: string;
    RequiredValue: unknown;
    DataItem: string;
    Label: string;
    ToolTip: string;
    ToolTipType: ToolTipType;
    Required: boolean;
    Untouched: boolean;
    Valid: boolean;
}

export interface ICloudMarketPopupPropertyDependency {
    PropertyDataItem: string;
    PropertyDataItemDependencyValue: unknown;
    DependentPropertyDataItem: string;
    Type: CloudMarketPopupPropertyDependencyType;
}

export enum CloudMarketPopupPropertyDependencyType {
    hide = 1,
    disable = 2,
}

export enum CloudMarketPopupType {
    subHeader = 1,
    textDisplay = 2,
    textInput = 3,
    number = 4,
    email = 5,
    checkbox = 6,
    htmlDisplay = 7,
}

export enum ToolTipType {
    question = 1,
    alert = 2,
}

export enum RequestMethod {
    get = 0,
    post = 1,
    put = 2,
    delete = 3,
    options = 4,
    head = 5,
    patch = 6,
}
