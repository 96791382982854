import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { BlockUiInterceptor } from '../feature-modules/block-ui/interceptors/block-ui.interceptor';
import { LoadingIndicatorModule } from '../feature-modules/loading-indicator/loading-indicator.module';

import { ServicesCustomerDetailsFacadeService } from './services/services-customer-details-facade.service';
import { ServicesCustomersListFacadeService } from './services/services-customers-list-facade.service';

const bootstrapModules: ModuleWithProviders<unknown>[] = [
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    TimepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    TooltipModule.forRoot(),
];

@NgModule({
    imports: [...bootstrapModules, LoadingIndicatorModule.forRoot()],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: BlockUiInterceptor, multi: true },
        ServicesCustomerDetailsFacadeService,
        ServicesCustomersListFacadeService,
    ],
})
export class CoreModule {}
