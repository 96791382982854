import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ILoginUserResponse } from '../../../../feature-modules/auth/interfaces/login-user-response.interface';
import { API_ENDPOINTS } from '../../../../shared/constants/api/api-endpoints.constants';
import { AuthorizedHttpService } from '../../http/authorized-http.service';


@Injectable({
    providedIn: 'root',
})
export class ApiLoginService {
    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    getLoginUser$(): Observable<ILoginUserResponse> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.LOGIN.LOGIN_USER);
    }
}
