import { Validators } from '@angular/forms';

import { FormConfig } from '../../../../../types/form-config.type'
import { IListCustomerForm } from '../interfaces/list-customer.types';


export const LIST_CUSTOMER_FORM_FIELDS: Record<keyof IListCustomerForm, string> = {
    selectCustomerInput: 'selectCustomerInput',
};

export const listCustomerFormConfig: (configObject: IListCustomerForm) => FormConfig = (
    configObject: IListCustomerForm
): FormConfig => ({
    [LIST_CUSTOMER_FORM_FIELDS.selectCustomerInput]: [configObject.selectCustomerInput, [Validators.required]],
});
