import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { IPermissionsResponse } from '../interfaces/permissions-response.interface';
import { ApiPermissionsService } from '../services/api/api-permissions.service';

@Injectable({
    providedIn: 'root',
})
export class PermissionsResolver implements Resolve<IPermissionsResponse> {
    constructor(private _apiPermissionsService: ApiPermissionsService) {}

    resolve(): Observable<IPermissionsResponse> {
        return this._apiPermissionsService.getPermissions$();
    }
}
