import { Component, Input } from '@angular/core';

import { AbstractPopup } from '../../../../../../feature-modules/popups/classes/abstract-popup.class';
import { IPopupEventHandler } from '../../../../../../feature-modules/popups/interfaces/popup-event-handler.interface';
import { MfaCommand } from '../../enums/mfa-command.enum';

import { IConfigureMfaMoreInformationPopupParams } from './configure-mfa-more-information-popup-params.interface';

@Component({
    selector: 'cm-configure-mfa-more-information-popup',
    templateUrl: './configure-mfa-more-information-popup.component.html',
    styleUrls: ['./configure-mfa-more-information-popup.component.scss'],
})
export class ConfigureMfaMoreInformationPopupComponent extends AbstractPopup<IConfigureMfaMoreInformationPopupParams> {
    @Input() userName: string;
    eventHandler: IPopupEventHandler;

    updateParams(val: IConfigureMfaMoreInformationPopupParams): void {
        this.userName = val.userName;
    }

    onContinue(): void {
        this.eventHandler.executeCommand(MfaCommand.continue, {});
        this.close();
    }

    onCancel(): void {
        this.eventHandler.executeCommand(MfaCommand.cancel, {});
        this.close();
    }
}
