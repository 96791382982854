import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_ENDPOINTS } from '../../../../shared/constants/api/api-endpoints.constants';
import { IExecutionResult } from '../../../interfaces/execution-result.interface';
import { AuthorizedHttpService } from '../../http/authorized-http.service';

@Injectable({
    providedIn: 'root',
})
export class ApiMicrosoft365Service {

    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    hasPartnerId$(): Observable<IExecutionResult<number>> {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.OFFICE365.HAS_PARTNER_ID)
            .pipe(
                this._authorizedHttpService.handleApiErrorOperator$,
                this._authorizedHttpService.mapResponseOperator$<IExecutionResult<number>>()
            );
    }
}
