import { Location } from '@angular/common';
import { AfterViewInit, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Resource, UserInfo } from '../../core/services/resource.service';
import {
  SwitchUserConfirmationHandlerService
} from '../../core/services/switch-user-types/switch-user-confirmation-handler.service';
import { PageViewMethod } from '../../shared/services/ga-service/ga-page-load';
import { GAService } from '../../shared/services/ga-service/ga-service';
import { AbstractPageLoad } from '../../shared/services/ga-service/shared-classes';
import { UserSwitcherService } from '../../shared/services/user-switcher.service';

@Component({
  selector: 'cm-switching-mailbox-confirmation',
  templateUrl: './switching-mailbox-confirmation.component.html',
  styleUrls: ['./switching-mailbox-confirmation.component.scss']
})
export class SwitchingMailboxConfirmationComponent extends AbstractPageLoad implements AfterViewInit {
  signedInUser: UserInfo;
  readonly pageName: string = 'switching-mailbox-confirmation';
  readonly pageType: string = 'switching-mailbox-confirmation';
  readonly pageViewMethod: PageViewMethod = PageViewMethod.page;
  private readonly _title: string = 'Switch mailbox confirmation';

  constructor(protected _gaService: GAService,
              private _switchUserConfirmationHandlerService: SwitchUserConfirmationHandlerService,
              private _titleService: Title,
              private _location: Location) {
    super(_gaService);

    this._titleService.setTitle(this._title);
  }

  switchUserType(): void {
    this._switchUserConfirmationHandlerService.doSwitchAndGiveUserInfo()
  }

  goBack(): void {
    this._location.back();
  }

  ngAfterViewInit(): void {
    this.recordPageLoad();
  }
}
