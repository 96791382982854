import {
    ComponentRef,
    Injectable,
    ViewContainerRef,
} from '@angular/core';

import { MonitoringService } from '../../../core/services/monitoring.service';
import {
    Office365ProductGroups,
    OrganisationService,
} from '../../../shared/enum/service-type.enum';
import { IDictionary } from '../../../shared/interfaces/dictionary.interface';
import { PageViewMethod } from '../../../shared/services/ga-service/ga-page-load';
import { GAService } from '../../../shared/services/ga-service/ga-service';
import { PageLoadSimple } from '../../../shared/services/ga-service/shared-classes';
import { AbstractPopup } from '../classes/abstract-popup.class';
import { PopUpDetails } from '../classes/pop-up-details.class';
import { IPopupEventHandler } from '../interfaces/popup-event-handler.interface';

@Injectable({
    providedIn: 'root',
})
export class PopupService {
    static container: ViewContainerRef;
    private _popups: IDictionary<PopUpDetails> = {};

    constructor(private _gaService: GAService, private _monitoringService: MonitoringService) {}

    show(eventHandler: IPopupEventHandler, name: string, params: any): AbstractPopup<unknown> {
        this._monitoringService.startNavigationEvent(`/popup/${name}`);
        const popups: any[] = [];

        if (params && params.agreements !== undefined) {
            params.agreements.forEach((agreement: any) => {
                popups.push(agreement);
            });

            if (popups.length > 0) {
                params.agreement = popups.shift();
            }
        }

        const popUpDetails: PopUpDetails = this._getPopup(name);

        if (!PopupService.container || !popUpDetails) {
            return;
        }

        const viewContainerRef: ViewContainerRef = PopupService.container;

        const componentRef: ComponentRef<any> = viewContainerRef.createComponent(popUpDetails.component);
        const popup: AbstractPopup<unknown> = componentRef.instance;

        popup.eventHandler = eventHandler;
        popup.component = componentRef;
        popup.params = params;

        // GA Code
        if (params && params.agreements !== undefined) {
            params.agreements.forEach((element: any) => {
                const pageload: PageLoadSimple = new PageLoadSimple(
                    this._gaService,
                    popUpDetails.pageName,
                    popUpDetails.pageType,
                    PageViewMethod.popUp
                );
                const additionalInfo: any = this._getAdditionalInfo(element);

                pageload.recordPageLoad(additionalInfo);
            });
        } else {
            const pageload: PageLoadSimple = new PageLoadSimple(
                this._gaService,
                popUpDetails.pageName,
                popUpDetails.pageType,
                PageViewMethod.popUp
            );
            const additionalInfo: any = this._getAdditionalInfo(params);

            pageload.recordPageLoad(additionalInfo);
        }
        this._monitoringService.endNavigationEvent(`/popup/${name}`);

        return popup;
    }

    registerPopups(popupsToAdd: IDictionary<PopUpDetails>): void {
        this._popups = {
            ...this._popups,
            ...popupsToAdd,
        };
    }

    private _getPopup(popupName: string): PopUpDetails | undefined {
        return this._popups[popupName];
    }

    private _getAdditionalInfo(object: object): any {
        if (!object) {
            return;
        }

        const additionalInfo: { [key: string]: string | number } = {};

        let custId: string;

        custId = this._findValLCase(object, 'organisationid');
        if (!custId) {
            custId = this._findValLCase(object, 'organisation_id');
        }
        if (!custId) {
            custId = this._findValLCase(object, 'customerid');
        }
        if (!custId) {
            custId = this._findValLCase(object, 'customer_id');
        }
        if (custId) {
            additionalInfo.customer_id = custId;
        }

        let serviceId: string | number = +this._findValLCase(object, 'serviceid');

        if (!serviceId) {
            serviceId = this._findValLCase(object, 'service_id');
        }
        if (!serviceId) {
            serviceId = this._findValLCase(object, 'productid');
        }
        if (!serviceId) {
            serviceId = this._findValLCase(object, 'product_id');
        }
        if (serviceId) {
            const sub: number = +this._findValLCase(object, 'subId');

            additionalInfo.service_name = this._getServiceFromId(
                serviceId as OrganisationService,
                sub
            );
        }

        let userId: string | number = +this._findValLCase(object, 'userid');

        if (!userId) {
            userId = this._findValLCase(object, 'user_id');
        }
        if (userId) {
            additionalInfo.user_id = userId;
        }

        return additionalInfo;
    }

    // Nasty-need a way to replace
    private _getServiceFromId(
        service: OrganisationService,
        officeGroup: Office365ProductGroups
    ): string {
        if (service === OrganisationService.Microsoft365) {
            switch (officeGroup) {
                case Office365ProductGroups.Azure:
                    return 'azure';
                case Office365ProductGroups.DynamicsCRM:
                    return 'dynamics-crm';
                case Office365ProductGroups.EnterpriseMobilitySuite:
                    return 'ems';
                case Office365ProductGroups.Intune:
                    return 'intune';
                case Office365ProductGroups.Office365Core:
                    return 'office-365';
                case Office365ProductGroups.PowerBI:
                    return 'power-bi';
                default:
                    return 'unknown';
            }
        }

        switch (service) {
            case OrganisationService.AcronisFilesCloud:
                return 'acronis-files-cloud';
            case OrganisationService.AdvancedSecurity:
                return 'email-security';
            case OrganisationService.AppInteract:
                return 'appinteract';
            case OrganisationService.BackupAcronis:
                return 'acronis-backup';
            case OrganisationService.BitDefender:
                return 'bitdefender';
            case OrganisationService.Cirius:
                return 'cirius-secure-messaging';
            case OrganisationService.Exchange:
                return 'hosted-exchange';
            case OrganisationService.HostedVoice:
                return 'hosted-voice';
            case OrganisationService.HostedVoiceHardware:
                return 'hosted-voice-hardware';
            case OrganisationService.MessageLabs:
                return 'symnatec-cloud';
            case OrganisationService.SharePoint:
                return 'sharepoint';
            case OrganisationService.CloudServer:
                return 'cloud-server';
            default:
                return 'unknown';
        }
    }

    private _findValLCase(object: any, key: string): string {
        if (!object || typeof object !== 'object') {
            return null;
        }

        let value: string;
        const lcKey: string = key.toLowerCase();

        Object.keys(object).some((k: string) => {
            if (k.toLowerCase() === '_router') {
                return false;
            }

            if (k.toLowerCase() === '_dataservice') {
                return false;
            }

            if (k.toLowerCase() === 'apidistributiongroupservice') {
                return false;
            }

            if (k.toLowerCase() === 'apidomainservice') {
                return false;
            }

            if (k.toLowerCase() === lcKey) {
                value = object[k];

                return true;
            }
            if (object[k] && typeof object[k] === 'object') {
                value = this._findValLCase(object[k], lcKey);

                return value !== undefined;
            }
        });

        return value;
    }
}
