import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { AbstractPopup } from '../../../../../../feature-modules/popups/classes/abstract-popup.class';
import { IPopupEventHandler } from '../../../../../../feature-modules/popups/interfaces/popup-event-handler.interface';
import { MfaCommand } from '../../enums/mfa-command.enum';
import { IMFAVerificationWindowData } from '../../mfa-verification-window-data.interface';

import { IConfigureMfaVerificationCodePopupParams } from './configure-mfa-verification-code-popup-params.interface';

@Component({
    selector: 'cm-configure-mfa-verification-code-popup',
    templateUrl: './configure-mfa-verification-code-popup.component.html',
    styleUrls: ['./configure-mfa-verification-code-popup.component.scss'],
})
export class ConfigureMfaVerificationCodePopupComponent extends AbstractPopup<IConfigureMfaVerificationCodePopupParams> {
    mfaVerificationData: IMFAVerificationWindowData;
    eventHandler: IPopupEventHandler;
    verificationCodeFormControl: UntypedFormControl = new UntypedFormControl('');

    get isVerifying(): boolean {
        return (
            !this.mfaVerificationData?.errorData?.isError && this.mfaVerificationData?.isVerifying
        );
    }

    updateParams(val: IConfigureMfaVerificationCodePopupParams): void {
        this.mfaVerificationData = val.mfaVerificationData;
    }

    resetData(): void {
        this.verificationCodeFormControl.setValue('');
    }

    onClose(): void {
        this.eventHandler.executeCommand(MfaCommand.cancel, null);
        this.close();
    }

    onVerify(): void {
        this.eventHandler.executeCommand(MfaCommand.verify, this.verificationCodeFormControl.value);
    }
}
