import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import {
    AgreementServiceResellerValidityFacadeService,
} from '../../../../../core/services/agreement-service-reseller-validity-facade.service';
import { ApiPermissionsService } from '../../../../../core/services/api/api-permissions.service';
import { ApiAuthenticationService } from '../../../../../core/services/api/authentication/api-authentication.service';
import { ApiLoginService } from '../../../../../core/services/api/login/api-login.service';
import { ApiUserService } from '../../../../../core/services/api/user/api-user.service';
import { SSO } from '../../../../../shared/constants/sso.constants';
import { IAuthorizeResponse } from '../../../interfaces/authorize-response.interface';
import { ILoginUserResponse } from '../../../interfaces/login-user-response.interface';
import { ILogin } from '../../../interfaces/login.interface';
import { LoginStorageService } from '../storage/login-storage.service';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    constructor(
        private _apiUserService: ApiUserService,
        private _apiAuthenticationService: ApiAuthenticationService,
        private _translateService: TranslateService,
        private _apiPermissionsService: ApiPermissionsService,
        private _resellerAgreementValidityFacadeService: AgreementServiceResellerValidityFacadeService,
        private _loginStorageService: LoginStorageService,
        private _apiLoginService: ApiLoginService
    ) {
    }

    getLoginFlow$(userName: string): Observable<string> {
        return this._apiUserService.getLoginFlow$(userName)
            .pipe(map((loginFlow: string) => {
                if (loginFlow === SSO.LOGIN_FLOW.UNKNOWN) {
                    throw new Error(this._translateService.instant('LOGIN.USERNAME_INVALID'));
                }

                return loginFlow;
            }));
    }

    sysOpsLoginToken$(loginModel: ILogin): Observable<void> {
        return this._apiAuthenticationService.sysOpsLoginToken$(loginModel)
            .pipe(this._setAndSaveAuthorisationData());
    }

    login$(loginModel: ILogin): Observable<void> {
        return this._apiAuthenticationService.login$(loginModel)
            .pipe(this._setAndSaveAuthorisationData());
    }

    getExchangedToken$(ssoIdToken: string): Observable<void> {
        return this._apiAuthenticationService.getExchangedToken$(ssoIdToken)
            .pipe(this._setAndSaveAuthorisationData());
    }

    ms365Login$(idToken: string): Observable<void> {
        return this._apiAuthenticationService.ms365Login$(idToken)
            .pipe(this._setAndSaveAuthorisationData());
    }

    getLoginUser$(): Observable<ILoginUserResponse> {
        return this._apiLoginService.getLoginUser$()
            .pipe(
                tap((loginUserResponse: ILoginUserResponse) => {
                    this._resellerAgreementValidityFacadeService.setMustSignNow(loginUserResponse.mustSignIn);
                    this._loginStorageService.setUserLoginDataInLocalStorage(loginUserResponse);
                })
            );
    }

    private _setAndSaveAuthorisationData(): (
        source$: Observable<IAuthorizeResponse>,
    ) => Observable<void> {
        return (source$: Observable<IAuthorizeResponse>): Observable<void> =>
            source$.pipe(tap((authorizeResponse: IAuthorizeResponse) => {
                this._apiPermissionsService.clearPermissions();
                this._resellerAgreementValidityFacadeService.clearAgreementState();
                this._loginStorageService.setupAuthorizeLocalStorage(authorizeResponse);
            }),
                switchMap(() => of(null)));
    }
}
