import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { SsoAuthService } from '../../../../../core/services/sso-auth/sso-auth.service';
import { ResetPasswordStateModel } from '../../../../../models/user/password.model';
import { ROUTES_PATHS } from '../../../../../shared/constants/routes.constants';
import { IErrorData } from '../../../../../shared/interfaces/error-data.interface';
import { PasswordService } from '../../../../../shared/services/password.service';
import { ILoginStrategy } from '../../../interfaces/login-strategy.interface';
import { ILogin } from '../../../interfaces/login.interface';
import { AuthService } from '../../auth/auth.service';

import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root',
})
export class LoginNonSsoService implements ILoginStrategy {

    constructor(
        private _loginService: LoginService,
        private _ssoAuthService: SsoAuthService,
        private _router: Router,
        private _passwordService: PasswordService,
        private _authService: AuthService,
    ) {
    }

    login$(loginData?: ILogin): Observable<void> {
        if (loginData) {
            return this._loginService.login$(loginData)
                .pipe(
                    catchError((error: IErrorData) => {
                        if (this._isErrorHandled(error)) {
                            return EMPTY;
                        }

                        return throwError(error);
                    }),
                    tap(() => {
                        this._ssoAuthService.setLegacyLoginFlow();
                    }),
                );
        }

        if (this._authService.tokenNotExpired()) {
            return of(null);
        }

        return EMPTY;
    }

    private _isErrorHandled(error: IErrorData | null): boolean {
        const errorDescription: string = error?.error_description || '';

        if (errorDescription.startsWith('resetrequired')) {
            this._resetPasswordRequired(errorDescription);

            return true;
        }

        return false;
    }

    private _resetPasswordRequired(errorMessage: string): void {
        const usertype: string = errorMessage.split('|')[1];
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        const username: string = errorMessage.split('|')[2];

        this._passwordService.setPasswordState(
            new ResetPasswordStateModel(null, username, usertype),
        );
        this._router.navigate([ROUTES_PATHS.CHANGE_PASSWORD]);
    }
}
