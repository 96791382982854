import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { PopUpDetails } from '../../../../feature-modules/popups/classes/pop-up-details.class';
import { PopupService } from '../../../../feature-modules/popups/services/popup.service';
import { GAFormModule } from '../../../../shared/directives/ga-form/ga-form.module';
import { TrustHtmlModule } from '../../../../shared/pipes/trust-html/trust-html.module';
import { CmAlertModule } from '../../cm-alert/cm-alert.module';
import { CmModalModule } from '../../cm-modal/modal.module';
import { CheckboxInputModule } from '../../forms/checkbox-input/cm-check-box-input.module';
import { WaitSpinnerModule } from '../../wait-spinner/wait-spinner.module';

import { CloudMarketPopupComponent } from './cm-popup.component';
import { CloudMarketPopupTextInputComponent } from './components/text-input/cm-popup-text-input.component';

@NgModule({
    declarations: [CloudMarketPopupComponent, CloudMarketPopupTextInputComponent],
    imports: [
        CmAlertModule,
        CmModalModule,
        CommonModule,
        FormsModule,
        CheckboxInputModule,
        GAFormModule,
        TranslateModule,
        TrustHtmlModule,
        WaitSpinnerModule,
    ],
    exports: [CloudMarketPopupComponent],
})
export class CloudMarketPopupModule {
    static popupRegistered: boolean = false;
    constructor(private _popupService: PopupService) {
        if (CloudMarketPopupModule.popupRegistered) {
            return;
        }

        CloudMarketPopupModule.popupRegistered = true;

        this._popupService.registerPopups({
            'cm-popup': new PopUpDetails(CloudMarketPopupComponent, 'shared', 'miscellaneous'),
        });
    }
}
