import { NgModule } from '@angular/core';

import { SsoAuthModule } from '../../core/services/sso-auth/sso-auth.module';
import { CmAlertModule } from '../../new-shared/components/cm-alert/cm-alert.module';
import { CheckboxInputModule } from '../../new-shared/components/forms/checkbox-input/cm-check-box-input.module';
import { ConfigureMfaPopupModule } from '../../new-shared/components/modals/mfa/components/configure-mfa/configure-mfa-popup.module';
import {
    ConfigureMfaMoreInformationPopupModule
} from '../../new-shared/components/modals/mfa/components/configure-mfa-more-information/configure-mfa-more-information-popup.module';
import {
    ConfigureMfaVerificationCodePopupModule
} from '../../new-shared/components/modals/mfa/components/configure-mfa-verification-code/configure-mfa-verification-code-popup.module';
import { WaitSpinnerModule } from '../../new-shared/components/wait-spinner/wait-spinner.module';
import { PasswordService } from '../../shared/services/password.service';
import { SharedModule } from '../../shared/shared.module';
import { LayoutModule } from '../layout/layout.module';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthenticationComponent } from './pages/authentication/authentication.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { HandleExtAuthComponent } from './pages/handle-ext-auth/handle-ext-auth.component';
import { LinkAccountComponent } from './pages/link-account/link-account.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthErrorModalModule } from './pages/modals/auth-error/auth-error-modal.module';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SwitchUserPopupModule } from './pages/sign-in/components/switch-user/switch-user-popup.module';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SharedSessionCookieStorageService } from './services/cookie/shared-session-cookie-storage.service';

@NgModule({
    declarations: [
        ChangePasswordComponent,
        HandleExtAuthComponent,
        LinkAccountComponent,
        SignInComponent,
        AuthenticationComponent,
        LoginComponent,
        ResetPasswordComponent,
    ],
    imports: [
        SharedModule,
        AuthRoutingModule,
        LayoutModule,
        CheckboxInputModule,
        CmAlertModule,
        WaitSpinnerModule,
        SsoAuthModule.forRoot(),
        ConfigureMfaPopupModule,
        ConfigureMfaMoreInformationPopupModule,
        ConfigureMfaVerificationCodePopupModule,
        SwitchUserPopupModule,
        AuthErrorModalModule
    ],
    providers: [PasswordService, SharedSessionCookieStorageService],
    exports: [AuthRoutingModule],
})
export class AuthModule {}
